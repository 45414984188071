
import { upper_teeth, lower_teeth } from "../redux/customeTeeth/init_teeth";

export function mapIPRTeethFromApi(data) {
    let new_lower_teeth = data.lower_teeth;
    let new_upper_teeth = data.upper_teeth;
    //add images to object
    for (var key in new_lower_teeth) {
        new_lower_teeth[key]["active"] = lower_teeth[key].active;
        new_lower_teeth[key]["not_active"] = lower_teeth[key].not_active;
        new_lower_teeth[key]["hover"] = lower_teeth[key].hover;
        new_lower_teeth[key]["image"] = lower_teeth[key].image;
    }
    //add images to object
    for (var key in new_upper_teeth) {
        new_upper_teeth[key]["active"] = upper_teeth[key].active;
        new_upper_teeth[key]["not_active"] = upper_teeth[key].not_active;
        new_upper_teeth[key]["hover"] = upper_teeth[key].hover;
        new_upper_teeth[key]["image"] = upper_teeth[key].image;
    }
    return { lower_teeth: new_lower_teeth, upper_teeth: new_upper_teeth }
}