export const history = {
  "aligner_case.created": "Case has been created",
  "aligner_case.submit": "Case has been submitted",
  "aligner_case.courier_notified": "Pickup has been arranged",
  "aligner_case.rejected": "Records have been rejected",
  "aligner_case.updated": "Case has been updated",
  "aligner_case.approved": "Records have been approved",
  "aligner_case.comment_posted": "Comment has been posted ",
  "aligner_case.doctor_notified_of_ts": "notified of treatment setup",
  "aligner_case.rejected_ts": "Treatment Setup has been rejected",
  "aligner_case.approved_ts": "Treatment Setup has been approved",
  "aligner_case.cleared_by_accounting": "Case has been cleared by accounting",
  "aligner_case.paused": "Case has been paused",
  "aligner_case.resumed": "Case has been resumed",
  "aligner_case.shipped": "Aligners has been marked as shipped",
  "aligner_case.retainer_requested": "Retainer requested",
  "aligner_case.replacement_aligners_requested":
    "Replacement aligners have been requested",
  "aligner_case.replacement_aligners_shipped":
    "Replacement aligners have been shipped",
  "aligner_case.adjustment_requested": "Adjustment has been requested",
  "aligner_case.retainer_shipped": "Retainer shipped",
};
