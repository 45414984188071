import React, {useState} from 'react'
import viewer from "../../../TS-VIEWER/viewer";
import RejectModel from "./reject-model"
import styles from "../eonAccess-style/eonAccess.module.scss";
function Revise(props) {
    const {
        tsOptions,
        setTSOptions,
    } = props;
    // console.log(props.revisePhoto, 'rev')
    const [description, setDescription] = useState("")
    const [viewRevise, setViewRevise] = useState("front");
    const [reviseImg, setreviseImg] = useState(props.revisePhoto.frontal_thumb)
    const [viewModel, setViewModel] = useState(false)
    const [viewTextArea, setViewTextArea] = useState(false)
    
    const onChange = (action) => {
        switch (action.viewRevise) {
        case "left-view":
            if (!props.isTSViewerFound) return;
            setViewRevise("left");
            setreviseImg(props.revisePhoto.left_buccal_thumb)
            setTSOptions((prevOptions) => ({
            ...prevOptions,
            showLowerArc: true,
            showUpperArc: true,
            }));
            viewer.leftRevise();
            break;
        case "right-view":
            if (!props.isTSViewerFound) return;
            setViewRevise("right");
            setreviseImg(props.revisePhoto.right_buccal_thumb)
            setTSOptions((prevOptions) => ({
            ...prevOptions,
            showLowerArc: true,
            showUpperArc: true,
            }));
            viewer.rightRevise();
            break;
        case "front-view":
            if (!props.isTSViewerFound) return;
            setViewRevise("front");
            setreviseImg(props.revisePhoto.frontal_thumb)
            setTSOptions((prevOptions) => ({
            ...prevOptions,
            showLowerArc: true,
            showUpperArc: true,
            showUpper: true,
            showLower: true,
            }));
            viewer.frontRevise();
            break;
        default:
            if (!tsOptions.isTSViewerFound) return;
            setViewRevise("front");
            setreviseImg(props.revisePhoto.frontal_thumb)
            setTSOptions((prevOptions) => ({
            ...prevOptions,
            showLowerArc: true,
            showUpperArc: true,
            }));
            viewer.frontRevise();
        }
  };
  const handleClick = () => {
    props.saveRejectionReason(description)
    props.setRevise(!props.revise)
    props.frontView()
}
    return (
        <>
        <div className={styles.revise_contaner}>
        <div className={styles.revise_img_contaner}>
                <img className={styles.revise_img} src={reviseImg} />
        </div>
        <div className={styles.revise_nav}>
                <div className={[
                    styles.revise_nav_card,
                    viewRevise === "right" ? styles.revise_nav_card_active : "",
                    ].join(" ")} onClick={() => onChange({ viewRevise: "right-view"})}>Right Buccal
                        <img className={styles.revise_nav_img} src={`${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/right_beccal.png`} />
                    </div>
                <div className={[
                    styles.revise_nav_card,
                    viewRevise === "front" ? styles.revise_nav_card_active : "",
                    ].join(" ")} onClick={() => onChange({ viewRevise: "front-view"})}>Frontal
                        <img className={styles.revise_nav_img} src={`${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/frontal.png`} />
                    </div>
                <div className={[
                    styles.revise_nav_card,
                    viewRevise === "left" ? styles.revise_nav_card_active : "",
                    ].join(" ")} onClick={() => onChange({ viewRevise: "left-view"})}>Left Buccal
                        <img className={styles.revise_nav_img} src={`${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/left_beccal.png`} />
                    </div>
            </div>  
        <div className={styles.revise_btn_contaner}>
                <button className={[
                    styles.recject_btn,
                    viewModel ? styles.recject_btn_active : "",
                        ].join(" ")}
                        onClick={() => {
                            setViewModel(!viewModel)
                        }}>{"Reject Bite Record"}</button>
            </div>
            <div className={styles.options_btn_contanier}>
                <button onClick={handleClick} className={styles.options_btn}>{"Tooth & IRP Options >"}</button> 
            </div>
            {viewModel ?
            <RejectModel 
                description={description}
                setDescription={setDescription}
                viewModel={viewModel}
                setViewModel={setViewModel}
                viewTextArea={viewTextArea}
                setViewTextArea={setViewTextArea}
            /> : <></>}
            
        </div>
        {viewTextArea ?
            <div className={styles.revise_textarea_contaner}>
                 <textarea className={styles.textarea_preview} disabled autoFocus rows={5} cols={75} placeholder={"Reason for rejection"} value={description} style={{color: 'black'}}>
                {description}
            </textarea> 
            </div> : <></>}
        </>
    )
}

export default Revise
