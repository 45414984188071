import React, { Component } from "react";
import {
    CardBody,
    CardTitle,
    CardFooter,
    Button,
    Card,
    CardImg,
    Input,
    Table
} from "reactstrap";
import ToggleSwitch from "../../../components/Switch/Switch";

export default class ToothMovement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LeftRight: Number(this.props.data.LeftRight.split(" ")[0]),
            Rotation: Number(this.props.data.Rotation.split(" ")[0]),
            Angulation: Number(this.props.data.Angulation.split(" ")[0]),
            Inclination: Number(this.props.data.Inclination.split(" ")[0]),
            ExtrusionIntrusion: Number(this.props.data.ExtrusionIntrusion.split(" ")[0]),
            ForwardBackward: Number(this.props.data.ForwardBackward.split(" ")[0]),
            ToothName: this.props.data.ToothName,
            use_extraction: this.props.data.use_extraction,
            useAttachments: this.props.data.useAttachments,
            attachments_step: Number(this.props.data.attachments_step),
            attachment_type: this.props.data.attachment_type,
            usePontics: this.props.data.usePontics,
            useTorqueEnhancers: this.props.data.useTorqueEnhancers,
            use_buttons: this.props.data.use_buttons,
            use_cuts: this.props.data.use_cuts,
            note: this.props.data.note,
            ToothNumber: this.props.data.ToothNumber
        }
    }

    saveToothMovementData = () => {
        let new_data = {
            ...this.props.data,
            LeftRight: this.props.data.LeftRight ? this.state.LeftRight + " mm" : "",
            Rotation: this.props.data.Rotation ? this.state.Rotation + " deg" : "",
            Angulation: this.props.data.Angulation ? this.state.Angulation + " deg" : "",
            Inclination: this.props.data.Inclination ? this.state.Inclination + " deg" : "",
            ExtrusionIntrusion: this.props.data.ExtrusionIntrusion ? this.state.ExtrusionIntrusion + " mm" : "",
            ForwardBackward: this.props.data.ForwardBackward ? this.state.ForwardBackward + " mm" : "",
            ToothName: this.state.ToothName,
            use_extraction: this.state.use_extraction,
            useAttachments: this.state.useAttachments,
            attachments_step: this.props.data.attachments_step ? this.state.attachments_step.toString() : "",
            attachment_type: this.state.attachment_type,
            note: this.state.note,
            usePontics: this.state.usePontics,
            useTorqueEnhancers: this.state.useTorqueEnhancers,
            use_buttons: this.state.use_buttons,
            use_cuts: this.state.use_cuts,
            updated: true,
        }

        this.props.saveToothMovementData(new_data)
    }
    render() {
        const { ToothName, LeftRight, Rotation, Angulation, Inclination, ExtrusionIntrusion, ForwardBackward, use_extraction
            , useAttachments
            , attachments_step
            , attachment_type
            , usePontics
            , useTorqueEnhancers
            , use_buttons
            , use_cuts
            , note
            , ToothNumber } = this.state;

        return (

            <Card className="mt-3">
                <CardTitle className="border-bottom p-2 mb-0 detailes_title case-title">
                    Tooth Number   {ToothNumber}
                </CardTitle>
                <CardBody className="p-0" >
                    <div className="movement_tables_grid">
                        <div className="small-movement-table">
                            <Table className="p-0" responsive bordered height="100" size="sm">
                                <thead>
                                    <tr>
                                        <th>Movement</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="td-grid"><span>Rotation : </span> <span className="sub-title-tooth">{` Distal(+) / Mesial(-)`}</span></td>
                                        <td >
                                            <div className="movement-num-detailes form-material">
                                                <div className="auto_grid6">
                                                    <i onClick={() => {
                                                        var value = Rotation + 0.1;
                                                        this.setState({ Rotation: Number(value.toFixed(1)) })
                                                    }} className="fas fa-angle-up num-angle"></i>
                                                    <i onClick={() => {

                                                        var value = Rotation - 0.1;
                                                        this.setState({ Rotation: Number(value.toFixed(1)) })
                                                    }} className={`fas fa-angle-down num-angle`}></i>

                                                </div>
                                                <Input type="number" value={Rotation} className="hide-arrows"
                                                    step="0.1" onChange={(e) => {

                                                        this.setState({ Rotation: Number(e.target.value) })

                                                    }} />
                                                <div> deg</div></div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="td-grid"><span>Angulation  :</span> <span className="sub-title-tooth">Toward midline(+) /<br></br> Away from midline(-)</span></td>
                                        <td>
                                            <div className="movement-num-detailes form-material">
                                                <div className="auto_grid6">
                                                    <i onClick={() => {
                                                        var value = Angulation + 0.1;
                                                        this.setState({ Angulation: Number(value.toFixed(1)) })
                                                    }} className="fas fa-angle-up num-angle"></i>
                                                    <i onClick={() => {
                                                        var value = Angulation - 0.1;
                                                        this.setState({ Angulation: Number(value.toFixed(1)) })
                                                    }} className={`fas fa-angle-down num-angle`}></i>

                                                </div>
                                                <Input type="number" value={Angulation} className="hide-arrows"
                                                    step="0.1" onChange={(e) => {
                                                        this.setState({ Angulation: Number(e.target.value) })
                                                    }} />
                                                <div>deg</div></div>


                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="td-grid"><span>Torque :</span><span className="sub-title-tooth">{` Labial(+) / Lingual(-)`}</span></td>
                                        <td>
                                            <div className="movement-num-detailes form-material">
                                                <div className="auto_grid6">
                                                    <i onClick={() => {
                                                        var value = Inclination + 0.1;
                                                        this.setState({ Inclination: Number(value.toFixed(1)) })
                                                    }} className="fas fa-angle-up num-angle"></i>
                                                    <i onClick={() => {
                                                        var value = Inclination - 0.1;
                                                        this.setState({ Inclination: Number(value.toFixed(1)) })
                                                    }} className={`fas fa-angle-down num-angle`}></i>

                                                </div>
                                                <Input type="number" value={Inclination} className="hide-arrows"
                                                    step="0.1" onChange={(e) => {
                                                        this.setState({ Inclination: Number(e.target.value) })
                                                    }} />
                                                <div>deg</div></div>


                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="small-movement-table">
                            <Table className="p-0" responsive bordered height="100" size="sm">
                                <thead>
                                    <tr>
                                        <th>Movement</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{`Mesial(+) / Distal(-)`}</td>
                                        <td>
                                            <div className="movement-num-detailes form-material">
                                                <div className="auto_grid6">
                                                    <i onClick={() => {
                                                        var value = LeftRight + 0.1;
                                                        this.setState({ LeftRight: Number(value.toFixed(1)) })
                                                    }} className="fas fa-angle-up num-angle"></i>
                                                    <i onClick={() => {
                                                        var value = LeftRight - 0.1;
                                                        this.setState({ LeftRight: Number(value.toFixed(1)) })
                                                    }} className={`fas fa-angle-down num-angle`}></i>

                                                </div>
                                                <Input type="number" value={LeftRight} className="hide-arrows"
                                                    step="0.1" onChange={(e) => {
                                                        this.setState({ LeftRight: Number(e.target.value) })
                                                    }} />
                                                <div>mm</div></div>


                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{`Intrusion(+) / Extrusion(-)`}</td>
                                        <td>
                                            <div className="movement-num-detailes form-material">
                                                <div className="auto_grid6">
                                                    <i onClick={() => {
                                                        var value = ExtrusionIntrusion + 0.1;
                                                        this.setState({ ExtrusionIntrusion: Number(value.toFixed(1)) })
                                                    }} className="fas fa-angle-up num-angle"></i>
                                                    <i onClick={() => {
                                                        var value = ExtrusionIntrusion - 0.1;
                                                        this.setState({ ExtrusionIntrusion: Number(value.toFixed(1)) })
                                                    }} className={`fas fa-angle-down num-angle`}></i>

                                                </div>
                                                <Input type="number" value={ExtrusionIntrusion} className="hide-arrows"
                                                    step="0.1" onChange={(e) => {
                                                        this.setState({ ExtrusionIntrusion: Number(e.target.value) })
                                                    }} />
                                                <div>mm</div></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{`Out(+) / In(-)`}</td>
                                        <td>
                                            <div className="movement-num-detailes form-material">
                                                <div className="auto_grid6">
                                                    <i onClick={() => {
                                                        var value = ForwardBackward + 0.1;
                                                        this.setState({ ForwardBackward: Number(value.toFixed(1)) })
                                                    }} className="fas fa-angle-up num-angle"></i>
                                                    <i onClick={() => {
                                                        var value = ForwardBackward - 0.1;
                                                        this.setState({ ForwardBackward: Number(value.toFixed(1)) })
                                                    }} className={`fas fa-angle-down num-angle`}></i>

                                                </div>
                                                <Input type="number" value={ForwardBackward} className="hide-arrows"
                                                    step="0.1" onChange={(e) => {
                                                        this.setState({ ForwardBackward: Number(e.target.value) })
                                                    }} />
                                                <div>mm</div></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className="movement-modules-grid">
                        <div>



                            <div className="auto_grid5 pb-2">
                                <div className="control-label title_active-gco">
                                    Attachments
                                </div>
                                <div>
                                    <ToggleSwitch
                                        id="useAttachments"
                                        checked={useAttachments}
                                        onChange={() => {
                                            this.setState({ useAttachments: !useAttachments })
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="auto_grid5 pb-2">
                                <div className="control-label title_active-gco">
                                    Attachments Step
                                </div>
                                <div className="number-ipr-detailes form-material">
                                    <Input type="number" value={attachments_step} className="hide-arrows" min="0"
                                        step="1" onChange={(e) => {

                                            if (e.target.value < 0) {
                                                this.setState({ attachments_step: 0 })
                                            } else {
                                                this.setState({ attachments_step: Number(e.target.value) })
                                            }

                                        }} />
                                    <div className="auto_grid6">


                                        <i onClick={() => {
                                            this.setState({ attachments_step: Number(attachments_step) + 1 })
                                        }} className="fas fa-angle-up num-angle"></i>
                                        <i onClick={() => {
                                            if (attachments_step > 0) {
                                                this.setState({ attachments_step: Number(attachments_step) - 1 })
                                            }

                                        }} className={`fas fa-angle-down ${attachments_step > 0 ? "num-angle" : "disable-num-angle"}  `}></i>

                                    </div>
                                </div>
                            </div>
                            <div className=" pb-2">
                                <div className="control-label title_active-gco pb-2">
                                    Attachments Type
                                </div>

                                <div className="">
                                    <Input type="textarea" rows={2} value={attachment_type} onChange={(e) => {
                                        this.setState({ attachment_type: e.target.value })
                                    }} />
                                </div>

                            </div>

                        </div>

                        <div>
                            <div className="auto_grid5 pb-2">
                                <div className="control-label title_active-gco">
                                    Extraction
                                </div>
                                <div>
                                    <ToggleSwitch
                                        id="use_extraction"
                                        checked={use_extraction}
                                        onChange={() => {
                                            this.setState({ use_extraction: !use_extraction })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="auto_grid5 pb-2">
                                <div className="control-label title_active-gco">
                                    Pontics
                                </div>
                                <div>
                                    <ToggleSwitch
                                        id="usePontics"
                                        checked={usePontics}
                                        onChange={() => {
                                            this.setState({ usePontics: !usePontics })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="auto_grid5 pb-2">
                                <div className="control-label title_active-gco">
                                    Torque Enhancers
                                </div>
                                <div>
                                    <ToggleSwitch
                                        id="useTorqueEnhancers"
                                        checked={useTorqueEnhancers}
                                        onChange={() => {
                                            this.setState({ useTorqueEnhancers: !useTorqueEnhancers })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="auto_grid5 pb-2">
                                <div className="control-label title_active-gco">
                                    Elastics Cuts
                                </div>
                                <div>
                                    <ToggleSwitch
                                        id="use_cuts"
                                        checked={use_cuts}
                                        onChange={() => {
                                            this.setState({ use_cuts: !use_cuts })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="auto_grid5 pb-2">
                                <div className="control-label title_active-gco">
                                    Elastics Buttons
                                </div>
                                <div>
                                    <ToggleSwitch
                                        id="use_buttons"
                                        checked={use_buttons}
                                        onChange={() => {
                                            this.setState({ use_buttons: !use_buttons })
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="actions-grid">
                            <div><Button
                                className={`btn h-100  actions-btn ${"preference-btn"}  float-right text-center  btn-radius`}
                                size="sm"
                                type="button"
                                onClick={() => { this.saveToothMovementData() }}  >
                                Save Changes  </Button></div>

                            <div><Button
                                className={`btn h-100 actions-btn  ${"preference-btn"}  float-right text-center  btn-radius`}
                                size="sm"
                                type="button"
                                onClick={() => { this.props.cancelChangesOnToothMovement() }}  >
                                Cancel  </Button>

                            </div>
                        </div>
                    </div>
                    <div className="movement-note-modules-grid">
                        <div className=" pb-2">
                            <div className="control-label title_active-gco pb-2">
                                Notes
                                </div>

                            <div className="">
                                <Input type="textarea" rows={4} value={note} onChange={(e) => {
                                    this.setState({ note: e.target.value })
                                }} />
                            </div>

                        </div>
                        <div></div>
                    </div>

                </CardBody>
            </Card>);
    }
}