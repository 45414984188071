import active_front_w from "./active_front_w.png";
import active_front from "./active_front.png";
import active_left_w from "./active_left_w.png";
import active_left from "./active_left.png";
import active_lower_w from "./active_lower_w.png";
import active_lower from "./active_lower.png";
import active_right_w from "./active_right_w.png";
import active_right from "./active_right.png";
import active_upper_w from "./active_upper_w.png";
import active_upper from "./active_upper.png";
import arrow_left_w from "./arrow_left_w.png";
import arrow_left from "./arrow_left.png";
import attachments_w from "./attachments_w.png";
import attachments from "./attachments.png";
import baseline_keyboard_arrow_left_black from "./baseline_keyboard_arrow_left_black.png";
import baseline_keyboard_arrow_left_white from "./baseline_keyboard_arrow_left_white.png";
import baseline_keyboard_arrow_right_black from "./baseline_keyboard_arrow_right_black.png";
import baseline_keyboard_arrow_right_white from "./baseline_keyboard_arrow_right_white.png";
import baseline_pause_black from "./baseline_pause_black.png";
import baseline_pause_white from "./baseline_pause_white.png";
import baseline_play_arrow_black from "./baseline_play_arrow_black.png";
import baseline_play_arrow_white from "./baseline_play_arrow_white.png";
import baseline_skip_next_black from "./baseline_skip_next_black.png";
import baseline_skip_next_white from "./baseline_skip_next_white.png";
import baseline_skip_previous_black from "./baseline_skip_previous_black.png";
import baseline_skip_previous_white from "./baseline_skip_previous_white.png";
import dark_BG from "./dark_BG.png";
import eon_access_logo from "./eon-access-logo.png";
import front_w from "./front_w.png";
import front from "./front.png";
import left_w from "./left_w.png";
import left from "./left.png";
import lower_w from "./lower_w.png";
import lower from "./lower.png";
import right_w from "./right_w.png";
import right from "./right.png";
import super_w from "./super_w.png";
import Super from "./super.png";
import upper_w from "./upper_w.png";
import upper from "./upper.png";
export default {
  active_front_w,
  active_front,
  active_left_w,
  active_left,
  active_lower_w,
  active_lower,
  active_right_w,
  active_right,
  active_upper_w,
  active_upper,
  arrow_left_w,
  arrow_left,
  attachments_w,
  attachments,
  baseline_keyboard_arrow_left_black,
  baseline_keyboard_arrow_left_white,
  baseline_keyboard_arrow_right_black,
  baseline_keyboard_arrow_right_white,
  baseline_pause_black,
  baseline_pause_white,
  baseline_play_arrow_black,
  baseline_play_arrow_white,
  baseline_skip_next_black,
  baseline_skip_next_white,
  baseline_skip_previous_black,
  baseline_skip_previous_white,
  dark_BG,
  eon_access_logo,
  front_w,
  front,
  left_w,
  left,
  lower_w,
  lower,
  right_w,
  right,
  super_w,
  Super,
  upper_w,
  upper,
};
