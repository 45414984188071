import React, { Component } from "react";
import { connect } from "react-redux";
import CustomeTeeth from "../../../../Teeth/CustomeTeeth";

import { CustomInput } from "reactstrap";
import {
  clearMalocclusionData
} from "../../../../../redux/CaseSubmission/action";
import {
  Clear_Teeth_Data
} from "../../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../../components/TreatmentOptions/TreatmentOptions";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  clearMalocclusionData: (payload) => dispatch(clearMalocclusionData(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload))

});
class ClassII extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.use_elastics =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classII
        ?.useElasticWhenCorrecting || false;

    this.extractionTwoUpperBicuspids =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classII
        ?.extractionTwoUpperBicuspids || false;

    this.extractionUpperLowerBicuspids =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classII
        ?.extractionUpperLowerBicuspids || false;

    this.upperArchDistalization =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classII
        ?.upperArchDistalization || false;

    this.lowerArchMesialization =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classII
        ?.lowerArchMesialization || false;

    this.leavingMolars =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classII
        ?.leavingMolars || false;

    this.maintain =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classII
        ?.maintain || false;
  }

  UpdateCrossBiteReducer = () => {
    let data = {
      useElasticWhenCorrecting: this.use_elastics,
      teeth: this.props.customeTeethReducer.Case_ClassII,
      extractionTwoUpperBicuspids: this.extractionTwoUpperBicuspids,
      extractionUpperLowerBicuspids: this.extractionUpperLowerBicuspids,
      upperArchDistalization: this.upperArchDistalization,
      lowerArchMesialization: this.lowerArchMesialization,
      leavingMolars: this.leavingMolars,
      maintain: this.maintain,
    };
console.log(this.props.customeTeethReducer, "class11");
    this.props.save("classII", data);
  };

  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.use_elastics = value
  }
  render() {
    const {
      use_elastics,
      extractionTwoUpperBicuspids,
      extractionUpperLowerBicuspids,
      upperArchDistalization,
      lowerArchMesialization,
      leavingMolars,
      maintain,
    } = this;
    return (
      <div>
        <div className="malocclusion-title">
          ClassII
    {!this.props.summary && <span
            className="close-btn pull-right"
            onClick={() => {
              this.props.clearMalocclusionData("classII")
              this.props.Clear_Teeth_Data("Case_ClassII")

              this.props.toggleComponents();
            }}
          >
            &times;
          </span>
          }
        </div>
        <div className=" pt-4">

          <div className="text-center">
            <div className="control-label title_active-gco text-center mb-2">
              Use elastics when correcting{" "}
            </div>
            <TreatmentOptions value={use_elastics} name="classII" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_elastics")
              if (value !== true) {
                this.props.Clear_Teeth_Data("Case_ClassII")
              }
              this.UpdateCrossBiteReducer();

            }} />
          </div>

          <div>
            <div className="control-label title_active-gco text-center pb-2 mt-2 ">
              Placement
    </div> <div className="teeth_selector">
              <CustomeTeeth
                action_key="Case_ClassII"
                disabled={!use_elastics || use_elastics === "eonToDecide"}
                key={"Case_ClassII"}
                UpdateCrossBiteReducer={this.UpdateCrossBiteReducer}
                summary={this.props.summary}

              />
            </div>
          </div>
        </div>


        <div className="p-2">
          <div className="control-label title_active-gco">
            Treatment options{" "}
          </div>
          {/* 1 */}
          <CustomInput
            className={`pt-1 ${extractionTwoUpperBicuspids && "replacment-checkbox"
              }`}
            type="checkbox"
            id="extractionTwoUpperBicuspids"
            label="Extraction of two upper bicuspids"
            checked={extractionTwoUpperBicuspids}
            disabled={
              maintain ||
              leavingMolars ||
              upperArchDistalization ||
              extractionUpperLowerBicuspids || this.props.summary
            }
            onChange={() => {
              this.extractionTwoUpperBicuspids = !this
                .extractionTwoUpperBicuspids;
              this.UpdateCrossBiteReducer();
            }}
          />
          {/* 2 */}

          <CustomInput
            className={`pt-1 ${extractionUpperLowerBicuspids && "replacment-checkbox"
              }`}

            type="checkbox"
            id="extractionUpperLowerBicuspids"
            label="Extraction of upper and lower bicuspids"
            checked={extractionUpperLowerBicuspids}
            disabled={
              maintain ||
              leavingMolars ||
              upperArchDistalization ||
              extractionTwoUpperBicuspids || this.props.summary
            }
            onChange={() => {
              this.extractionUpperLowerBicuspids = !this
                .extractionUpperLowerBicuspids;
              this.UpdateCrossBiteReducer();
            }}
          />
          {/* 3 */}

          <CustomInput
            className={`pt-1 ${upperArchDistalization && "replacment-checkbox"
              }`}

            type="checkbox"
            id="upperArchDistalization"
            label="Upper arch distalization"
            checked={upperArchDistalization}
            disabled={
              maintain ||
              leavingMolars ||
              extractionUpperLowerBicuspids ||
              extractionTwoUpperBicuspids || this.props.summary
            }
            onChange={() => {
              this.upperArchDistalization = !this.upperArchDistalization;
              this.UpdateCrossBiteReducer();
            }}
          />
          <div className="checkbox-hint pl-4">
            sequential distalization- may increase number of steps - elastics
            using" , May necessitate extraction of wisdoms if present
          </div>
          {/* 4 */}

          <CustomInput
            className={`pt-1 ${lowerArchMesialization && "replacment-checkbox"
              }`}

            type="checkbox"
            id="lowerArchMesialization"
            label="Lower arch mesialization "
            disabled={leavingMolars || maintain || this.props.summary}
            checked={lowerArchMesialization}
            onChange={() => {
              this.lowerArchMesialization = !this.lowerArchMesialization;
              this.UpdateCrossBiteReducer();
            }}
          />
          <div className="checkbox-hint pl-4">
            sequential mesialization- may increase number of steps - elastics
            using
          </div>
          {/* 5 */}

          <CustomInput

            className={`pt-1 ${leavingMolars && "replacment-checkbox"}`}
            type="checkbox"
            id="leavingMolars"
            label="Leaving the molars in Class II and doing posterior reproximation (IPR distal of canine to molar) as needed to improve the canine relationship."
            checked={leavingMolars}
            disabled={
              maintain ||
              lowerArchMesialization ||
              upperArchDistalization ||
              extractionUpperLowerBicuspids ||
              extractionTwoUpperBicuspids || this.props.summary
            }
            onChange={() => {
              this.leavingMolars = !this.leavingMolars;
              this.UpdateCrossBiteReducer();
            }}
          />
          {/* 6 */}

          <CustomInput

            className={`pt-1 ${maintain && "replacment-checkbox"}`}
            type="checkbox"
            id="maintain"
            label="Maintain Class II with esthetic anterior alignment only"
            checked={maintain}
            disabled={
              leavingMolars ||
              lowerArchMesialization ||
              upperArchDistalization ||
              extractionUpperLowerBicuspids ||
              extractionTwoUpperBicuspids || this.props.summary
            }
            onChange={() => {
              this.maintain = !this.maintain;
              this.UpdateCrossBiteReducer();
            }}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClassII);
