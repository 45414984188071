import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input, Row, Col } from "reactstrap";
import CustomeTeeth from "../../../Teeth/CustomeTeeth";
import ToggleSwitch from "../../../../components/Switch/Switch";
import { mapTeethToApi } from "../../../../services/mapTeethToApi";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class IPR extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_ipr: this.props.caseSubmission.advanced_modules.ipr.useIpr,
      ipr_timing_1: false,
      ipr_timing_3: false,
      iprTiming: this.props.caseSubmission.advanced_modules.ipr.iprTiming,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "ipr",
      data: {
        useIpr: this.state.use_ipr,
        iprTiming: this.state.iprTiming,
        iprRestriction: this.props.customeTeethReducer.Case_IPR,
      },
    });
    this.props.save();
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  render() {
    const { use_ipr, iprTiming } = this.state;
    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">
          <div className="text-center">
            <div className="control-label title_active-gco text-center mb-2">
              Use IPR for this case
             </div>

            <TreatmentOptions value={use_ipr} name="use_ipr" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_ipr")
              if (value !== true) {
                this.setState({
                  iprTiming: ""
                })
                this.props.Clear_Teeth_Data("Case_IPR")
              }
            }} />
          </div>

          <div className="grid_container">
            {" "}
            <div className="auto_grid_timing pb-2">
              <div className="control-label title_active-gco align-self-end">
                IPR timing{" "}
              </div>
              <div className="auto_grid2">
                {/* <Button
                  className={`rounded-circle ${iprTiming === 1 ? "num-btn-active" : "num-btn"
                    }`}
                  onClick={() => {
                    this.setState({
                      iprTiming: 1,
                    });
                  }}
                  disabled={!use_ipr || use_ipr === "eonToDecide"}
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}

                >
                  1
                </Button>
                <Button

                  className={`rounded-circle ${iprTiming === 3 ? "num-btn-active" : "num-btn"
                    }`}
                  onClick={() => {
                    this.setState({
                      iprTiming: 3,
                    });
                  }}
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}
                  disabled={!use_ipr || use_ipr === "eonToDecide"}

                >
                  3
                </Button> */}
                {/* <div className="control-label title_active-gco custom-label">
                  Custom
                </div> */}
                <div className="form-material">
                  <Input
                    type="number"
                    placeholder=""
                    className="custom-number"
                    // value={iprTiming === 1 || iprTiming === 3 ? "" : iprTiming
                    // }
                    onClick = {e => {
                      e.target.focus();
                      }}
                      onMouseUp = {e => {
                        e.target.blur();
                      }}
                      value={
                        iprTiming
                      }
                      min="1"                 
                      onChange={(e) => {
                        this.setState({
                          iprTiming: e.target.value,
                        });
                      }}
 
                    disabled={!use_ipr || use_ipr === "eonToDecide" || this.props.summary}

                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="control-label title_active-gco text-center pb-2 mt-2  ">
                Restrictions
              </div>
              <div className="teeth_selector">
                <CustomeTeeth action_key="Case_IPR" disabled={!use_ipr || use_ipr === "eonToDecide"}
                  summary={this.props.summary}
                />
              </div>
            </div>
          </div>
        </div>

        {!this.props.summary && <Button
          className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
          size="sm"
          type="button"
          onClick={() => {
            this.UpdateAdvancedModules();
          }}
        >
          Save
        </Button>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(IPR);
