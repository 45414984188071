import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Row, Col } from "reactstrap";
import CustomeTeeth from "../../../Teeth/CustomeTeeth";
import ToggleSwitch from "../../../../components/Switch/Switch";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
import { mapTeethToApi } from "../../../../services/mapTeethToApi";
import { Clear_Teeth_Data } from "../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class ArchExpansion extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      increaseCaninesPremolarsMolars: this.props.caseSubmission.advanced_modules
        .archExpansion.increaseCaninesPremolarsMolars,
      increaseCaninesPremolars: this.props.caseSubmission.advanced_modules
        .archExpansion.increaseCaninesPremolars,
      increasePremolarsMolars: this.props.caseSubmission.advanced_modules
        .archExpansion.increasePremolarsMolars,
      use_arch_expansion: this.props.caseSubmission.advanced_modules
        .archExpansion.use_arch_expansion,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "archExpansion",
      data: {
        use_arch_expansion: this.state.use_arch_expansion,
        increaseCaninesPremolarsMolars: this.state
          .increaseCaninesPremolarsMolars,
        increaseCaninesPremolars: this.state.increaseCaninesPremolars,
        increasePremolarsMolars: this.state.increasePremolarsMolars,
        // teeth:
        //   this.props.customeTeethReducer.Case_Arch_Expansion
        // ,
      },
    });
    this.props.save();
  };

  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  clearState = () => {
    this.setState({
      increaseCaninesPremolarsMolars: false,
      increaseCaninesPremolars: false,
      increasePremolarsMolars: false,
    })
  }
  render() {
    const {
      increaseCaninesPremolarsMolars,
      increaseCaninesPremolars,
      increasePremolarsMolars,
      use_arch_expansion,
    } = this.state;

    return (
      <div>
        <div className="form-group content form-block-holder grid_container m-2">

          <div className="text-center">
            <div className="control-label title_active-gco text-center mb-2">
              Use Arch Expansion for this case{" "}
            </div>

            <TreatmentOptions value={use_arch_expansion} name="use_arch_expansion" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_arch_expansion")
              if (value !== true) {
                //this.props.Clear_Teeth_Data("Case_Arch_Expansion")
                this.clearState()
              }
            }} />
          </div>

          <div className="arch_expansion_grid pb-2">
            <Button
              onClick={() => {
                this.setState({
                  increaseCaninesPremolarsMolars: true,
                  increaseCaninesPremolars: false,
                  increasePremolarsMolars: false,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${increaseCaninesPremolarsMolars
                ? "active_discrepancy-btn"
                : "discrepancy-btn"
                } `}
              disabled={!use_arch_expansion || use_arch_expansion === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              Increasing the arch width between canines, premolars and molars
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  increaseCaninesPremolarsMolars: false,
                  increaseCaninesPremolars: true,
                  increasePremolarsMolars: false,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${increaseCaninesPremolars
                ? "active_discrepancy-btn"
                : "discrepancy-btn"
                } `}
              disabled={!use_arch_expansion || use_arch_expansion === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              increasing the arch width between canines, premolars only{" "}
            </Button>{" "}
            <Button
              onClick={() => {
                this.setState({
                  increaseCaninesPremolarsMolars: false,
                  increaseCaninesPremolars: false,
                  increasePremolarsMolars: true,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${increasePremolarsMolars
                ? "active_discrepancy-btn"
                : "discrepancy-btn"
                } `}
              disabled={!use_arch_expansion || use_arch_expansion === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              Increasing the arch width between premolars and molars only{" "}
            </Button>{" "}
          </div>
          {/* <div>
            <div className="control-label title_active-gco text-center pb-2 mt-2 ">
              Placement
              </div> <div className="teeth_selector">
              <CustomeTeeth
                action_key="Case_Arch_Expansion"
                disabled={!use_arch_expansion || use_arch_expansion === "eonToDecide"}
                summary={this.props.summary}
              />
            </div>

          </div> */}

        </div>

        { !this.props.summary && <Button
          className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
          size="sm"
          type="button"
          onClick={() => this.UpdateAdvancedModules()}
        >
          Save
        </Button>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ArchExpansion);
