import { upper_teeth, lower_teeth } from "../redux/newCase/init_teeth";
import { mapTeeth } from "./mapTeeth";
export function mapDataWithReducer(dataFromApi, fix) {
  let media = dataFromApi.media;
  let data = {
    userInfo: userInfo_data(dataFromApi),
    treatmentPlan: {
      tab1: false,
      tab2: true,
      tab3: true,
      clinical_findings: clinical_findings_data(dataFromApi.clinical_findings),
      treatment_goals: treatment_goals_data(dataFromApi.treatment_goals),
      summary_and_special_instructions: summary_and_special_instructions_data(
        dataFromApi
      ),
      express: express_data(dataFromApi),
    },
    impressions: impressions_data(dataFromApi, media, fix),
    photos: photos_data(media),
    xrays: xrays_data(media),
    submission: {
      acceptTerms: false,
      savedToCloud: false,
      validateTerms: false,
    },
    // photos_xrays: photos_xrays_data(media),
    // impressions_new: impressions_data_new(dataFromApi, media, fix),
    // prescription: prescription_data(dataFromApi),
    // advanced_modules: advanced_data(dataFromApi.advanced_modules),
  };
  return data;
}
/* --------------------------------------------------------------------------------*/
/* map clinical findings data */
/* --------------------------------------------------------------------------------*/
function userInfo_data(data) {
  return {
    caseType: data.case_type,
    express_api_v2_case: data.express_api_v2_case,
  };
}
/* --------------------------------------------------------------------------------*/
/* map clinical findings data */
/* --------------------------------------------------------------------------------*/
function clinical_findings_data(data) {
  return {
    skeletal_1: data?.skeletal === "I",
    skeletal_2: data?.skeletal === "II",
    skeletal_3: data?.skeletal === "III",
    canine_r_1: data?.canine?.class1?.R === "true",
    canine_r_2: data?.canine?.class2?.R === "true",
    canine_r_3: data?.canine?.class3?.R === "true",
    canine_l_1: data?.canine?.class1?.L === "true",
    canine_l_2: data?.canine?.class2?.L === "true",
    canine_l_3: data?.canine?.class3?.L === "true",
    molar_r_1: data?.molar?.class1?.R === "true",
    molar_r_2: data?.molar?.class2?.R === "true",
    molar_r_3: data?.molar?.class3?.R === "true",
    molar_l_1: data?.molar?.class1?.L === "true",
    molar_l_2: data?.molar?.class2?.L === "true",
    molar_l_3: data?.molar?.class3?.L === "true",
    upper_midline_center: data?.upper_midline === "centered",
    upper_midline_shifted_left: data?.upper_midline === "shifted_left",
    upper_midline_shifted_right: data?.upper_midline === "shifted_right",
    lower_midline_center: data?.upper_midline === "centered",
    lower_midline_shifted_left: data?.upper_midline === "shifted_left",
    lower_midline_shifted_right: data?.upper_midline === "shifted_right",
    chief_complaint: data ? data?.chief_complaint : "",
    upper_midline_displacement: data ? data.upper_midline_displacement : 0,
    lower_midline_displacement: data ? data.lower_midline_displacement : 0,
    skeletal: data ? data.skeletal : "",
    upper_midline: data ? data.upper_midline : "",
    lower_midline: data ? data.lower_midline : "",
    Tooth_Movement_Restrictions: data
      ? mapTeeth(data.restriction)
      : {
          upper_teeth: Object.assign({}, upper_teeth),
          lower_teeth: Object.assign({}, lower_teeth),
        },
    Do_not_place_attachments: data
      ? data.no_attachment
        ? mapTeeth(data.no_attachment)
        : {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }
      : {
          upper_teeth: Object.assign({}, upper_teeth),
          lower_teeth: Object.assign({}, lower_teeth),
        },
  };
}
/* --------------------------------------------------------------------------------*/
/* map treatment goals data */
/* --------------------------------------------------------------------------------*/
function treatment_goals_data(data) {
  return {
    treat_arches_upper: data?.treat_arches === "upper_only",

    treat_arches_lower: data?.treat_arches === "lower_only",

    treat_arches_both: data?.treat_arches === "both",

    midline_upper_maintain: data?.upper_midline === "maintain",

    midline_upper_improve: data?.upper_midline === "improve",

    midline_upper_center: data?.upper_midline === "center",

    midline_lower_maintain: data?.lower_midline === "maintain",

    midline_lower_improve: data?.lower_midline === "improve",

    midline_lower_center: data?.lower_midline === "center",
    overjet_maintain: data?.overjet === "maintain",
    overjet_improve: data?.overjet === "improve",
    overjet_Ideal: data?.overjet === "ideal",
    overbite_maintain: data?.overbite === "maintain",
    overbite_improve: data?.overbite === "improve",
    overbite_Ideal: data?.overbite === "ideal",
    arch_form_maintain: data?.arch_form === "maintain",
    arch_form_improve: data?.arch_form === "improve",
    arch_form_constrict: data?.arch_form === "constrict",
    procline_upper_primary: data?.resolve_crowding?.procline?.U === "true",
    procline_lower_primary: data?.resolve_crowding?.procline?.L === "true",
    procline_upper_if_needed: data?.resolve_crowding?.procline_in?.U === "true",
    procline_lower_if_needed: data?.resolve_crowding?.procline_in?.L === "true",
    expand_upper_primary: data?.resolve_crowding?.expand?.U === "true",
    expand_lower_primary: data?.resolve_crowding?.expand?.L === "true",
    expand_upper_if_needed: data?.resolve_crowding?.expand_in?.U === "true",
    expand_lower_if_needed: data?.resolve_crowding?.expand_in?.L === "true",
    ipr_upper_primary: data?.resolve_crowding?.ipr?.U === "true",
    ipr_lower_primary: data?.resolve_crowding?.ipr?.L === "true",
    ipr_upper_if_needed: data?.resolve_crowding?.ipr_in?.U === "true",
    ipr_lower_if_needed: data?.resolve_crowding?.ipr_in?.L === "true",
    canine_r_1: data?.canine?.class1?.R === "true",
    canine_r_2: data?.canine?.class2?.R === "true",
    canine_r_3: data?.canine?.class3?.R === "true",
    canine_l_1: data?.canine?.class1?.L === "true",
    canine_l_2: data?.canine?.class2?.L === "true",
    canine_l_3: data?.canine?.class3?.L === "true",
    molar_r_1: data?.molar?.class1?.R === "true",
    molar_r_2: data?.molar?.class2?.R === "true",
    molar_r_3: data?.molar?.class3?.R === "true",

    molar_l_1: data?.molar?.class1?.L === "true",
    molar_l_2: data?.molar?.class2?.L === "true",
    molar_l_3: data?.molar?.class3?.L === "true",
    goal_upper_ipr: data?.ap_goal?.ipr36?.U === "true",
    goal_lower_ipr: data?.ap_goal?.ipr36?.L === "true",
    goal_upper_distalization: data?.ap_goal?.distalization?.U === "true",
    goal_lower_distalization: data?.ap_goal?.distalization?.L === "true",
    goal_upper_mezialization: data?.ap_goal?.mezialization?.U === "true",
    goal_lower_mezialization: data?.ap_goal?.mezialization?.L === "true",
    treat_arches: data ? data.treat_arches : "",
    upper_midline: data ? data.upper_midline : "",
    lower_midline: data ? data.lower_midline : "",
    midline: {
      U: "",
      L: "",
    },
    overjet: data ? data.overjet : "",
    overbite: data ? data.overbite : "",
    arch_form: data ? data.arch_form : "",
    Extraction: data
      ? data.extraction
        ? mapTeeth(data.extraction)
        : {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }
      : {
          upper_teeth: Object.assign({}, upper_teeth),
          lower_teeth: Object.assign({}, lower_teeth),
        },
    Crossbites: data
      ? data.crossbites
        ? mapTeeth(data.crossbites)
        : {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }
      : {
          upper_teeth: Object.assign({}, upper_teeth),
          lower_teeth: Object.assign({}, lower_teeth),
        },
    LeaveSpace: data
      ? data.leave_space
        ? mapTeeth(data.leave_space)
        : {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }
      : {
          upper_teeth: Object.assign({}, upper_teeth),
          lower_teeth: Object.assign({}, lower_teeth),
        },
  };
}
/* --------------------------------------------------------------------------------*/
/* map summary and special instructions data */
/* --------------------------------------------------------------------------------*/
function summary_and_special_instructions_data(data) {
  return {
    treatment_summary: data.treatment_summary,
    special_instructions: data.special_instructions,
  };
}
/* --------------------------------------------------------------------------------*/
/* map express data  */
/* --------------------------------------------------------------------------------*/
function express_data(data) {
  return {
    chief_complaint: data.clinical_findings
      ? data.clinical_findings.chief_complaint
      : "",
    treatment_summary: data.treatment_summary,
  };
}
/* --------------------------------------------------------------------------------*/
/* map impressions data */
/* --------------------------------------------------------------------------------*/
function impressions_data(dataFromApi, media, fix) {
  return {
    Digital_Impression: dataFromApi.impressions_method === "upload",
    Digital_Means: dataFromApi.impressions_method === "third_party",
    Arrange_Pickup: dataFromApi.impressions_method === "courier",
    upperImageValue: fix
      ? ""
      : media.impressions.upper_impression?.match(/http([^&]+)/) &&
        dataFromApi.impressions_method === "upload"
      ? media.impressions.upper_impression
      : "",
    lowerImageValue: fix
      ? ""
      : media.impressions.lower_impression?.match(/http([^&]+)/) &&
        dataFromApi.impressions_method === "upload"
      ? media.impressions.lower_impression
      : "",
    additional_comments: dataFromApi.impressions_third_party_note || "",
    Impression_Type: dataFromApi.impressions_method || "",
    upperImage: fix
      ? false
      : media.impressions.upper_impression?.match(/http([^&]+)/) &&
        dataFromApi.impressions_method === "upload"
      ? true
      : false,
    lowerImage: fix
      ? false
      : media.impressions.lower_impression?.match(/http([^&]+)/) &&
        dataFromApi.impressions_method === "upload"
      ? true
      : false,
  };
}

/* --------------------------------------------------------------------------------*/
/* map impressions data */
/* --------------------------------------------------------------------------------*/
function impressions_data_new(dataFromApi, media, fix) {
  return {
    impressions_method: dataFromApi.impressions_method || "",
    impressions_third_party_note:
      dataFromApi.impressions_third_party_note || "",
    upper_arch_url:
      media.impressions.upper_impression?.match(/http([^&]+)/) &&
      dataFromApi.impressions_method === "upload"
        ? media.impressions.upper_impression
        : "",
    lower_arch_url:
      media.impressions.lower_impression?.match(/http([^&]+)/) &&
      dataFromApi.impressions_method === "upload"
        ? media.impressions.lower_impression
        : "",
  };
}
/* --------------------------------------------------------------------------------*/
/* map photos data */
/* --------------------------------------------------------------------------------*/
function photos_data(media) {
  return {
    front_smiling_photo: media.patient_photos.front_smiling || "",
    front_smiling: media.patient_photos.front_smiling || "",
    front_smiling_image: media.patient_photos.front_smiling ? true : false,
    front_pose_photo: media.patient_photos.front_pose || "",
    front_pose: media.patient_photos.front_pose || "",
    front_pose_image: media.patient_photos.front_pose ? true : false,
    profile_photo: media.patient_photos.profile || "",
    profile: media.patient_photos.profile || "",
    profile_image: media.patient_photos.profile ? true : false,
    upper_occlusal_photo: media.patient_photos.upper_occlusal || "",
    upper_occlusal: media.patient_photos.upper_occlusal || "",
    upper_occlusal_image: media.patient_photos.upper_occlusal ? true : false,
    lower_occlusal_photo: media.patient_photos.lower_occlusal || "",
    lower_occlusal: media.patient_photos.lower_occlusal || "",
    lower_occlusal_image: media.patient_photos.lower_occlusal ? true : false,
    left_buccal_photo: media.patient_photos.left_buccal || "",
    left_buccal: media.patient_photos.left_buccal || "",
    left_buccal_image: media.patient_photos.left_buccal ? true : false,
    frontal_photo: media.patient_photos.frontal || "",
    frontal: media.patient_photos.frontal || "",
    frontal_image: media.patient_photos.frontal ? true : false,
    right_buccal_photo: media.patient_photos.right_buccal || "",
    right_buccal: media.patient_photos.right_buccal || "",
    right_buccal_image: media.patient_photos.right_buccal ? true : false,
  };
}
/* --------------------------------------------------------------------------------*/
/* map xrays data */
/* --------------------------------------------------------------------------------*/
function xrays_data(media) {
  return {
    panaromic_photo: media.xrays.panoramic || "",
    panaromic: media.xrays.panoramic || "",
    panaromic_image: media.xrays.panoramic ? true : false,
    cephalometric_photo: media.xrays.cephalometric || "",
    cephalometric: media.xrays.cephalometric || "",
    cephalometric_image: media.xrays.cephalometric ? true : false,
  };
}

function photos_xrays_data(media) {
  return {
    front_smiling: media.patient_photos.front_smiling || "",
    front_pose: media.patient_photos.front_pose || "",
    profile: media.patient_photos.profile || "",
    upper_occlusal: media.patient_photos.upper_occlusal || "",
    lower_occlusal: media.patient_photos.lower_occlusal || "",
    left_buccal: media.patient_photos.left_buccal || "",
    frontal: media.patient_photos.frontal || "",
    right_buccal: media.patient_photos.right_buccal || "",
    panaromic: media.xrays.panoramic || "",
    cephalometric: media.xrays.cephalometric || "",
  };
}

function prescription_data(data) {
  return {
    chief_complaint: data.clinical_findings
      ? data.clinical_findings?.chief_complaint
      : "",
    summary_and_special_instructions: data ? data.treatment_summary : "",
    treat_arches: data
      ? data.treatment_goals
        ? data.treatment_goals.treat_arches
        : ""
      : "",
    wear_cycle: data ? data?.wear_cycle : "",
    case_type: data ? data?.case_type : "",
    restriction: data.clinical_findings
      ? data.clinical_findings?.restriction
        ? mapTeeth(data.clinical_findings?.restriction)
        : {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          }
      : {
          upper_teeth: Object.assign({}, upper_teeth),
          lower_teeth: Object.assign({}, lower_teeth),
        },
  };
}

function advanced_data(data) {
  return {
    all_modules: data ? data.all_modules : [],
    treatment_type: data?.treatment_type
      ? data.treatment_type
      : {
          type: "",
          hybrid_treatment: {},
        },
    malocclusion: {
      crossbite: data?.malocclusion?.crossbite
        ? data.malocclusion.crossbite
        : {
            state: false,
            teeth: {
              upper_teeth: Object.assign({}, upper_teeth),
              lower_teeth: Object.assign({}, lower_teeth),
            },
            useElasticWhenCorrecting: false,
            treatment_Options: {
              expansion: false,
              constriction: false,
              combination: false,
            },
          },
      spacing: data?.malocclusion?.spacing
        ? data.malocclusion.spacing
        : {
            closeAllSpaces: {
              byRetractionOfAnteriors: false,
              byMesialisation: false,
              both: false,
            },
            partiallyClose: false,
            IPRopposing: false,
          },
      classII: data?.malocclusion?.classII
        ? data.malocclusion.classII
        : {
            extractionTwoUpperBicuspids: false,
            extractionUpperLowerBicuspids: false,
            upperArchDistalization: false,
            lowerArchMesialization: false,
            leavingMolars: false,
            maintain: false,
          },
      classIII: data?.malocclusion?.classIII
        ? data.malocclusion.classIII
        : {
            advanceUppers: false,
            retractLowersExtraction: false,
            retractLowersIPR: false,
            estheticAlignmentOnly: false,
            preSurgicalAlignment: false,
          },
      openbite: data?.malocclusion?.openbite
        ? data.malocclusion.openbite
        : {
            extrudeAnterior: false,
            intrudePosterior: false,
            both: false,
          },
      deepbite: data?.malocclusion?.deepbite
        ? data.malocclusion.deepbite
        : {
            intrudeUpperAnteriors: false,
            intrudeLowerAnteriors: false,
            combination: false,
            extrudePremolars: false,
          },
      midline: data?.malocclusion?.midline
        ? data.malocclusion.midline
        : {
            upperMidline: {
              upperMidline: "",
              upperMidline_displacement: "",
            },
            lowerMidline: {
              lowerMidline: "",
              lowerMidline_displacement: "",
            },
            treatMidlineUpper: {
              resultMidlineAlignment: false,
              maintain: false,
              improveIPR: false,
              improveExtraction: false,
            },
            treatMidlineLower: {
              resultMidlineAlignment: false,
              maintain: false,
              improveIPR: false,
              improveExtraction: false,
            },
          },
    },
    extraction: data?.extraction
      ? {
          use_extraction: data.extraction.use_extraction,
          teeth: mapTeeth(data.extraction.teeth),
        }
      : {
          use_extraction: false,
          teeth: {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          },
        },
    ipr: data?.ipr
      ? {
          useIpr: data.ipr.useIpr,
          iprTiming: data.ipr.iprTiming,
          iprRestriction: mapTeeth(data.ipr.iprRestriction),
        }
      : {
          useIpr: false,
          iprTiming: "",
          iprRestriction: {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          },
        },
    attachments: data?.attachments
      ? {
          useAttachments: data.attachments.useAttachments,
          attachmentsTiming: data.attachments.attachmentsTiming,
          attachmentsRestriction: mapTeeth(
            data.attachments.attachmentsRestriction
          ),
        }
      : {
          useAttachments: false,
          attachmentsTiming: "",
          attachmentsRestriction: {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          },
        },
    pontics: data?.pontics
      ? {
          usePontics: data.pontics.usePontics,
          teeth: mapTeeth(data.pontics.teeth),
        }
      : {
          usePontics: false,
          teeth: {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          },
        },
    overCorrection: data?.overCorrection
      ? {
          useOverCorrection: data.overCorrection.useOverCorrection,
          teeth: mapTeeth(data.overCorrection.teeth),
          details: data.overCorrection.details,
        }
      : {
          useOverCorrection: false,
          teeth: {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          },

          details: "",
        },
    passiveAligners: data?.passiveAligners
      ? {
          usePassiveAligners: data.passiveAligners.usePassiveAligners,
          details: data.passiveAligners.details,
        }
      : {
          usePassiveAligners: false,
          details: "",
        },
    toothSizeDiscrepancy: data?.toothSizeDiscrepancy
      ? {
          use_address_discrepancy:
            data.toothSizeDiscrepancy.use_address_discrepancy,
          leaveSpaceAroundLaterals:
            data.toothSizeDiscrepancy.leaveSpaceAroundLaterals,
          leaveSpaceDistalLaterals:
            data.toothSizeDiscrepancy.leaveSpaceDistalLaterals,
          iprOpposing: data.toothSizeDiscrepancy.iprOpposing,
          other: data.toothSizeDiscrepancy.other,
          other_details: data.toothSizeDiscrepancy.other_details,
        }
      : {
          use_address_discrepancy: false,
          leaveSpaceAroundLaterals: false,
          leaveSpaceDistalLaterals: false,
          iprOpposing: false,
          other: false,
          other_details: "",
        },
    torqueEnhancers: data?.torqueEnhancers
      ? {
          useTorqueEnhancers: data.torqueEnhancers.useTorqueEnhancers,
          teeth: mapTeeth(data.torqueEnhancers.teeth),
          details: data.torqueEnhancers.details,
        }
      : {
          useTorqueEnhancers: false,
          teeth: {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          },

          details: "",
        },
    biteRamps: data?.biteRamps
      ? {
          useBiteRamps: data.biteRamps.useBiteRamps,
          incisors: data.biteRamps.incisors,
          incisors_and_canines: data.biteRamps.incisors_and_canines,
          details: data.biteRamps.details,
        }
      : {
          useBiteRamps: false,
          incisors: false,
          incisors_and_canines: false,
          details: "",
        },
    archExpansion: data?.archExpansion
      ? {
          use_arch_expansion: data.archExpansion.use_arch_expansion,
          increaseCaninesPremolarsMolars:
            data.archExpansion.increaseCaninesPremolarsMolars,
          increaseCaninesPremolars: data.archExpansion.increaseCaninesPremolars,
          increasePremolarsMolars: data.archExpansion.increasePremolarsMolars,
          teeth: mapTeeth(data.archExpansion.teeth),
        }
      : {
          use_arch_expansion: false,
          increaseCaninesPremolarsMolars: false,
          increaseCaninesPremolars: false,
          increasePremolarsMolars: false,
          teeth: {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          },
        },
    elastics: data?.elastics
      ? {
          useElastics: data.elastics.useElastics,
          teeth: mapTeeth(data.elastics.teeth),
          details: data.elastics.details,
          type: data.elastics.type,
        }
      : {
          useElastics: false,
          teeth: {
            upper_teeth: Object.assign({}, upper_teeth),
            lower_teeth: Object.assign({}, lower_teeth),
          },
          details: "",
          type: "",
        },
  };
}
