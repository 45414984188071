import React, { Component } from "react";
import { connect } from "react-redux";

import {
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { schema } from "../../helpers/prescriptionValidation";
import CustomeTeeth from "../Teeth/CustomeTeeth";
import { updateAlignerCase } from "../../api/api";
import { mapTeethToApi } from "../../services/mapTeethToApi";
import {
  updatePrescription,
} from "../../redux/CaseSubmission/action";
import { mapAdvancedToApi } from "../../services/mapAdvancedToApi";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updatePrescription: (payload) => dispatch(updatePrescription(payload)),

});
class Prescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      use_advanced: false,
      doctorId: props.userReducer.currentUser.id,

      caseId:
        this.props.caseId ||
        (window.location.search.match(/id=([^&]+)/) || [])[1],
    };
  }
  /*--------------------------------------------------------------------------------*/
  /*Prescription step submit  */
  /*--------------------------------------------------------------------------------*/
  handleSubmit = (values, { setSubmitting }) => {
    const { doctorId } = this.state;
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    setSubmitting(true);
    let step =
      this.props.rejectable_id
        ? "clinical_findings"
        : "prescription";
    let data = {
      aligner_case: {
        step: step,
        advanced_modules: {
          ...this.props.caseSubmission.advanced_modules,
          ipr: {
            ...this.props.caseSubmission.advanced_modules.ipr,
            iprRestriction: mapTeethToApi(this.props.customeTeethReducer.Case_IPR)
          },
          archExpansion: {
            ...this.props.caseSubmission.advanced_modules.archExpansion,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Arch_Expansion
            )
          },
          attachments: {
            ...this.props.caseSubmission.advanced_modules.attachments,
            attachmentsRestriction: mapTeethToApi(
              this.props.customeTeethReducer.Case_Attachments
            )
          },
          elastics: {
            ...this.props.caseSubmission.advanced_modules.elastics,
            teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics),
            buttons_teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics_Buttons),
            cuts_teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics_Cuts),
          },
          extraction: {
            ...this.props.caseSubmission.advanced_modules.extraction,
            teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Extraction),
          },
          overCorrection: {
            ...this.props.caseSubmission.advanced_modules.overCorrection,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Overcorrections
            ),
          },
          pontics: {
            ...this.props.caseSubmission.advanced_modules.pontics,
            teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Pontics),
          },
          torqueEnhancers: {
            ...this.props.caseSubmission.advanced_modules.torqueEnhancers,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Torque_Enhancers
            ),
          },
          malocclusion: {
            ...this.props.caseSubmission.advanced_modules.malocclusion,
            crossbite: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .crossbite,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Crossbites
              ),
            },
            crowding: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .crowding,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Crowding
              ),
            },
            classII: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .classII,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_ClassII
              ),
            },
            classIII: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .classIII,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_ClassIII
              ),
            },
          },
          all_modules: mapAdvancedToApi(this.props.advancedModules.modules) ,
        },
        treatment_goals: {
          treat_arches: values.treat_arches,
        },
        wear_cycle: values.wear_cycle,
        treatment_summary: values.summary_and_special_instructions,
        clinical_findings: {
          chief_complaint: values.chief_complaint,
          restriction: mapTeethToApi(
            this.props.customeTeethReducer.Case_Tooth_Movement_Restrictions
          ),
        },
      }, ...(this.props.rejectable_id && {
        resolve_rejection_id: this.props.rejectable_id,
      })
    };
    let reducer_data = {
      chief_complaint: values.chief_complaint,
      summary_and_special_instructions: values.summary_and_special_instructions,
      treat_arches: values.treat_arches,
      wear_cycle: values.wear_cycle,
    }
    updateAlignerCase(doctorId, caseId, JSON.stringify(data))
      .then((res) => {
        //this.props.NextStep();
        setSubmitting(false);
        if (this.props.rejectable_id) {
          this.props.FixAlignerCase()
        } else {
          this.props.ShowAdvancedModule(values.use_advanced);
          this.props.updatePrescription(reducer_data);
        }
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };
  render() {
    const { prescription } = this.props.caseSubmission;
    return (
      <div>
        {!this.props.rejectable_id && <CardTitle className="border-bottom pb-3 title_active-gco case-title">
          <div className="step-header-title">
            <span>Prescription</span>
            <span className="patient_info">
              <div> {this.props.patient.full_name}
              </div>
              <div> Case {this.state.caseId}
              </div>
            </span>
          </div>
        </CardTitle>}
        <Formik
          enableReinitialize
          initialValues={{
            chief_complaint: prescription.chief_complaint,
            summary_and_special_instructions:
              prescription.summary_and_special_instructions || "",
            treat_arches: prescription.treat_arches,
            wear_cycle: prescription.wear_cycle,
            use_advanced: this.state.use_advanced
          }}
          validationSchema={schema}
          onSubmit={this.handleSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="step-progress-new-case upload-container">
                {isSubmitting && (
                  <div className={`loading2`}>
                    <div className="spinner_2"></div>
                  </div>
                )}
              </div>
              <CardBody className="prescription_grid mb-5">
                <div>
                  <FormGroup>
                    <Label className="title_active-gco ">Chief Complaint</Label>
                    <Input
                      name="chief_complaint"
                      id="chief_complaint"
                      type="textarea"
                      rows={5}
                      defaultValue={values.chief_complaint}
                      onChange={(e) => {
                        setFieldValue("chief_complaint", e.target.value);
                      }}
                    />{" "}
                    <ErrorMessage
                      component="div"
                      name="chief_complaint"
                      className="custom-invalid-feedback mt-1"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="title_active-gco ">Treat Arch</Label>
                    <div className="treat_arch_grid">
                      <div className="wear_cycle_grid">
                        {" "}
                        <Button
                          style={{ width: "auto" }}
                          name="treat_arches"
                          className={`btn h-100  ${values.treat_arches === "upper_only"
                            ? "treat_arch_btn_active"
                            : "treat_arch_btn"
                            } text-center  btn-radius`}
                          size="sm"
                          onClick={() => {
                            setFieldValue("treat_arches", "upper_only");
                          }}
                        >
                          Upper
                        </Button>
                        <Button
                          style={{ width: "auto" }}
                          name="treat_arches"
                          className={`btn h-100 ${values.treat_arches === "lower_only"
                            ? "treat_arch_btn_active"
                            : "treat_arch_btn"
                            } text-center  btn-radius`}
                          size="sm"
                          onClick={() => {
                            setFieldValue("treat_arches", "lower_only");
                          }}
                        >
                          Lower
                        </Button>
                      </div>
                      <Button
                        style={{ width: "auto" }}
                        name="treat_arches"
                        className={`btn h-100 ${values.treat_arches === "both"
                          ? "treat_arch_btn_active"
                          : "treat_arch_btn"
                          } text-center  btn-radius`}
                        size="sm"
                        onClick={() => {
                          setFieldValue("treat_arches", "both");
                        }}
                      >
                        Upper and Lower
                      </Button>
                    </div>{" "}
                    <ErrorMessage
                      component="div"
                      name="treat_arches"
                      className="custom-invalid-feedback mt-1"
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="title_active-gco ">Wear Cycle</Label>
                    <div className="wear_cycle_grid">
                      <Button
                        name="wear_cycle"
                        className={`btn w-100 h-100 ${values.wear_cycle === "express"
                          ? "treat_arch_btn_active"
                          : "treat_arch_btn"
                          } text-center  btn-radius`}
                        size="sm"
                        onClick={() => {
                          setFieldValue("wear_cycle", "express");
                        }}
                      >
                        One week cycle
                      </Button>
                      <Button
                        name="wear_cycle"
                        className={`btn  w-100 h-100 ${values.wear_cycle === "regular"
                          ? "treat_arch_btn_active"
                          : "treat_arch_btn"
                          } text-center  btn-radius`}
                        size="sm"
                        onClick={() => {
                          setFieldValue("wear_cycle", "regular");
                        }}
                      >
                        Two week cycle
                      </Button>
                    </div>{" "}
                    <ErrorMessage
                      component="div"
                      name="wear_cycle"
                      className="custom-invalid-feedback mt-1"
                    />
                  </FormGroup>
                </div>
                <div>
                  <FormGroup>
                    <Label className="title_active-gco ">
                      Treatment Summary and Specific Instructions
                    </Label>
                    <Input
                      name="summary_and_special_instructions"
                      id="summary_and_special_instructions"
                      type="textarea"
                      rows={5}
                      defaultValue={values.summary_and_special_instructions}
                      onChange={(e) => {
                        setFieldValue(
                          "summary_and_special_instructions",
                          e.target.value
                        );
                      }}
                    />{" "}
                    <ErrorMessage
                      component="div"
                      name="summary_and_special_instructions"
                      className="custom-invalid-feedback mt-1"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="title_active-gco ">
                      Teeth Movement Restrictions
                    </Label>
                    <div className="teeth_selector">   <CustomeTeeth action_key="Case_Tooth_Movement_Restrictions" /></div>

                  </FormGroup>
                </div>
              </CardBody>
              <CardFooter className="p-3">

                {this.props.rejectable_id ? <Button
                  className={`btn  ${"preference-btn"} float-right text-center mb-2 btn-radius`}
                  size="sm"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Fix
                   {/* <i className="fas fa-angle-right arrows-icon pl-1"></i> */}
                </Button> : <div className="footer2">

                    <div className="prescription_new_footer_grid">

                      <div>  </div>
                      <div>
                        <Button
                          className={`btn  ${"prescription-btn"} c-w float-right text-center mb-2  btn-radius`}
                          size="sm"
                          type="submit"
                          disabled={isSubmitting}
                          onClick={() => {
                            setFieldValue("use_advanced", false);

                            //this.handleSubmit(values, true);
                          }}
                        >
                          Go to case summary <i className="fas fa-angle-right arrows-icon pl-1"></i>
                        </Button>
                      </div>

                    </div>
                    <div className="prescription_new_footer_grid">
                      <div></div>
                      <div><Label className="title_active-gco float-right c-w ml-auto text-center ">Or</Label></div>
                    </div>
                    <div className="prescription_new_footer_grid">
                      <div>
                        <Button
                          className={`btn  ${"preference-btn"} c-w  float-left text-center mb-2  btn-radius`}
                          size="sm"
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => this.props.PrevStep()}
                        >
                          <i className="fas fa-angle-left arrows-icon pr-1"></i>   Impression
                    </Button></div>
                      <div>
                        <Button
                          className={`btn  ${"prescription-btn"} c-w  float-right text-center  btn-radius`}
                          size="sm"
                          type="submit"
                          disabled={isSubmitting}
                          onClick={() => {
                            setFieldValue("use_advanced", true);

                            //this.handleSubmit(values, true);
                          }}
                        >
                         Select advanced options <i className="fas fa-angle-right arrows-icon pl-1"></i>
                        </Button></div>
                    </div>

                  </div>}

              </CardFooter>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Prescription);
