import { TEETH_IPR_DATA ,SAVED_DATA,REJECTION_REASON,PROTOCOL_FEEDBACK} from "../constants/";

export const setTeethIPRData = (payload) => {
  return {
    type: TEETH_IPR_DATA,
    payload,
  };
};

export const setSavedData = (payload) => {
  return {
    type: SAVED_DATA,
    payload,
  };
};

export const setProtocolFeedback =(payload)=>{
  return {
    type: PROTOCOL_FEEDBACK,
    payload,
  };
}

export const setRejectionReson =(payload)=>{
  return {
    type: REJECTION_REASON,
    payload,
  };
}