import React, { Component } from "react";
import { connect } from "react-redux";

import { CustomInput } from "reactstrap";
import {
  clearMalocclusionData
} from "../../../../../redux/CaseSubmission/action";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  clearMalocclusionData: (payload) => dispatch(clearMalocclusionData(payload)),

});
class Deepbite extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */

    this.intrudeUpperAnteriors =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.deepbite
        ?.intrudeUpperAnteriors || false;

    this.intrudeLowerAnteriors =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.deepbite
        ?.intrudeLowerAnteriors || false;

    this.combination =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.deepbite
        ?.combination || false;

    this.extrudePremolars =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.deepbite
        ?.extrudePremolars || false;
  }

  UpdateCrossBiteReducer = () => {
    let data = {
      intrudeUpperAnteriors: this.intrudeUpperAnteriors,
      intrudeLowerAnteriors: this.intrudeLowerAnteriors,
      combination: this.combination,
      extrudePremolars: this.extrudePremolars,
    };

    this.props.save("deepbite", data);
  };
  render() {
    const {
      intrudeUpperAnteriors,
      intrudeLowerAnteriors,
      combination,
      extrudePremolars,
    } = this;
    return (
      <div>
        <div className="malocclusion-title">
          Deepbite
     {!this.props.summary &&<span
            className="close-btn pull-right"
            onClick={() => {
              this.props.clearMalocclusionData("deepbite")

              this.props.toggleComponents();
            }}
          >
            &times;
          </span> }     
        </div>

        <div className="p-2">
          <div className="control-label title_active-gco">
            Treatment options{" "}
          </div>
          <CustomInput
            className={`pt-1 ${intrudeUpperAnteriors && "replacment-checkbox"}`}
            type="checkbox"
            id="intrudeUpperAnteriors"
            label={`Intrude upper anteriors " may require IPR "`}
            checked={intrudeUpperAnteriors}
            disabled={intrudeLowerAnteriors || combination ||this.props.summary}
            onChange={() => {
              this.intrudeUpperAnteriors = !this.intrudeUpperAnteriors;
              this.UpdateCrossBiteReducer();
            }}
          />
          <CustomInput
            className={`pt-1 ${intrudeLowerAnteriors && "replacment-checkbox"}`}
            type="checkbox"
            id="intrudeLowerAnteriors"
            label={`Intrude lower anteriors " may require IPR "`}
            checked={intrudeLowerAnteriors}
            disabled={combination || intrudeUpperAnteriors ||this.props.summary}
            onChange={() => {
              this.intrudeLowerAnteriors = !this.intrudeLowerAnteriors;
              this.UpdateCrossBiteReducer();
            }}
          />
          <CustomInput
            className={`pt-1 ${combination && "replacment-checkbox"}`}
            type="checkbox"
            id="combination2"
            label="Combination of upper and lower anteriors intrusion"
            checked={combination}
            disabled={intrudeLowerAnteriors || intrudeUpperAnteriors ||this.props.summary}
            onChange={() => {
              this.combination = !this.combination;
              this.UpdateCrossBiteReducer();
            }}
          />
          <CustomInput
            className={`pt-1 ${extrudePremolars && "replacment-checkbox"}`}
            type="checkbox"
            id="extrudePremolars"
            label="Extrude posteriors"
            checked={extrudePremolars}
            disabled={this.props.summary}
            onChange={() => {
              this.extrudePremolars = !this.extrudePremolars;
              this.UpdateCrossBiteReducer();
            }}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Deepbite);
