import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input, Row, Col } from "reactstrap";
import CustomeTeeth from "../../../../Teeth/CustomeTeeth";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../../redux/customeTeeth/action"
import ToggleSwitch from "../../../../../components/Switch/Switch";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Attachments extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_attachments: this.props.caseSubmission.advanced_modules.attachments
        .useAttachments,
      attachments_timing_3: false,
      attachments_timing_7: false,
      attachmentsTiming: this.props.caseSubmission.advanced_modules.attachments
        .attachmentsTiming,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "attachments",
      data: {
        useAttachments: this.state.use_attachments,
        attachmentsTiming: this.state.attachmentsTiming,
        attachmentsRestriction:
          this.props.customeTeethReducer.Case_Attachments

      },
    });
    this.props.save();
  };

  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const {
      use_attachments,

      attachmentsTiming,
    } = this.state;

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">

          {/* <div>
            <div className="control-label title_active-gco text-center mb-2">
              Used attachments for this case{" "}
            </div>
            <div className="toggle-slider form-group content form-block-holder custome-toggle m-auto mt-2 ">
              <input
                type="range"
                step="50"
                onChange={(e) => {
                  this.slider(e, "use_attachments")

                  if (e?.target?.value === "0" || e?.target?.value === "50") {
                    this.setState({
                      attachmentsTiming: ""
                    })
                    this.props.Clear_Teeth_Data("Case_Attachments")

                  }
                }}
                value={this.sliderValue("use_attachments")}
                style={{ pointerEvents: this.props.summary ? "none" : "" }}

              />
              <Row className="align-items-center ">
                <Col xs="4" md="4" lg="4" className="text-left">
                  <label className="title_active">No</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-center">
                  <label className="title_active">EON to Decide</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-right">
                  <label className="title_active">Yes</label>
                </Col>
              </Row>
            </div>

          </div> */}


          <div className="auto_grid4 pb-2">
            <div className="control-label title_active-gco">
              Used attachments for this case{" "}
            </div>
            <div>
              <ToggleSwitch
                id="use-attachments"
                checked={use_attachments}
                disabled={this.props.summary}
                onChange={() => {
                  this.setState({
                    use_attachments: !use_attachments,

                    ...use_attachments && {
                      attachmentsTiming: ""
                    }
                  });

                  if (use_attachments) {
                    this.props.Clear_Teeth_Data("Case_Attachments")
                  }
                }}
              />
            </div>
          </div>

          <div className="grid_container">
            {" "}
            <div className="auto_grid_timing pb-2">
              <div className="control-label title_active-gco align-self-end">
                Attachments timing{" "}
              </div>

              <div className="auto_grid2">


                <div className="form-material">
                  <Input
                    type="number"
                    placeholder=""
                    className="custom-number"
                    // value={iprTiming === 1 || iprTiming === 3 ? "" : iprTiming
                    // }
                    onClick={e => {
                      e.target.focus();
                    }}
                    onMouseUp={e => {
                      e.target.blur();
                    }}
                    value={
                      attachmentsTiming
                    }
                    min="1"
                    onChange={(e) => {
                      this.setState({
                        attachmentsTiming: e.target.value,
                      });
                    }}

                    disabled={!use_attachments || use_attachments === "eonToDecide" || this.props.summary}

                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="control-label title_active-gco pb-2 text-center">
                Placement
              </div>
              <div className="teeth_selector">
                <CustomeTeeth
                  action_key="Case_Attachments"
                  disabled={!use_attachments || use_attachments === "eonToDecide"}
                  summary={this.props.summary}
                />
              </div>
            </div>
          </div>
        </div>

        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            Save
        </Button>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Attachments);
