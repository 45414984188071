import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Row, Col, Input } from "reactstrap";
import CustomeTeeth from "../../../../Teeth/CustomeTeeth";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../../redux/customeTeeth/action"
import ToggleSwitch from "../../../../../components/Switch/Switch";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Elastics extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_elastics: this.props.caseSubmission.advanced_modules.elastics
        .useElastics,
      use_buttons: this.props.caseSubmission.advanced_modules.elastics.use_buttons,
      use_cuts: this.props.caseSubmission.advanced_modules.elastics.use_cuts,
      buttons:
        this.props.caseSubmission.advanced_modules.elastics.type === "buttons",
      cuts: this.props.caseSubmission.advanced_modules.elastics.type === "cuts",
      type: this.props.caseSubmission.advanced_modules.elastics.type,
      details: this.props.caseSubmission.advanced_modules.elastics.details,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "elastics",
      data: {
        useElastics: this.state.use_elastics,
        teeth: this.props.customeTeethReducer.Case_Elastics,
        buttons_teeth: this.props.customeTeethReducer.Case_Elastics_Buttons,
        cuts_teeth: this.props.customeTeethReducer.Case_Elastics_Cuts,
        use_buttons: this.state.use_buttons,
        use_cuts: this.state.use_cuts,
        details: this.state.details,
        type: this.state.type,
      },
    });
    this.props.save();

  };
  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const { use_elastics, buttons, cuts, type, use_buttons, use_cuts, details } = this.state;

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">

          {/* <div>
            <div className="control-label title_active-gco text-center mb-2">
              Used elastics for this case{" "}
            </div>
            <div className="toggle-slider form-group content form-block-holder custome-toggle m-auto mt-2 ">
              <input
                type="range"
                step="50"
                onChange={(e) => {
                  this.slider(e, "use_elastics")

                  if (e?.target?.value === "0" || e?.target?.value === "50") {
                    this.setState({
                      type: ""

                    })
                    this.props.Clear_Teeth_Data("Case_Elastics")
                    this.props.Clear_Teeth_Data("Case_Elastics_Buttons")
                    this.props.Clear_Teeth_Data("Case_Elastics_Cuts")

                  }
                }}
                value={this.sliderValue("use_elastics")}
                style={{ pointerEvents: this.props.summary ? "none" : "" }}

              />
              <Row className="align-items-center ">
                <Col xs="4" md="4" lg="4" className="text-left">
                  <label className="title_active">No</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-center">
                  <label className="title_active">EON to Decide</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-right">
                  <label className="title_active">Yes</label>
                </Col>
              </Row>
            </div>

          </div> */}


          <div className="auto_grid4  pb-2">
            <div className="control-label title_active-gco">
              Used elastics for this case{" "}
            </div>
            <div>
              <ToggleSwitch
                id="use_elastics"
                disabled={this.props.summary}

                checked={use_elastics}
                onChange={() => {
                  this.setState({
                    use_elastics: !use_elastics,
                    ...use_elastics && {
                      type: "",
                      details: ""
                    }
                  });

                  if (use_elastics) {
                    this.props.Clear_Teeth_Data("Case_Elastics")
                    this.props.Clear_Teeth_Data("Case_Elastics_Buttons")
                    this.props.Clear_Teeth_Data("Case_Elastics_Cuts")
                  }
                }}
              />
            </div>
          </div>


          <div>
            <Button
              onClick={() => {
                this.setState({
                  buttons: !buttons,
                  type: "buttons",
                  use_buttons: !use_buttons
                });
                this.props.Clear_Teeth_Data("Case_Elastics_Buttons")

              }}
              className={`btn btn-radius text-center w-8 custom_shadow ${use_buttons
                ? "active_discrepancy-btn"
                : "discrepancy-btn"
                } `}
              disabled={!use_elastics || use_elastics === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              Buttons
            </Button>
            <div className="teeth_selector">
              <div className="control-label title_active-gco text-center pb-2 mt-2 ">
                Placement
              </div>
              <CustomeTeeth action_key="Case_Elastics_Buttons" disabled={!use_elastics || use_elastics === "eonToDecide" || !use_buttons}

                summary={this.props.summary} />
            </div>
          </div>

          <div>
            <Button
              onClick={() => {
                this.setState({
                  cuts: !cuts,
                  type: "cuts",
                  use_cuts: !use_cuts
                });
                this.props.Clear_Teeth_Data("Case_Elastics_Cuts")

              }}
              className={`btn btn-radius text-center w-8 custom_shadow ${use_cuts ? "active_discrepancy-btn" : "discrepancy-btn"
                } `}
              disabled={!use_elastics || use_elastics === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              Cuts
            </Button>
            <div className="teeth_selector">
              <div className="control-label title_active-gco text-center pb-2 mt-2 ">
                Placement
              </div>
              <CustomeTeeth action_key="Case_Elastics_Cuts" disabled={!use_elastics || use_elastics === "eonToDecide" || type !== "cuts" || !use_cuts}
                cuts={true}
                summary={this.props.summary} />
            </div>

          </div>
          {/* <div className="address_discrepancy">
            <div></div>
            <Button
              onClick={() => {
                this.setState({
                  buttons: !buttons,
                  type: "buttons",
                });
              }}
              className={`btn btn-radius text-center h-8 custom_shadow ${type === "buttons"
                ? "active_discrepancy-btn"
                : "discrepancy-btn"
                } `}
              disabled={!use_elastics || use_elastics === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              Buttons
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  cuts: !cuts,
                  type: "cuts",
                });
              }}
              className={`btn btn-radius text-center h-8 custom_shadow ${type === "cuts" ? "active_discrepancy-btn" : "discrepancy-btn"
                } `}
              disabled={!use_elastics || use_elastics === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              Cuts
            </Button>
            <div></div>
          </div>

          <div className="teeth_selector">
            <CustomeTeeth action_key="Case_Elastics" disabled={!use_elastics || use_elastics === "eonToDecide"}

              summary={this.props.summary} />
          </div>
         */}
          <div className="readOnly-text">
            {" "}
            <Input
              className="teeth_selector"
              name="elastics_details"
              id="elastics_details"
              type="textarea"
              placeholder="Please add details"
              rows={5}
              value={details}
              onChange={(e) => {
                this.setState({ details: e.target.value });
              }}
              readOnly
            />{" "}
          </div>
        </div>

        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            Save
        </Button>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Elastics);
