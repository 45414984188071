import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  resolvedData,
  howManyTsFiles,
  resolvePromise,
  generateStepsForMeshes,
} from "../../TS-Helper";
import loader from "../../TS-VIEWER/loader";
import { createScene, meshesForStep } from "../../TS-VIEWER/Scene";
import viewer from "../../TS-VIEWER/viewer";
import styles from "./eonAccess-style/eonAccess.module.scss";
import {
  updateMeshesAccordingToStepNumber,
  indicatorTracking,
} from "./eonAccess-functionality";
import ProgressBar from "./eonAccess-components/progress-bar";
import ViewerNotFound from "./eonAccess-components/ts-not-found";
import GenerateSteps from "./eonAccess-components/generate-steps";
import GenerateLabels from "./eonAccess-components/generate-labels";
import PlayPauseAnimate from "./eonAccess-components/animate-steps";
import UpperNav from "./eonAccess-components/upperNav"
import LowerNav from "./eonAccess-components/lowerNav"
import SideBar from "./eonAccess-components/side-bar"
import Revise from "./eonAccess-components/revise"
import ModalSettings from "./eonAccess-components/modal-settings";
import 'react-responsive-modal/styles.css';
import { Helmet } from "react-helmet";
function EonAccessTemplate(props) {
  const [revise, setRevise] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const { caseId, tsId, doctorId} = props;
  const renderCanvas = useRef(null);
  const currentWidthStepsRef = useRef(null);

  const [tsOptions, setTSOptions] = useState({
    //default options
    STEPS_PAUSE: 500,
    showUpperArc: true,
    showLowerArc: true,
    showUpper: true, // for maxilla
    showLower: true, // for mandible
    loading: true,
    isAttachmentsFromParam: false, // check if this template toggled the attachments from the params url
    isAttachment: true, // for toggle attachments
    isTSViewerFound: true,
    isAnimateStart: false,
    isSuperImpose: false,
    hasSuperImpose: true,
    isLoaded: false,
    isTsPrepared: false,
  });
  const [stepsOptions, setStepsOptions] = useState({});

  
  const [progress, setProgress] = useState(0);
  const [countProgress, setCountProgress] = useState(1);
  const [currentStep, setCurrentStep] = useState(0);

  const [indicatorWidth, setIndicatorWidth] = useState(
    indicatorTracking(currentStep, meshesForStep, currentWidthStepsRef)
  );
let preparingTSViewer = useCallback(() => {
  resolvePromise(caseId, tsId, doctorId).then(
    resolver => {
      return resolvedData(resolver("manifest.json")).then(({data})=>{
      const lowerSteps = data["lowerStepsNumber"];
      const upperSteps = data["upperStepsNumber"];
      const startTogether = data["startTogether"];
      let passiveAligners = data["passiveAligners"];
      let upperOvercorrectionStepsNumber =
        data["upperOvercorrectionStepsNumber"];
      let lowerOvercorrectionStepsNumber =
        data["lowerOvercorrectionStepsNumber"];

      if (lowerSteps < upperSteps) {
        // override lowerOvercorrectionStepsNumber
        lowerOvercorrectionStepsNumber = passiveAligners
          ? 0
          : data["lowerOvercorrectionStepsNumber"];
          
      } if (upperSteps < lowerSteps) {
        // override lowerOvercorrectionStepsNumber
        upperOvercorrectionStepsNumber = passiveAligners
          ? 0
          : data["upperOvercorrectionStepsNumber"];
      }

      setStepsOptions({
        lowerSteps,
        upperSteps,
        startTogether,
        passiveAligners,
        lowerOvercorrectionStepsNumber,
        upperOvercorrectionStepsNumber,
      });

      let howManyFiles = howManyTsFiles(data, true, tsOptions.hasSuperImpose);
      setCountProgress(howManyFiles);
      generateStepsForMeshes(lowerSteps, upperSteps);
      let scene = createScene(lowerSteps, upperSteps, startTogether);
      return loader(
        resolver,
        data,
        scene,
        setProgress,
        setCountProgress,
        setTSOptions,
        tsOptions.isAttachmentsFromParam,
        tsOptions.hasSuperImpose
      );
      }).then(()=>{
        updateMeshesAccordingToStepNumber(currentStep, tsOptions);
        
        setTSOptions((prevTSOptions) => ({
          ...prevTSOptions,
          isTsPrepared: false,
          loading: false,
          isLoaded: true,
        }));
        
        viewer.initializeAndRun(renderCanvas.current, {
          action: "milkyway-template",
        });
      })
    } 
  )
  .catch((err)=>{
    //  Treatment plan not found
      setTSOptions((prevTSOptions) => ({
        ...prevTSOptions,
        loading: false,
        isTSViewerFound: false,
        isTsPrepared: false,
        isLoaded: true,
      }));
      console.log(err, "Treatment plan not found"); 
  })
},[])

  const handleResizeContainer = () => {
    setIndicatorWidth(
      indicatorTracking(currentStep, meshesForStep, currentWidthStepsRef)
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeContainer);
    window.addEventListener("resize", viewer.zoomValue);
    // console.log(props.saveRejectionReason('yosef'), 'func');
  }, []);
  useEffect(() => {
    if (!tsOptions.isLoaded) {
      preparingTSViewer();
    }
  }, [tsOptions.isLoaded]);
  useEffect(() => {
    setIndicatorWidth(
      indicatorTracking(currentStep, meshesForStep, currentWidthStepsRef)
    );
  }, [currentStep, meshesForStep.length, props.collapse]);

  useEffect(() => {
    if (meshesForStep.length) {
      updateMeshesAccordingToStepNumber(currentStep, tsOptions);
    }
  }, [
    currentStep,
    tsOptions.showUpperArc,
    tsOptions.showLowerArc,
    tsOptions.showUpper,
    tsOptions.showLower,
    tsOptions.isAttachment,
    tsOptions.isSuperImpose,
    tsOptions.isAnimateStart,
  ]);
  useEffect(() => {
    if (tsOptions.isLoaded && tsOptions.isTSViewerFound) {
      props.finished(true)
      if (props.showBiteRecord) {
        setCurrentStep(0)
        setTSOptions((prevOptions) => {
          return { ...prevOptions, isSuperImpose: false, showLowerArc: true,
            showUpperArc: true,
            showUpper: true,
            showLower: true,};
        });
        setRevise(() => true)
        viewer.frontRevise()
      } else {
        setRevise(() => false)
        viewer.frontView()
      }
    } else {
      props.finished(false)
    }
  }, [tsOptions.isLoaded, props.showBiteRecord])
  
  useEffect(() => {
    return () => {
      meshesForStep.filter(() => {
        return meshesForStep.splice(0);
      })
    }
  }, [])
  
  return (
    <div
      className={styles.container}
    >
      <Helmet>
        {/* disable zoom on mobile web page */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, 
            user-scalable=0"
        />
        <title>Plan Viewer</title>
      </Helmet>

      <div className={styles.thewiewerwindow}>
        {/* <!-- Trigger/Open The Modal --> */}
        <div className={styles.header_dot_container}>
          <div
            className={styles.dot_container}
            onClick={() => {
              if (
                tsOptions.loading ||
                tsOptions.isTsPrepared ||
                !tsOptions.isTSViewerFound
              ) return; 
              else {
                toggleModal()
              }}}
          >
            <div
              className={styles.dot}
            ></div>
            <div
              className={styles.dot}
            ></div>
            <div
              className={styles.dot}
            ></div>
          </div>
        </div>
        <ModalSettings
              isModalOpen={isModalOpen}
              toggleModal={toggleModal}
              tsOptions={tsOptions}
              setTSOptions={setTSOptions}
              renderCanvas={renderCanvas.current}
        />
        {revise ? <></> : <UpperNav 
          tsOptions={tsOptions}
          setTSOptions={setTSOptions}
        /> }

        <div className={styles.canvas_contaner}>
          <canvas className={revise ? styles.rendercanvas_revise : styles.rendercanvas } ref={renderCanvas}></canvas>
          {revise ?  <Revise
              setCurrentStep={setCurrentStep} 
              loading={tsOptions.loading}
              isTSViewerFound={tsOptions.isTSViewerFound}
              setTSOptions={setTSOptions}
              revise={revise}
              setRevise={setRevise}
              renderCanvas={renderCanvas.current}
              isTsPrepared={tsOptions.isTsPrepared}
              revisePhoto={props.photos}
              saveRejectionReason={props.saveRejectionReason}
              frontView={viewer.frontView}
          /> : <></> }
      </div>
        

        {/* Progress bar  */}
        <ProgressBar
          loading={tsOptions.loading}
          progress={progress}
          countProgress={countProgress}
          isTsPrepared={tsOptions.isTsPrepared}
          frontView={viewer.frontView}
        />
        {/* Not found viwer  */}
        <ViewerNotFound isTSViewerFound={tsOptions.isTSViewerFound} />

        {/* <!-- Play/Pause animate --> */}
        {revise ? <></> : <PlayPauseAnimate
          tsOptions={tsOptions}
          setTSOptions={setTSOptions}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          meshesForStep={meshesForStep}
        />}
        
        {/* <!-- Steps container --> */}
        {revise ? <></> :
        <div
          className={styles.steps_container}
          style={
            tsOptions.loading ||
            tsOptions.isTsPrepared ||
            !tsOptions.isTSViewerFound
              ? { zIndex: "-1" }
              : { zIndex: "1" }
          }
        >
          <div className={styles.steps_content}>
            {/* <!-- Generate upper steps --> */}
            <GenerateSteps
              isTsPrepared={tsOptions.isTsPrepared}
              meshesForStep={meshesForStep}
              setCurrentStep={setCurrentStep}
              loading={tsOptions.loading}
              lowerSteps={stepsOptions.lowerSteps}
              upperSteps={stepsOptions.upperSteps}
              allStepsForUpperOrLower={stepsOptions.upperSteps} // all upper steps
              startTogether={stepsOptions.startTogether}
              passiveAligners={stepsOptions.passiveAligners}
              overCorrectionStepsNumber={
                stepsOptions.upperOvercorrectionStepsNumber // overcorrection for upper steps
              }
            />
          </div>

          <div className={styles.steps_content}>
            {/* <!-- Generate lower steps --> */}
            <GenerateSteps
              isTsPrepared={tsOptions.isTsPrepared}
              meshesForStep={meshesForStep}
              setCurrentStep={setCurrentStep}
              loading={tsOptions.loading}
              lowerSteps={stepsOptions.lowerSteps}
              upperSteps={stepsOptions.upperSteps}
              allStepsForUpperOrLower={stepsOptions.lowerSteps} // all lower steps
              startTogether={stepsOptions.startTogether}
              passiveAligners={stepsOptions.passiveAligners}
              overCorrectionStepsNumber={
                stepsOptions.lowerOvercorrectionStepsNumber // overcorrection for lower steps
              }
            />
          </div>
          <div className={styles.labels_content} ref={currentWidthStepsRef}>
            <div
              className={styles.tooltip_orange }
              style={
                {
                  left: indicatorTracking(
                    currentStep,
                    meshesForStep,
                    currentWidthStepsRef
                  ),
                  }
              }
            ></div>
            <div
              className={styles.tooltip_white}
              style={
                {
                  left: indicatorTracking(
                    currentStep,
                    meshesForStep,
                    currentWidthStepsRef
                  ) + 5,
                }
              }
            ></div>
            <GenerateLabels
              meshesForStep={meshesForStep}
              setCurrentStep={setCurrentStep}
              loading={tsOptions.loading}
              isTsPrepared={tsOptions.isTsPrepared}
            />
          </div>
        </div> }
        
        {revise ? <></> :
        <LowerNav 
          tsOptions={tsOptions}
          setTSOptions={setTSOptions}/>
        }
      </div>
      {revise ? <></> :
        <SideBar 
          tsOptions={tsOptions}
          setTSOptions={setTSOptions}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          meshesForStep={meshesForStep}/>
      }
    </div>
  );
}

export default EonAccessTemplate;