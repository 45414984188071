import React, { Component } from "react";
import { connect } from "react-redux";
import {
  FormGroup,
  Label,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  Row,
  Col,
  Input,
  Spinner
} from "reactstrap";
import { createDoctorPreferences, getDoctorPreferences } from "../../api/api";
import ToggleSwitch from "../../components/Switch/Switch";
import CustomeTeeth from "../Teeth/CustomeTeeth";
import { mapTeethToApi } from "../../services/mapTeethToApi";
import {
  Doctor_Preferences_IPR,
  Doctor_Preferences_Attachments,
} from "../../redux/customeTeeth/action";
import { mapTeeth } from "../../services/mapTeeth";
import { successToaster } from "../../services/toast";
import { Clear_Teeth_Data } from "../../redux/customeTeeth/action"
import TreatmentOptions from "../../components/TreatmentOptions/TreatmentOptions";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  Doctor_Preferences_IPR: (payload) =>
    dispatch(Doctor_Preferences_IPR(payload)),
  Doctor_Preferences_Attachments: (payload) =>
    dispatch(Doctor_Preferences_Attachments(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload))
});
class DoctorPreferences extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      doctorId: props.userReducer.currentUser.id,
      use_ipr: false,
      ipr_timing_1: false,
      ipr_timing_3: false,
      use_attachments: false,
      attachments_timing_3: false,
      attachments_timing_7: false,
      passive_aligners: false,
      wear_cycle: "",
      pontics: false,
      overcorrection: false,
      level_incisal_edge: false,
      level_gingival_margins: false,
      doctor_preferences: {},
      ipr_timing: "",
      attachments_timing: "",
      loadingData: true,
      other_preferences: ""
    };
  }

  componentDidMount() {
    this.GetDoctorPreferences();
  }
  /* --------------------------------------------------------------------------------*/
  /* Get Doctor Preferences */
  /* --------------------------------------------------------------------------------*/
  GetDoctorPreferences = () => {
    getDoctorPreferences(this.state.doctorId).then((res) => {
      if (res.length) {
        let filtered_doctor_preferences = res.filter((value, index) => {
          return value.current === true;
        });
        this.mapDoctorPreferences(filtered_doctor_preferences);
      } else {
        this.setState({
          loadingData: false,
        })
      }
    });
  };
  /* --------------------------------------------------------------------------------*/
  /* Create Doctor Preferences */
  /* --------------------------------------------------------------------------------*/
  CreateDoctorPreferences = () => {
    let data = {
      doctor_preferences: {
        preferences: {
          passive_aligners: this.state.passive_aligners,
          wear_cycle: this.state.wear_cycle,
          pontics: this.state.pontics,
          overcorrection: this.state.overcorrection,
          upper_arch_leveling: {
            level_incisal_edge: this.state.level_incisal_edge,
            level_gingival_margins: this.state.level_gingival_margins,
          },
          ipr: {
            use_ipr: this.state.use_ipr,
            ipr_timing: this.state.ipr_timing,
            iprRestriction: mapTeethToApi(
              this.props.customeTeethReducer.Doctor_Preferences_IPR
            ),
          },
          attachments: {
            use_attachments: this.state.use_attachments,
            attachments_timing: this.state.attachments_timing,
            attachmentsRestriction: mapTeethToApi(
              this.props.customeTeethReducer.Doctor_Preferences_Attachments
            ),
          },
          other_preferences: 
            this.state.other_preferences
          
        },
      },
    };
    createDoctorPreferences(this.state.doctorId, JSON.stringify(data)).then(
      (res) => {

        successToaster("update Doctor Preferences successfully")
        this.GetDoctorPreferences();
      }
    );
  };

  /* --------------------------------------------------------------------------------*/
  /* map Doctor Preferences */
  /* --------------------------------------------------------------------------------*/
  mapDoctorPreferences = (data) => {
    let doctor_preferences = data[0]?.preferences;
    this.setState({
      loadingData: false,
      use_ipr: doctor_preferences.ipr.use_ipr,
      use_attachments: doctor_preferences.attachments.use_attachments,
      passive_aligners: doctor_preferences.passive_aligners,
      wear_cycle: doctor_preferences.wear_cycle,
      pontics: doctor_preferences.pontics,
      overcorrection: doctor_preferences.overcorrection,
      level_incisal_edge:
        doctor_preferences.upper_arch_leveling.level_incisal_edge,
      level_gingival_margins:
        doctor_preferences.upper_arch_leveling.level_gingival_margins,
      ipr_timing: doctor_preferences.ipr.ipr_timing,
      attachments_timing: doctor_preferences.attachments.attachments_timing,
      other_preferences: doctor_preferences.other_preferences
    });
    let iprRestriction = mapTeeth(doctor_preferences.ipr.iprRestriction);
    let attachmentsRestriction = mapTeeth(
      doctor_preferences.attachments.attachmentsRestriction
    );
    this.props.Doctor_Preferences_IPR(iprRestriction);
    this.props.Doctor_Preferences_Attachments(attachmentsRestriction);
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  render() {
    const {
      use_ipr,
      use_attachments,
      passive_aligners,
      wear_cycle,
      pontics,
      overcorrection,
      level_incisal_edge,
      level_gingival_margins,
      ipr_timing,
      attachments_timing,
      other_preferences
    } = this.state;
    return (
      <div>
        <Card>
          <CardTitle className="border-bottom p-3 title_active-gco">
           <div className="l-title">Doctor Preferences</div> 
            <div className="paragraph pt-2">Doctor preferences are only applied to cases submitted without advanced options.</div>
          </CardTitle>
          
          {this.state.loadingData ? <div className="align-items-center  m-auto d-flex p-3"> <Spinner className="green-spinner" type="grow" /></div>

            : <>
              <CardBody>
                <Row>
                  <Col xs="12" md="12" lg="5">
                    <div className="form-group content form-block-holder grid_container">

                      <div className="grid_container">
                        <div className="control-label title_active-gco">
                          Passive Aligners
                    </div>

                        <TreatmentOptions value={passive_aligners} name="passive_aligners" onChange={(value) => {
                          this.handelTreatmentOptionsChanges(value, "passive_aligners")
                        }} />

                      </div>


                      <div className="grid_container">
                        <label className="control-label title_active-gco ">
                          Wear Cycle
                        </label>

                        <div className="auto_grid3 mt-1">
                          <Button
                            className={`btn  ${wear_cycle === "express"
                              ? "preference-btn-active"
                              : "preference-btn"
                              } text-center  btn-radius`}
                            size="sm"
                            onClick={() => {
                              this.setState({ wear_cycle: "express" });
                            }}
                          >
                            One week cycle
                         </Button>
                          <Button
                            className={`btn ${wear_cycle === "regular"
                              ? "preference-btn-active"
                              : "preference-btn"
                              } text-center  btn-radius`}
                            size="sm"
                            onClick={() => {
                              this.setState({ wear_cycle: "regular" });
                            }}
                          >
                            Two week cycle
                      </Button>
                        </div>
                      </div>


                      <div className="grid_container">
                        <div className="control-label title_active-gco mt-2">
                          Pontics
                    </div>
                        <TreatmentOptions value={pontics} name="pontics" onChange={(value) => {
                          this.handelTreatmentOptionsChanges(value, "pontics")
                        }} />
                      </div>


                      <div className="grid_container">
                        <div className="control-label title_active-gco mt-2">
                          Overcorrection
                    </div>
                        <TreatmentOptions value={overcorrection} name="overcorrection" onChange={(value) => {
                          this.handelTreatmentOptionsChanges(value, "overcorrection")
                        }} />


                      </div>

                      <div className="grid_container">
                        <label className="control-label title_active-gco mt-3 ">
                          Upper arch leveling{" "}
                        </label>

                        <div className="auto_grid3 mt-1">
                          <Button
                            className={`btn  ${level_incisal_edge
                              ? "preference-btn-active"
                              : "preference-btn"
                              } text-center  btn-radius`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                level_incisal_edge: !level_incisal_edge,
                                level_gingival_margins: false,

                              });
                            }}
                          >
                            level incisal edge
                      </Button>
                          <Button
                            className={`btn ${level_gingival_margins
                              ? "preference-btn-active"
                              : "preference-btn"
                              } text-center  btn-radius`}
                            size="sm"
                            onClick={() => {
                              this.setState({
                                level_incisal_edge: false,
                                level_gingival_margins: !level_gingival_margins,
                              });
                            }}
                          >
                            level gingival margins
                      </Button>
                        </div>
                      </div>
                      <div className="grid_container">
                      <FormGroup>
                      <label className="control-label title_active-gco mt-4">
                          Other preferences
                        </label>
                        <Input
                          className="mt-4"
                          name="other-preferences"
                          id="other-preferences"
                          type="textarea"
                          rows={5}
                          value={other_preferences}
                          onChange={(e) => {
                            this.setState({
                              other_preferences: e.target.value,
                            });
                          }}
                        />{" "}
                      </FormGroup>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12" md="12" lg="7">
                    <div className="form-group content form-block-holder grid_container">
                      <div className="control-label title_active-gco ">IPR</div>


                      <div>


                        <TreatmentOptions value={use_ipr} name="use_ipr" onChange={(value) => {
                          this.handelTreatmentOptionsChanges(value, "use_ipr")
                          if (value !== true) {
                            this.props.Clear_Teeth_Data("Doctor_Preferences_IPR")
                            this.setState({ ipr_timing: "" })
                          }
                        }} />



                      </div>


                      <div className="grid_container">
                        {" "}
                        <div className="auto_grid_timing2">
                          <div className="control-label title_active-gco align-self-end">
                            IPR timing{" "}
                          </div>
                          <div className="auto_grid2">
                            {/* <Button
                              className={`rounded-circle ${ipr_timing === 1 ? "num-btn-active" : "num-btn"
                                }`}
                              onClick={() => {
                                this.setState({
                                  ipr_timing: 1,
                                });
                              }}
                              disabled={!use_ipr || use_ipr === "eonToDecide"}
                            >
                              1
                        </Button>
                            <Button
                              className={`rounded-circle ${ipr_timing === 3 ? "num-btn-active" : "num-btn"
                                }`}
                              onClick={() => {
                                this.setState({
                                  ipr_timing: 3,
                                });
                              }}
                              disabled={!use_ipr || use_ipr === "eonToDecide"}

                            >
                              3
                        </Button> */}
                            {/* <div className="control-label title_active-gco custom-label">
                              Custom
                        </div> */}
                            <div className="form-material">
                              <Input
                                type="number"
                                placeholder=""
                                className="custom-number"
                                // value={
                                //   ipr_timing === 1 || ipr_timing === 3
                                //     ? ""
                                //     : ipr_timing
                                // }
                                onClick = {e => {
                                e.target.focus();
                                }}
                                onMouseUp = {e => {
                                  e.target.blur();
                                }}
                                value={
                                  ipr_timing
                                }
                                min="1"                 
                                onChange={(e) => {
                                  this.setState({
                                    ipr_timing: e.target.value,
                                  });
                                }}

                                disabled={!use_ipr || use_ipr === "eonToDecide"}

                              />
                            </div>
                          </div>
                        </div>
                        <div className="auto_grid">
                          <div className="control-label title_active-gco pr-2">
                            Restrictions
                      </div>
                          <div className="teeth_selector">
                            <CustomeTeeth
                              action_key="Doctor_Preferences_IPR"
                              disabled={!use_ipr || use_ipr === "eonToDecide"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="mt-5 mb-5"></hr>
                    <div className="form-group content form-block-holder grid_container ">
                      <div className="control-label title_active-gco ">
                        Attachments{" "}
                      </div>
                      <div>

                        <TreatmentOptions value={use_attachments} name="use_attachments" onChange={(value) => {
                          this.handelTreatmentOptionsChanges(value, "use_attachments")
                          if (value !== true) {
                            this.props.Clear_Teeth_Data("Doctor_Preferences_Attachments")
                            this.setState({ attachments_timing: "" })
                          }
                        }} />
                      </div>


                      <div className="grid_container">
                        {" "}
                        <div className="auto_grid_timing2">
                          <div className="control-label title_active-gco align-self-end">
                            Attachments timing{" "}
                          </div>
                          <div className="auto_grid2">
                            {/* <Button
                              className={`rounded-circle ${attachments_timing === 3
                                ? "num-btn-active"
                                : "num-btn"
                                }`}
                              onClick={() => {
                                this.setState({
                                  attachments_timing: 3,
                                });
                              }}

                              disabled={!use_attachments || use_attachments === "eonToDecide"}
                            >
                              3
                        </Button>
                            <Button
                              className={`rounded-circle ${attachments_timing === 7
                                ? "num-btn-active"
                                : "num-btn"
                                }`}
                              onClick={() => {
                                this.setState({
                                  attachments_timing: 7,
                                });
                              }}
                              disabled={!use_attachments || use_attachments === "eonToDecide"}

                            >
                              7
                        </Button> */}
                            {/* <div className="control-label title_active-gco custom-label">
                              Custom
                        </div> */}
                            <div className="form-material">
                              <Input
                                type="number"
                                placeholder=""
                                className="custom-number"
                                // value={
                                //   attachments_timing === 3 ||
                                //     attachments_timing === 7
                                //     ? ""
                                //     : attachments_timing
                                // }
                                onClick = {e => {
                                  e.target.focus();
                                  }}
                                  onMouseUp = {e => {
                                    e.target.blur();
                                  }}
                                  value={
                                    attachments_timing
                                  }
                                  min="1"                 
                                  onChange={(e) => {
                                    this.setState({
                                      attachments_timing: e.target.value,
                                    });
                                  }}

                                disabled={!use_attachments || use_attachments === "eonToDecide"}

                              />
                            </div>
                          </div>
                        </div>
                        <div className="auto_grid">
                          <div className="control-label title_active-gco pr-2">
                            Restrictions
                      </div>
                          <div className="teeth_selector">
                            <CustomeTeeth
                              action_key="Doctor_Preferences_Attachments"
                              disabled={!use_attachments || use_attachments === "eonToDecide"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="border-bottom p-3">
                <Button
                  className={`btn  ${"preference-btn"} float-right text-center  btn-radius`}
                  size="sm"
                  onClick={() => {
                    this.CreateDoctorPreferences();
                  }}
                >
                  Save Preferences{" "}
                </Button>{" "}
              </CardFooter>
            </>
          }
        </Card>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DoctorPreferences);
