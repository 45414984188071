import { upper_teeth, lower_teeth } from "../redux/newCase/init_teeth";

export function mapTeeth_ipr(newData) {
  console.log(newData, "daaaaata");
  let RightTeethUpper = mapRightTeeth(
    newData,
    "upper_r_",
    upper_teeth,
    "upper_teeth"
  );
  let RightTeethLower = mapRightTeeth(
    newData,
    "lower_r_",
    lower_teeth,
    "lower_teeth"
  );

  let LeftTeethUpper = mapLeftTeeth(
    newData,
    "upper_l_",
    upper_teeth,
    "upper_teeth"
  );
  let LeftTeethLower = mapLeftTeeth(
    newData,
    "lower_l_",
    lower_teeth,
    "lower_teeth"
  );

  let teeth = {
    upper_teeth: { ...RightTeethUpper, ...LeftTeethUpper },
    lower_teeth: { ...RightTeethLower, ...LeftTeethLower },
  };
  return teeth;
}

function mapRightTeeth(newData, key, teeth, key2) {
  let num = 8;
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]:
        newData[key2][`${key}${num}`] !== {} && newData[key2][`${key}${num}`] !== undefined
          ? {
              ...teeth[`${key}${num}`],
              image: newData[key2][`${key}${num}`].value
                ? teeth[`${key}${num}`].not_active
                : teeth[`${key}${num}`].not_active,
              value: newData[key2][`${key}${num}`].value,
              amount:newData[key2][`${key}${num}`].amount,
              before_step:newData[key2][`${key}${num}`].before_step,
              use_ipr:newData[key2][`${key}${num}`].use_ipr,
              do_not_perform_ipr:newData[key2][`${key}${num}`].do_not_perform_ipr

            }
          : {
              ...teeth[`${key}${num}`],
            },
    };
    num--;
  }
  return rightTeeth;
}

function mapLeftTeeth(newData, key, teeth, key2) {
  let num = 1;
  let leftTeeth = {};
  for (var x = 1; x < 9; x++) {
    leftTeeth = {
      ...leftTeeth,

      [`${key}${num}`]:
        newData[key2][`${key}${num}`] !== {} && newData[key2][`${key}${num}`] !== undefined
          ? {
              ...teeth[`${key}${num}`],
              image: newData[key2][`${key}${num}`].value
                ? teeth[`${key}${num}`].not_active
                : teeth[`${key}${num}`].not_active,
              value: newData[key2][`${key}${num}`].value,
              amount:newData[key2][`${key}${num}`].amount,
              before_step:newData[key2][`${key}${num}`].before_step,
              use_ipr:newData[key2][`${key}${num}`].use_ipr,
              do_not_perform_ipr:newData[key2][`${key}${num}`].do_not_perform_ipr
            }
          : {
              ...teeth[`${key}${num}`],
            },
    };
    num++;
  }
  return leftTeeth;
}
