import React, { Component } from "react";
import { connect } from "react-redux";

import {
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  Card,
  CardImg,
  Input,
} from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { schema, schema2, schema3 } from "../../helpers/ImpressionsValidations";
import three_d_scan from "../../assets/images/caseSubmission/upload3d_full_not_active.svg";
import three_d_scan_active from "../../assets/images/caseSubmission/upload3d_full_active.svg";
import pickup from "../../assets/images/caseSubmission/pickup.svg";
import pickup_active from "../../assets/images/caseSubmission/pickup_active.svg";
import other_digital_not_active from "../../assets/images/caseSubmission/other_digital_not_active.svg";
import other_digital_active from "../../assets/images/caseSubmission/other_digital_active.svg"

import { upload_impression_data } from "../../helpers/impressions";
import { CircularProgressbar } from "react-circular-progressbar";
import { uploadFile, keyToUrl } from "../../helpers/s3";
import { fixAdjustmentRejections, updateAlignerCase } from "../../api/api";
import { mapTeethToApi } from "../../services/mapTeethToApi";
import { infoToaster } from "../../services/toast";
import { mapAdvancedToApi } from "../../services/mapAdvancedToApi";
import { updateImpressions } from "../../redux/CaseSubmission/action";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateImpressions: (payload) => dispatch(updateImpressions(payload)),

});
class Impressions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorId: props.userReducer.currentUser.id,
      gco_doctor: props.userReducer.currentUser.gco_doctor,

      chose_one: "",
      impressions_method: "",
      loading_percentage: 0,
      intervalID: 0,
      showProgress: false,
      loading_percentage_lower_arch_url: 0,
      loading_percentage_upper_arch_url: 0,
      impressions_third_party_note: "",
      upper_arch_url: "",
      lower_arch_url: "",
      caseId:
        this.props.caseId ||
        (window.location.search.match(/id=([^&]+)/) || [])[1],
    };
  }

  checkIfChanged = (oldobj, newobj) => {

    if (oldobj.impressions_method === newobj.impressions_method
      &&
      oldobj.impressions_third_party_note === newobj.impressions_third_party_note
      &&
      oldobj.upper_arch_url === newobj.upper_arch_url
      &&
      oldobj.lower_arch_url === newobj.lower_arch_url
    ) {
      return false
    } else {
      return true
    }
  }
  handleSubmit = (values, { setSubmitting }) => {
    const { doctorId } = this.state;
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    let impressions = {
      upper_arch_url: values.upper_arch_url,
      lower_arch_url: values.lower_arch_url,
    };
    let filtered_impressions = {};

    for (var key1 in impressions) {
      if (impressions[key1].match(/http([^&]+)/)) {
        filtered_impressions[key1] = impressions[key1];
      }
    }
    let newImpressions = {
      impressions_method: values.impressions_method,
      impressions_third_party_note: values.impressions_third_party_note,
      lower_arch_url: values.lower_arch_url,
      upper_arch_url: values.upper_arch_url
    }
    let newImpressionsForReducer = {
      impressions_method: values.impressions_method,
      impressions_third_party_note: values.impressions_method !== "upload" ? values.impressions_third_party_note : "",
      lower_arch_url: values.impressions_method === "upload" ? values.lower_arch_url : "",
      upper_arch_url: values.impressions_method === "upload" ? values.upper_arch_url : ""
    }
    let checkimpressions = this.checkIfChanged(this.props.caseSubmission.impressions, newImpressions)
    if (checkimpressions) {
      setSubmitting(true);

      let key =
        this.props.rejectable_type === "Adjustment"
          ? "adjustment"
          : "aligner_case";
      let data = {
        [key]: {
          step: "impressions",
          impressions_method: values.impressions_method,
          impressions_third_party_note: values.impressions_third_party_note,
          ...(values.impressions_method === "upload" && {
            impressions: filtered_impressions,
          }),
          ...(!this.props.rejectable_id && {
            advanced_modules: {
              ...this.props.caseSubmission.advanced_modules,
              ipr: {
                ...this.props.caseSubmission.advanced_modules.ipr,
                iprRestriction: mapTeethToApi(this.props.customeTeethReducer.Case_IPR)
              },
              archExpansion: {
                ...this.props.caseSubmission.advanced_modules.archExpansion,
                teeth: mapTeethToApi(
                  this.props.customeTeethReducer.Case_Arch_Expansion
                )
              },
              attachments: {
                ...this.props.caseSubmission.advanced_modules.attachments,
                attachmentsRestriction: mapTeethToApi(
                  this.props.customeTeethReducer.Case_Attachments
                )
              },
              elastics: {
                ...this.props.caseSubmission.advanced_modules.elastics,
                teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics),
                buttons_teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics_Buttons),
                cuts_teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics_Cuts),
              },
              extraction: {
                ...this.props.caseSubmission.advanced_modules.extraction,
                teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Extraction),
              },
              overCorrection: {
                ...this.props.caseSubmission.advanced_modules.overCorrection,
                teeth: mapTeethToApi(
                  this.props.customeTeethReducer.Case_Overcorrections
                ),
              },
              pontics: {
                ...this.props.caseSubmission.advanced_modules.pontics,
                teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Pontics),
              },
              torqueEnhancers: {
                ...this.props.caseSubmission.advanced_modules.torqueEnhancers,
                teeth: mapTeethToApi(
                  this.props.customeTeethReducer.Case_Torque_Enhancers
                ),
              },
              malocclusion: {
                ...this.props.caseSubmission.advanced_modules.malocclusion,
                crossbite: {
                  ...this.props.caseSubmission.advanced_modules.malocclusion
                    .crossbite,
                  teeth: mapTeethToApi(
                    this.props.customeTeethReducer.Case_Crossbites
                  ),
                },
              },
              all_modules: mapAdvancedToApi(this.props.advancedModules.modules),
            }

          }),

        },
        ...(this.props.rejectable_id && {
          resolve_rejection_id: this.props.rejectable_id,
        })
      };
      // this.props.NextStep();

      if (this.props.rejectable_type === "Adjustment") {
        fixAdjustmentRejections(doctorId, caseId, this.props.stepToFix_id, JSON.stringify(data))
          .then((res) => {
            this.props.rejectable_id ?
              this.props.FixAlignerCase() :
              this.props.NextStep();
            this.props.updateImpressions(newImpressionsForReducer)
            setSubmitting(false);
          })
          .catch((error) => {
            setSubmitting(false);
          });
      } else {
        updateAlignerCase(doctorId, caseId, JSON.stringify(data))
          .then((res) => {

            this.props.rejectable_id ?
              this.props.FixAlignerCase() :
              this.props.NextStep();
            this.props.updateImpressions(newImpressionsForReducer)

            setSubmitting(false);
          })
          .catch((error) => {
            setSubmitting(false);
          });
      }

    } else {
      infoToaster("nothing is changed .......")
      this.props.rejectable_id ?
        this.props.FixAlignerCase() :
        this.props.NextStep();
    }

  };

  /*--------------------------------------------------------------------------------*/
  /* uploading file timer                                             */
  /*--------------------------------------------------------------------------------*/
  timer = (id) => {
    this.setState({
      [`loading_percentage_${id}`]: this.state[`loading_percentage_${id}`] + 5,
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* Upload image to s3  */
  /* --------------------------------------------------------------------------------*/
  uploadImage = async (image, id) => {
    let extention = image.name.split(".");
    return uploadFile({
      name: `case-${this.state.caseId}/patient-impression/${id}/${new Date().valueOf()}.${image.name.split(".")[extention.length - 1]
        }`,
      contentType: image.type,
      file: image,
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* upload photos */
  /* --------------------------------------------------------------------------------*/

  UploadPhoto = async (e, id, setFieldValue) => {
    e.persist();

    if (e.target.files[0]) {
      const intervalID = setInterval(this.timer(id), 1000);
      this.setState({
        loading1: true,
        intervalID,
        [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
      });
      let reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
      const file = e.target ? e.target.files[0] : e.files[0];
      const { key } = await this.uploadImage(file, id);
      clearInterval(this.state.intervalID);
      this.setState({
        [id]: keyToUrl(key),
        [`loading_percentage_${id}`]: 100,
      });

      setFieldValue(id, keyToUrl(key));
      setFieldValue("chose_one", true)
      setTimeout(() => {
        this.setState({
          [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
          [`loading_percentage_${id}`]: 0,
        });
      }, 500);
    }
  };
  render() {
    const { impressions } = this.props.caseSubmission;
    return (
      <div>
        {
          !this.props.rejectable_id && <CardTitle className="border-bottom pb-3 title_active-gco case-title">
            <div className="step-header-title">
              <span>Impressions</span>
              <span className="patient_info">
                <div> {this.props.patient.full_name}
                </div>
                <div> Case {this.state.caseId}
                </div>
              </span>
            </div>
          </CardTitle>
        }
        <Formik
          initialValues={{
            impressions_method: impressions.impressions_method,
            impressions_third_party_note:
              impressions.impressions_third_party_note,
            upper_arch_url: impressions.upper_arch_url,
            lower_arch_url: impressions.lower_arch_url,
            chose_one: this.state.chose_one
          }}
          validationSchema={
            (this.props.case.case_type === "UnlimitedII" || this.props.case.case_type === "LiteII")
              && this.state.impressions_method === "upload" ? schema2 : this.state.impressions_method === "upload" ? schema : schema3}
          onSubmit={this.handleSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="step-progress-new-case upload-container">
                {isSubmitting && (
                  <div className={`loading2`}>
                    <div className="spinner_2"></div>
                  </div>
                )}
              </div>
              <CardBody id="case-submission" className="impressions_grid">
                <Card
                  className={`p-3 ${values.impressions_method === "upload"
                    ? "active_impression_card"
                    : "impression_card"
                    }`}
                  onClick={() => {
                    setFieldValue("impressions_method", "upload");
                    this.setState({ impressions_method: "upload" });
                  }}
                  id="impressions_method"
                >
                  <div className="add-arrow">
                    <img
                      src={
                        values.impressions_method === "upload"
                          ? three_d_scan_active
                          : three_d_scan
                      }
                      className="treatment-type-img"
                      alt="Upload 3D Scans"
                    />
                    {/* <img
                      src={
                        values.impressions_method === "upload"
                          ? arrow_up_active
                          : arrow_up
                      }
                      className="arrow_up"
                    /> */}
                  </div>
                  <CardTitle className="pt-2 m-0"> Upload 3D Scans</CardTitle>
                </Card>

                <Card
                  className={`p-3 ${values.impressions_method === "third_party"
                    ? "active_impression_card"
                    : "impression_card"
                    }`}
                  onClick={() => {
                    setFieldValue("impressions_method", "third_party");
                    // setFieldValue("lower_arch_url", "");
                    // setFieldValue("upper_arch_url", "");
                    setFieldValue("chose_one", "")

                    this.setState({ impressions_method: "third_party" });
                  }}
                  id="impressions_method"
                >
                  <img
                    src={
                      values.impressions_method === "third_party"
                        ? other_digital_active
                        : other_digital_not_active
                    }
                    className="treatment-type-img"
                    alt="Other Digital Means"
                  />
                  <CardTitle className="pt-2 m-0" >
                    {" "}
                 3rd Party Integration
                    <span
                      className="icon-container tooltip1"
                      id="HybridTreatmentMoreInfo"
                    >
                      <i className="fas fa-exclamation-circle "></i>
                      <div
                        className={`${window.innerWidth < 840
                          ? "tooltip-bottom"
                          : "tooltip1-right"
                          }  custom_shadow`}
                      >

                        <div className="tooltip-body">
                          Use this option for third party integration systems
                          only (for example: 3shape communicate and other services )
                        </div>
                        <i></i>
                      </div>
                    </span>

                  </CardTitle>
                </Card>
                {
                  !this.state.gco_doctor && <Card
                    className={`p-3 ${values.impressions_method === "courier"
                      ? "active_impression_card"
                      : "impression_card"
                      }`}
                    onClick={() => {
                      setFieldValue("impressions_method", "courier");
                      // setFieldValue("lower_arch_url", "");
                      // setFieldValue("upper_arch_url", "");
                      setFieldValue("chose_one", "")

                      this.setState({ impressions_method: "courier" });
                    }}
                    id="impressions_method"
                  >
                    <img
                      src={
                        values.impressions_method === "courier"
                          ? pickup_active
                          : pickup
                      }
                      className="treatment-type-img"
                      alt="Impression Pickup"
                    />
                    <CardTitle className="pt-2 m-0"> Impression Pickup</CardTitle>
                  </Card>

                }     </CardBody>
              <ErrorMessage
                component="div"
                name={"impressions_method"}
                className="custom-invalid-feedback m-1 text-center"
              />

              {values.impressions_method === "upload" && (
                <div className="upload-impression-container">
                  {" "}
                  <CardTitle className="pt-0 pb-4 m-0">
                    {" "}
                    Upload Impressions
                  </CardTitle>
                  <div className="upload_impressions_grid">
                    {upload_impression_data.map((value, index) => (
                      <div key={index}>
                        <Card className="p-2">
                          <CardTitle className="border-bottom pb-2 ">
                            {value.title}
                          </CardTitle>
                          <div className="image-overlay-container">
                            <label
                              name={value.key}
                              htmlFor={value.key}
                              className="m-0"
                            >
                              <CardImg
                                src={value.img}
                                className="upload-impression-img"
                              />
                              {values[value.key] && (
                                <div className="overlay-loader" htmlFor={value.key}
                                >
                                  <div className="layer overlay-loader"></div>
                                </div>
                              )}

                              {this.state[`showProgress_${value.key}`] && (
                                <div className="overlay-loader">
                                  <CircularProgressbar
                                    className="circule-progress-bar"
                                    value={
                                      this.state[
                                      `loading_percentage_${value.key}`
                                      ]
                                    }
                                    text={`${this.state[
                                      `loading_percentage_${value.key}`
                                    ]
                                      }%`}
                                  />
                                </div>
                              )}
                            </label>
                            <input
                              type="file"
                              id={value.key}
                              onChange={(e) => {
                                this.UploadPhoto(e, value.key, setFieldValue);
                              }}
                              multiple={false}
                              className="visually-hidden"
                              accept=".zip,.rar,.7zip,.ply,.stl"
                            />


                          </div>
                        </Card>
                        <ErrorMessage
                          component="div"
                          name={value.key}
                          className="custom-invalid-feedback m-1"
                        />
                      </div>
                    ))}

                  </div>



                  <ErrorMessage
                    component="div"
                    name={"chose_one"}
                    className="custom-invalid-feedback m-1 text-center chose_one"
                  /></div>
              )}

              {/*--------------------------------------------------------------------------------*/}
              {/* Digital Means Comments */}
              {/*--------------------------------------------------------------------------------*/}
              {values.impressions_method === "third_party" && (
                <div className="form-group content form-block-holder">
                  <label className="control-label title_active mt-3 mb-3 header">
                    Additional Comments
                  </label>
                  <div>
                    <Input
                      autoComplete="off"
                      type="textarea"
                      rows={8}
                      placeholder="Provide links to 3rd party integration systems.
                       (Ex. 3shape communicate and other services)"
                      className="green_input"
                      defaultValue={values.impressions_third_party_note}
                      onChange={(e) => {
                        this.setState({
                          impressions_third_party_note: e.target.value,
                        });
                        setFieldValue(
                          "impressions_third_party_note",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              )}
              {/*--------------------------------------------------------------------------------*/}
              {/*End Digital Means Comments */}
              {/*--------------------------------------------------------------------------------*/}
              <CardFooter className="p-3">

                {
                  this.props.rejectable_id ?
                    <Button
                      className={`btn  ${"preference-btn"} float-right text-center mb-2 btn-radius`}
                      size="sm"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Fix
                   {/* <i className="fas fa-angle-right arrows-icon pl-1"></i> */}
                    </Button>
                    : <>

                      <Button
                        className={`btn  ${"preference-btn"} float-left text-center mb-2  btn-radius`}
                        size="sm"
                        type="button"
                        disabled={isSubmitting}
                        onClick={() => this.props.PrevStep()}
                      >
                        <i className="fas fa-angle-left arrows-icon pr-1"></i>     Photos + Xrays
                </Button>{" "}
                      <Button
                        className={`btn  ${"preference-btn"} float-right text-center mb-2 btn-radius`}
                        size="sm"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Prescription <i className="fas fa-angle-right arrows-icon pl-1"></i>
                      </Button>{" "}
                    </>
                }

              </CardFooter>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Impressions);
