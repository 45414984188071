import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input, Row, Col } from "reactstrap";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import ToggleSwitch from "../../../../../components/Switch/Switch";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
});
class ToothSizeDiscrepancy extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_address_discrepancy: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.use_address_discrepancy,
      leaveSpaceAroundLaterals: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.leaveSpaceAroundLaterals,
      leaveSpaceDistalLaterals: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.leaveSpaceDistalLaterals,
      iprOpposing: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.iprOpposing,
      other: this.props.caseSubmission.advanced_modules.toothSizeDiscrepancy
        .other,
      other_details: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.other_details,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "toothSizeDiscrepancy",
      data: {
        use_address_discrepancy: this.state.use_address_discrepancy,
        leaveSpaceAroundLaterals: this.state.leaveSpaceAroundLaterals,
        leaveSpaceDistalLaterals: this.state.leaveSpaceDistalLaterals,
        iprOpposing: this.state.iprOpposing,
        other: this.state.other,
        other_details: this.state.other_details,
      },
    });
    this.props.save();
  };
  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const {
      use_address_discrepancy,
      leaveSpaceAroundLaterals,
      leaveSpaceDistalLaterals,
      iprOpposing,
      other,
      other_details,
    } = this.state;

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">
          {/* <div>
            <div className="control-label title_active-gco text-center mb-2">
              Address discrepancy for this case{" "}
            </div>
            <div className="toggle-slider form-group content form-block-holder custome-toggle m-auto mt-2 ">
              <input
                type="range"
                step="50"
                onChange={(e) => {
                  this.slider(e, "use_address_discrepancy")

                  if (e?.target?.value === "0" || e?.target?.value === "50") {
                    this.setState({
                      leaveSpaceAroundLaterals: false,
                      leaveSpaceDistalLaterals: false,
                      iprOpposing: false,
                      other: false,
                      other_details: ""
                    })

                  }
                }}
                value={this.sliderValue("use_address_discrepancy")}
                style={{ pointerEvents: this.props.summary ? "none" : "" }}

              />
              <Row className="align-items-center ">
                <Col xs="4" md="4" lg="4" className="text-left">
                  <label className="title_active">No</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-center">
                  <label className="title_active">EON to Decide</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-right">
                  <label className="title_active">Yes</label>
                </Col>
              </Row>
            </div>

          </div> */}

           <div className="auto_grid4  pb-2">
            <div className="control-label title_active-gco">
              Address discrepancy for this case{" "}
            </div>
            <div>
              <ToggleSwitch
                id="use_address_discrepancy"
                disabled={this.props.summary}

                checked={use_address_discrepancy}
                onChange={() => {
                  this.setState({
                    use_address_discrepancy: !use_address_discrepancy,
                    ...use_address_discrepancy && {
                      leaveSpaceAroundLaterals: false,
                      leaveSpaceDistalLaterals: false,
                      iprOpposing: false,
                      other: false,
                      other_details: ""
                    }
                  });
                }}
              />
            </div>
          </div>
         

          <div className="address_discrepancy">
            <Button
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

              onClick={() => {
                this.setState({
                  leaveSpaceAroundLaterals: true,
                  leaveSpaceDistalLaterals: false,
                  iprOpposing: false,
                  other: false,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${leaveSpaceAroundLaterals
                ? "active_discrepancy-btn"
                : "discrepancy-btn"
                } `}

              disabled={!use_address_discrepancy || use_address_discrepancy === "eonToDecide"}
            >
              Leave spaces around the laterals
            </Button>
            <Button
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

              onClick={() => {
                this.setState({
                  leaveSpaceAroundLaterals: false,
                  leaveSpaceDistalLaterals: true,
                  iprOpposing: false,
                  other: false,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${leaveSpaceDistalLaterals
                ? "active_discrepancy-btn"
                : "discrepancy-btn"
                } `}
              disabled={!use_address_discrepancy || use_address_discrepancy === "eonToDecide"}

            >
              Leave space distal to lateral
            </Button>
            <Button
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

              onClick={() => {
                this.setState({
                  leaveSpaceAroundLaterals: false,
                  leaveSpaceDistalLaterals: false,
                  iprOpposing: true,
                  other: false,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${iprOpposing ? "active_discrepancy-btn" : "discrepancy-btn"
                } `}
              disabled={!use_address_discrepancy || use_address_discrepancy === "eonToDecide"}

            >
              IPR opposing
            </Button>
            <Button
              style={{ pointerEvents: this.props.summary ? "none" : "" }}
              onClick={() => {
                this.setState({
                  leaveSpaceAroundLaterals: false,
                  leaveSpaceDistalLaterals: false,
                  iprOpposing: false,
                  other: true,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${other ? "active_discrepancy-btn" : "discrepancy-btn"
                } `}
              disabled={!use_address_discrepancy || use_address_discrepancy === "eonToDecide"}

            >
              Other
            </Button>
          </div>

          {other && (
            <div className="readOnly-text">
              <Input
                className="teeth_selector"
                name="other_details"
                id="other_details"
                type="textarea"
                placeholder="Please add details"
                rows={5}
                value={other_details}
                onChange={(e) => {
                  this.setState({
                    other_details: e.target.value,
                  });
                }}
                readOnly
              />
            </div>
          )}
        </div>

        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            Save
        </Button>
        }
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToothSizeDiscrepancy);
