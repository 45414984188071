import React, { Component } from "react";
import { connect } from "react-redux";
import { Card } from "reactstrap";
import {
  getDoctorAddresses,
  getAlignerCase,
  getDoctorInfo,
} from "../../api/api";
import { mapCountry } from "../../helpers/countries";
import {
  changeCurrentStep,
  activateStep,
  prevStep,
  showAdvancedModule,
  updateAdvancedModules,
  updateAllAdvancedModules,
  updatePhotosXrays,
  updateImpressions,
  updatePrescription,
  updatePatientInfo
} from "../../redux/CaseSubmission/action";
import { allModules } from "../../redux/advanced/action";
import {
  Case_Tooth_Movement_Restrictions,
  Case_Extraction,
  Case_Attachments,
  Case_IPR,
  Case_Pontics,
  Case_Overcorrections,
  Case_Torque_Enhancers,
  Case_Arch_Expansion,
  Case_Elastics,
  Case_Elastics_Buttons,
  Case_Elastics_Cuts,
  Case_Crossbites,
  Case_Crowding,
  Case_ClassII,
  Case_ClassIII
} from "../../redux/customeTeeth/action";
import { newMapDataWithReducer } from "../../services/newDataMapper";
import AdvancedModules from "./AdvancedModules";
import Impressions from "./Impressions";
import PatientInfo from "./PatientInfo";
import PhotosXrays from "./PhotosXrays";
import Prescription from "./Prescription";
import Summary from "./Summary";
//import TreatmentType from "./TreatmentType";
import Terms from "./Terms";
import Submit from "./Submit";
import gco_light from "../../assets/images/DSO_logo_light.svg"
import gco_2_light from "../../assets/images/DSO_logo2_light.svg"

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updatePatientInfo: (payload) => dispatch(updatePatientInfo(payload)),

  changeCurrentStep: (payload) => dispatch(changeCurrentStep(payload)),
  activateStep: (payload) => dispatch(activateStep(payload)),
  prevStep: (payload) => dispatch(prevStep(payload)),
  showAdvancedModule: (payload) => dispatch(showAdvancedModule(payload)),
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  updateAllAdvancedModules: (payload) =>
    dispatch(updateAllAdvancedModules(payload)),
  updatePhotosXrays: (payload) => dispatch(updatePhotosXrays(payload)),
  updateImpressions: (payload) => dispatch(updateImpressions(payload)),
  updatePrescription: (payload) => dispatch(updatePrescription(payload)),
  //teeth reducers
  Case_Tooth_Movement_Restrictions: (payload) =>
    dispatch(Case_Tooth_Movement_Restrictions(payload)),
  Case_Extraction: (payload) => dispatch(Case_Extraction(payload)),
  Case_Attachments: (payload) => dispatch(Case_Attachments(payload)),
  Case_IPR: (payload) => dispatch(Case_IPR(payload)),
  Case_Pontics: (payload) => dispatch(Case_Pontics(payload)),
  Case_Overcorrections: (payload) => dispatch(Case_Overcorrections(payload)),
  Case_Torque_Enhancers: (payload) => dispatch(Case_Torque_Enhancers(payload)),
  Case_Arch_Expansion: (payload) => dispatch(Case_Arch_Expansion(payload)),
  Case_Elastics: (payload) => dispatch(Case_Elastics(payload)),
  Case_Elastics_Buttons: (payload) => dispatch(Case_Elastics_Buttons(payload)),
  Case_Elastics_Cuts: (payload) => dispatch(Case_Elastics_Cuts(payload)),
  Case_Crossbites: (payload) => dispatch(Case_Crossbites(payload)),
  Case_Crowding: (payload) => dispatch(Case_Crowding(payload)),
  Case_ClassII: (payload) => dispatch(Case_ClassII(payload)),
  Case_ClassIII: (payload) => dispatch(Case_ClassIII(payload)),

  //all modules
  allModules: (payload) => dispatch(allModules(payload)),
});

class CaseSubmission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth,
      hideToggle: false,
      addresses: [],
      newAddressArr: [],
      doctorId: props.userReducer.currentUser.id,
      caseId:
        props.caseId || (window.location.search.match(/id=([^&]+)/) || [])[1],
      case: {},
      loadData: false,
      showSubmit: false,
      mappedData: {},
      new_cases_Date: "2021-01-25",
      terms: false,
      gco_doctor: props.userReducer.currentUser.gco_doctor

    };
    // console.log(props.userReducer.currentUser.gco_doctor)

    this.props.history.listen((location, action) => {
      if (window.innerWidth < 840) {
        if (document.getElementById("mySidenav") && document.getElementById("main-gco"))
          document.getElementById("mySidenav").style.width = "0";
        document.getElementById("main-gco").style.marginLeft = "0";
      } else {
        if (document.getElementById("mySidenav") && document.getElementById("main-gco")) {
          document.getElementById("mySidenav").style.width = "22%";
          document.getElementById("main-gco").style.marginLeft = "22%";
        }

      }
    });
  }
  /*--------------------------------------------------------------------------------*/
  /*Open SideNav                                         */
  /*--------------------------------------------------------------------------------*/
  openNav = () => {
    if (this.state.width > 840) {
      if (document.getElementById("mySidenav") && document.getElementById("main-gco")) {
        document.getElementById("mySidenav").style.width = "22%";
        document.getElementById("main-gco").style.marginLeft = "22%";
      }

    } else {
      if (document.getElementById("main-gco")) {
        document.getElementById("mySidenav").style.width = "60%";
      }

    }
    this.setState({ hideToggle: !this.state.hideToggle });
  };
  /*--------------------------------------------------------------------------------*/
  /*Close SideNav                                         */
  /*--------------------------------------------------------------------------------*/
  closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";

    if (this.state.width > 840) {
      document.getElementById("main-gco").style.marginLeft = "0";
    }
    this.setState({ hideToggle: !this.state.hideToggle });
  };
  /*--------------------------------------------------------------------------------*/
  /*update Side Nav Dimintions                                        */
  /*--------------------------------------------------------------------------------*/
  updateSideNavDim = () => {
    if (this.state.width > 1300) {
      document.getElementById("mySidenav").style.width = "22%";
      document.getElementById("main-gco").style.marginLeft = "22%";
      this.setState({ hideToggle: true });
    } else {
      if (document.getElementById("mySidenav") && document.getElementById("main-gco")) {
        document.getElementById("mySidenav").style.width = "0";
        document.getElementById("main-gco").style.marginLeft = "0";
        this.setState({ hideToggle: false });
      }
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*update screen dimintions                                        */
  /*--------------------------------------------------------------------------------*/
  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
    this.updateSideNavDim();
  };
  /* --------------------------------------------------------------------------------*/
  /* get aligner case info , doctor info and doctor addresses  */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    this.updateDimensions();
    if (this.state.caseId) {
      this.GetAlignerCaseById();
      getDoctorInfo(this.state.doctorId).then((res) => {
        this.setState({
          doctorInfo: res,
        });
      });
      getDoctorAddresses(this.props.userReducer.currentUser.id).then((res) => {
        let newAddress = mapCountry(res);
        this.setState({ addresses: res, newAddressArr: newAddress });
      });
    } else {
      getDoctorAddresses(this.props.userReducer.currentUser.id).then((res) => {
        let newAddress = mapCountry(res);
        this.setState({
          addresses: res,
          newAddressArr: newAddress,
          loadData: true,
        });
      });
    }
  }

  GetDoctorAddresses = () => {
    getDoctorAddresses(this.props.userReducer.currentUser.id).then((res) => {
      let newAddress = mapCountry(res);
      this.setState({
        addresses: res,
        newAddressArr: newAddress,
        loadData: true,
      });
    });
  }
  /*--------------------------------------------------------------------------------*/
  /* get aligner case by id                                                         */
  /*--------------------------------------------------------------------------------*/
  GetAlignerCaseById = () => {
    let caseId =
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    getAlignerCase(
      this.props.userReducer.currentUser.id,
      caseId
    ).then((res) => {
      if (res.case_type === "Retainer") {
        window.location.href = `/case/${res.id}`
      } else {
        this.mapData(res);
      }
    });
  }


  /*--------------------------------------------------------------------------------*/
  /* map data from api with reducer                                                 */
  /*--------------------------------------------------------------------------------*/
  mapData(data) {
    let newData = newMapDataWithReducer(data); //= mapDataWithReducer(data, this.state.fix);
    this.setState({ mappedData: newData });
    //this.props.updateCase({ ...this.props.newCase, ...newData });
    this.props.updatePhotosXrays(newData.photos_xrays);
    this.props.updateImpressions(newData.impressions_new);
    this.props.updateAllAdvancedModules(newData.advanced_modules);
    this.props.updatePrescription(newData.prescription);
    this.props.updatePatientInfo(newData.patient_info)
    this.props.Case_Tooth_Movement_Restrictions(
      newData.prescription.restriction
    );
    this.props.Case_Extraction(newData.advanced_modules.extraction.teeth);
    this.props.Case_Attachments(
      newData.advanced_modules.attachments.attachmentsRestriction
    );
    this.props.Case_IPR(newData.advanced_modules.ipr.iprRestriction);
    this.props.Case_Pontics(newData.advanced_modules.pontics.teeth);
    this.props.Case_Overcorrections(
      newData.advanced_modules.overCorrection.teeth
    );
    this.props.Case_Torque_Enhancers(
      newData.advanced_modules.torqueEnhancers.teeth
    );
    this.props.Case_Arch_Expansion(
      newData.advanced_modules.archExpansion.teeth
    );
    this.props.Case_Elastics(newData.advanced_modules.elastics.teeth);
    this.props.Case_Elastics_Buttons(newData.advanced_modules.elastics.buttons_teeth);
    this.props.Case_Elastics_Cuts(newData.advanced_modules.elastics.cuts_teeth);
    this.props.Case_Crossbites(
      newData.advanced_modules.malocclusion.crossbite.teeth
    );
    this.props.Case_Crowding(
      newData.advanced_modules.malocclusion.crowding.teeth
    )
    this.props.Case_ClassII(
      newData.advanced_modules.malocclusion.classII.teeth
    );
    this.props.Case_ClassIII(
      newData.advanced_modules.malocclusion.classII.teeth
    );
    this.props.allModules(newData.advanced_modules.all_modules);



    this.setState({
      case: data,
      currentStep: data.step,
      loadData: true,
      mappedData: newData,
    });
    let { Steps } = this.props.caseSubmission;

    if (new Date(data.submitted_at) < new Date(this.state.new_cases_Date)) {
      this.props.changeCurrentStep(this.renderActiveStep("Treatment Type"));

    }
    if (data.uid) {
      this.setState({ showSubmit: true, terms: true })
      this.props.activateStep(Steps[5]);

      this.props.changeCurrentStep(this.renderActiveStep("summary"));

    }
    else {
      this.ActivateCurreStep(data.step);
    }


  }
  /*--------------------------------------------------------------------------------*/
  /* activate current step                                               */
  /*--------------------------------------------------------------------------------*/
  ActivateCurreStep = (step) => {
    let advancedModules = [];

    if (this.props.advancedModules?.modules) {
      advancedModules = this.props.advancedModules?.modules.filter(
        (value, index) => {
          return value.active === true;
        }
      );
    }

    let { Steps, currentStep } = this.props.caseSubmission;
    if (this.renderActiveStep(step) === 5 || advancedModules.length > 0) {
      this.props.showAdvancedModule(true);
    }
    if (currentStep < 6) {
      this.props.changeCurrentStep(this.renderActiveStep(step));
      this.props.activateStep(Steps[this.renderActiveStep(step) - 1]);
    }
  };

  /*--------------------------------------------------------------------------------*/
  /* render active step                                                             */
  /*--------------------------------------------------------------------------------*/
  renderActiveStep(step) {
    switch (step) {
      case "Patient Information":
        return 1;
      case "":
        return 1;
      case "Treatment Type":
        return 2;
      case "photos":
        return 2;
      case "xrays":
        return 2;
      case "impressions":
        return 3;
      case "prescription":
        return 4;
      case "advanced_modules":
        return 5;
      case "terms":
        return 6;
      case "summary":
        return 6;
      default:
        return 2;
    }
  }
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  componentDidUpdate(prevProps, prevState) {
    // if (prevState.width !== this.state.width) {
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);
    // }
  }
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    window.removeEventListener("load", this.updateDimensions);
    window.removeEventListener("resize", this.updateDimensions);
  }
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  NextStep = () => {
    let { Steps, currentStep } = this.props.caseSubmission;
    if (currentStep < 6) {
      this.props.changeCurrentStep(currentStep + 1);
      this.props.activateStep(Steps[currentStep]);
      this.setState({ terms: false, showSubmit: false })


    } else {
      this.GetAlignerCaseById();
    }
  };
  TermsStep = () => {
    this.setState({ terms: !this.state.terms })
  }
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  PrevStep = () => {
    let { Steps, currentStep } = this.props.caseSubmission;
    if (currentStep === 6) {
      this.props.changeCurrentStep(4);
      this.props.prevStep(Steps[3]);
    } else {
      this.props.changeCurrentStep(currentStep - 1);
      this.props.prevStep(Steps[currentStep - 2]);
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  ShowAdvancedModule = (props) => {
    let { Steps, currentStep } = this.props.caseSubmission;

    this.props.showAdvancedModule(props);
    if (props === true) {
      this.props.changeCurrentStep(currentStep + 1);
      this.props.activateStep(Steps[currentStep]);
    } else {
      this.props.changeCurrentStep(currentStep + 2);
      this.props.activateStep(Steps[currentStep + 1]);
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/

  ShowSubmit = () => {
    this.GetAlignerCaseById()
    this.setState({ showSubmit: !this.state.showSubmit });


  };

  render() {
    let { Steps, currentStep, showAdvancedModule } = this.props.caseSubmission;
    const { loadData, showSubmit, terms, gco_doctor } = this.state;
    return (
      <div className="mt-0">
        <aside id="mySidenav" className="sidenav-gco">
          <div className="sidenav-rl">
            <div className="side-container">
              <label className=" steps-title">
                <span>Case Submission </span>
                <span className="closebtn" onClick={this.closeNav}>
                  &#9776;              </span>
              </label>
              {Steps.map((value, index) => {
                return !value.subTitle ? (
                  <div
                    key={value.id}
                    className={`step ${value.active ? "active-step" : ""}`}
                    style={{
                      pointerEvents:
                        !value.active || showSubmit ? "none" : "",
                    }}
                  >
                    <span
                      onClick={() => {
                        this.props.prevStep(Steps[index]);
                        this.props.changeCurrentStep(index + 1);
                      }}
                    >
                      {value.name}{" "}
                      {value.active && (
                        <span>
                          <i className=" pl-1 fas fa-check-circle active-icon"></i>
                        </span>
                      )}
                    </span>
                  </div>
                ) : (
                  <div
                    key={value.id}
                    className={`custome-step  ${value.active ? "active-step" : ""
                      }`}
                    style={{ pointerEvents: value.active && !showSubmit ? "" : "none" }}
                    onClick={() => {
                      this.props.prevStep(Steps[index]);
                      this.props.changeCurrentStep(index + 1);
                    }}
                  >
                    {showAdvancedModule && value.subTitle && (
                      <div className="pt-1 pl-4"> * {value.name}</div>
                    )}
                  </div>
                );
              })}


            </div>


          </div>
          {gco_doctor && <>
            <div className="logo-container">
              <img src={gco_light} className="gco-light" alt="gco logo" />
              <center><img src={gco_2_light} className="gco-2-light" alt="gco logo" /></center>
            </div>

          </>}
        </aside>
        <Card className="case-grid mb-0" id="main-gco">
          <div>
            {" "}
            {!this.state.hideToggle && (
              <span className="close-gco" onClick={this.openNav}>
                &#9776;
              </span>
            )}
          </div>
          <div>
            {currentStep === 1 && loadData && (
              <PatientInfo
                addresses={this.state.addresses}
                NextStep={this.NextStep}
                props={this.props}
                caseId={this.state.caseId}
                GetAlignerCaseById={this.GetAlignerCaseById}
                GetDoctorAddresses={this.GetDoctorAddresses}
                case={this.state.case}

              />
            )}
            {/* {currentStep === 2 && loadData && this.state.case && (
              <TreatmentType
                PrevStep={this.PrevStep}
                NextStep={this.NextStep}
                props={this.props}
                doctorId={this.state.doctorId}
                caseId={this.state.caseId}
                patient={this.state.case}
                gco_doctor={this.state.gco_doctor}

              />
            )} */}
            {currentStep === 2 && loadData && (
              <PhotosXrays
                PrevStep={this.PrevStep}
                NextStep={this.NextStep}
                props={this.props}
                doctorId={this.state.doctorId}
                caseId={this.state.caseId}
                patient={this.state.case.patient}
              />
            )}

            {currentStep === 3 && loadData && (
              <Impressions
                PrevStep={this.PrevStep}
                NextStep={this.NextStep}
                props={this.props}
                doctorId={this.state.doctorId}
                caseId={this.state.caseId}
                case={this.state.case}
                patient={this.state.case.patient}
                gco_doctor={this.state.gco_doctor}
              />
            )}
            {currentStep === 4 && loadData && (
              <Prescription
                PrevStep={this.PrevStep}
                NextStep={this.NextStep}
                ShowAdvancedModule={this.ShowAdvancedModule}
                props={this.props}
                doctorId={this.state.doctorId}
                caseId={this.state.caseId}
                patient={this.state.case.patient}
              />
            )}
            {currentStep === 5 && loadData && (
              <AdvancedModules
                PrevStep={this.PrevStep}
                NextStep={this.NextStep}
                props={this.props}
                doctorId={this.state.doctorId}
                caseId={this.state.caseId}
                patient={this.state.case.patient}
                mappedData={this.state.mappedData
                }
                GetAlignerCaseById={this.GetAlignerCaseById}
              />
            )}

            {currentStep === 6 &&
              loadData &&

              !showSubmit && !terms && (
                <Terms
                  PrevStep={this.PrevStep}
                  NextStep={this.TermsStep}
                  props={this.props}
                  doctorId={this.state.doctorId}
                  caseId={this.state.caseId}
                  patient={this.state.case.patient}
                />
              )}
            {currentStep === 6 &&
              loadData &&

              !showSubmit && terms && (
                <Summary
                  PrevStep={this.PrevStep}
                  NextStep={this.ShowSubmit}
                  props={this.props}
                  doctorId={this.state.doctorId}
                  caseData={this.state.case}
                  caseId={this.state.caseId}
                  patient={this.state.case.patient}
                />
              )}
            {currentStep === 6 &&
              loadData &&
              this.state.case.uid && terms &&
              showSubmit && (
                <Submit
                  props={this.props}
                  doctorId={this.state.doctorId}
                  caseId={this.state.caseId}
                  case={this.state.case}
                  mappedData={this.state.mappedData}
                  doctorInfo={this.state.doctorInfo}
                />
              )}

            {/* {currentStep > 1 && (
              <Button
                className="btn green-btn h-100 "
                size="lg"
                onClick={() => {
                  this.props.changeCurrentStep(currentStep - 1);
                  //   this.props.activateStep(Steps[currentStep]);
                  this.props.prevStep(Steps[currentStep - 2]);
                }}
              >
                Prev
              </Button>
            )}
            {currentStep < Steps.length && (
              <Button
                className="btn green-btn h-100 pull-right"
                size="lg"
                onClick={() => {
                  this.props.changeCurrentStep(currentStep + 1);
                  this.props.activateStep(Steps[currentStep]);
                }}
              >
                Next
              </Button>
            )} */}
          </div>
        </Card>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseSubmission);
