import React, { Component } from "react";
import { connect } from "react-redux";

import ToggleSwitch from "../../../../../components/Switch/Switch";
import CustomeTeeth from "../../../../Teeth/CustomeTeeth";
import { CustomInput, Row, Col } from "reactstrap";
import {
  clearMalocclusionData
} from "../../../../../redux/CaseSubmission/action";

import {
  Clear_Teeth_Data
} from "../../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../../components/TreatmentOptions/TreatmentOptions";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  clearMalocclusionData: (payload) => dispatch(clearMalocclusionData(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload))
});
class Crossbite extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    this.state = {
      use_elastics: ""
    }
    /* init state */
    //use_elastics
    this.use_elastics =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.crossbite
        ?.useElasticWhenCorrecting || false;
    //expansion treatment Options
    this.expansion =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.crossbite
        ?.treatment_Options?.expansion || false;
    //constriction treatment Options
    this.constriction =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.crossbite
        ?.treatment_Options?.constriction || false;
    //combination treatment Options
    this.combination =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.crossbite
        ?.treatment_Options?.combination || false;
    //elastics treatment Options
    this.elastics =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.crossbite
        ?.treatment_Options?.elastics || false;


  }

  UpdateCrossBiteReducer = () => {
    let data = {
      useElasticWhenCorrecting: this.use_elastics,
      teeth: this.props.customeTeethReducer.Case_Crossbites,
      treatment_Options: {
        expansion: this.expansion,
        constriction: this.constriction,
        combination: this.combination,
        elastics: this.elastics,
      },
    };

    this.props.save("crossbite", data);
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.use_elastics = value
  }
  render() {
    const {
      use_elastics,
      expansion,
      constriction,
      combination,
      elastics,
    } = this;
    return (
      <div>
        <div className="malocclusion-title">
          Crossbite
      {!this.props.summary && <span
            className="close-btn pull-right"
            onClick={() => {
              this.props.Clear_Teeth_Data("Case_Crossbites")
              this.props.clearMalocclusionData("crossbite")
              this.props.toggleComponents();
            }}
          >
            &times;
          </span>}
        </div>

        <div className=" pt-4">

          <div className="text-center">
            <div className="control-label title_active-gco text-center mb-2">
              Use elastics when correcting{" "}
            </div>
            <TreatmentOptions value={use_elastics} name="crossbite" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_elastics")
              if (value !== true) {
                this.props.Clear_Teeth_Data("Case_Crossbites")
              }
              this.UpdateCrossBiteReducer();

            }} />
          </div>

          <div>
            <div className="control-label title_active-gco text-center pb-2 mt-2 ">
              Placement
              </div> <div className="teeth_selector">
              <CustomeTeeth
                action_key="Case_Crossbites"
                disabled={!use_elastics || use_elastics === "eonToDecide"}
                key={"Case_Crossbites"}
                UpdateCrossBiteReducer={this.UpdateCrossBiteReducer}
                summary={this.props.summary}

              />
            </div>
          </div>
        </div>

        <div className="p-2">
          <div className="control-label title_active-gco">
            Treatment options{" "}
          </div>
          <CustomInput

            className={`pt-1 ${expansion && "replacment-checkbox"}`}
            type="checkbox"
            id="expansion"
            checked={expansion}
            disabled={constriction || combination || this.props.summary}
            onChange={() => {
              this.expansion = !this.expansion;
              this.UpdateCrossBiteReducer();
            }}
            label="Expansion of the upper arch"
          />
          <CustomInput

            className={`pt-1 ${constriction && "replacment-checkbox"}`}
            type="checkbox"
            id="constriction"
            checked={constriction}
            disabled={expansion || combination || this.props.summary}
            onChange={() => {
              this.constriction = !this.constriction;
              this.UpdateCrossBiteReducer();
            }}
            label={`Constriction of the lower arch " may require IPR "`}
          />
          <CustomInput
            className={`pt-1 ${combination && "replacment-checkbox"}`}
            type="checkbox"
            id="combination"
            checked={combination}
            disabled={expansion || constriction || this.props.summary}
            onChange={() => {
              this.combination = !this.combination;
              this.UpdateCrossBiteReducer();
            }}
            label={`Combination of upper expansion and lower constriction " may require IPR "
            `}
          />

        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Crossbite);
