import { getParams } from "./getParams";
import { apiResolver } from "./apiResolver";

export const resolvePromise = (caseId, tsId, doctorId) => {
  let params = getParams();
  let resolverPromise;
  if (params["path"])
    resolverPromise = new Promise(function (resolve, reject) {
      resolve(function (m) {
        return "/" + params["path"] + "/" + m;
      });
    });
  else resolverPromise = apiResolver(caseId, tsId, doctorId);

  return resolverPromise;
};
