import React, { Component } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import { connect } from "react-redux";
import { Row, Col, Input } from "reactstrap";
import user from "../../assets/images/users/user.png";
const mapStateToProps = (state) => ({
  ...state,
});
class MsgContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      filtered: [],
      searchInput: "",
      serach: "",
    };
  }
  /*--------------------------------------------------------------------------------*/
  /*  scroll to bottom of messages                                                  */
  /*--------------------------------------------------------------------------------*/
  updateScrollbar = () => {
    const ctn = document.getElementById(`chat-list`);
    setTimeout(() => {
      if (ctn) {
        ctn.scrollTo(0, ctn.scrollHeight);
      }
    }, 300);
  };
  /*--------------------------------------------------------------------------------*/
  /*  after get all messages scroll to bottom                                       */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    this.updateScrollbar();
  }
  /*--------------------------------------------------------------------------------*/
  /*  scroll element to center                                        */
  /*--------------------------------------------------------------------------------*/
  updateScrollbar2 = (ctn) => {
    this.clearHighlight();
    this.addCurrentHighlight(ctn);
    setTimeout(() => {
      if (ctn) {
        ctn.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          // inline: "start",
        });
      }
    }, 300);
  };
  /*--------------------------------------------------------------------------------*/
  /*  Filter Messages                                       */
  /*--------------------------------------------------------------------------------*/
  FilterMessage = (text) => {
    if (text) {
      let filteredMessages = this.props.inbox.messages.filter((key, index) => {
        var elements = document.getElementsByClassName("highlight");
        if (elements) {
          for (var x = 0; x < elements.length; x++) {
            elements[x].classList.remove("highlight");
          }
        }
        return key.body.includes(text);
      });

      for (var x = 0; x < filteredMessages.length; x++) {
        const ctn = document.getElementById(filteredMessages[x].id);
        var innerHTML = ctn.innerHTML;
        var index = innerHTML.indexOf(text);
        if (index >= 0) {
          innerHTML =
            innerHTML.substring(0, index) +
            "<span class='highlight'>" +
            innerHTML.substring(index, index + text.length) +
            "</span>" +
            innerHTML.substring(index + text.length);
          ctn.innerHTML = innerHTML;
        }
      }
      this.setState({
        filtered: filteredMessages,
        step: 1,
        searchInput: text,
      });
      if (filteredMessages.length > 0) {
        const el = document.getElementById(filteredMessages[0].id);
        if (el) this.updateScrollbar2(el);
      }
    } else {
      this.clearData();
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*  next item                                      */
  /*--------------------------------------------------------------------------------*/
  nextItem = () => {
    if (
      this.state.filtered.length > 0 &&
      this.state.step <= this.state.filtered.length - 1
    ) {
      const el = document.getElementById(
        this.state.filtered[this.state.step].id
      );
      this.clearHighlight();
      if (el) this.updateScrollbar2(el);
      this.setState({
        step: this.state.step + 1,
      });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*  prev item                                     */
  /*--------------------------------------------------------------------------------*/
  prevItem = () => {
    if (this.state.filtered.length > 0 && this.state.step > 1) {
      const el = document.getElementById(
        this.state.filtered[this.state.step - 2].id
      );
      this.clearHighlight();
      if (el) this.updateScrollbar2(el);
      this.setState({
        step: this.state.step - 1,
      });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*  filter messages include text                                       */
  /*--------------------------------------------------------------------------------*/
  addCurrentHighlight = (ctn) => {
    var innerHTML = ctn.innerHTML;
    var text = this.state.searchInput;
    var index = innerHTML.indexOf(text);
    if (index >= 0) {
      innerHTML =
        innerHTML.substring(0, index) +
        "<span class='highlight2'>" +
        innerHTML.substring(index, index + text.length) +
        "</span>" +
        innerHTML.substring(index + text.length);
      ctn.innerHTML = innerHTML;
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*  clear Highlight                                    */
  /*--------------------------------------------------------------------------------*/
  clearHighlight = () => {
    for (var i = 0; i < this.state.filtered.length; i++) {
      var elements = document.getElementsByClassName("highlight2");
      if (elements) {
        for (var x = 0; x < elements.length; x++) {
          elements[x].classList.remove("highlight2");
        }
      }
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*  clear data                                     */
  /*--------------------------------------------------------------------------------*/
  clearData = () => {
    for (var i = 0; i < this.props.inbox.messages.length; i++) {
      var elements = document.getElementsByClassName("highlight");
      if (elements) {
        for (var x = 0; x < elements.length; x++) {
          elements[x].classList.remove("highlight");
        }
      }
    }

    this.setState({
      filtered: [],
      step: 0,
      searchInput: "",
    });
    // this.updateScrollbar();
  };

  /*--------------------------------------------------------------------------------*/
  render() {
    return (
      <div>
        {/* <Row className="chat-list p-2 pb-3">
          <Col
            xs="12"
            md={this.props.formKey === "viewer" ? "12" : "8"}
            lg={this.props.formKey === "viewer" ? "12" : "8"}
          >
            <Row className="align-items-center ">
              <Col xs="10" md="10" lg="10" className="pr-0">
                <div className="btn-group w-100 ">
                  <Input
                    bsSize="large"
                    name="searchInput"
                    placeholder=" Search "
                    value={this.state.searchInput || ""}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        this.clearData();
                      } else {
                        this.setState({
                          searchInput: e.target.value,
                        });
                      }
                    }}
                    label="Search"
                    autoComplete="off"
                    className="green_input"
                    onKeyDown={(e) => {
                      if (e.target.value !== "" && e.key === "Enter") {
                        this.FilterMessage(e.target.value);
                      }
                    }}
                  />
                  <span
                    id="searchclear"
                    className={`${
                      this.state.searchInput === ""
                        ? "disabled_search"
                        : "active-clear"
                    }`}
                    onClick={() => {
                      this.clearData();
                    }}
                  >
                    <i className="fas fa-times"></i>{" "}
                  </span>
                </div>
              </Col>
              <Col xs="2" md="2" lg="2" className="pl-0 ">
                <div
                  className="green-btn3"
                  style={{
                    pointerEvents: `${
                      this.state.searchInput ? "auto" : "none"
                    }`,
                  }}
                  onClick={() => {
                    this.FilterMessage(this.state.searchInput);
                  }}
                >
                  <i className="fas fa-search" aria-hidden="true"></i>
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            xs="12"
            md={this.props.formKey === "viewer" ? "12" : "4"}
            lg={this.props.formKey === "viewer" ? "12" : "4"}
            className={this.props.formKey === "viewer" ? "mt-3" : ""}
          >
            {this.state.filtered.length > 0 && (
              <Row className="filter-msg-num">
                {this.state.step > 1 && (
                  <Col>
                    <button
                      className="btn green-btn w-100"
                      type="button"
                      onClick={() => {
                        this.prevItem();
                      }}
                    >
                      prev
                    </button>
                  </Col>
                )}
                <Col>
                  {this.state.step} of {this.state.filtered.length}
                </Col>

                {this.state.step <= this.state.filtered.length - 1 && (
                  <Col>
                    <button
                      className="btn green-btn w-100"
                      type="button"
                      onClick={() => {
                        this.nextItem();
                      }}
                    >
                      next
                    </button>
                  </Col>
                )}
              </Row>
            )}
          </Col>
        </Row> */}

        {this.props.inbox.messages.length !== 0 && (
          <PerfectScrollbar
            containerRef={(ref) => {
              this.chatScrollHandler = ref;
            }}
          >
            <ul
              id={`chat-list`}
              className="chat-list p-4"
              style={{ height: "calc(100vh - 334px)" }}
            >
              {this.props.inbox.messages.map((message, index) =>
                message.commenter_type === "Doctor" ? (
                  <li
                    className="chat-item odd mt-0"
                    key={index}
                    id={message.id}
                  >
                    {/* doctor messages */}
                    <div className="chat_grid2 align-items-center border-bottom">
                      <div>
                        <Row className=" p-2">
                          <Col xs="12" md="12" lg="12">
                            <span className="small-date ">
                              {moment(new Date(message.submitted_at)).format(
                                "MMMM Do YYYY, h:mm"
                              )}
                            </span>
                          </Col>
                          {/* <Col xs="12" md="2" lg="2"></Col> */}
                          <Col xs="12" md="12" lg="12" className="p-0">
                            <Row className="p-2">
                              <Col>
                                <div className="box bg-light-info p-3 doctor-msg">
                                  {message.body}
                                </div>
                              </Col>
                            </Row>

                            {message.attachment.match(/http([^&]+)/) && (
                              <Row className="p-2">
                                <Col>
                                  <div className="box bg-light-info p-3 doctor-msg ">
                                    <a target="blank" href={message.attachment}>
                                      {`attachment  `}
                                      <i className="fas fa-cloud-download-alt"></i>{" "}
                                    </a>
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                      </div>

                      <div>
                        <Row>
                          <Col className="text-center">
                            <div className="chat-img ">
                              <img
                                src={
                                  this.props.userReducer.currentUser.avatar.match(
                                    /http([^&]+)/
                                  )
                                    ? this.props.userReducer.currentUser.avatar
                                    : "https://muratselek.com.tr/wp-content/uploads/2019/01/yorum-icon-avatar-men-768x918.png"
                                }
                                alt={
                                  this.props.userReducer.currentUser.full_name
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li className="chat-item" key={index} id={message.id}>
                    {/* admin messages */}
                    <div className=" chat_grid align-items-center border-bottom">
                      <div>
                        <Row className="pr-2">
                          <Col className="p-0 mr-1 text-center">
                            <div className="chat-img ">
                              <img src={user} alt={"admin"} />
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Row className="">
                          <Col xs="12" md="12" lg="12">
                            <span className="small-date pull-right">
                              {moment(new Date(message.submitted_at)).format(
                                "MMMM Do YYYY, h:mm"
                              )}
                            </span>
                          </Col>
                          <Col xs="12" md="12" lg="12">
                            <Row className="p-2">
                              <Col>
                                <div className="box bg-light-info p-3 admin-msg">
                                  {message.body}
                                </div>
                              </Col>
                            </Row>

                            {message.attachment.match(/http([^&]+)/) && (
                              <Row className="p-2">
                                <Col>
                                  <div className="box bg-light-info p-3 admin-msg ">
                                    <a target="blank" href={message.attachment}>
                                      {`attachment  `}
                                      <i className="fas fa-cloud-download-alt"></i>{" "}
                                    </a>
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          </PerfectScrollbar>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(MsgContent);
