import React, { Component } from "react";
import {
  Col,
  Row,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import upper from "../../../assets/images/imperssions/upper.png";
import lower from "../../../assets/images/imperssions/lower.png";
import { updateAlignerCase, fixAdjustmentRejections } from "../../../api/api";
import { uploadFile, keyToUrl } from "../../../helpers/s3";
import { infoToaster } from "../../../services/toast";

export default class Impressions extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = props.getStore();
    this.UID = props.UID || null;
    this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
    this.caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    this.fix = this.props.fix;
    this.rejectable_id = this.props.rejectable_id;
    this.rejectable_type = this.props.data.rejectable_type;
    this.stepToFix_id = this.props.data.stepToFix_id || null;
    this.treatment_goals = this.props.data.newCase.treatmentPlan.treatment_goals;
    this.validate = false;
    this.confirmation = false;
    this.modal = false;
  }
  /* --------------------------------------------------------------------------------*/
  /* scroll to top after render */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  /* --------------------------------------------------------------------------------*/
  /* check validation on submit   */
  /* --------------------------------------------------------------------------------*/
  isValidated = () => {
    const ImpressionsInput = this._grabImpressionsInput(); // grab user entered vals
    const validateNewInput = this._validateData(ImpressionsInput); // run the new input against the validator
    this.validate = true;
    let isDataValid = false;
    // if full validation passes then save to store and pass as valid
    if (
      this.props.component === "adjustment" &&
      !this.props.data.newCase.userInfo.express_api_v2_case
    ) {
      this.confirmation = true;
    }
    if (
      (this.state.lowerImage && this.state.upperImage) ||
      ImpressionsInput.Impression_Type !== "upload"
    ) {
      this.confirmation = true;
    }
    if (
      Object.keys(validateNewInput).every((k) => {
        return validateNewInput[k] === true;
      }) &&
      ((this.props.component === "adjustment" ||
        this.props.data.newCase.userInfo.express_api_v2_case) &&
      ImpressionsInput.Impression_Type === "upload"
        ? (this.state.lowerImage || this.state.upperImage) && this.confirmation
        : true)
    ) {
      if (
        this.props.getStore().Impression_Type !==
          ImpressionsInput.Impression_Type ||
        this.props.getStore().upperImageValue !==
          ImpressionsInput.upperImageValue ||
        this.props.getStore().lowerImageValue !==
          ImpressionsInput.lowerImageValue ||
        this.props.getStore().additional_comments !==
          ImpressionsInput.additional_comments
      ) {
        if (this.props.component === "newCase") {
          let key =
            this.rejectable_type === "Adjustment"
              ? "adjustment"
              : "aligner_case";

          let impressions = {
            upper_arch_url: ImpressionsInput.upperImageValue,
            lower_arch_url: ImpressionsInput.lowerImageValue,
          };
          let filtered_impressions = {};

          for (var key1 in impressions) {
            if (impressions[key1].match(/http([^&]+)/)) {
              filtered_impressions[key1] = impressions[key1];
            }
          }
          let data = {
            [key]:
              ImpressionsInput.Impression_Type === "upload"
                ? {
                    step: "impressions",
                    impressions_method: ImpressionsInput.Impression_Type,
                    impressions_third_party_note:
                      ImpressionsInput.additional_comments,
                    impressions: filtered_impressions,
                  }
                : {
                    step: "impressions",
                    impressions_method: ImpressionsInput.Impression_Type,
                    impressions_third_party_note:
                      ImpressionsInput.additional_comments,
                  },
            ...(this.rejectable_id && {
              resolve_rejection_id: this.rejectable_id,
            }),
          };

          this.props.sendingData();
          if (this.rejectable_type === "Adjustment") {
            return this.FixAdjustment(data, ImpressionsInput, isDataValid);
          } else {
            return this.UpdateCase(data, ImpressionsInput, isDataValid);
          }
        }
        if (this.props.component === "adjustment") {
          this.props.jumpToStep(2);
          this.props.updateStore({
            ...this.props.getStore(),
            ...ImpressionsInput,
          });
          isDataValid = true;
        }
      } else {
        if (this.props.data.newCase.userInfo.express_api_v2_case) {
          if (this.props.component === "newCase") {
            let key =
              this.rejectable_type === "Adjustment"
                ? "adjustment"
                : "aligner_case";
            let data = {
              [key]:
                ImpressionsInput.Impression_Type === "upload"
                  ? {
                      step: "impressions",
                      impressions_method: ImpressionsInput.Impression_Type,
                      impressions_third_party_note:
                        ImpressionsInput.additional_comments,
                      impressions: {},
                    }
                  : {
                      step: "impressions",
                      impressions_method: ImpressionsInput.Impression_Type,
                      impressions_third_party_note:
                        ImpressionsInput.additional_comments,
                    },
              ...(this.rejectable_id && {
                resolve_rejection_id: this.rejectable_id,
              }),
            };

            this.props.sendingData();
            if (this.rejectable_type === "Adjustment") {
              return this.FixAdjustment(data, ImpressionsInput, isDataValid);
            } else {
              return this.UpdateCase(data, ImpressionsInput, isDataValid);
            }
          }
          if (this.props.component === "adjustment") {
            this.props.jumpToStep(2);
            this.props.updateStore({
              ...this.props.getStore(),
              ...ImpressionsInput,
            });
            isDataValid = true;
          }
        } else if (ImpressionsInput.Impression_Type === "courier") {
          if (this.props.component === "newCase") {
            let key =
              this.rejectable_type === "Adjustment"
                ? "adjustment"
                : "aligner_case";
            let data = {
              [key]:
                ImpressionsInput.Impression_Type === "upload"
                  ? {
                      step: "impressions",
                      impressions_method: ImpressionsInput.Impression_Type,
                      impressions_third_party_note:
                        ImpressionsInput.additional_comments,
                      impressions: {},
                    }
                  : {
                      step: "impressions",
                      impressions_method: ImpressionsInput.Impression_Type,
                      impressions_third_party_note:
                        ImpressionsInput.additional_comments,
                    },
              ...(this.rejectable_id && {
                resolve_rejection_id: this.rejectable_id,
              }),
            };

            this.props.sendingData();
            if (this.rejectable_type === "Adjustment") {
              return this.FixAdjustment(data, ImpressionsInput, isDataValid);
            } else {
              return this.UpdateCase(data, ImpressionsInput, isDataValid);
            }
          }
          if (this.props.component === "adjustment") {
            this.props.jumpToStep(2);
            this.props.updateStore({
              ...this.props.getStore(),
              ...ImpressionsInput,
            });
            isDataValid = true;
          }
        } else {
          infoToaster("nothing change .......");
          isDataValid = true;
        }
      }
    } else {
      if (
        this.props.data.newCase.userInfo.express_api_v2_case &&
        ImpressionsInput.Impression_Type === "upload"
          ? (this.state.lowerImage || this.state.upperImage) &&
            !this.confirmation
          : false
      ) {
        this.toggleModal();
      }
      // if anything fails then update the UI validation state but NOT the UI Data State
      this.setState(
        Object.assign(
          ImpressionsInput,
          validateNewInput,
          this._validationErrors(validateNewInput)
        )
      );
    }

    return isDataValid;
  };
  FixAdjustment = (data, ImpressionsInput, isDataValid) => {
    return fixAdjustmentRejections(
      this.props.data.userReducer.currentUser.id,
      this.props.case.uid,
      this.stepToFix_id,
      JSON.stringify(data)
    )
      .then((res) => {
        this.props.sendingData();

        // only update store of something changed
        this.props.updateStore({
          ...ImpressionsInput,
          savedToCloud: false,
        });

        if (this.fix) {
          this.props.data.FixAlignerCase();
        }
        isDataValid = true;
      })
      .catch((error) => {
        this.props.sendingData();
        return (isDataValid = false);
      });
  };

  UpdateCase = (data, ImpressionsInput, isDataValid) => {
    return updateAlignerCase(
      this.props.data.userReducer.currentUser.id,
      this.caseId,
      JSON.stringify(data)
    )
      .then((res) => {
        this.props.sendingData();
        if (
          this.props.data.newCase.userInfo.express_api_v2_case &&
          ImpressionsInput.Impression_Type === "upload" &&
          (!this.state.lowerImage || !this.state.upperImage)
        ) {
          this.props.jumpToStep(3);
        }
        // only update store of something changed
        this.props.updateStore({
          ...ImpressionsInput,
          savedToCloud: false,
        });

        if (this.fix) {
          this.props.data.FixAlignerCase();
        }
        isDataValid = true;
      })
      .catch((error) => {
        this.props.sendingData();
        return (isDataValid = false);
      });
  };
  /* --------------------------------------------------------------------------------*/
  /* validation Check on submit  */
  /* --------------------------------------------------------------------------------*/
  validationCheck = () => {
    if (!this._validateOnDemand) return;

    const ImpressionsInput = this._grabImpressionsInput(); // grab user entered vals
    const validateNewInput = this._validateData(ImpressionsInput); // run the new input against the validator

    this.setState(
      Object.assign(
        ImpressionsInput,
        validateNewInput,
        this._validationErrors(validateNewInput)
      )
    );
  };
  /* --------------------------------------------------------------------------------*/
  /* check validation for each element on submit   */
  /* --------------------------------------------------------------------------------*/
  _validateData = (data) => {
    return {
      ImpressionTypeVal:
        this.props.component === "adjustment"
          ? data.Impression_Type !== ""
          : data.Impression_Type !== "", // required: anything besides N/A
      upperImageValueVal:
        this.treatment_goals.treat_arches_both ||
        this.treatment_goals.treat_arches_upper
          ? data.Impression_Type === "upload"
            ? data.upperImageValue !== ""
            : true
          : true, // required: anything besides N/A
      lowerImageValueVal:
        this.treatment_goals.treat_arches_both ||
        this.treatment_goals.treat_arches_lower
          ? data.Impression_Type === "upload"
            ? data.lowerImageValue !== ""
            : true
          : true, // required: anything besides N/A
      // additionalCommentsVal: data.additional_comments !== "", // required: anything besides N/A
    };
  };
  /* --------------------------------------------------------------------------------*/
  /* validation Errors on submit   */
  /* --------------------------------------------------------------------------------*/
  _validationErrors = (val) => {
    const errMsgs = {
      ImpressionTypeValMsg: val.ImpressionTypeVal ? "" : "Please choose one",
      upperImageValueValMsg: val.upperImageValueVal
        ? ""
        : "Upper Impression is  required",
      lowerImageValueValMsg: val.lowerImageValueVal
        ? ""
        : "Lower Impression is required",
      // additionalCommentsValVaMsg: val.additionalCommentsVal
      //   ? ""
      //   : "Additional Comments is required",
    };
    return errMsgs;
  };
  /* --------------------------------------------------------------------------------*/
  /* grab Impressions Input data on submit  */
  /* --------------------------------------------------------------------------------*/
  _grabImpressionsInput = () => {
    return {
      Digital_Impression: this.state.Digital_Impression,
      Digital_Means: this.state.Digital_Means,
      Arrange_Pickup: this.state.Arrange_Pickup,
      Impression_Type: this.state.Impression_Type,
      upperImage: this.state.upperImage,
      lowerImage: this.state.lowerImage,
      upperImageValue: this.state.upperImageValue,
      lowerImageValue: this.state.lowerImageValue,
      additional_comments: this.state.additional_comments,
    };
  };
  /* --------------------------------------------------------------------------------*/
  /* Upload image to s3  */
  /* --------------------------------------------------------------------------------*/
  uploadImage = async (image, id) => {
    let extention = image.name.split(".");
    return uploadFile({
      name: `case-${
        this.UID ? this.UID : this.caseId
      }/patient-impression/${id}/${new Date().valueOf()}.${
        image.name.split(".")[extention.length - 1]
      }`,
      contentType: image.type,
      file: image,
    });
  };
  /* --------------------------------------------------------------------------------*/
  /* Upload upper Image */
  /* --------------------------------------------------------------------------------*/
  UpperImageOnUpload = async (e, id) => {
    if (e.target.files[0]) {
      this.setState({ loading1: true });
      let reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
      const file = e.target ? e.target.files[0] : e.files[0];
      const { key } = await this.uploadImage(file, id);
      this.validate = false;
      this.setState({
        upperImageValue: keyToUrl(key),
        upperImage: true,
        loading1: false,
      });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* Upload lower Image */
  /* --------------------------------------------------------------------------------*/
  LowerImageOnUpload = async (e, id) => {
    if (e.target.files[0]) {
      this.setState({ loading2: true });
      let reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
      const file = e.target ? e.target.files[0] : e.files[0];
      const { key } = await this.uploadImage(file, id);
      this.validate = false;
      this.setState({
        lowerImageValue: keyToUrl(key),
        lowerImage: true,
        loading2: false,
      });
    }
  };

  toggleModal = () => {
    this.setState({ modal: this.state.modal ? !this.state.modal : true });
  };
  render() {
    // explicit class assigning based on validation
    let notValidClasses = {};
    // Impression Type validation
    if (
      typeof this.state.Impression_Type === "undefined" ||
      this.state.Impression_Type
    ) {
      notValidClasses.ImpressionTypeCls = "form-control ";
    } else {
      notValidClasses.ImpressionTypeCls = "is-invalid form-control ";
      notValidClasses.ImpressionTypeValGrpCls = "text-danger";
    }

    // upper image validation
    if (
      typeof this.state.upperImageValue === "undefined" ||
      this.state.upperImageValue
    ) {
      notValidClasses.upperImageValueCls = "form-control ";
    } else {
      notValidClasses.upperImageValueCls = "is-invalid form-control ";
      notValidClasses.upperImageValueValGrpCls = "text-danger";
    }
    // lower image validation
    if (
      typeof this.state.lowerImageValue === "undefined" ||
      this.state.lowerImageValue
    ) {
      notValidClasses.lowerImageValueCls = "form-control ";
    } else {
      notValidClasses.lowerImageValueCls = "is-invalid form-control ";
      notValidClasses.lowerImageValueValGrpCls = "text-danger";
    }
    return (
      <div className="step step1 mt-5 mb-5 ">
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className="modal-express"
        >
          <ModalHeader toggle={this.toggleModal}>
            Upload Impression Confirmation
          </ModalHeader>

          <ModalBody className="p-0">
            <div className="form-group content form-block-holder h-100">
              <label className="control-label mt-3 ml-3 mb-1">
                {` Are You Sure you want to upload just ${
                  this.state.lowerImage
                    ? "Lower "
                    : "" || this.state.upperImage
                    ? "Upper "
                    : ""
                } Impression ?`}
              </label>
              <Row className="m-1">
                <Col xs="4" md="4" lg="4" className="p-2 w-100">
                  <Button
                    className="btn green-btn w-100"
                    size="md"
                    onClick={() => {
                      this.confirmation = true;
                      this.toggleModal();
                      this.isValidated();
                    }}
                  >
                    Yes
                  </Button>
                </Col>
                <Col xs="4" md="4" lg="4" className="p-2 w-100">
                  <Button
                    className="btn red-btn w-100 "
                    size="md"
                    color="danger"
                    onClick={() => {
                      this.confirmation = false;
                      this.toggleModal();
                    }}
                  >
                    No
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
        <Row className="justify-content-center align-content-center">
          <Col xs="12" md="12" lg="10">
            <form id="Form" className="form-horizontal mt-2 mb-2">
              {/*--------------------------------------------------------------------------------*/}
              {/* Impression Type  */}
              {/*--------------------------------------------------------------------------------*/}
              <Row className="justify-content-center align-content-center">
                <Col xs="12" md="4" lg="4">
                  <Row className="h-100">
                    <Col className="p-2">
                      <Button
                        className={`btn  ${
                          this.state.Digital_Impression
                            ? "green-btn-active"
                            : "green-btn-not-active"
                        }  p-3 w-100 h-100`}
                        size="xl"
                        onClick={() => {
                          this.validate = false;
                          this.setState({
                            Digital_Impression: true,
                            Digital_Means: false,
                            Arrange_Pickup: false,
                            Impression_Type: "upload",
                          });
                        }}
                      >
                        <div className="btn-haeder">
                          Upload Digital Impressions
                        </div>
                        <div className="btn-description">
                          Upload upper and lower 3D models by clicking the
                          images on the right
                        </div>
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4" lg="4">
                  <Row className="h-100">
                    <Col className="p-2">
                      <Button
                        className={`btn ${
                          this.state.Digital_Means
                            ? "green-btn-active"
                            : "green-btn-not-active"
                        }  p-3 w-100 h-100`}
                        size="xl"
                        onClick={() => {
                          this.validate = false;

                          this.setState({
                            Digital_Means: true,
                            Digital_Impression: false,
                            Arrange_Pickup: false,
                            Impression_Type: "third_party",
                          });
                        }}
                      >
                        <div className="btn-haeder">Other Digital Means</div>
                        <div className="btn-description">
                          Use this option for third party integration systems
                          only (for example: 3shape communicate)
                        </div>
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" md="4" lg="4">
                  <Row className="h-100">
                    <Col className="p-2">
                      <Button
                        className={`btn ${
                          this.state.Arrange_Pickup
                            ? "green-btn-active"
                            : "green-btn-not-active"
                        }  p-3 w-100 h-100`}
                        size="xl"
                        onClick={() => {
                          this.validate = false;

                          this.setState({
                            Arrange_Pickup: true,
                            Digital_Means: false,
                            Digital_Impression: false,
                            Impression_Type: "courier",
                          });
                        }}
                      >
                        <div className="btn-haeder">Arrange Pickup</div>
                        <div className="btn-description">
                          Use this option to send traditional silicon
                          impressions to eon via courier
                        </div>
                      </Button>
                    </Col>
                  </Row>
                </Col>
                {this.state.Impression_Type === "" && (
                  <div className={notValidClasses.ImpressionTypeValGrpCls}>
                    {this.state.ImpressionTypeValMsg}
                  </div>
                )}
              </Row>
              {/*--------------------------------------------------------------------------------*/}
              {/* End Impression Type  */}
              {/*--------------------------------------------------------------------------------*/}
              <Row className="justify-content-center align-content-center">
                <Col xs="12" md="12" lg="12">
                  {/*--------------------------------------------------------------------------------*/}
                  {/* Upload Digital Impression  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {this.state.Digital_Impression && (
                    <div>
                      <label className="control-label pl-3 title_active mt-3 mb-3 header  ">
                        Upload Impressions
                      </label>

                      <Row className="justify-content-center align-content-center">
                        {/* Upload Upper Digital Impression  */}
                        <Col xs="12" md="4" lg="4">
                          <div className="buttons-container3 ">
                            <input
                              type="file"
                              id="Upload_Upper_Arch"
                              onChange={(e) =>
                                this.UpperImageOnUpload(e, "Upload_Upper_Arch")
                              }
                              multiple={false}
                              className="visually-hidden"
                              accept=".zip,.rar,.7zip,.ply,.stl"
                            />
                            {
                              <div className="upload-container m-auto w-100 h-100 text-center">
                                {
                                  <label
                                    htmlFor="Upload_Upper_Arch"
                                    className="upload"
                                  >
                                    <img
                                      className="w-100 h-100"
                                      src={upper}
                                      width="100%"
                                      alt="Upload_Upper_Arch"
                                    />
                                    {this.state.upperImage &&
                                      this.state.upperImageValue.match(
                                        /http([^&]+)/
                                      ) && (
                                        <center>
                                          <div
                                            className={` ${
                                              this.state.upperImage
                                                ? "layer"
                                                : ""
                                            }`}
                                          ></div>
                                        </center>
                                      )}
                                    {this.state.loading1 && (
                                      <center>
                                        <div className={` ${"loading"}`}></div>
                                      </center>
                                    )}
                                  </label>
                                }
                              </div>
                            }
                            <div className="title_active mt-3 mb-3 center">
                              Upload Upper Arch
                            </div>
                          </div>
                          {this.state.upperImageValue === "" &&
                            (this.treatment_goals.treat_arches_both ||
                              this.treatment_goals.treat_arches_upper) && (
                              <div
                                className={
                                  notValidClasses.upperImageValueValGrpCls
                                }
                              >
                                {this.state.upperImageValueValMsg}
                              </div>
                            )}
                          {/* <div
                            className="w-100 h-100"
                            style={{ position: "relative", cursor: "pointer" }}
                          >
                            <img src={upper} className="w-100 h-100" />
                            {this.state.upperImage && (
                              <center>
                                <div
                                  className={` ${
                                    this.state.upperImage ? "layer" : ""
                                  }`}
                                ></div>
                              </center>
                            )}
                          </div>

                          <label className="pl-3 title_active mt-3">
                            Upload Upper Arch
                          </label> */}
                        </Col>
                        {/* Upload Lower  Digital Impression  */}
                        <Col xs="12" md="4" lg="4">
                          <div className="buttons-container3 ">
                            <input
                              type="file"
                              id="Upload_Lower_Arch"
                              onChange={(e) =>
                                this.LowerImageOnUpload(e, "Upload_Lower_Arch")
                              }
                              multiple={false}
                              className="visually-hidden"
                              accept=".zip,.rar,.7zip,.ply,.stl"
                            />
                            {
                              <div className="upload-container m-auto w-100 h-100 text-center">
                                {
                                  <label
                                    htmlFor="Upload_Lower_Arch"
                                    className="upload"
                                  >
                                    <img
                                      className="w-100 h-100"
                                      src={lower}
                                      width="100%"
                                      alt="Upload_Lower_Arch"
                                    />
                                    {this.state.lowerImage &&
                                      this.state.lowerImageValue.match(
                                        /http([^&]+)/
                                      ) && (
                                        <center>
                                          <div
                                            className={` ${
                                              this.state.lowerImage
                                                ? "layer"
                                                : ""
                                            }`}
                                          ></div>
                                        </center>
                                      )}
                                    {this.state.loading2 && (
                                      <center>
                                        <div className={` ${"loading"}`}></div>
                                      </center>
                                    )}
                                  </label>
                                }
                              </div>
                            }
                            <div className="title_active mt-3 mb-3 center">
                              Upload Lower Arch
                            </div>
                          </div>
                          {this.state.lowerImageValue === "" &&
                            (this.treatment_goals.treat_arches_both ||
                              this.treatment_goals.treat_arches_lower) && (
                              <div
                                className={
                                  notValidClasses.lowerImageValueValGrpCls
                                }
                              >
                                {this.state.lowerImageValueValMsg}
                              </div>
                            )}
                          {/* <div
                            className="w-100 h-100"
                            style={{ position: "relative", cursor: "pointer" }}
                          >
                            <img src={upper} className="w-100 h-100" />
                            {this.state.upperImage && (
                              <center>
                                <div
                                  className={` ${
                                    this.state.upperImage ? "layer" : ""
                                  }`}
                                ></div>
                              </center>
                            )}
                          </div>

                          <label className="pl-3 title_active mt-3">
                            Upload Upper Arch
                          </label> */}
                        </Col>
                      </Row>
                      {/* validation if adjustment and express case  */}
                      {this.state.upperImageValue === "" &&
                        this.state.lowerImageValue === "" &&
                        this.validate &&
                        this.props.data.newCase.userInfo
                          .express_api_v2_case && (
                          <div className="is-invalid text-danger text-center">
                            please choose one
                          </div>
                        )}
                    </div>
                  )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*End Upload Digital Impression  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* Digital Means Comments */}
                  {/*--------------------------------------------------------------------------------*/}
                  {this.state.Digital_Means && (
                    <div className="form-group content form-block-holder">
                      <label className="control-label title_active mt-3 mb-3 header">
                        Additional Comments
                      </label>
                      <div>
                        <Input
                          autoComplete="off"
                          type="textarea"
                          rows={8}
                          placeholder="Additional Comments (third party)"
                          className="green_input"
                          defaultValue={this.state.additional_comments}
                          onChange={(e) => {
                            this.setState({
                              additional_comments: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*End Digital Means Comments */}
                  {/*--------------------------------------------------------------------------------*/}
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
        {/*--------------------------------------------------------------------------------*/}
        {/* Fix Button  */}
        {/*--------------------------------------------------------------------------------*/}
        {this.fix && (
          <button
            type="button"
            id="finish-button"
            className="btn btn-prev btn-primary btn-lg pull-right"
            onClick={() => {
              this.isValidated();
            }}
          >
            Finish
          </button>
        )}
        {/*--------------------------------------------------------------------------------*/}
        {/* End Fix Button  */}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}
