import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input, Row, Col } from "reactstrap";
import ToggleSwitch from "../../../../components/Switch/Switch";
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
});
class PassiveAligner extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_passive_aligners: this.props.caseSubmission.advanced_modules
        .passiveAligners.usePassiveAligners,
      passive_aligners_details: this.props.caseSubmission.advanced_modules
        .passiveAligners.details,
      add_passive_aligners_at_the_end: this.props.caseSubmission.advanced_modules
        .passiveAligners.add_passive_aligners_at_the_end,
      add_passive_aligners_at_the_beginning: this.props.caseSubmission.advanced_modules
        .passiveAligners.add_passive_aligners_at_the_beginning,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "passiveAligners",
      data: {
        usePassiveAligners: this.state.use_passive_aligners,
        add_passive_aligners_at_the_end: this.state.add_passive_aligners_at_the_end,
        add_passive_aligners_at_the_beginning: this.state.add_passive_aligners_at_the_beginning,
        details: this.state.passive_aligners_details,
      },
    });
    this.props.save();
  };

  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  render() {
    const { use_passive_aligners,
      passive_aligners_details,
      add_passive_aligners_at_the_end,
      add_passive_aligners_at_the_beginning } = this.state;

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">

          <div className="text-center">
            <div className="control-label title_active-gco text-center mb-2">
              Use Passive aligners for this case{" "}
            </div>

            <TreatmentOptions value={use_passive_aligners} name="use_passive_aligners" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_passive_aligners")
              if (value !== true) {
                this.setState({
                  passive_aligners_details: "",
                  add_passive_aligners_at_the_end: false,
                  add_passive_aligners_at_the_beginning: false,
                })
              }
            }} />
          </div>
          <div className="passive_aligners_grid">
            <div></div>
            <Button
              onClick={() => {
                this.setState({
                  add_passive_aligners_at_the_end: false,
                  add_passive_aligners_at_the_beginning: true,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${add_passive_aligners_at_the_beginning ? "active_discrepancy-btn" : "discrepancy-btn"
                } `}
              disabled={!use_passive_aligners || use_passive_aligners === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              Add passive aligners at the beginning
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  add_passive_aligners_at_the_end: true,
                  add_passive_aligners_at_the_beginning: false,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${add_passive_aligners_at_the_end
                ? "active_discrepancy-btn"
                : "discrepancy-btn"
                } `}
              disabled={!use_passive_aligners || use_passive_aligners === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              Add passive aligners at the end
            </Button>
            <div></div>
          </div>



          <div>
            {" "}
            <Input
              className="teeth_selector"
              name="passive_aligners_details"
              id="passive_aligners_details"
              type="textarea"
              placeholder="Please add details"
              rows={5}
              value={passive_aligners_details}
              onChange={(e) => {
                this.setState({ passive_aligners_details: e.target.value });
              }}
              disabled={!use_passive_aligners || use_passive_aligners === "eonToDecide" || this.props.summary}
            />{" "}
          </div>
        </div>

        {!this.props.summary && <Button
          className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
          size="sm"
          type="button"
          onClick={() => this.UpdateAdvancedModules()}
        >
          Save
        </Button>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PassiveAligner);
