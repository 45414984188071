import React from "react";
import { stepsStyleValidation } from "../eonAccess-functionality/stepsStyleValidation";
export default function GenerateSteps(props) {
  const {
    meshesForStep,
    setCurrentStep,
    loading,
    isTsPrepared,
    lowerSteps,
    upperSteps,
    allStepsForUpperOrLower,
    startTogether,
    passiveAligners,
    overCorrectionStepsNumber,
  } = props;
  let maxStepNumber = meshesForStep.length - 1;

  return meshesForStep
    ? meshesForStep.map((step, stepNumber) => {
        return (
          <div
            key={`${stepNumber}`}
            onClick={() => {
              if (loading || isTsPrepared) return;
              setCurrentStep(stepNumber);
            }}
            className={stepsStyleValidation(
              stepNumber,
              maxStepNumber,
              lowerSteps,
              upperSteps,
              allStepsForUpperOrLower,
              startTogether,
              passiveAligners,
              overCorrectionStepsNumber
            )}
          ></div>
        );
      })
    : null;
}