import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input, Row, Col } from "reactstrap";
import ToggleSwitch from "../../../../components/Switch/Switch";
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
});
class ToothSizeDiscrepancy extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_address_discrepancy: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.use_address_discrepancy,
      leaveSpaceAroundLaterals: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.leaveSpaceAroundLaterals,
      leaveSpaceDistalLaterals: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.leaveSpaceDistalLaterals,
      iprOpposing: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.iprOpposing,
      other: this.props.caseSubmission.advanced_modules.toothSizeDiscrepancy
        .other,
      other_details: this.props.caseSubmission.advanced_modules
        .toothSizeDiscrepancy.other_details,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "toothSizeDiscrepancy",
      data: {
        use_address_discrepancy: this.state.use_address_discrepancy,
        leaveSpaceAroundLaterals: this.state.leaveSpaceAroundLaterals,
        leaveSpaceDistalLaterals: this.state.leaveSpaceDistalLaterals,
        iprOpposing: this.state.iprOpposing,
        other: this.state.other,
        other_details: this.state.other_details,
      },
    });
    this.props.save();
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/

  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  clearState = () => {
    this.setState({
      leaveSpaceAroundLaterals: false,
      leaveSpaceDistalLaterals: false,
      iprOpposing: false,
      other: false,
      other_details: ""
    })
  }
  render() {
    const {
      use_address_discrepancy,
      leaveSpaceAroundLaterals,
      leaveSpaceDistalLaterals,
      iprOpposing,
      other,
      other_details,
    } = this.state;

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">
          <div className="text-center">
            <div className="control-label title_active-gco text-center mb-2">
              Address discrepancy for this case{" "}
            </div>
            <TreatmentOptions value={use_address_discrepancy} name="use_address_discrepancy" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_address_discrepancy")
              if (value !== true) {
                this.clearState()
              }
            }} />
          </div>



          <div className="address_discrepancy">
            <Button
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

              onClick={() => {
                this.setState({
                  leaveSpaceAroundLaterals: true,
                  leaveSpaceDistalLaterals: false,
                  iprOpposing: false,
                  other: false,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${leaveSpaceAroundLaterals
                ? "active_discrepancy-btn"
                : "discrepancy-btn"
                } `}

              disabled={!use_address_discrepancy || use_address_discrepancy === "eonToDecide"}
            >
              Leave spaces around the laterals
            </Button>
            <Button
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

              onClick={() => {
                this.setState({
                  leaveSpaceAroundLaterals: false,
                  leaveSpaceDistalLaterals: true,
                  iprOpposing: false,
                  other: false,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${leaveSpaceDistalLaterals
                ? "active_discrepancy-btn"
                : "discrepancy-btn"
                } `}
              disabled={!use_address_discrepancy || use_address_discrepancy === "eonToDecide"}

            >
              Leave space distal to lateral
            </Button>
            <Button
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

              onClick={() => {
                this.setState({
                  leaveSpaceAroundLaterals: false,
                  leaveSpaceDistalLaterals: false,
                  iprOpposing: true,
                  other: false,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${iprOpposing ? "active_discrepancy-btn" : "discrepancy-btn"
                } `}
              disabled={!use_address_discrepancy || use_address_discrepancy === "eonToDecide"}

            >
              IPR opposing
            </Button>
            <Button
              style={{ pointerEvents: this.props.summary ? "none" : "" }}
              onClick={() => {
                this.setState({
                  leaveSpaceAroundLaterals: false,
                  leaveSpaceDistalLaterals: false,
                  iprOpposing: false,
                  other: true,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${other ? "active_discrepancy-btn" : "discrepancy-btn"
                } `}
              disabled={!use_address_discrepancy || use_address_discrepancy === "eonToDecide"}

            >
              Other
            </Button>
          </div>

          {other && (
            <div>
              <Input
                className="teeth_selector"
                name="other_details"
                id="other_details"
                type="textarea"
                placeholder="Please add details"
                rows={5}
                value={other_details}
                onChange={(e) => {
                  this.setState({
                    other_details: e.target.value,
                  });
                }}
                disabled={!use_address_discrepancy || use_address_discrepancy === "eonToDecide" || this.props.summary}

              />
            </div>
          )}
        </div>

        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            Save
        </Button>
        }
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToothSizeDiscrepancy);
