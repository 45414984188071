import React from "react";
import styles from "../eonAccess-style/eonAccess.module.scss";
export default function ModalSettings(props) {
  const { tsOptions, setTSOptions, toggleModal, isModalOpen } = props;


  return (
    // <!-- The Modal -->
    <div   className={isModalOpen ? styles.modal_hidden_on_desktop_open:
      styles.modal_hidden_on_desktop_close}>
      {/* <!-- Modal content --> */}
      <div className={styles.modal_content}>
        <span onClick={() => toggleModal()} class="close">x</span>
        <div className={styles.modal_title}>More Settings</div>

        <div className={styles.side_bottom}></div>

        <div className={styles.modal_switch_container}>
          <div
            style={{
              paddingLeft: 10,
              cursor: "auto",
              color: "#000",
              textAlign: "start",
            }}
          >
            Attachments
          </div>
          <div className={styles.side_line}></div>
          <label className={styles.switch}>
            <input
              type="checkbox"
              defaultChecked={tsOptions.isAttachment}
              onClick={() =>
                setTSOptions((prevOptions) => {
                  return {
                    ...prevOptions,
                    isAttachment: !prevOptions.isAttachment,
                  };
                })
              }
            />
            <span
              className={[styles.switch_slider, styles.round].join(" ")}
            ></span>
          </label>
        </div>

        <div className={styles.side_bottom}></div>

        <div className={styles.modal_switch_container}>
          <div
            style={{
              paddingLeft: 10,
              cursor: "auto",
              color: "#000",
              textAlign: "start",
            }}
          >
            Superimpose
          </div>
          <div className={styles.side_line}></div>
          <label className={styles.switch}>
            <input
              type="checkbox"
              defaultChecked={tsOptions.isSuperImpose}
              onClick={() =>
                setTSOptions((prevOptions) => {
                  return {
                    ...prevOptions,
                    isSuperImpose: !prevOptions.isSuperImpose,
                  };
                })
              }
            />
            <span
              className={[styles.switch_slider, styles.round].join(" ")}
            ></span>
          </label>
        </div>
      </div>
    </div>
  );
}
