import React, { Component } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
} from "reactstrap";
import classnames from "classnames";

import Inbox from "../../inbox";
import {
  approveTreatmentSetup,
  disApproveTreatmentSetup,
  getTretmentSetupById,
} from "../../../api/api";
import { successToaster } from "../../../services/toast";
import { caseType } from "../../../helpers/caseType";
import Gallery from "../Gallery";
export default class ViewerDetails extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /*init state */
    this.state = {
      activeTab: 1,
      case: props.data,
      details: props.details,
      id: props.doctorId,
      Approve: false,
      DisApprove: false,
      RejectionReason: "",
      loading1: false,
      loading2: false,
    };
  }
  componentDidMount() {
    this.setState({ case: this.props.data, details: this.props.details });
  }
  /*--------------------------------------------------------------------------------*/
  /* Toggle tabs                                                                    */
  /*--------------------------------------------------------------------------------*/
  toggleTabs = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /* Get Tretment Setup By Id                                                     */
  /*--------------------------------------------------------------------------------*/
  GetTretmentSetupById = () => {
    getTretmentSetupById(
      this.state.id,
      this.state.case.id,
      this.state.details.id
    ).then((res) => {
      this.setState({ details: res });
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* Confirmation Approve                                                           */
  /*--------------------------------------------------------------------------------*/
  Approve = () => {
    this.setState({ Approve: !this.state.Approve });
  };
  /*--------------------------------------------------------------------------------*/
  /* Confirmation DisApprove                                                        */
  /*--------------------------------------------------------------------------------*/
  DisApprove = () => {
    this.setState({ DisApprove: !this.state.DisApprove });
  };
  /*--------------------------------------------------------------------------------*/
  /* Approve Treatment Setup                                                     */
  /*--------------------------------------------------------------------------------*/
  ApproveTreatmentSetup = () => {
    this.setState({ loading1: !this.state.loading1 });
    approveTreatmentSetup(
      this.props.doctorId,
      this.state.case.uid,
      this.state.details.id
    )
      .then((res) => {
        this.GetTretmentSetupById();
        this.Approve();
        this.setState({ loading1: !this.state.loading1 });
        this.props.GetAlignerCase();
        successToaster("Treatment Setup has been approved");
      })
      .catch((error) => {
        this.props.GetAlignerCase();
        this.setState({ loading1: !this.state.loading1 });
      });
  };
  /*--------------------------------------------------------------------------------*/
  /* DisApprove Treatment Setup                                                     */
  /*--------------------------------------------------------------------------------*/
  DisApproveTreatmentSetup = () => {
    this.setState({ loading2: !this.state.loading2 });

    let data = {
      ts_rejection_reasons: this.state.RejectionReason,
    };
    disApproveTreatmentSetup(
      this.props.doctorId,
      this.state.case.uid,
      this.state.details.id,
      JSON.stringify(data)
    )
      .then((res) => {
        this.GetTretmentSetupById();
        this.DisApprove();
        this.setState({ loading2: !this.state.loading2 });
        this.props.GetAlignerCase();
        successToaster("Treatment Setup has been disapproved");
      })
      .catch((error) => {
        this.props.GetAlignerCase();
        this.setState({ loading2: !this.state.loading2 });
      });
  };
  render() {
    return (
      <div className="w-100">
        {/*--------------------------------------------------------------------------------*/}
        {/* Approve Treatment Setup Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.Approve}
          toggle={this.Approve}
        // className="modalx4"
        >
          <ModalHeader toggle={this.Approve}>
            Approve Treatment Setup
          </ModalHeader>
          <ModalBody>
            Patient Name: {this.props.data.patient.full_name}
            <br></br>
            Case Type:{" "}
            {caseType[this.props.data.case_type] || this.props.data.case_type}
            <br></br>
            Invoice will be based on case type
            <hr></hr>
            By approving this Treatment Setup, you hereby authorize Eon Aligner
            to fabricate the aligners as per the specifications provided by you
            in the Treatment Form and as contained in this Treatment Setup. You
            will be invoiced according to the approved case type unless the
            required treatment plan exceeds it.
          </ModalBody>
          <ModalFooter>
            <Row className=" w-100 m-1 justify-content-end">
              <Col xs="4" md="4" lg="4" className="p-2 w-100">
                <Button
                  className="btn red-btn w-100 "
                  size="md"
                  color="danger"
                  disabled={this.state.loading1}
                  onClick={() =>
                    this.setState({ Approve: !this.state.Approve })
                  }
                >
                  Cancel
                </Button>
              </Col>
              <Col xs="8" md="6" lg="6" className="p-2 w-100">
                <Button
                  className="btn green-btn w-100"
                  size="md"
                  disabled={this.state.loading1}
                  onClick={() => this.ApproveTreatmentSetup()}
                >
                  Submit Approval
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Approve Treatment Setup Modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* DisApprove Treatment Setup Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.DisApprove}
          toggle={this.DisApprove}
        // className="modalx4"
        >
          <ModalHeader toggle={this.DisApprove}>
            Disapprove Treatment Setup{" "}
          </ModalHeader>
          <ModalBody>
            Patient Name: {this.props.data.patient.full_name}
            <br></br>
            Case Type:{" "}
            {caseType[this.props.data.case_type] || this.props.data.case_type}
            <hr></hr>
            <div className="form-group content form-block-holder">
              <label className="control-label title_active ">
                Rejection Reason{" "}
              </label>
              <div>
                <Input
                  autoComplete="off"
                  type="textarea"
                  rows={3}
                  className="green_input"
                  defaultValue={this.state.RejectionReason}
                  onChange={(e) => {
                    this.setState({
                      RejectionReason: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Row className=" w-100 m-1 justify-content-end">
              <Col xs="4" md="4" lg="4" className="p-2 w-100">
                <Button
                  className="btn red-btn w-100 "
                  size="md"
                  color="danger"
                  onClick={() =>
                    this.setState({ DisApprove: !this.state.DisApprove })
                  }
                  disabled={this.state.loading2}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs="8" md="6" lg="6" className="p-2 w-100">
                <Button
                  className="btn green-btn w-100"
                  size="md"
                  disabled={this.state.loading2}
                  onClick={() => this.DisApproveTreatmentSetup()}
                >
                  Disapprove
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/*end  DisApprove Treatment Setup Modal */}
        {/*--------------------------------------------------------------------------------*/}

        <Nav tabs className="justify-content-center">
          {/* Case Details Tab */}
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === 1,
              })}
              onClick={() => {
                this.toggleTabs(1);
              }}
            >
              {`Case Details`}
            </NavLink>
          </NavItem>
          {/* Inbox Tab */}
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === 2,
              })}
              onClick={() => {
                this.toggleTabs(2);
              }}
            >
              {`Inbox`}
            </NavLink>
          </NavItem>
          {/* Records Tab */}
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === 3,
              })}
              onClick={() => {
                this.toggleTabs(3);
              }}
            >
              {`Records`}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          {/* Case Details Tab content */}
          <TabPane tabId={1}>
            <div
              className="viewer_case_details"
              style={{
                height:
                  document.getElementById("container")?.offsetHeight - 100 ||
                  "100%",
                overflow: "auto",
              }}
            >
              <Row className="m-3 text-center">
                <Col xs="12" md="12" lg="4">
                  <div className="viewer-detail_header">Case</div>
                </Col>
                <Col xs="12" md="12" lg="8">
                  <div className="viewer-detail_header">
                    {this.state.case.uid}
                  </div>
                </Col>
              </Row>
              {this.state.details.can_approve_or_disapprove &&
                !this.state.case.is_archived && (
                  <Row className=" m-3 pt-3">
                    <Col className="w-100 mb-2">
                      <Button
                        className="btn green-btn w-100"
                        size="lg"
                        onClick={this.Approve}
                      >
                        Approve
                      </Button>
                    </Col>
                    <Col className="w-100 mb-2">
                      <Button
                        className="btn red-btn w-100"
                        size="lg"
                        color="danger"
                        onClick={this.DisApprove}
                      >
                        Disapprove
                      </Button>
                    </Col>
                  </Row>
                )}
              <Row className="m-3 text-center justify-content-center m-auto">
                <Col>
                  <img
                    src={this.state.details.patient.photo}
                    alt={this.state.case.uid}
                    height="100"
                    width="100"
                  />
                </Col>
              </Row>
              <Row className=" m-3">
                <Col>
                  {(this.state.details.state === "Active" ||
                    this.state.details.state === "Inactive") && (
                      <Row className="p-1">
                        <Col>
                          <div>Treatment setup status </div>
                        </Col>
                        <Col className="pl-0 pr-0">
                          {this.state.details.state === "Active" && (
                            <div style={{ color: "green" }}>Approved</div>
                          )}
                          {this.state.details.state === "Inactive" && (
                            <div style={{ color: "red" }}>Rejected</div>
                          )}
                        </Col>
                      </Row>
                    )}
                  <Row className="p-1">
                    <Col>
                      <div>Treatment setup </div>
                    </Col>
                    <Col className="pl-0 pr-0">
                      <div>{this.state.details.front_title}</div>
                    </Col>
                  </Row>
                  <Row className="p-1">
                    <Col>
                      <div>Treatment status </div>
                    </Col>
                    <Col className="pl-0 pr-0">
                      <div>{this.state.case.status}</div>
                    </Col>
                  </Row>
                  <Row className="p-1">
                    <Col>
                      <div>Patient name </div>
                    </Col>
                    <Col className="pl-0 pr-0">
                      <div>{this.state.case.patient.full_name}</div>
                    </Col>
                  </Row>
                  <Row className="p-1">
                    <Col>
                      <div>Submitted at </div>
                    </Col>
                    <Col className="pl-0 pr-0">
                      <div>{this.state.case.submitted_at}</div>
                    </Col>
                  </Row>
                  <Row className="p-1">
                    <Col>
                      <div> Case type </div>
                    </Col>
                    <Col className="pl-0 pr-0">
                      <div>
                        {caseType[this.state.case.case_type] ||
                          this.state.case.case_type}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className=" m-3 pt-3">
                <Col className="w-100 mb-2">
                  {this.state.details.ipr.match(/http([^&]+)/) ? (
                    <a
                      href={this.state.details.ipr}
                      target="blank"
                      download={`${this.state.case.uid} ipr`}
                    >
                      <Button className="btn green-btn w-100 h-100" size="lg">
                        IPR File
                      </Button>
                    </a>
                  ) : (
                      <Button
                        className="btn green-btn w-100 h-100"
                        size="lg"
                        disabled
                      >
                        IPR File
                      </Button>
                    )}
                </Col>
                <Col className="w-100 mb-2">
                  {this.state.details.sps.match(/http([^&]+)/) ? (
                    <a
                      href={this.state.details.sps}
                      target="blank"
                      download={`${this.state.case.uid} sps`}
                    >
                      <Button className="btn green-btn w-100 h-100" size="lg">
                        SPS File
                      </Button>
                    </a>
                  ) : (
                      <Button
                        className="btn green-btn w-100 h-100"
                        size="lg"
                        disabled
                      >
                        SPS File
                      </Button>
                    )}
                </Col>
              </Row>
              {this.state.details.technician_notes !== "" && (
                <Row className="m-3 text-center">
                  <Col xs="12" md="12" lg="12">
                    <div className="tech-notes_header">Technician Notes</div>
                  </Col>
                  <Col xs="12" md="12" lg="12">
                    <div className="tech-notes_detail">
                      {" "}
                      {this.state.details.technician_notes}
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </TabPane>
          {/* Inbox Tab content*/}
          <TabPane tabId={2}>
            <div
              style={{
                height:
                  document.getElementById("container")?.offsetHeight - 150 ||
                  "100%",
                overflow: "auto",
              }}
              className="mt-5"
            >
              {this.state.activeTab === 2 && (
                <Inbox caseId={this.state.case.uid} formKey={"viewer"} />
              )}
            </div>
          </TabPane>
          {/* Records Tab content */}
          <TabPane tabId={3}>
            <div
              style={{
                height:
                  document.getElementById("container")?.offsetHeight - 100 ||
                  "100%",
                overflow: "auto",
              }}
            >
              {" "}
              <Gallery
                CaseDetails={this.props.details}
                data={this.props.props}
              />
            </div>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
