import React, { Component } from "react";
import { connect } from "react-redux";

import ToggleSwitch from "../../../../../components/Switch/Switch";
import CustomeTeeth from "../../../../Teeth/CustomeTeeth";
import { CustomInput, Row, Col } from "reactstrap";
import {
    clearMalocclusionData
} from "../../../../../redux/CaseSubmission/action";

import {
    Clear_Teeth_Data
} from "../../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../../components/TreatmentOptions/TreatmentOptions";
const mapStateToProps = (state) => ({
    ...state,
});
const mapDispatchToProps = (dispatch) => ({
    clearMalocclusionData: (payload) => dispatch(clearMalocclusionData(payload)),
    Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload))
});
class Crowding extends Component {
    /* --------------------------------------------------------------------------------*/
    /* constructor */
    /* --------------------------------------------------------------------------------*/
    constructor(props) {
        super(props);
        this.state = {
            use_extraction: ""
        }

        /* init state */
        //use_extraction
        this.use_extraction =
            this.props.caseSubmission?.advanced_modules?.malocclusion?.crowding
                ?.use_extraction || false;
        //Proclination treatment Options
        this.proclination =
            this.props.caseSubmission?.advanced_modules?.malocclusion?.crowding
                ?.treatment_Options?.proclination || false;
        //expansion treatment Options
        this.expansion =
            this.props.caseSubmission?.advanced_modules?.malocclusion?.crowding
                ?.treatment_Options?.expansion || false;
        //ipr treatment Options
        this.ipr =
            this.props.caseSubmission?.advanced_modules?.malocclusion?.crowding
                ?.treatment_Options?.ipr || false;
        //Distalisation treatment Options
        this.distalisation =
            this.props.caseSubmission?.advanced_modules?.malocclusion?.crowding
                ?.treatment_Options?.distalisation || false;


    }

    UpdateCrossBiteReducer = () => {
        let data = {
            use_extraction: this.use_extraction,
            teeth: this.props.customeTeethReducer.Case_Crowding,
            treatment_Options: {
                proclination: this.proclination,
                expansion: this.expansion,
                ipr: this.ipr,
                distalisation: this.distalisation,
            },
        };

        this.props.save("crowding", data);
    };
    /* --------------------------------------------------------------------------------*/
    /* handel Treatment Options Changes */
    /* --------------------------------------------------------------------------------*/
    handelTreatmentOptionsChanges = (value, key) => {
        this.use_extraction = value
    }
    render() {
        const {
            use_extraction,
            proclination,
            expansion,
            ipr,
            distalisation,
        } = this;
        return (
            <div>
                <div className="malocclusion-title">
                    Crowding
      {!this.props.summary && <span
                        className="close-btn pull-right"
                        onClick={() => {
                            this.props.Clear_Teeth_Data("Case_Crowding")
                            this.props.clearMalocclusionData("crowding")
                            this.props.toggleComponents();
                        }}
                    >
                        &times;
          </span>}
                </div>

                <div className=" pt-4">

                    <div className="text-center">
                        <div className="control-label title_active-gco text-center mb-2">
                            Use extraction when correcting{" "}
                        </div>
                        <TreatmentOptions value={use_extraction} name="extraction" summary={this.props.summary} onChange={(value) => {
                            this.handelTreatmentOptionsChanges(value, "use_extraction")
                            if (value !== true) {
                                this.props.Clear_Teeth_Data("Case_Crowding")
                            }
                            this.UpdateCrossBiteReducer();

                        }} />
                    </div>

                    <div>
                        <div className="control-label title_active-gco text-center pb-2 mt-2 ">
                            Extraction
              </div> <div className="teeth_selector">
                            <CustomeTeeth
                                action_key="Case_Crowding"
                                disabled={!use_extraction || use_extraction === "eonToDecide"}
                                key={"Case_Crossbites"}
                                UpdateCrossBiteReducer={this.UpdateCrossBiteReducer}
                                summary={this.props.summary}

                            />
                        </div>
                    </div>
                </div>

                <div className="p-2">
                    <div className="control-label title_active-gco">
                        Treatment options{" "}
                    </div>
                    <CustomInput

                        className={`pt-1 ${proclination && "replacment-checkbox"}`}
                        type="checkbox"
                        id="proclination"
                        checked={proclination}
                        disabled={this.props.summary}
                        onChange={() => {
                            this.proclination = !this.proclination;
                            this.UpdateCrossBiteReducer();
                        }}
                        label="Resolve with Proclination"
                    />
                    <CustomInput

                        className={`pt-1 ${expansion && "replacment-checkbox"}`}
                        type="checkbox"
                        id="expansion"
                        checked={expansion}
                        disabled={this.props.summary}
                        onChange={() => {
                            this.expansion = !this.expansion;
                            this.UpdateCrossBiteReducer();
                        }}
                        label="Resolve with Expansion"
                    />
                    <CustomInput
                        className={`pt-1 ${ipr && "replacment-checkbox"}`}
                        type="checkbox"
                        id="ipr"
                        checked={ipr}
                        disabled={this.props.summary}
                        onChange={() => {
                            this.ipr = !this.ipr;
                            this.UpdateCrossBiteReducer();
                        }}
                        label="Resolve with IPR"
                    />
                    <CustomInput
                        className={`pt-1 ${distalisation && "replacment-checkbox"}`}
                        type="checkbox"
                        id="distalisation"
                        checked={distalisation}
                        disabled={this.props.summary}
                        onChange={() => {
                            this.distalisation = !this.distalisation;
                            this.UpdateCrossBiteReducer();
                        }}
                        label="Resolve with Distalisation"
                    />
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Crowding);
