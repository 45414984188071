import React, { Component } from "react";
import {
    CardBody,
    CardTitle,
    CardFooter,
    Button,
    Card,
    CardImg,
    Input,
} from "reactstrap";
import ToggleSwitch from "../../../components/Switch/Switch";

export default class IPRDetailes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            use_ipr: this.props.data.use_ipr,
            ipr_step: this.props.data.before_step,
            ipr_value: Number(this.props.data.amount),
            tooth_key: this.props.data.tooth_key
        }
    }

    saveIPRData = () => {
        let new_data = {
            ...this.props.data,
            use_ipr: this.state.use_ipr,
            before_step: this.state.ipr_step,
            amount: this.state.ipr_value,
            updated:true
        }
        this.props.saveIPRData(new_data)
    }
    render() {
        const { use_ipr, ipr_step, ipr_value, tooth_key } = this.state;
        return (

            <Card className="mt-3">
                <CardTitle className="border-bottom p-2 detailes_title case-title">
                    {`IPR ${tooth_key}`}
                </CardTitle>
                <CardBody className="ipr-detailes-grid">
                    <div>

                        <div className="auto_grid5 pb-2">
                            <div className="control-label title_active-gco">
                                IPR
                                </div>
                            <div>

                                <ToggleSwitch
                                    id="use-ipr"
                                    checked={use_ipr}
                                    onChange={() => {
                                        this.setState({ use_ipr: !use_ipr })
                                    }}
                                />
                            </div>
                        </div>



                        <div className="auto_grid5 pb-2">
                            <div className="control-label title_active-gco">
                                IPR Step
                                </div>
                            <div className="number-ipr-detailes form-material">
                                <Input type="number" value={ipr_step} className="hide-arrows" min="1"
                                    step="1" onChange={(e) => {

                                        if (e.target.value < 1) {
                                            this.setState({ ipr_step: 1 })
                                        } else {
                                            this.setState({ ipr_step: Number(e.target.value) })
                                        }

                                    }} />
                                <div className="auto_grid6">


                                    <i onClick={() => {
                                        this.setState({ ipr_step: ipr_step + 1 })
                                    }} className="fas fa-angle-up num-angle"></i>
                                    <i onClick={() => {
                                        if (ipr_step > 1) {
                                            this.setState({ ipr_step: ipr_step - 1 })
                                        }

                                    }} className={`fas fa-angle-down ${ipr_step > 1 ? "num-angle" : "disable-num-angle"}  `}></i>

                                </div>
                            </div>
                        </div>
                        <div className="auto_grid5 pb-2">
                            <div className="control-label title_active-gco">
                                IPR Value
                                </div>
                            <div className="number-ipr-detailes form-material">
                                <Input type="number" value={ipr_value} min="0"
                                    step="0.1" className="hide-arrows" onChange={(e) => {
                                        if (e.target.value < 0) {
                                            this.setState({ ipr_value: 0.1 })
                                        } else {
                                            this.setState({ ipr_value: Number(e.target.value) })
                                        }



                                    }} />
                                <div className="auto_grid6">
                                    <i onClick={() => {
                                              var value = ipr_value + 0.1;
                                              this.setState({ ipr_value: Number(value.toFixed(1)) })
                                    }} className="fas fa-angle-up num-angle"></i>
                                    <i onClick={() => {
                                        if (ipr_value > 0) {
                                            var value = ipr_value - 0.1;
                                            this.setState({ ipr_value: Number(value.toFixed(1)) })
                                        }

                                    }} className={`fas fa-angle-down ${ipr_value > 0 ? "num-angle" : "disable-num-angle"}  `}></i>

                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="actions-grid">
                        <div><Button
                            className={`btn  ${"preference-btn"}  float-right text-center  btn-radius`}
                            size="sm"
                            type="button"
                            onClick={() => { this.saveIPRData() }}  >
                            Save Changes  </Button></div>

                        <div><Button
                            className={`btn  ${"preference-btn"}  float-right text-center  btn-radius`}
                            size="sm"
                            type="button"
                            onClick={() => { this.props.cancelChangesOnIPR() }}  >
                            Cancel  </Button>

                        </div>
                    </div>

                </CardBody>
            </Card>);
    }
}