import {
  CASE_STEPS,
  CURRENT_STEP,
  PREV_STEPS,
  SHOW_ADVANCED,
  UPDATE_ADVANCED_MODULES,
  UPDATE_ALL_ADVANCED_MODULES,
  UPDATE_PHOTOS_XRAYS,
  UPDATE_IMPRESSIONS,
  UPDATE_PRESCRIPTION,
  UPDATE_MALOCCLUSION,
  CLEAR_MALOCCLUSION_DATA,
  CLEAR_ADVANCED_MODULES_DATA,
  CLEAR_STEPS,
  UPDATE_PATIENT_INFO
} from "../constants/";
import { advanced_modules_data } from "./advanced_modules_data";
const INIT_STATE = {
  currentStep: 1,
  showAdvancedModule: false,
  Steps: [
    {
      id: 1,
      name: "Patient Information",
      active: true,
      subTitle: false,
    },
    // {
    //   id: 2,
    //   name: "Treatment Type",
    //   active: false,
    //   subTitle: false,
    // },
    {
      id: 2,
      name: "Photos + X-rays",
      active: false,
      subTitle: false,
    },
    {
      id: 3,
      name: "Impressions",
      active: false,
      subTitle: false,
    },

    {
      id: 4,
      name: "Prescription",
      active: false,
      subTitle: false,
    },
    {
      id: 5,
      name: "Advanced Treatment Options",
      active: false,
      subTitle: true,
    },
    {
      id: 6,
      name: "Summary",
      active: false,
      subTitle: false,
    },
  ],
  advanced_modules: advanced_modules_data,
  patient_info: {
    date_of_birth: "",
    first_name: "",
    gender: "",
    last_name: "",
    email: "",
    case_type: "",
    address_id: ""
  },
  photos_xrays: {
    front_smiling: "",
    front_pose: "",
    profile: "",
    upper_occlusal: "",
    lower_occlusal: "",
    left_buccal: "",
    frontal: "",
    right_buccal: "",
    panaromic: "",
    cephalometric: "",
  },
  impressions: {
    impressions_method: "",
    impressions_third_party_note: "",
    upper_arch_url: "",
    lower_arch_url: "",
  },

  prescription: {
    chief_complaint: "",
    summary_and_special_instructions: "",
    treat_arches: "",
    wear_cycle: "",
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CASE_STEPS:
      return {
        ...state,
        Steps: state.Steps.map((item, index) => {
          // Find the item with the matching id
          if (item.id <= action.payload.id) {
            // Return a new object
            return {
              ...item, // copy the existing item
              active: true, // activate step
            };
          }

          // Leave every other item unchanged
          return item;
        }),
      };
    case PREV_STEPS:
      return {
        ...state,
        Steps: state.Steps.map((item, index) => {
          // Find the item with the matching id
          if (item.id <= action.payload.id) {
            // Return a new object
            return {
              ...item, // copy the existing item
              active: true, // activate step
            };
          } else {
            return {
              ...item, // copy the existing item
              active: false, // activate step
            };
          }
        }),
      };
    case CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case SHOW_ADVANCED:
      return {
        ...state,
        showAdvancedModule: action.payload,
      };
    case UPDATE_PATIENT_INFO:
      return {
        ...state,
        patient_info: action.payload
      }
    case UPDATE_ADVANCED_MODULES:
      return {
        ...state,
        advanced_modules: {
          ...state.advanced_modules,
          [action.payload.key]: action.payload.data,
        },
      };
    case UPDATE_ALL_ADVANCED_MODULES:
      return {
        ...state,
        advanced_modules: action.payload,
      };
    case UPDATE_PHOTOS_XRAYS:
      return {
        ...state,
        photos_xrays: action.payload,
      };
    case UPDATE_IMPRESSIONS:
      return {
        ...state,
        impressions: action.payload,
      };
    case UPDATE_PRESCRIPTION:
      return {
        ...state,
        prescription: action.payload,
      };
    case UPDATE_MALOCCLUSION:
      return {
        ...state,
        advanced_modules: {
          ...state.advanced_modules,
          malocclusion: {
            ...state.advanced_modules.malocclusion,
            [action.payload.key]: action.payload.data,
          },
        },
      };
    case CLEAR_MALOCCLUSION_DATA:
      return {
        ...state,
        advanced_modules: {
          ...state.advanced_modules,
          malocclusion: {
            ...state.advanced_modules.malocclusion,
            [action.payload]: INIT_STATE.advanced_modules.malocclusion[action.payload],
          },
        },
      };
    case CLEAR_ADVANCED_MODULES_DATA:
      return {
        ...state,
        advanced_modules: {
          ...state.advanced_modules,
          [action.payload]: INIT_STATE.advanced_modules[action.payload],

        },
      };
    case CLEAR_STEPS:
      return {
        ...state,
        Steps: INIT_STATE.Steps
      }
    default:
      return state;
  }
};
