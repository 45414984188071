import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View, Page } from "@react-pdf/renderer";
import Extraction from "./advancedModules/Extraction";
import IPR from "./advancedModules/IPR.js";
import Attachments from "./advancedModules/Attachments";
import Pontics from "./advancedModules/Pontics";
import Overcorrection from "./advancedModules/Overcorrection";
import PassiveAligner from "./advancedModules/PassiveAligner";
import TorqueEnhancers from "./advancedModules/TorqueEnhancers";
import ToothSizeDiscrepancy from "./advancedModules/ToothSizeDiscrepancy";
import BiteRamps from "./advancedModules/BiteRamps";
import ArchExpansion from "./advancedModules/ArchExpansion";
import Elastics from "./advancedModules/Elastics";
import Crossbite from "./Malocclusion/Crossbite";
import Spacing from "./Malocclusion/Spacing";
import ClassII from "./Malocclusion/ClassII";
import ClassIII from "./Malocclusion/ClassIII";
import Openbite from "./Malocclusion/Openbite";
import Deepbite from "./Malocclusion/Deepbite";
import Midline from "./Malocclusion/Midline";
import Crowding from "./Malocclusion/Crowding";

import Footer from "./Footer";

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 80,
        paddingHorizontal: 35,
    },
    row: {
        paddingTop: 10,
        alignItems: "flex-start",
        justifyContent: "flex-start",
        paddingBottom: 10,

    },

    header: {
        fontSize: 12,
        textAlign: "left",
        color: "#00a78c",
        width: "100%",
        paddingBottom: 10,
        borderBottomWidth: 1.2,
        borderColor: "#00a78c",
    },
    container: {
        flexDirection: "row",
        justifyContent: "flex-start",
        borderBottomWidth: 1,
        borderColor: "#E6E6FA",
        paddingBottom: 15,
        paddingTop: 10,
    },
    container2: {
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: 5,
    },
    text_area: {
        flexGrow: 1,
        borderColor: "#a0f0e7",
        borderWidth: 1,
        borderRadius: 2,
        fontSize: 8,
        padding: 2,
        margin: 3,
        minHeight: "70px",
        flexDirection: "column",
    },
    field: {
        flexDirection: "row",
        paddingTop: 10,
    },
    col: {
        flexDirection: "column",
        width: "50%",
    },
    col_4: {
        flexDirection: "column",
        width: "40%",
    },
    col_5: {
        flexDirection: "column",
        width: "50%",
    },
    col_55: {
        flexDirection: "column",
        width: "55%",
    },
    col_6: {
        flexDirection: "column",
        width: "60%",
    },
    col_1: {
        flexDirection: "column",
        width: "10%",
    },
    col_3: {
        flexDirection: "column",
        width: "30%",
    },
    col_7: {
        flexDirection: "column",
        width: "70%",
    },
    col_15: {
        flexDirection: "column",
        width: "15%",
    },
    col_25: {
        flexDirection: "column",
        width: "25%",
    },
    col_05: {
        flexDirection: "column",
        width: "5%",
    },
    title: {
        fontSize: 10,
        textAlign: "left",
        color: "#00a78c",
        padding: "10px 0 5px 0",
    },
    title2: {
        fontSize: 10,
        textAlign: "left",
        color: "#00a78c",
    },
    info: {
        fontSize: 10,
        textAlign: "left",
    },
    image: {
        // backgroundColor: "red",
        borderRadius: 4,
    },

    green_btn_not_active: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        borderColor: "#d7f5f2",
        borderWidth: 1,
        backgroundColor: "white",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    hybrid_green_btn_not_active: {
        width: "auto",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        borderColor: "#d7f5f2",
        borderWidth: 1,
        backgroundColor: "white",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    col_title: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
        fontSize: 8,
        padding: "0 5px 0 0",
        // margin: 1,
        flexDirection: "column",
        width: "10%",
    },
    green_btn_active: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00a78c",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    hybrid_green_btn_active: {
        width: "auto",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00a78c",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    emplty_col: {
        width: "30%",
    },
    text_active: {
        fontSize: 8,
        color: "white",
        padding: 1,
    },
    text_not_active: {
        fontSize: 8,
        color: "#00a78c",
        padding: 1,
    },
    teeth_border: {
        borderWidth: 1,
        borderColor: "#00a78c",
    },
    text_area: {
        flexGrow: 1,
        borderColor: "#a0f0e7",
        borderWidth: 1,
        borderRadius: 2,
        fontSize: 8,
        padding: 2,
        margin: 3,
        minHeight: "50px",
        flexDirection: "column",
    },
    photo_header: {
        flexDirection: "column",
        width: "100px",
        margin: 5,
        fontSize: 8,
        color: "#00a78c",
        textAlign: "center",
    },
    value: {
        padding: "10px",
    },
    photo: {
        flexDirection: "column",
        width: "100px",
        height: "auto",
        margin: 5,
        zIndex: 99,
    },
    space: {
        flexDirection: "column",
        width: "10%",
    },
    active_card: {
        flexDirection: "column",
        width: "100px",
        height: "auto",
        margin: 5,
    },
    card: {
        flexDirection: "column",
        width: "100px",
        height: "auto",
        margin: 5,
        backgroundColor: "white",
    },
    hybrid: {
        flexDirection: "column",
        width: "90%",
        height: "auto",
        padding: "50px",
    },
    hybrid_header: {
        flexDirection: "column",
        width: "100%",
        margin: 5,
        fontSize: 8,
        color: "#00a78c",
        textAlign: "left",
    },
    buttons_container: {
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        paddingBottom: 5,
    },
    hybrid_subTitle: {
        fontSize: 8,
        color: "#00a78c",
        width: "30px",
        flexDirection: "column",
        margin: 5,
    },
    row_space: {
        flexDirection: "row",
        height: "10px",
    },
});

export default class AllModules6 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterd_advanced: [],
            filterd_malocclusions: []
        };
    }
    componentDidMount = () => {
        let advancedModules = [];

        if (this.props.mappedData.advanced_modules.all_modules) {
            advancedModules = this.props.mappedData.advanced_modules.all_modules.filter(
                (value, index) => {
                    return value.active === true && value.key !== "malocclusion";
                }
            );
        }

        this.setState({
            filterd_advanced: advancedModules

        })

        let malocclusions = [
            {
                key: "crossbite", active: this.props.mappedData.advanced_modules.malocclusion.crossbite_btn
            },
            {
                key: "spacing", active: this.props.mappedData.advanced_modules.malocclusion.spacing_btn
            },
            {
                key: "classII", active: this.props.mappedData.advanced_modules.malocclusion.classII_btn
            },
            {
                key: "classIII", active: this.props.mappedData.advanced_modules.malocclusion.classIII_btn
            },
            {
                key: "openbite", active: this.props.mappedData.advanced_modules.malocclusion.openbite_btn
            },
            {
                key: "deepbite", active: this.props.mappedData.advanced_modules.malocclusion.deepbite_btn
            },
            {
                key: "midline", active: this.props.mappedData.advanced_modules.malocclusion.midline_btn
            },
            {
                key: "crowding", active: this.props.mappedData.advanced_modules.malocclusion.crowding_btn || false
            }
        ]


        let filtered = malocclusions.filter((value, index) => {
            return value.active === true
        })
        this.setState({ filterd_malocclusions: filtered })
    }
    render() {
        const { filterd_advanced, filterd_malocclusions } = this.state
        return (
            <Fragment>
                {filterd_advanced.length === 6 &&
                    <Fragment>
                        <Page style={styles.body}>
                            <Fragment>
                                <View style={styles.row}>
                                    <Text style={styles.header}>{`Advanced Treatment Options`}</Text>
                                    {
                                        filterd_advanced.slice(0, 4).map((value, index) => (
                                            <View key={index}>
                                                {
                                                    value.key === "extraction" && <Extraction AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }

                                                {
                                                    value.key === "ipr" && <IPR AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }
                                                {
                                                    value.key === "attachments" && <Attachments AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }


                                                {
                                                    value.key === "pontics" && <Pontics AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }

                                                {
                                                    value.key === "overCorrection" && <Overcorrection AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }
                                                {
                                                    value.key === "passiveAligners" && <PassiveAligner AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }
                                                {
                                                    value.key === "torqueEnhancers" && <TorqueEnhancers AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }
                                                {
                                                    value.key === "toothSizeDiscrepancy" && <ToothSizeDiscrepancy AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }

                                                {
                                                    value.key === "biteRamps" && <BiteRamps AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }
                                                {
                                                    value.key === "archExpansion" && <ArchExpansion AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }
                                                {
                                                    value.key === "elastics" && <Elastics AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }
                                            </View>
                                        ))
                                    }

                                </View>
                            </Fragment>
                            <Footer doctorInfo={this.props.doctorInfo} />
                        </Page>
                        <Page style={styles.body}>
                            <Fragment>
                                <View style={styles.row}>
                                    <Text style={styles.header}>{`Advanced Treatment Options`}</Text>
                                    {
                                        filterd_advanced.slice(4, 8).map((value, index) => (
                                            <View key={index}>
                                                {
                                                    value.key === "extraction" && <Extraction AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }

                                                {
                                                    value.key === "ipr" && <IPR AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }
                                                {
                                                    value.key === "attachments" && <Attachments AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }

                                                {
                                                    value.key === "pontics" && <Pontics AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }

                                                {
                                                    value.key === "overCorrection" && <Overcorrection AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }
                                                {
                                                    value.key === "passiveAligners" && <PassiveAligner AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }
                                                {
                                                    value.key === "torqueEnhancers" && <TorqueEnhancers AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }
                                                {
                                                    value.key === "toothSizeDiscrepancy" && <ToothSizeDiscrepancy AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }

                                                {
                                                    value.key === "biteRamps" && <BiteRamps AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }
                                                {
                                                    value.key === "archExpansion" && <ArchExpansion AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }
                                                {
                                                    value.key === "elastics" && <Elastics AdvancedModules={this.props.mappedData.advanced_modules} />
                                                }
                                            </View>
                                        ))
                                    }
                                    {
                                        filterd_malocclusions.length > 0 &&
                                        <View style={styles.row}>
                                            <Text style={styles.header}>{`Malocclusion`}</Text>

                                            {
                                                filterd_malocclusions.slice(0, 1).map((value, index) => (<View key={index}>
                                                    {
                                                        value.key === "crossbite" && <Crossbite malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                    }

                                                    {
                                                        value.key === "spacing" && <Spacing malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                    }
                                                    {
                                                        value.key === "classII" && <ClassII malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                    }

                                                    {
                                                        value.key === "classIII" && <ClassIII malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                    }

                                                    {
                                                        value.key === "openbite" && <Openbite malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                    }

                                                    {
                                                        value.key === "deepbite" && <Deepbite malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                    }
                                                    {
                                                        value.key === "midline" && <Midline malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                    }
                                                    {
                                                        value.key === "crowding" && <Crowding malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                    }
                                                    {
                                                        index === 2 && <View style={styles.row_space2}></View>
                                                    }

                                                </View>
                                                ))
                                            }
                                        </View>
                                    }
                                </View>
                            </Fragment>
                            <Footer doctorInfo={this.props.doctorInfo} />

                        </Page>
                        {
                            filterd_malocclusions.length > 1 && <Page style={styles.body}>
                                <Fragment>
                                    <View style={styles.row}>
                                        <Text style={styles.header}>{`Malocclusion`}</Text>

                                        {
                                            filterd_malocclusions.slice(1, 4).map((value, index) => (<View key={index}>
                                                {
                                                    value.key === "crossbite" && <Crossbite malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                }

                                                {
                                                    value.key === "spacing" && <Spacing malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                }
                                                {
                                                    value.key === "classII" && <ClassII malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                }

                                                {
                                                    value.key === "classIII" && <ClassIII malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                }

                                                {
                                                    value.key === "openbite" && <Openbite malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                }

                                                {
                                                    value.key === "deepbite" && <Deepbite malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                }
                                                {
                                                    value.key === "midline" && <Midline malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                }
                                                {
                                                    value.key === "crowding" && <Crowding malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                                }
                                                {
                                                    index === 2 && <View style={styles.row_space2}></View>
                                                }

                                            </View>
                                            ))
                                        }
                                    </View>
                                </Fragment>
                                <Footer doctorInfo={this.props.doctorInfo} />
                            </Page>

                        }
                        {filterd_malocclusions.length > 4 && <Page style={styles.body}>
                            <Fragment>
                                <View style={styles.row}>
                                    <Text style={styles.header}>{`Malocclusion`}</Text>

                                    {
                                        filterd_malocclusions.slice(4, 8).map((value, index) => (<View key={index}>
                                            {
                                                value.key === "crossbite" && <Crossbite malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                            }

                                            {
                                                value.key === "spacing" && <Spacing malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                            }
                                            {
                                                value.key === "classII" && <ClassII malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                            }

                                            {
                                                value.key === "classIII" && <ClassIII malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                            }

                                            {
                                                value.key === "openbite" && <Openbite malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                            }

                                            {
                                                value.key === "deepbite" && <Deepbite malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                            }
                                            {
                                                value.key === "midline" && <Midline malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                            }
                                            {
                                                value.key === "crowding" && <Crowding malocclusion={this.props.mappedData.advanced_modules.malocclusion} />
                                            }
                                            {
                                                index === 2 && <View style={styles.row_space2}></View>
                                            }

                                        </View>
                                        ))
                                    }
                                </View>
                            </Fragment>
                            <Footer doctorInfo={this.props.doctorInfo} />
                        </Page>
                        }
                    </Fragment>
                }

            </Fragment>

        );
    }
}