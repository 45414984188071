import React, { Component } from "react";
import { connect } from "react-redux";
import CustomeTeeth from "../../../../Teeth/CustomeTeeth";
import { CustomInput } from "reactstrap";
import {
  clearMalocclusionData
} from "../../../../../redux/CaseSubmission/action";
import {
  Clear_Teeth_Data
} from "../../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../../components/TreatmentOptions/TreatmentOptions";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  clearMalocclusionData: (payload) => dispatch(clearMalocclusionData(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload))

});
class ClassIII extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.use_elastics =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classIII
        ?.useElasticWhenCorrecting || false;

    this.advanceUppers =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classIII
        ?.advanceUppers || false;

    this.retractLowersExtraction =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classIII
        ?.retractLowersExtraction || false;

    this.retractLowersIPR =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classIII
        ?.retractLowersIPR || false;

    this.estheticAlignmentOnly =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classIII
        ?.estheticAlignmentOnly || false;

    this.preSurgicalAlignment =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.classIII
        ?.preSurgicalAlignment || false;
  }

  UpdateCrossBiteReducer = () => {
    let data = {
      useElasticWhenCorrecting: this.use_elastics,
      teeth: this.props.customeTeethReducer.Case_ClassII,
      advanceUppers: this.advanceUppers,
      retractLowersExtraction: this.retractLowersExtraction,
      retractLowersIPR: this.retractLowersIPR,
      estheticAlignmentOnly: this.estheticAlignmentOnly,
      preSurgicalAlignment: this.preSurgicalAlignment,
    };

    this.props.save("classIII", data);
  };
    /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.use_elastics = value
  }
  render() {
    const { 
      use_elastics,
      advanceUppers,
      retractLowersExtraction,
      retractLowersIPR,
      estheticAlignmentOnly,
      preSurgicalAlignment,
    } = this;
    return (
      <div>
        <div className="malocclusion-title">
          Class III
     {!this.props.summary && <span
            className="close-btn pull-right"
            onClick={() => {
              this.props.clearMalocclusionData("classIII")
              this.props.Clear_Teeth_Data("Case_ClassIII")

              this.props.toggleComponents();
            }}
          >
            &times;
          </span>}
        </div>
        <div className=" pt-4">

          <div className="text-center">
            <div className="control-label title_active-gco text-center mb-2">
              Use elastics when correcting{" "}
            </div>
            <TreatmentOptions value={use_elastics} name="classIII" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_elastics")
              if (value !== true) {
                this.props.Clear_Teeth_Data("Case_ClassIII")
              }
              this.UpdateCrossBiteReducer();

            }} />
          </div>

          <div>
            <div className="control-label title_active-gco text-center pb-2 mt-2 ">
              Placement
</div> <div className="teeth_selector">
              <CustomeTeeth
                action_key="Case_ClassIII"
                disabled={!use_elastics || use_elastics === "eonToDecide"}
                key={"Case_ClassIII"}
                UpdateCrossBiteReducer={this.UpdateCrossBiteReducer}
                summary={this.props.summary}

              />
            </div>
          </div>
        </div>



        <div className="p-2">
          <div className="control-label title_active-gco">
            Treatment options{" "}
          </div>
          {/* 1 */}
          <CustomInput

            className={`pt-1 ${advanceUppers && "replacment-checkbox"}`}
            type="checkbox"
            id="advanceUppers"
            label="Advance uppers by aligners and Class III elastics"
            checked={advanceUppers}
            disabled={preSurgicalAlignment || estheticAlignmentOnly || this.props.summary}
            onChange={() => {
              this.advanceUppers = !this.advanceUppers;
              this.UpdateCrossBiteReducer();
            }}
          />
          {/* 2 */}

          <CustomInput

            className={`pt-1 ${retractLowersExtraction && "replacment-checkbox"
              }`}
            type="checkbox"
            id="retractLowersExtraction"
            label="Retract lowers by extraction and Class III elastics"
            checked={retractLowersExtraction}
            disabled={
              retractLowersIPR || preSurgicalAlignment || estheticAlignmentOnly || this.props.summary
            }
            onChange={() => {
              this.retractLowersExtraction = !this.retractLowersExtraction;
              this.UpdateCrossBiteReducer();
            }}
          />
          {/* 3 */}

          <CustomInput

            className={`pt-1 ${retractLowersIPR && "replacment-checkbox"}`}
            type="checkbox"
            id="retractLowersIPR"
            label="Retract lowers after IPR "
            checked={retractLowersIPR}
            disabled={
              retractLowersExtraction ||
              preSurgicalAlignment ||
              estheticAlignmentOnly || this.props.summary
            }
            onChange={() => {
              this.retractLowersIPR = !this.retractLowersIPR;
              this.UpdateCrossBiteReducer();
            }}
          />
          {/* 4 */}

          <CustomInput
            className={`pt-1 ${estheticAlignmentOnly && "replacment-checkbox"}`}
            type="checkbox"
            id="estheticAlignmentOnly"
            label="Esthetic alignment only"
            checked={estheticAlignmentOnly}
            disabled={
              advanceUppers ||
              preSurgicalAlignment ||
              retractLowersExtraction ||
              retractLowersIPR || this.props.summary
            }
            onChange={() => {
              this.estheticAlignmentOnly = !this.estheticAlignmentOnly;
              this.UpdateCrossBiteReducer();
            }}
          />
          {/* 5 */}
          <CustomInput
            className={`pt-1 ${preSurgicalAlignment && "replacment-checkbox"}`}
            type="checkbox"
            id="preSurgicalAlignment"
            label="Pre-surgical alignment followed by surgery"
            checked={preSurgicalAlignment}
            disabled={
              advanceUppers ||
              estheticAlignmentOnly ||
              retractLowersExtraction ||
              retractLowersIPR || this.props.summary
            }
            onChange={() => {
              this.preSurgicalAlignment = !this.preSurgicalAlignment;
              this.UpdateCrossBiteReducer();
            }}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClassIII);
