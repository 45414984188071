import React, { Component } from "react";
import { connect } from "react-redux";
import { CustomInput } from "reactstrap";
import {
  clearMalocclusionData
} from "../../../../../redux/CaseSubmission/action";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  clearMalocclusionData: (payload) => dispatch(clearMalocclusionData(payload)),

});
class Spacing extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */

    this.closeAllSpacesToggle =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.spacing
        ?.closeAllSpacesToggle || false;

    this.partiallyClose =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.spacing
        ?.partiallyClose || false;

    this.IPRopposing =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.spacing
        ?.IPRopposing || false;

    this.byRetractionOfAnteriors =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.spacing
        ?.closeAllSpaces?.byRetractionOfAnteriors || false;

    this.byMesialisation =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.spacing
        ?.closeAllSpaces?.byMesialisation || false;

    this.both =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.spacing
        ?.closeAllSpaces?.both || false;
  }

  UpdateCrossBiteReducer = () => {
    let data = {
      closeAllSpacesToggle: this.closeAllSpacesToggle,
      closeAllSpaces: {
        byRetractionOfAnteriors: this.byRetractionOfAnteriors,
        byMesialisation: this.byMesialisation,
        both: this.both,
      },
      partiallyClose: this.partiallyClose,
      IPRopposing: this.IPRopposing,
    };

    this.props.save("spacing", data);
  };
  render() {
    const {
      closeAllSpacesToggle,
      partiallyClose,
      IPRopposing,
      byRetractionOfAnteriors,
      byMesialisation,
      both,
    } = this;
    return (
      <div>
        <div className="malocclusion-title">
          Spacing
       {!this.props.summary && <span
            className="close-btn pull-right"
            onClick={() => {
              this.props.clearMalocclusionData("spacing")
              this.props.toggleComponents();
            }}
          >
            &times;
          </span>}
        </div>

        <div className="p-2">
          <div className="control-label title_active-gco">
            Treatment options{" "}
          </div>
          <CustomInput
            className={`pt-1 ${closeAllSpacesToggle && "replacment-checkbox"}`}
            type="checkbox"
            id="closeAllSpacesToggle"
            label="Close all spaces"
            checked={closeAllSpacesToggle}
            disabled={partiallyClose || this.props.summary}
            onChange={() => {
              this.closeAllSpacesToggle = !this.closeAllSpacesToggle;
              this.UpdateCrossBiteReducer();
            }}
          />
          {closeAllSpacesToggle && (
            <div className="pl-4">
              <CustomInput
                className={`pt-1 ${byRetractionOfAnteriors && "replacment-checkbox"
                  }`}
                type="checkbox"
                id="byRetractionOfAnteriors"
                label="By retraction of anteriors"
                checked={byRetractionOfAnteriors}
                disabled={byMesialisation || both || this.props.summary}
                onChange={() => {
                  this.byRetractionOfAnteriors = !this.byRetractionOfAnteriors;
                  this.UpdateCrossBiteReducer();
                }}
              />{" "}
              <CustomInput
                className={`pt-1 ${byMesialisation && "replacment-checkbox"}`}
                type="checkbox"
                id="byMesialisation"
                label="By Mesialisation"
                checked={byMesialisation}
                disabled={byRetractionOfAnteriors || both || this.props.summary}
                onChange={() => {
                  this.byMesialisation = !this.byMesialisation;
                  this.UpdateCrossBiteReducer();
                }}
              />{" "}
              <CustomInput
                className={`pt-1 ${both && "replacment-checkbox"}`}
                type="checkbox"
                id="both"
                label="Combination of both"
                checked={both}
                disabled={byMesialisation || byRetractionOfAnteriors || this.props.summary}
                onChange={() => {
                  this.both = !this.both;
                  this.UpdateCrossBiteReducer();
                }}
              />
            </div>
          )}{" "}
          <CustomInput
            className={`pt-1 ${partiallyClose && "replacment-checkbox"}`}
            type="checkbox"
            id="partiallyClose"
            checked={partiallyClose}
            disabled={closeAllSpacesToggle || this.props.summary}
            onChange={() => {
              this.partiallyClose = !this.partiallyClose;
              this.both = false;
              this.byRetractionOfAnteriors = false;
              this.byMesialisation = false;
              this.UpdateCrossBiteReducer();
            }}
            label="partially close and follow with restorative or prosthodon-tics"
          />
          <CustomInput
            className={`pt-1 ${IPRopposing && "replacment-checkbox"}`}
            type="checkbox"
            id="IPRopposing"
            checked={IPRopposing}
            disabled={this.props.summary}
            onChange={() => {
              this.IPRopposing = !this.IPRopposing;
              this.UpdateCrossBiteReducer();
            }}
            label="IPR opposing"
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Spacing);
