import React, { Component } from "react";
import {
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  Label,
  Input,
  FormGroup,
  Card,
  CustomInput,
} from "reactstrap";
import { connect } from "react-redux";

import { Formik, Form, ErrorMessage } from "formik";
import { updateAlignerCase } from "../../api/api";
import { schema } from "../../helpers/termsValidation";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
 class Terms extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      doctorId: props.userReducer.currentUser.id,

      terms: "",
      caseId:
        this.props.caseId ||
        (window.location.search.match(/id=([^&]+)/) || [])[1],
    };
  }
  /*--------------------------------------------------------------------------------*/
  /*Summary step submit  */
  /*--------------------------------------------------------------------------------*/
  handleSubmit = (values, { setSubmitting }) => {
    const { doctorId } = this.state;
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    setSubmitting(true);
    this.setState({ terms: !this.state.terms })
    let data = {
      aligner_case: {
        step: "terms",
        terms_and_agreements: "1",
      },
    };
    let data2 = {
      aligner_case: {
        step: "summary",
      }, ...(this.props.rejectable_id && {
        resolve_rejection_id: this.props.rejectable_id,
      })
    };

    if (this.props.rejectable_id) {
      updateAlignerCase(doctorId, caseId, JSON.stringify(data2))
        .then((res) => {
          this.props.FixAlignerCase()
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
        });
    } else {
      // updateAlignerCase(doctorId, caseId, JSON.stringify(data))
      //   .then((res) => {
      this.props.NextStep();
      setSubmitting(false);
      // this.props.ShowAdvancedModule(showAdvancedModule);
      // })
      // .catch((error) => {
      //   setSubmitting(false);
      // });
    }

  };
  render() {
    const { terms } = this.state;
    return (
      <div>
        <CardTitle className="border-bottom pb-3 title_active-gco case-title">
          {this.props.rejectable_id ?
            <div className="step-header-title">
              <span> Terms and Agreements</span>
              <span className="new_terms_date text-right">
                Updated May 10 th 2018
               </span>
            </div>
            :
            <div className="step-header-title">
              <span> Terms and Agreements     <br></br>
                <span className="new_terms_date">
                  Updated May 10 th 2018
  </span></span>
              <span className="patient_info">
                <div>{this.props.patient?.full_name}</div>
                <div> Case {this.state?.caseId}</div>

              </span>
            </div>
          }


        </CardTitle>
        <Formik
          enableReinitialize
          initialValues={{
            terms: "",
          }}
          // validationSchema={schema}
          onSubmit={this.handleSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="step-progress-new-case upload-container">
                {isSubmitting && (
                  <div className={`loading2`}>
                    <div className="spinner_2"></div>
                  </div>
                )}
              </div>
              <div className="mt-2 mb-2">
                <div className="row justify-content-md-center">
                  <div className="col">
                    <div className="">
                      <div className="form-group content form-block-holder">
                        <div className="terms">
                          <div className="terms_header">
                            eon Dental (Pricing Terms)
                          </div>
                          eon Dental Pricing Terms and Conditions (“Terms”)
                          <div className="terms_header">INTRODUCTION:</div>
                          <div>
                            These Terms are the only terms or conditions which
                            apply to contracts between eon Dental or one of our
                            subsidiaries, as applicable (“we”/ “us”/ "eon "),
                            and any Customer ("our Customer" / "Customer") for
                            purchases of products (“Products”, or an individual
                            "Product") and/ or related services (“Services”),
                            except where we have expressly otherwise agreed in
                            writing. These Terms apply to all aspects of the
                            relationship between eon and Customer from the time
                            that Customer first accesses our systems and
                            software, whether to place an order for Products or
                            Services ("Customer Orders"), or to provide patient
                            information for the purpose of placing such orders,
                            or to obtain access to eon branding or marketing
                            materials. No other terms shall apply to the
                            relationship between eon and our Customer for the
                            purchase of Products or Services, whether implied or
                            otherwise, except terms expressly agreed to in
                            writing by eon.
                          </div>
                          <div className="terms_header">
                            CUSTOMER ORDERS AND CONTRACTS:
                          </div>
                          <div>
                            If Customer accesses eon’s IT systems or uses eon
                            software, including any eon website, eon’s Store or
                            Webstore and/ or the eon Doctor Access and Treatment
                            setup viewer software (together “eon Systems”),
                            Customer does so with the permission of eon, for the
                            purpose of a good faith relationship with eon, for
                            ordering Products or Services offered by eon from
                            time to time. Use of Access software is subject to
                            the terms of the eon software license. These Terms
                            govern Customer's use of eon Systems and the
                            processes and requirements for ordering Products and
                            Services and Customer agrees to be bound by them in
                            consideration for eon providing Customer with access
                            to the eon Systems
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">

                            Customer Orders are placed or submitted using eon
                            Access and are considered to be placed or submitted
                            (pending acceptance by eon): if the Records Approval
                            (‘Records Approval’) is required. The price
                            applicable to a Customer Order will be the price
                            current at the time the Customer Order is placed.
                            Customer Orders are accepted by sending the Customer
                            a confirmation email or as indicated by eon Access.
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            Order for Products that are aligners or retainers
                            must be for either delivery to the country in which
                            the named patient (‘Patient”) for those aligners is
                            receiving treatment, or for delivery to the doctor
                            shipping address as indicated in the Doctors Access
                            Profile.
                          </div>
                          <div className="terms_header">
                            WHO IS OUR CUSTOMER?
                          </div>
                          <div>
                            eon Dental customers are the Doctors whose email
                            address is used to access the protected eon System
                            (‘eon Doctor’). If a practice, partnership,
                            corporate body or other legal entity for or with
                            which the eon Doctor works is named on the eon
                            invoice(s) and/or that practice pays eon’s invoices,
                            then eon is entitled to assume that it is the eon
                            Doctor who uses eon Access and places Customer
                            Orders with the authorization of, for and on behalf
                            of that entity (the ‘practice’). Under these
                            circumstances, the Practice will also be an eon
                            Customer and the eon Doctor and the Practice will be
                            jointly and severally liable for obligations in
                            these Terms which are obligations of the ‘Customer’
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            The eon Doctor and the Practice must read and
                            understand these terms before downloading any
                            materials or software, uploading Patient information
                            or placed any Customer Order, because in each case,
                            a contract will be formed and Customer will be bound
                            by these Terms which will govern that contract.
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            The Align contract also has ‘Limitation of
                            Liability’, ‘Invoice payment date’ and ‘Art and
                            advertising standards’, ‘law and jurisdiction
                            applicable to these terms’ clauses here.
                          </div>
                          <div className="terms_header">PRODUCTS:</div>
                          <div>Product : Eon Unlimited</div>
                          <div>
                            Number of aligners: Unlimited aligners to be used
                            within 5 years based on a patient’s 2 week wear
                            cycle.
                          </div>
                          <div>Expiry Date : 60 months</div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            <div>Product : Eon Lite</div>
                            <div>
                              Number of aligners: 14 upper and 14 lower aligners
                              & a Free refinement of an additional 6 Upper & 6
                              lower aligners to reach the same initial treatment
                              goal that’s approved by eons team.
                            </div>

                            <div>Expiry Date : 18 Months</div>
                            <div>
                              Notes :<br></br> We provide an additional 6
                              individual steps (1 upper and lower aligner) free
                              as refinement aligners to reach the treatment goal
                              provided the treatment goal has not changed from
                              the initial treatment goal. An additional 6 steps
                              can be provided for USD $300 to reach the
                              treatment goal if it has not been achieved after
                              the initial 6 refinement aligners, provided the
                              initial treatment goal has not changed. All
                              aligners will be shipped in one shipment
                            </div>
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            <div>Product : Eon Lite Single Arch</div>
                            <div>
                              Number of aligners : 14 Upper OR 14 Lower aligners
                            </div>

                            <div>Expiry Date : 18 Months</div>
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            Prices are subject to change with a 45 days prior
                            notice.
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            Expiration dates: <br></br> Unlimited cases expire 5
                            years from the date of shipment for the initial set
                            of aligners for the eon Aligner ® Unlimited product
                            category, based on a wear cycle of 2 weeks.
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            Lite cases expire 18 months from the date of
                            shipment of the initial set of aligner for the eon
                            Aligner ® Lite and eon Aligner ® Lite Single Arch
                            product categories based on a wear cycle of 2 weeks.
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            Once a treatment expiration date has passed, any
                            additional order for the same patient will be
                            considered a new treatment.
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            Refinement is an option to order extra aligners for
                            eon Aligner Lite and Lite Single Arch product
                            categories that meet the clinical criteria for
                            treatment completion within 14 steps of treatment,
                            if further tooth movement is required in order to
                            meet the original treatment goals. For the eon
                            Aligner ® Lite product category, one refinement
                            stage (six upper and lower aligners) is included in
                            the price if the treatment objectives have not
                            changed. The order must be received minimum 6 months
                            prior to the Treatment Expirate Date. Only one
                            refinement may be ordered per treatment.
                          </div>
                          <div className="terms_header">
                            PRODUCT DESCRIPTIONS
                          </div>
                          <div className="term-subHeader pt-2 pb-2">

                            eon Unlimited
                          </div>
                          <div>
                            Unlimited aligners to be used within 5 years based
                            on a patients 2 week wear cycle .
                          </div>
                          <div className="term-subHeader pt-2 pb-2">

                            eon Lite
                          </div>
                          <div>
                            An Aligner treatment up to 14 upper and 14 lower
                            aligners
                          </div>
                          <div>
                            We provide an additional 6 individual steps (1 upper
                            and lower aligner) free as refinement aligners to
                            reach the treatment goal provided the treatment goal
                            has not changed from the initial treatment goal.
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            An additional 6 steps can be purchased by the
                            customer to reach the treatment goal if it has not
                            been achieved after the initial 6 refinement
                            aligners, provided the initial treatment goal has
                            not changed
                          </div>
                          <div>
                            All aligners will be shipped in one shipment
                          </div>
                          <div className="terms_header">
                            eon Lite Single Arch
                          </div>
                          <div>
                            A Single arch treatment up to 14 aligners , then 6
                            free aligners are included in the package as a
                            refinement to reach the same initial treatment
                            objective .
                          </div>
                          <br></br>
                          <div className="terms_header">
                            DISCOUNT SCHEME AND ADVANTAGE PROGRAM
                          </div>
                          <div>The Current Loyalty Program</div>
                          <br></br>
                          <div className="terms_header">
                            OTHER TERMS AND CONDITIONS
                          </div>
                          <div className="term-subHeader pt-2 pb-2">

                            Shipment, Risk, Ownership, Cancellations and Returns
                          </div>
                          <div>
                            It is Customer's responsibility to provide for each
                            Patient all material records ("All Materials
                            Required") and to accept a treatment plan. Eon will
                            only manufacture the Products once it has received
                            the necessary All Materials Required and Customer's
                            treatment setup acceptance. Customer must manage
                            Customer's Patient's expectations on the timing of
                            their I treatment, particularly where delays are
                            caused by Customer's own delays or failures to act.
                            All Materials Required will not be returned to
                            Customer.
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            Products are manufactured and dispatched from the
                            site of manufacture within approximately 14 days of
                            approval of the treatment plan or, for replacement
                            aligners, within seven (7) days of placement of the
                            Customer Order. All dates are approximate only and
                            the time of dispatch is not of the essence.
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            eon is not liable for any liability arising as a
                            result of a delay in delivery of Products .
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            If the Products are not delivered, Customer will
                            inform eon who will determine the cause of the delay
                            and, if in accordance with these Terms eon is under
                            an obligation to deliver, eon will manufacture and
                            ship replacement Products and this will be
                            Customer's sole remedy in respect of a failed
                            delivery.
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            If Customer fails to accept delivery of the Products
                            within five (5) days of a notice from the delivery
                            company that the Products are ready for delivery,
                            then delivery of the Products will be deemed to have
                            been completed at 11am on the fifth day after such
                            notice. eon shall charge Customer for redelivery and
                            storage costs, including insurance, but shall have
                            no obligation to redeliver the Products seven (7)
                            days after the notice to Customer of the Products'
                            readiness for delivery.
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            The Products will be shipped to the delivery address
                            selected by the Customer at the time the Customer
                            Order is placed from the list of Customer addresses
                            recorded in the eon Access Systems.
                          </div>
                          <div className="terms_header">
                            Intellectual Property Rights, Confidentiality and
                            Trade Marks
                          </div>
                          <div>
                            All rights in intellectual property (including all
                            patents, trademarks, service marks, registered
                            designs, utility models, design right, database
                            rights, copyright (including copyright in software
                            and computer algorithms), trade secrets and other
                            confidential information, know-how, and all other
                            intellectual and industrial property and rights of a
                            similar or corresponding nature in any part of the
                            world) in or relating to the Products, the Services,
                            any materials, information, access software, eon
                            Systems, documents or items that eon prepares or
                            produces for Customer or makes available to Customer
                            will belong to eon absolutely.
                          </div>
                          <div className="terms_header">Termination</div>
                          <div>
                            Once Customer has placed a Customer Order that is
                            accepted by eon, Customer cannot cancel or terminate
                            the Customer Order unless eon fails to provide the
                            Products or Services to Customer within a reasonable
                            time.
                          </div>
                          <br></br>
                          <div className="pt-3 pb-3">
                            eon has the right to give Customer written notice
                            terminating any or all contracts(s) with immediate
                            effect (without need for a court order) if any of
                            the following occur, or eon considers in its sole
                            discretion that they are likely to occur (and which
                            might refer to either the Customer or the Practice,
                            or both):
                          </div>
                          <br></br>
                          <div className="points">
                            <ol>
                              <li className="pt-2 pb-2">
                                Customer fails to pay the sums that are owed to
                                eon under any agreement(s) when they are due for
                                payment, and/ or are persistently late in paying
                                sums due to eon under any agreement(s); or
                              </li>
                              <li className="pt-2 pb-2">

                                eon considers that Customer's financial position
                                is such that Customer will not be able to pay
                                sums due to eon; or
                              </li>
                              <li className="pt-2 pb-2">

                                the Practice ceases to operate as a dental
                                practice and/ or Customer, the Doctor, ceases to
                                practice dentistry (whether permanently or
                                temporarily so that eon at its sole discretion
                                considers that Customer is not able to treat
                                Customer's Patients in a timely manner);
                              </li>
                            </ol>
                          </div>
                          <div className="terms_header">
                            TERMS AND CONDITIONS
                          </div>
                          <div>
                            <span className="term-content-bold">

                              These terms and conditions constitute a legally
                              binding agreement between “Eon Dental, BVBA
                            </span>
                            a company incorporated, organized and existing under
                            the laws of Belgium, with registered seat at
                            Korenlei 22, 9000 Ghent (Belgium), (hereinafter
                            referred to as the
                            <span className="term-content-bold">“ Eon ”</span>);
                            ; <span className="term-content-bold">and you</span>
                            (hereinafter referred to as the “ Doctor ”) , and
                            are referred to herein as either "Terms and
                            Conditions" or this "Agreement".
                          </div>
                          <br></br>
                          <div className="pt-3 ">
                            <i>
                              [The parties hereto shall be singly referred to as
                              “ <span className="term-content-bold">Party</span>
                              ” and collectively as the “
                              <span className="term-content-bold">Parties</span>
                              ”]
                            </i>
                          </div>
                          <br></br>
                          <div>
                            These Terms and Conditions are subject to change at
                            any time and for any reason. Eon reserves the right
                            to update these terms and conditions at any time
                            without prior notice.
                          </div>
                          <div>
                            <ol>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Case Acceptance :
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  <ol>
                                    <li className="pt-2 pb-2">
                                      All Cases are subject to acceptance by Eon
                                      in writing ,by submitting an “Eon Case
                                      Submission Form”, or in a manner and
                                      format prescribed by Eon from time to
                                      time,
                                    </li>
                                    <li className="pt-2 pb-2">
                                      Cases are accepted upon receipt if:
                                      <br></br>
                                      <div>
                                        The Case Submission Form is accurate and
                                        complete;
                                      </div>
                                      <div>
                                        The accompanying records of the patient
                                        are accurate and complete; and
                                      </div>
                                      <div>
                                        There are no special instructions that
                                        would require Doctor’s clarification or
                                        decision
                                      </div>
                                    </li>
                                    <li className="pt-2 pb-2">
                                      Payment is due immediately when case is
                                      accepted by Eon.
                                    </li>
                                    <li className="pt-2 pb-2">
                                      If there is a credit card on file, it will
                                      be charged in amount of the current price
                                      for the Treatment Setup at that time.
                                    </li>
                                    <li className="pt-2 pb-2">
                                      Cases that are not automatically accepted
                                      are not charged. Should this occur the
                                      Doctor will be contacted for consultation.
                                    </li>
                                  </ol>
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Price and Payment :
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  <ol>
                                    <li className="pt-2 pb-2">
                                      Prices shall be as specified by Eon based
                                      on a published price list by Eon or its
                                      resellers, or on the date of Treatment
                                      Setup acceptance of the case by the
                                      Doctor. Eon reserves the right to update
                                      the price list from time to time without
                                      prior notice. All prices shall not include
                                      customs duties, sales tax, or any other
                                      charges levied by any governmental
                                      authority.
                                    </li>
                                    <li className="pt-2 pb-2">
                                      Unless otherwise agreed to in writing by
                                      both parties, payment of the Case ordered
                                      by the Doctor shall be made upon the Case
                                      acceptance by Eon.
                                    </li>
                                  </ol>
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Cancellation and Refunds:
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  <ol>
                                    <li className="pt-2 pb-2">
                                      If Doctor cancels a case before delivery
                                      of the Treatment Setup, Doctor will not
                                      incur any cancellation fee.
                                    </li>
                                    <li className="pt-2 pb-2">
                                      For cases cancelled after delivery of the
                                      Treatment Setup, but prior to the Doctor’s
                                      approval of the Treatment Setup a
                                      cancellation fee of USD $ 195 will be
                                      incurred by Doctor.
                                    </li>
                                    <li className="pt-2 pb-2">
                                      Cases that are cancelled after the
                                      delivery of the Treatment Setup and after
                                      the Doctor’s approval will be charged the
                                      full fee.
                                    </li>
                                    <li className="pt-2 pb-2">
                                      Lab fees for cases cancelled before
                                      delivery of the Treatment Setup will be
                                      refunded in full.
                                    </li>
                                    <li className="pt-2 pb-2">
                                      Lab fees for cases cancelled after
                                      delivery of the Treatment Setup will be
                                      credited to Doctor’s account minus any
                                      applicable cancellation fees.
                                    </li>
                                  </ol>
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Shipment and Delivery:
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  <ol>
                                    <li className="pt-2 pb-2">
                                      The Aligners will not be fabricated until
                                      Doctor approves the Treatment Setup.
                                      Doctor should receive the Treatment Setup
                                      for a case within 2 to 3 weeks of case
                                      submission and acceptance by Eon. The
                                      first stage of aligners will be fabricated
                                      and shipped within 2 to 3 weeks of
                                      Doctor’s approval of the Treatment Setup.
                                      Subsequent stages will be shipped
                                      automatically. Their delivery will be
                                      timed to meet the wear cycle of 2 weeks/14
                                      days per aligner. Stages can be shipped as
                                      early as 4 weeks before their wear date to
                                      allow Doctor time to verify receipt of
                                      shipment, review upcoming stages and
                                      schedule the patient.
                                    </li>
                                    <li className="pt-2 pb-2">
                                      Delivery dates are estimates. Eon shall
                                      not be liable for any damages, losses or
                                      expenses incurred by Doctor if Eon fails
                                      to meet the estimated delivery dates. The
                                      Title to products shipped under this
                                      Agreement and risk of loss or damage
                                      during shipment pass from Eon to Doctor
                                      upon delivery to the address specified by
                                      Doctor. Shipping of aligners and the
                                      records required for original case
                                      submissions is included at no additional
                                      cost in the lab fee. Delay in the delivery
                                      of products shall not relieve the Doctor
                                      of its obligations to accept and pay for
                                      products.
                                    </li>
                                    <li className="pt-2 pb-2">
                                      If the Doctor causes or requests a delay
                                      in shipment without any intervening or
                                      superseding fault or negligence of Eon,
                                      risk of loss shall be deemed to have
                                      passed as of the date Eon notifies the
                                      Doctor of its readiness for shipment. If
                                      Eon incurs costs and expenses because of a
                                      delay in shipment as described in this
                                      subparagraph, such costs, shall be paid by
                                      the Doctor.
                                    </li>
                                  </ol>
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Records:
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  Doctor shall not submit original records to
                                  Eon. Records submitted to Eon become the
                                  property of Eon and will not be returned to
                                  Doctor. Doctor shall obtain patient’s informed
                                  consent for Doctor to provide such patient’s
                                  medical records to Eon as necessary for
                                  Doctor’s treatment of the patient.
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">

                                  Doctor Representations:
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  Doctor represents and warrants to Eon that:
                                  (i) Doctor is licensed to practice dentistry
                                  and/or orthodontics in the location where, and
                                  at all times during which, treatment is being
                                  provided (ii) Doctor has the proper training,
                                  expertise and/or experience to perform
                                  procedures associated with and/or using Eon
                                  clear aligner products; (iii) Doctor’s use of
                                  Eon products will be in accordance with all
                                  applicable medical and dental standards and
                                  used in compliance with Eon’s product
                                  specifications; and (iv) Doctor has obtained
                                  an executed informed consent form, from each
                                  patient Doctor treats using Eon clear aligner
                                  products. Such form will be furnished by the
                                  Doctor and must comply with the local laws
                                  governing the Doctor.
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Warranties and Disclaimer:
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  <ol>
                                    <li className="pt-2 pb-2">
                                      Eon warrants that its Treatment Setup: (i)
                                      shall conform to the specifications
                                      provided by Doctor in the Case Submission
                                      Form and as contained in the Treatment
                                      Setup approved by the Doctor; and (ii) are
                                      free from defects in material and
                                      workmanship.
                                    </li>
                                    <li className="pt-2 pb-2">
                                      Eon shall not be liable for (i) any
                                      defects that are caused by neglect,
                                      misuse, or mistreatment of its products by
                                      any third party, (ii) for any products
                                      that have been altered or modified in any
                                      way by an individual or entity other than
                                      Eon, or (iii) for any products being used
                                      in combination with other third party
                                      products. Moreover, Eon shall not be
                                      liable for any defects that result from
                                      Doctor’s specifications or instructions
                                      for such products. This limited warranty
                                      expires three (3) months after shipment of
                                      the product. If any Eon product fails to
                                      conform to the warranty set forth above,
                                      Eon’s sole liability, at its option, shall
                                      be to: (i) repair or replace such product;
                                      or (ii) credit Doctor’s account for such
                                      product. If Eon elects to repair or
                                      replace such product, it shall have a
                                      reasonable time to repair such products or
                                      provide replacements. Repaired products
                                      shall be warranted for the remainder of
                                      the original warranty period. Replaced
                                      products shall be warranted for a three
                                      (3) month full warranty period.
                                    </li>
                                    <li className="pt-2 pb-2">
                                      To the extent allowed by local law, except
                                      for the obligations set forth in the
                                      warranty statement above, in no event
                                      shall EON be held liable for any other
                                      warranty or condition of any kind whether
                                      express or implied warranties or
                                      conditions of merchantability,
                                      satisfactory quality, and fitness for any
                                      specific purpose.
                                    </li>
                                  </ol>
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Limitations of Liability :
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  <ol>
                                    <li className="pt-2 pb-2">
                                      To the extent allowed by local law, except
                                      for the obligations specifically set forth
                                      in this warranty statement, in no event
                                      shall EON be liable for direct, indirect,
                                      special, incidental or consequential
                                      damages in connection with or arising out
                                      of this Agreement or the use of the
                                      Treatment Setup provided hereunder,
                                      however caused, and under any theory of
                                      liability whether in negligence, breach of
                                      warranty, strict liability, contract,
                                      tort, indemnity or any other cause or
                                      theory whatsoever .
                                    </li>
                                    <li className="pt-2 pb-2">
                                      In no event shall Eons aggregate monetary
                                      liability for damages of any kind arising
                                      out of or in connection with this
                                      agreement, or any use of any product
                                      provided hereunder, exceed the total
                                      amount paid to eon by doctor for the
                                      particular products sold under the
                                      agreement for which losses or damages are
                                      claimed. the existence of more than one
                                      claim against the particular products sold
                                      to doctor under this agreement, or the
                                      existence of more than one agreement with
                                      the doctor or the sales of additional
                                      products to the doctor shall not enlarge
                                      or extend this limit.
                                    </li>
                                    <li className="pt-2 pb-2">
                                      The Doctor shall be liable to compensate
                                      Eon for any expenses incurred by Eon as a
                                      result of the Doctor’s failure to comply
                                      with this Agreement.
                                    </li>
                                  </ol>
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Indemnification :
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  DOCTOR AGREES TO INDEMNIFY, DEFEND AND HOLD
                                  HARMLESS EON AND/OR ITS OFFICERS, AGENTS,
                                  EMPLOYEES, CONTRACTORS, SUCCESSORS AND ASSIGNS
                                  FROM AND AGAINST ANY AND ALL LIABILITY,
                                  OBLIGATIONS, LOSSES, CLAIMS, ACTIONS, DAMAGES,
                                  PENALTIES, FINES, DEMANDS OR SUITS AND ALL
                                  RELATED COSTS, ATTORNEY’S FEES AND EXPENSES OF
                                  ANY KIND AND NATURE WHATSOEVER ARISING UNDER
                                  ANY THEORY OF LEGAL LIABILITY (A henceforth
                                  referred to as the ”CLAIM”) THAT MAY BE
                                  ASSERTED AGAINST EON ARISING OUT OF, OR
                                  RESULTING FROM, OR RELATING TO: (I) THIS
                                  AGREEMENT OR USE OF PRODUCTS SOLD UNDER THIS
                                  AGREEMENT; (II) ANY BREACH OF OR FAILURE OF
                                  DOCTOR TO ABIDE BY ANY TERM OF THIS AGREEMENT;
                                  (III) ANY BREACH OR ALLEGED BREACH OF ANY
                                  REPRESENTATIONS OR WARRANTIES MADE BY DOCTOR
                                  IN THIS AGREEMENT OR ANY INCORRECT INFORMATION
                                  PROVIDED BY DOCTOR OR DOCTOR’S PATIENT TO EON;
                                  OR (IV) EON’S PROVIDING OF OR FAILURE TO
                                  PROVIDE PRODUCTS TO DOCTOR, UNLESS THE
                                  PROVIDING OF OR FAILURE TO PROVIDE SUCH
                                  PRODUCTS WAS DUE TO EON’S WILLFUL MISCONDUCT
                                  OR GROSS NEGLIGENCE.
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Relationship of Parties :
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  The Parties intend by this Agreement that
                                  Doctor is and at all times shall be an
                                  independent contractor and not the agent or
                                  employee of Eon. Neither this Agreement nor
                                  any contract with Eon nor any course of
                                  dealing or practice shall be interpreted as
                                  creating, or shall be deemed to create, any
                                  employer-employee, principal-agent,
                                  partnership, joint venture or other
                                  relationship between Doctor and Eon.
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Advertising and Marketing :
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  Eon reserves the right to use Doctor’s name,
                                  address, telephone number, and descriptions of
                                  care and specialty services in any
                                  advertising, promotional and marketing
                                  materials, provided that Doctor has the right
                                  to review such marketing materials before
                                  final publication or distribution.
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Assignment :
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  This Agreement shall not be assigned by either
                                  Party hereto without the prior written consent
                                  of the other Party.
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Successor and Assigns :
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  This Agreement shall be binding upon and shall
                                  inure solely to the benefit of the Parties
                                  hereto and their respective successors and
                                  shall not be for the benefit of any other
                                  person, persons, or legal entities.
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Entire Agreement and Amendment :
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  This Agreement, the Eon Case Submission Form,
                                  which provided by the Doctor and approved by
                                  Eon, shall constitute and contain the entire
                                  agreement of the Parties and supersede any and
                                  all prior negotiations, correspondence,
                                  understandings and agreements between the
                                  Parties respecting the subject matter hereof.
                                  This Agreement may be modified only by an
                                  agreement in writing duly executed by the
                                  Parties hereto.
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Severability :
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  If any provision of this Agreement is or shall
                                  be deemed a violation of any applicable law,
                                  rule or regulation, such legal invalidity
                                  shall not void this Agreement or affect the
                                  remaining terms and provision of this
                                  Agreement and this Agreement shall be
                                  construed and interpreted to comply with all
                                  laws, rules or regulations.
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Force Majeure :
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  Eon cannot be in default or breach by reason
                                  of any failure of its performance under this
                                  Agreement if such failure results, whether
                                  directly or indirectly, from fire, explosion,
                                  strike, freight embargo, act of God, or of
                                  war, civil disturbance, act of any government,
                                  de jure or de facto, or any agency or official
                                  thereof, labor shortage, transportation
                                  contingencies, severe weather, default of
                                  manufacturer or supplier, quarantine or
                                  restriction, epidemic or catastrophe, lack of
                                  timely instructions or essential information
                                  from Doctor or any other third party, or other
                                  conditions beyond the control of Eon.
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Notices :
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  All notices, demands, requests, approvals and
                                  other communications given or made pursuant
                                  hereto shall be in writing and shall be deemed
                                  to have been given or made as of the date
                                  delivered or mailed if delivered personally or
                                  mailed by certified mail (postage prepaid,
                                  return receipt requested), or on the date
                                  transmitted if transmitted by facsimile or
                                  electronic mail, to Doctor at the address
                                  provided by Doctor.
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Waiver :
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  The failure of either Party at any time or
                                  times to require performance of any provision
                                  hereof shall in no manner affect the right to
                                  enforce the same. No waiver by either Party of
                                  any condition, or of the breach of any term,
                                  provision, covenant or warranty contained in
                                  this Agreement, shall be deemed to be or
                                  construed as a further or continuing waiver of
                                  any such condition or breach or a waiver of
                                  any other condition or of the breach of any
                                  other term, provision, covenant or warranty.
                                </div>
                              </li>
                              <li className="pt-2 pb-2 ">
                                <span className="term-content-bold">
                                  Governing Law :
                                </span>
                                <br></br>
                                <div className="pt-2">
                                  This Agreement shall be governed by, and
                                  construed in accordance with, Belgian law.
                                </div>
                              </li>
                            </ol>
                          </div>
                        </div>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Accept Terms checkbox  */}
                        {/*--------------------------------------------------------------------------------*/}

                        {/* <div className="custom-control custom-checkbox pt-4 pb-1 ">
                          <FormGroup>
                            <CustomInput
                              className={`pt-1 ${terms && "replacment-checkbox"
                                }`}
                              label="Accept and Continue"
                              id="terms"
                              name="terms"
                              type="checkbox"
                              onChange={() => {
                                setFieldValue("terms", !terms);
                                this.setState({ terms: !terms });
                              }}
                              checked={terms}
                            />

                            <ErrorMessage
                              component="div"
                              name="terms"
                              className="custom-invalid-feedback mt-1"
                            />
                          </FormGroup>
                        </div>
                       
                        */}
                        {/*--------------------------------------------------------------------------------*/}
                        {/* End Accept Terms checkbox  */}
                        {/*--------------------------------------------------------------------------------*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CardFooter className="p-3">
                {
                  !this.props.rejectable_id && <Button
                    className={`btn  ${"preference-btn"} float-left text-center mb-2  btn-radius`}
                    size="sm"
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => this.props.PrevStep()}
                  >
                    <i className="fas fa-angle-left arrows-icon pr-1"></i>   Prescription
                </Button>
                }
                <Button
                  className={`btn  ${"preference-btn"} float-right text-center mb-2  btn-radius`}
                  size="sm"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {this.props.rejectable_id ? `fix` : <>Agree<i className="fas fa-angle-right arrows-icon pl-1"></i></>}
                </Button>
              </CardFooter>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Terms);