import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";

export default class Gallery extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /*init state  */
    this.state = {
      case: this.props.data.currentCase.state,
      lightbox: false,
      lightBoxArray: [],
      selectedItem: 0,
    };
    this.myRef = React.createRef();
  }
  /*--------------------------------------------------------------------------------*/
  /* show hide light box                                                            */
  /*--------------------------------------------------------------------------------*/
  toggleLightbox = () => {
    this.setState({ lightbox: !this.state.lightbox });
  };
  /*--------------------------------------------------------------------------------*/
  /* rename image                                                            */
  /*--------------------------------------------------------------------------------*/
  ImageName(image) {
    let without_thumb = image.replace(/_thumb/g, "");
    let test = without_thumb.replace(/_/g, " ");
    return this.camelize(test);
  }
  /*--------------------------------------------------------------------------------*/
  /* image name with camelcase                                                      */
  /*--------------------------------------------------------------------------------*/
  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match) {
      return match.toUpperCase();
    });
  }

  render() {
    /*--------------------------------------------------------------------------------*/
    /*  filter small images , rename images with camel case format                    */
    /*--------------------------------------------------------------------------------*/
    let small = Object.keys(
      this.props.data.currentCase.state.media.patient_photos
    )
      .filter((key, index) => {
        return index % 2 !== 0;
      })
      .map((key, index) => {
        return {
          src: this.props.data.currentCase.state.media.patient_photos[key],
          caption: this.ImageName(key),
          altText: this.ImageName(key),
        };
      });
    /*--------------------------------------------------------------------------------*/
    /*  filter big images , rename images with camel case format                      */
    /*--------------------------------------------------------------------------------*/
    let big = Object.keys(
      this.props.data.currentCase.state.media.patient_photos
    )
      .filter((key, index) => {
        return index % 2 === 0;
      })
      .map((key, index) => {
        return {
          url: this.props.data.currentCase.state.media.patient_photos[key],
          title: this.ImageName(key),
          altText: this.ImageName(key),
        };
      });
    /*--------------------------------------------------------------------------------*/
    /*  add title to xrays images                      */
    /*--------------------------------------------------------------------------------*/
    let xrays = [
      {
        url: this.props.data.currentCase.state.media.xrays.panoramic,
        title: "Panoramic",
        altText: "Panoramic",
      },
      {
        url: this.props.data.currentCase.state.media.xrays.cephalometric,
        title: "Cephalometric",
        altText: "Cephalometric",
      },
    ];
    return (
      <div className="m-3 mt-5 ">
        <div
          onWheel={(event) => {
            // zoom in / out on wheel
            if (event.deltaY > 0) {
              this.myRef.current.applyZoom("out");
            }
            if (event.deltaY < 0) {
              this.myRef.current.applyZoom("in");
            }
          }}
        >
          {/*--------------------------------------------------------------------------------*/}
          {/* lightbox allow to zoom in / zoom out / rotate images  */}
          {/*--------------------------------------------------------------------------------*/}
          {this.state.lightbox &&
            (this.state.lightBoxArray.length === 1 ? (
              <Lightbox
                ref={this.myRef}
                onClose={this.toggleLightbox}
                zoomStep={0.1}
                image={this.state.lightBoxArray[0].url}
                title={this.state.lightBoxArray[0].title}
                doubleClickZoom={2}
              ></Lightbox>
            ) : (
              <Lightbox
                ref={this.myRef}
                images={this.state.lightBoxArray}
                startIndex={this.state.selectedItem}
                onClose={this.toggleLightbox}
                zoomStep={0.1}
                doubleClickZoom={2}
              ></Lightbox>
            ))}
        </div>
        {/*--------------------------------------------------------------------------------*/}
        {/*  End lightbox   */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/*  Impression type    */}
        {/*--------------------------------------------------------------------------------*/}
        <Row>
          <Col>
            <label className="control-label title_active ">
              Main impressions method selected
            </label>
            <br />
            <br />
            <h6>{this.props.data.currentCase.state.impressions_method}</h6>
            <hr></hr>
          </Col>
        </Row>
        {/*--------------------------------------------------------------------------------*/}
        {/*  End Impression type    */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/*  Impresssions values    */}
        {/*--------------------------------------------------------------------------------*/}
        <Row>
          <Col>
            <label className="control-label title_active ">Impresssions </label>
            <br />
            <br />
            <div>
              {" "}
              {!this.props.data.currentCase.state.media.impressions
                .lower_impression &&
                !this.props.data.currentCase.state.media.impressions
                  .upper_impression && (
                  <h6>No Impressions were uploaded to Access.</h6>
                )}
              {this.props.data.currentCase.state.impressions_method !==
                "upload" &&
                this.props.data.currentCase.state
                  .impressions_third_party_note && (
                  <div>
                    <h6 className="title_active ">
                      {" "}
                      Impressions Note sent by Dr:{" "}
                    </h6>
                    <h6>
                      {
                        this.props.data.currentCase.state
                          .impressions_third_party_note
                      }
                    </h6>
                  </div>
                )}
              {this.props.data.currentCase.state.media.impressions
                .lower_impression && (
                <Button
                  style={{
                    pointerEvents: `${
                      this.props.data.currentCase.state.media.impressions.lower_impression.match(
                        /http([^&]+)/
                      ) &&
                      this.props.data.currentCase.state.media.impressions
                        .lower_impression !== null
                        ? ""
                        : "none"
                    }`,
                  }}
                  disabled={
                    this.props.data.currentCase.state.media.impressions.lower_impression.match(
                      /http([^&]+)/
                    ) &&
                    this.props.data.currentCase.state.media.impressions
                      .lower_impression !== null
                      ? false
                      : true
                  }
                  className={`btn green-btn-active text-center m-1`}
                  onClick={() => {
                    window.open(
                      this.props.data.currentCase.state.media.impressions
                        .lower_impression,
                      "_blank"
                    );
                  }}
                  size="md"
                >
                  Lower Impression
                </Button>
              )}
              {this.props.data.currentCase.state.media.impressions
                .upper_impression && (
                <Button
                  style={{
                    pointerEvents: `${
                      this.props.data.currentCase.state.media.impressions.upper_impression.match(
                        /http([^&]+)/
                      ) &&
                      this.props.data.currentCase.state.media.impressions
                        .upper_impression !== null
                        ? ""
                        : "none"
                    }`,
                  }}
                  disabled={
                    this.props.data.currentCase.state.media.impressions.upper_impression.match(
                      /http([^&]+)/
                    ) &&
                    this.props.data.currentCase.state.media.impressions
                      .upper_impression !== null
                      ? false
                      : true
                  }
                  className={`btn green-btn-active text-center m-1`}
                  onClick={() => {
                    window.open(
                      this.props.data.currentCase.state.media.impressions
                        .upper_impression,
                      "_blank"
                    );
                  }}
                  size="md"
                >
                  Upper Impression
                </Button>
              )}
            </div>
            <hr></hr>
          </Col>
        </Row>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Impresssions values    */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Photos  */}
        {/*--------------------------------------------------------------------------------*/}
        <Row>
          <Col>
            <label className="control-label title_active ">Photos</label>
            <br />
            <br />
            <Row className="d-flex align-items-center justify-content-start">
              {small.map((prop, index) => {
                return (
                  <div key={index}>
                    <Col>
                      <img
                        src={prop.src}
                        style={{
                          cursor: "pointer",
                          pointerEvents: `${
                            prop?.src?.match(/http([^&]+)/) &&
                            prop?.src !== null
                              ? ""
                              : "none"
                          }`,
                        }}
                        height="100"
                        width="100"
                        alt=""
                        onClick={() => {
                          // photos with real value
                          let filteredphoto = big.filter((key, index) => {
                            return (
                              key?.url?.match(/http([^&]+)/) && key.url !== null
                            );
                          });
                          this.setState({
                            lightbox: !this.state.lightbox,
                            lightBoxArray: filteredphoto,
                            selectedItem: index,
                          });
                        }}
                      ></img>
                      <h6 className="text-center pt-2">{prop.caption}</h6>
                    </Col>
                  </div>
                );
              })}
            </Row>

            <hr></hr>
          </Col>
        </Row>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Photos  */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* X-Rays  */}
        {/*--------------------------------------------------------------------------------*/}
        <Row>
          <Col>
            <label className="control-label title_active ">X-Rays</label>
            <br />
            <br />
            <Row className="d-flex align-items-center justify-content-start">
              {xrays.map((value, index) => (
                <div key={index}>
                  <Col>
                    <img
                      style={{
                        cursor: "pointer",
                        pointerEvents: `${
                          value?.url?.match(/http([^&]+)/) &&
                          value?.url !== null
                            ? ""
                            : "none"
                        }`,
                      }}
                      src={value.url}
                      height="100"
                      width="100"
                      alt=""
                      onClick={() => {
                        // xrays with real value
                        let filteredXray = xrays.filter((key, index) => {
                          return (
                            key?.url?.match(/http([^&]+)/) && key.url !== null
                          );
                        });
                        this.setState({
                          lightbox: !this.state.lightbox,
                          lightBoxArray: filteredXray,
                          selectedItem: index,
                        });
                      }}
                    ></img>
                    <br></br>
                    <h6 className="pt-2">{value.title}</h6>
                  </Col>{" "}
                </div>
              ))}
            </Row>
          </Col>
          <hr></hr>
        </Row>
        {/*--------------------------------------------------------------------------------*/}
        {/* End X-Rays  */}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}
