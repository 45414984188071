import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input, Row, Col } from "reactstrap";
import CustomeTeeth from "../../../../Teeth/CustomeTeeth";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../../redux/customeTeeth/action"
import ToggleSwitch from "../../../../../components/Switch/Switch";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class TorqueEnhancers extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_torque_enhancers: this.props.caseSubmission.advanced_modules
        .torqueEnhancers.useTorqueEnhancers,
      torque_enhancers_details: this.props.caseSubmission.advanced_modules
        .torqueEnhancers.details,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "torqueEnhancers",
      data: {
        useTorqueEnhancers: this.state.use_torque_enhancers,
        teeth:
          this.props.customeTeethReducer.Case_Torque_Enhancers
        ,
        details: this.state.torque_enhancers_details,
      },
    });
    this.props.save();
  };
  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const { use_torque_enhancers, torque_enhancers_details } = this.state;

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">
          {/* 
          <div>
            <div className="control-label title_active-gco text-center mb-2">
              Used torque enhancers for this case{" "}
            </div>
            <div className="toggle-slider form-group content form-block-holder custome-toggle m-auto mt-2 ">
              <input
                type="range"
                step="50"
                onChange={(e) => {
                  this.slider(e, "use_torque_enhancers")

                  if (e?.target?.value === "0" || e?.target?.value === "50") {
                    this.setState({
                      torque_enhancers_details: ""
                    })
                    this.props.Clear_Teeth_Data("Case_Torque_Enhancers")

                  }
                }}
                value={this.sliderValue("use_torque_enhancers")}
                style={{ pointerEvents: this.props.summary ? "none" : "" }}
              />
              <Row className="align-items-center ">
                <Col xs="4" md="4" lg="4" className="text-left">
                  <label className="title_active">No</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-center">
                  <label className="title_active">EON to Decide</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-right">
                  <label className="title_active">Yes</label>
                </Col>
              </Row>
            </div>

          </div>
       */}
          <div className="auto_grid4  pb-2">
            <div className="control-label title_active-gco">
              Used torque enhancers for this case{" "}
            </div>
            <div>
              <ToggleSwitch
                id="use_torque_enhancers"
                disabled={this.props.summary}
                checked={use_torque_enhancers}
                onChange={() => {
                  this.setState({
                    use_torque_enhancers: !use_torque_enhancers,
                    ...use_torque_enhancers && {
                      torque_enhancers_details: ""
                    }
                  });

                  if (use_torque_enhancers) {
                    this.props.Clear_Teeth_Data("Case_Torque_Enhancers")
                  }
                }}
              />
            </div>
          </div>

          <div className="readOnly-text">
            <Input
              className="teeth_selector"
              name="torque_enhancers_details"
              id="torque_enhancers_details"
              type="textarea"
              placeholder="from step #"
              rows={5}
              value={torque_enhancers_details}
              onChange={(e) => {
                this.setState({ torque_enhancers_details: e.target.value });
              }}
              readOnly
            />
          </div>
          <div className="teeth_selector">
            <div className="control-label title_active-gco text-center pb-2 mt-2 ">
              Placement
              </div> <CustomeTeeth
              action_key="Case_Torque_Enhancers"
              disabled={!use_torque_enhancers || use_torque_enhancers === "eonToDecide"}
              summary={this.props.summary}
            /></div>

        </div>
        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            Save
        </Button>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TorqueEnhancers);
