import React, { Component } from "react";
import Banners from "./Banners";
import { Row, Col } from "reactstrap";
import Controles from "./Controles";
import LoyaltyProgram from "./LoyaltyProgram";
import { connect } from "react-redux";
import { getDoctorInfo, getCasesNumbersByStatus } from "../../api/api";
import { setUserInfo } from "../../redux/user/action";
import { CardTitle, Card, CardBody, Modal } from "reactstrap";
import submit_a_case from "../../assets/images/caseSubmission/submit-a-case.svg";
import review_a_case from "../../assets/images/caseSubmission/review-a-case.svg";
import doctor_preferances from "../../assets/images/caseSubmission/doctor-preferances.svg";
import eonacademy from "../../assets/images/caseSubmission/eonacademy.png";
import dso_logo from "../../assets/images/DSO_logo.svg"
import dso_logo2 from "../../assets/images/DSO_logo2.svg"
import comingSoon from "../../assets/images/caseSubmission/comingSoon.png";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setUserInfo: (payload) => dispatch(setUserInfo(payload)),
});
class HomePage extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      id: props.userReducer.currentUser.id,
      banners: [],
      start: false,
      loyalty: null,
      incomplete: "",
      rejected_records: "",
      pending_ts_approval: "",
      awaiting_completion: "",
      gco_doctor: false
    };
  }
  /* --------------------------------------------------------------------------------*/
  /* get doctor info , save info in local storage */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    getDoctorInfo(this.state.id).then((res) => {
      console.log(res, "ressssssssss");
      const currentUser = {
        avatar: res.avatar,
        email: res.email,
        first_name: res.first_name,
        full_name: res.full_name,
        id: res.id,
        last_name: res.last_name,
        phone: res.phone,
        gco_doctor: res.gco_doctor
      };
      this.props.setUserInfo(currentUser);
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
      this.setState({
        banners: res.banners,
        start: true,
        loyalty: res.loyalty ? res.loyalty : null,
        gco_doctor: res.gco_doctor
      });
    });

    let status = [
      "incomplete",
      "rejected_records",
      "pending_ts_approval",
      "awaiting_completion",
    ];

    status.map((value, index) => {
      getCasesNumbersByStatus(this.state.id, value).then((res) => {
        this.setState({ [value]: res.count });
      });
    });
  }
  /* --------------------------------------------------------------------------------*/
  /* component Will Unmount */
  /* --------------------------------------------------------------------------------*/
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  RedirectToCaseWithFilter = (filter) => {
    this.props.history.push({
      pathname: `/cases`,
      state: {
        data: {
          filters: {
            uid_or_patient_full_name_cont: "",
            aligner_case_transition_to_state_in: [filter],
            s: `created_at desc`, archived_case_eq: false,
          },

          page: 1,
          per_page: 20,
        },
      },
    });
  };
  render() {
    const {
      incomplete,
      rejected_records,
      pending_ts_approval,
      awaiting_completion,
      gco_doctor
    } = this.state;
    return (
      <div>
        {this.state.start ? (
          <div className="main-page-con">

            {gco_doctor && <><img src={dso_logo} alt="dso" className="dso_logo" />
              <img src={dso_logo2} alt="dso" className="dso_logo2" /></>
            }

            <div className="main-page-grid mb-3">
              <div className="mb-3">
                <div className="controles-grid">
                  <div>
                    <Card
                      className={`p-1 custom_shadow align-items-center advanced_module-card`}
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/case-submission",
                          state: { id: this.state.id },
                        });
                      }}
                    >
                      <img
                        src={submit_a_case}
                        className="modules-img"
                        alt="Aligner Treatment"
                      />
                      <CardTitle className="pt-2 m-0 module_title main-filters-title text-center ">
                        Submit a Case
                      </CardTitle>
                    </Card>
                  </div>
                  <div>
                    <Card
                      className={`p-1 custom_shadow align-items-center advanced_module-card`}
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/cases",
                          state: { id: this.state.id },
                        });
                      }}
                    >
                      <img
                        src={review_a_case}
                        className="modules-img"
                        alt="Aligner Treatment"
                      />
                      <CardTitle className="pt-2 m-0 module_title main-filters-title text-center ">
                        Review Cases{" "}
                      </CardTitle>
                    </Card>
                  </div>{" "}
                  <div>
                    <Card
                      className={`p-1 custom_shadow align-items-center advanced_module-card`}
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/doctor-preferences",
                          state: { id: this.state.id },
                        });
                      }}
                    >
                      <img
                        src={doctor_preferances}
                        className="modules-img"
                        alt="Aligner Treatment"
                      />
                      <CardTitle className="pt-2 m-0 module_title main-filters-title text-center ">
                        Doctor Preferences{" "}
                      </CardTitle>
                    </Card>
                  </div>
                  <div>
                    <Card 
                      className={`p-1 custom_shadow align-items-center advanced_module-card cursor-none`}
                    // onClick={() => {
                    //   this.toggleModals(values.key);
                    // }}
                    >                       <img className="coming-soon" src={comingSoon}  />

                      <img
                        src={eonacademy}
                        className="modules-img"
                        alt="Aligner Treatment"
                      />
                      <CardTitle className="pt-2 m-0 module_title main-filters-title text-center"  style={{ color: "#DBDBDB" }}>
                        eon Academy{" "} 
                      </CardTitle>
                    </Card>
                  </div>{" "}
                </div>
              </div>{" "}
              <div className="banner_mobile">
                <Banners banners={this.state.banners} />
              </div>
            </div>

            {/* --------------------------------------------------------------------------------*/}
            {/* main page */}
            {/* --------------------------------------------------------------------------------*/}
            <div className="main-filter-header pb-3 mt-5">
              Cases requiring your attention
            </div>
            <div className="main-filters-grid">
              <Card
                className={`p-1 custom_shadow align-items-center advanced_module-card h-card`}
                onClick={() => {
                  this.RedirectToCaseWithFilter("incomplete");
                }}
              >
                <CardTitle className="pt-2 m-0 module_title main-filters-title text-center " style={{color:"#67757c"}}>
                  Continue Case Submission
                </CardTitle>
                <div className="status-number">{incomplete}</div>
              </Card>
              <Card
                className={`p-1 custom_shadow align-items-center advanced_module-card h-card`}
                onClick={() => {
                  this.RedirectToCaseWithFilter("rejected_records");
                }}
              >
                <CardTitle className="pt-2 m-0 module_title  main-filters-title text-center" style={{ color: "#FF5733" }}>
                  {" "}
                  Review Rejected Records{" "}
                </CardTitle>
                <div className="status-number">{rejected_records}</div>
              </Card>
              <Card
                className={`p-1 custom_shadow align-items-center advanced_module-card h-card`}
                onClick={() => {
                  this.RedirectToCaseWithFilter("pending_ts_approval");
                }}
              >
                <CardTitle className="pt-2 m-0 module_title main-filters-title text-center" style={{color:"#FFB30F"}}>
                  {" "}
                  Approve Treatment Plan
                </CardTitle>
                <div className="status-number">{pending_ts_approval}</div>
              </Card>
              <Card
                className={`p-1 custom_shadow align-items-center advanced_module-card h-card`}
                onClick={() => {
                  this.RedirectToCaseWithFilter("awaiting_completion");
                }}
              >
                <CardTitle className="pt-2 m-0 module_title main-filters-title text-center">
                  Mark Case as Complete
                </CardTitle>

                <div className="status-number">{awaiting_completion}</div>
              </Card>
            </div>
            {/* <Row className="pt-3 pb-5 pl-2 pr-2">
              <Col xs="12" md="4" className="p-2">
                <Controles props={this.props} />
              </Col>
              <Col xs="12" md="1"></Col>
              <Col xs="12" md="7" className="">
                {this.state.loyalty && (
                  <LoyaltyProgram loyalty={this.state.loyalty} />
                )}
              </Col>
            </Row> */}
          </div>
        ) : (
            <div></div>
          )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
