import React from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";

class Banners extends React.Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      activeIndex: 0,
      items: props.banners,
    };
  }
  /* --------------------------------------------------------------------------------*/
  /* onExiting */
  /* --------------------------------------------------------------------------------*/
  onExiting = () => {
    this.animating = true;
  };
  /* --------------------------------------------------------------------------------*/
  /* onExited */
  /* --------------------------------------------------------------------------------*/
  onExited = () => {
    this.animating = false;
  };
  /* --------------------------------------------------------------------------------*/
  /* next Item */
  /* --------------------------------------------------------------------------------*/
  next = () => {
    if (this.animating) {
      return;
    }
    const nextIndex =
      this.state.activeIndex === this.state.items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };
  /* --------------------------------------------------------------------------------*/
  /* previous item */
  /* --------------------------------------------------------------------------------*/
  previous = () => {
    if (this.animating) {
      return;
    }
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };
  /* --------------------------------------------------------------------------------*/
  /* go To specific Index  */
  /* --------------------------------------------------------------------------------*/
  goToIndex = (newIndex) => {
    if (this.animating) {
      return;
    }
    this.setState({ activeIndex: newIndex });
  };

  render() {
    const activeIndex = this.state.activeIndex,
      slides = this.state.items.map((item) => (
        /* --------------------------------------------------------------------------------*/
        /* Carouse lItem */
        /* --------------------------------------------------------------------------------*/
        <CarouselItem
          className={`custom-tag custom_shadow `}
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.image}
        >
          <img
            className="img-carousel w-100"
            src={item.image}
            alt={item.name}
            onClick={() => {
              window.open(item.link, "_blank");
            }}
            style={{
              pointerEvents: `${item.link.match(/http([^&]+)/) ? "" : "none"}`,
            }}
          />
          <CarouselCaption captionText={""} captionHeader={""} />
        </CarouselItem>
      ));

    return (
      <div>

        {/* --------------------------------------------------------------------------------*/}
        {/* Start Inner Div*/}
        {/* --------------------------------------------------------------------------------*/}
        <Row>
          <Col xs="12" md="12">
            {/* --------------------------------------------------------------------------------*/}
            {/* Carousel-1*/}
            {/* --------------------------------------------------------------------------------*/}
            <Card className="p-0 mb-0  custom_shadow"      >
              <CardBody className="p-0">
                <Carousel
                  activeIndex={activeIndex}
                  next={this.next}
                  previous={this.previous}
                  className="home-carousel"
                >
                  <CarouselIndicators
                    items={this.state.items}
                    activeIndex={activeIndex}
                    onClickHandler={this.goToIndex}
                  />
                  {slides}
                  <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={this.previous}
                  />
                  <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={this.next}
                  />
                </Carousel>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* --------------------------------------------------------------------------------*/}
        {/* End Inner Div*/}
        {/* --------------------------------------------------------------------------------*/}
      </div>
    );
  }
}

export default Banners;
