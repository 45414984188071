import React, { Component } from "react";
import { connect } from "react-redux";

import { CustomInput } from "reactstrap";
import {
  clearMalocclusionData
} from "../../../../../redux/CaseSubmission/action";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  clearMalocclusionData: (payload) => dispatch(clearMalocclusionData(payload)),

});
class Openbite extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.extrudeAnterior =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.openbite
        ?.extrudeAnterior || false;
    this.intrudePosterior =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.openbite
        ?.intrudePosterior || false;
    this.both =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.openbite
        ?.both || false;
  }

  UpdateCrossBiteReducer = () => {
    let data = {
      extrudeAnterior: this.extrudeAnterior,
      intrudePosterior: this.intrudePosterior,
      both: this.both,
    };
    this.props.save("openbite", data);
  };
  render() {
    const { extrudeAnterior, intrudePosterior, both } = this;
    return (
      <div>
        <div className="malocclusion-title">
          Openbite
      {!this.props.summary && <span
            className="close-btn pull-right"
            onClick={() => {
              this.props.clearMalocclusionData("openbite")

              this.props.toggleComponents();
            }}
          >
            &times;
          </span>}
        </div>

        <div className="p-2">
          <div className="control-label title_active-gco">
            Treatment options{" "}
          </div>
          <CustomInput
            className={`pt-1 ${extrudeAnterior && "replacment-checkbox"}`}
            type="checkbox"
            id="extrudeAnterior"
            label="Extrude anterior teeth only "
            checked={extrudeAnterior}
            disabled={both || intrudePosterior || this.props.summary}
            onChange={() => {
              this.extrudeAnterior = !this.extrudeAnterior;
              this.UpdateCrossBiteReducer();
            }}
          />
          <CustomInput
            className={`pt-1 ${intrudePosterior && "replacment-checkbox"}`}
            type="checkbox"
            id="intrudePosterior"
            label="Intrude posterior teeth only "
            checked={intrudePosterior}
            disabled={both || extrudeAnterior || this.props.summary}
            onChange={() => {
              this.intrudePosterior = !this.intrudePosterior;
              this.UpdateCrossBiteReducer();
            }}
          />
          <CustomInput
            className={`pt-1 ${both && "replacment-checkbox"}`}
            type="checkbox"
            id="both2"
            label="Both"
            checked={both}
            disabled={intrudePosterior || extrudeAnterior || this.props.summary}
            onChange={() => {
              this.both = !this.both;
              this.UpdateCrossBiteReducer();
            }}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Openbite);
