import React, { Component } from "react";
import { connect } from "react-redux";

import {
    CardBody,
    CardTitle,
    Card,
    CardFooter,
    CardImg,
    Button,
} from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { XraysSchema } from "../../../helpers/photosXraysValidations";
import { XraysData } from "../../../helpers/photos-xrays";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { uploadFile, keyToUrl } from "../../../helpers/s3";
import Resizer from "react-image-file-resizer";
import { updateAlignerCase } from "../../../api/api";
import { infoToaster } from "../../../services/toast";

const mapStateToProps = (state) => ({
    ...state,
});
const mapDispatchToProps = (dispatch) => ({});
class FixXrays extends Component {
    constructor(props) {
        super(props);
        this.state = {
            doctorId: props.userReducer.currentUser.id,

            loading_percentage: 0,
            intervalID: 0,
            showProgress: false,
            loading_percentage_front_smiling: 0,
            loading_percentage_front_pose: 0,
            loading_percentage_profile: 0,
            loading_percentage_panoramic_xray: 0,
            loading_percentage_upper_occlusal: 0,
            loading_percentage_frontal: 0,
            loading_percentage_lower_occlusal: 0,
            loading_percentage_cephalometric_xray: 0,
            loading_percentage_right_buccal: 0,
            loading_percentage_left_buccal: 0,
            caseId:
                this.props.caseId ||
                (window.location.search.match(/id=([^&]+)/) || [])[1],
        };
    }

    /*--------------------------------------------------------------------------------*/
    /* uploading file timer                                             */
    /*--------------------------------------------------------------------------------*/
    timer = (id) => {
        this.setState({
            [`loading_percentage_${id}`]: this.state[`loading_percentage_${id}`] + 5,
        });
    };


    checkIfChanged2 = (oldobj, newobj) => {
        if (oldobj.panoramic_url === newobj.panoramic_url && oldobj.cephalometric_url === newobj.cephalometric_url) {
            return false
        } else {
            return true
        }
    }
    /*--------------------------------------------------------------------------------*/
    /*Photos and xrays step submit  */
    /*--------------------------------------------------------------------------------*/
    handleSubmit = (values, { setSubmitting }) => {
        const { doctorId } = this.state;
        const caseId = this.props.caseId ||
            (window.location.search.match(/id=([^&]+)/) || [])[1]

        let oldxrays = {
            panoramic_url: this.props.caseSubmission.photos_xrays.panaromic,
            cephalometric_url: this.props.caseSubmission.photos_xrays.cephalometric,
        }

        let xrays = {
            panoramic_url: values.panoramic_xray,
            cephalometric_url: values.cephalometric_xray,
        };
        let checkxrays = this.checkIfChanged2(oldxrays, xrays);
        if (checkxrays) {
            setSubmitting(true);
            let filtered_xrays = {};

            for (var key2 in xrays) {
                if (xrays[key2].match(/http([^&]+)/)) {
                    filtered_xrays[key2] = xrays[key2];
                }
            }

            let xrays_data = {
                aligner_case: {
                    step: "xrays",
                    xrays: filtered_xrays,

                }, ...(this.props.rejectable_id && {
                    resolve_rejection_id: this.props.rejectable_id,
                })
            };


            updateAlignerCase(doctorId, caseId, JSON.stringify(xrays_data)).then((res) => {
                setSubmitting(false);
                this.props.FixAlignerCase();
            }).catch((error) => {
                setSubmitting(false);
            });
        } else {
            infoToaster("nothing is changed .......")
            this.props.FixAlignerCase();
        }

        // updateAlignerCase(doctorId, caseId, JSON.stringify(photos_data))
        //   .then((res) => { })
        //   .then(() => {
        //     updateAlignerCase(doctorId, caseId, JSON.stringify(xrays_data)).then(
        //       (res) => {
        //         setSubmitting(false);
        //         this.props.NextStep();
        //       }
        //     );
        //   })
        //   .catch((error) => {
        //     setSubmitting(false);
        //   });
    };

    /* --------------------------------------------------------------------------------*/
    /* Upload image to s3  */
    /* --------------------------------------------------------------------------------*/
    uploadImage = async (image, id, file) => {
        return uploadFile({
            name: `case-${this.state.caseId}/patient-photos/${id}/${new Date().valueOf()}.JPEG`,
            contentType: image.type,
            file: image,
        });
    };

    /* --------------------------------------------------------------------------------*/
    /* upload photos */
    /* --------------------------------------------------------------------------------*/

    UploadPhoto = async (e, id, setFieldValue) => {
        e.persist();
        if (e.target.files[0]) {
            const intervalID = setInterval(this.timer(id), 1000);
            this.setState({
                loading1: true,
                intervalID,
                [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
            });
            let reader = new FileReader();
            reader.addEventListener("load", (evt) => {
                this.setState({
                    id: evt.currentTarget.result,
                });
            });
            reader.readAsDataURL(e.target.files[0]);
            Resizer.imageFileResizer(
                e.target.files[0],
                720,
                1080,
                "JPEG",
                100,
                0,
                async (uri) => {
                    const { key } = await this.uploadImage(uri, id, e.target.files[0]);
                    clearInterval(this.state.intervalID);
                    this.setState({
                        [id]: keyToUrl(key),
                        [`loading_percentage_${id}`]: 100,
                    });
                    setFieldValue(id, keyToUrl(key));
                    setTimeout(() => {
                        this.setState({
                            [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
                            [`loading_percentage_${id}`]: 0,
                        });
                    }, 500);
                },
                "blob"
            );
        }
    };

    render() {
        const { photos_xrays } = this.props.caseSubmission;
        return (
            <div>
                {/* <CardTitle className="border-bottom pb-3 title_active-gco case-title">
                    <div className="step-header-title">
                        <span>{`Photos & Xrays`}</span>
                        <span className="patient_info">
                            <div> {this.props.patient.full_name}
                            </div>
                            <div> Case {this.state.caseId}
                            </div>
                        </span>
                    </div>
                </CardTitle> */}

                <Formik
                    enableReinitialize
                    initialValues={{
                        panoramic_xray: photos_xrays.panaromic,
                        cephalometric_xray: photos_xrays.cephalometric,
                    }}
                    validationSchema={XraysSchema}
                    onSubmit={this.handleSubmit}
                >
                    {({
                        touched,
                        errors,
                        isSubmitting,
                        values,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                    }) => (
                        <Form className="form-material" onSubmit={handleSubmit}>
                            <div className="step-progress-new-case upload-container">
                                {isSubmitting && (
                                    <div className={`loading2`}>
                                        <div className="spinner_2"></div>
                                    </div>
                                )}
                            </div>
                            <CardBody id="case-submission">
                                <div className="main-xrays-grid ">
                                    {XraysData.map((value, index) => (
                                        <div key={index}>
                                            {value.key && (
                                                <>
                                                    <Card className="p-2">
                                                        <CardTitle className="border-bottom pb-2 title_active-gco">
                                                            {values[value.key] ? value.title : this.state[`showProgress_${value.key}`] ? `Uploading ${value.title}` : <span className="not-active-img">{value.title} </span>}


                                                            {
                                                                value.key === "cephalometric_xray" && <span className="pl-2 optional-input">*optional</span>
                                                            }  </CardTitle>
                                                        <div className="image-overlay-container">
                                                            <label name={value.key} htmlFor={value.key} className=" fix-xrays-img">
                                                                <CardImg
                                                                    src={
                                                                        values[value.key]
                                                                            ? values[value.key]
                                                                            : value.img
                                                                    }
                                                                    className="cursor-pointer"
                                                                />
                                                            </label>
                                                            <input
                                                                type="file"
                                                                id={value.key}
                                                                onChange={(e) => {
                                                                    this.UploadPhoto(e, value.key, setFieldValue);
                                                                }}
                                                                multiple={false}
                                                                className="visually-hidden"
                                                                accept="image/x-png,image/gif,image/jpeg"
                                                            />
                                                            {this.state[`showProgress_${value.key}`] && (
                                                                <div className="overlay-loader">
                                                                    <CircularProgressbar
                                                                        className="circule-progress-bar"
                                                                        value={
                                                                            this.state[
                                                                            `loading_percentage_${value.key}`
                                                                            ]
                                                                        }
                                                                        text={`${this.state[
                                                                            `loading_percentage_${value.key}`
                                                                        ]
                                                                            }%`}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Card>
                                                    <ErrorMessage
                                                        component="div"
                                                        name={value.key}
                                                        className="custom-invalid-feedback m-1"
                                                    />
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </CardBody>
                            <CardFooter className="p-3">

                                <Button
                                    className={`btn  ${"preference-btn"} float-right text-center mb-2  btn-radius`}
                                    size="sm"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Fix
                                    {/* <i className="fas fa-angle-right arrows-icon pl-1"></i> */}
                                </Button>{" "}
                            </CardFooter>{" "}
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FixXrays);
