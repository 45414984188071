import React, { useState } from "react";
import styles from "../eonAccess-style/eonAccess.module.scss";
import viewer from "../../../TS-VIEWER/viewer";
export default function LowerNav(props) {
  const {
    isDrawerOpen,
    tsOptions,
    setTSOptions,
  } = props;

  const [viewActiveTs, setViewActiveTs] = useState("front");
  const [viewAttachments, setViewAttachments] = useState(true);
  const [viewLayers, setViewLayers] = useState(false);

  const onTsViewChange = (action) => {
    switch (action.viewActiveTs) {
      case "maxilla-view":
        if (!tsOptions.isTSViewerFound) return;
        setTSOptions((prevOptions) => {
          return { ...prevOptions, showLower: !prevOptions.showLower };
        });
        break;
      case "mandible-view":
        if (!tsOptions.isTSViewerFound) return;
        setTSOptions((prevOptions) => {
          return { ...prevOptions, showUpper: !prevOptions.showUpper };
        });
        break;

      case "attachments-view":
        if (!tsOptions.isTSViewerFound) return;
        setTSOptions((prevOptions) => {
          return { ...prevOptions, isAttachment: !prevOptions.isAttachment };
        });
        break;

      case "superImpose-view":
        if (!tsOptions.isTSViewerFound) return;
        setTSOptions((prevOptions) => {
          return { ...prevOptions, isSuperImpose: !prevOptions.isSuperImpose };
        });
        break;
      default:
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("front");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: true,
        }));
        viewer.frontView();
    }
  };

  return (
    <div
      className={styles.lowerNav_container}
    >
      {/* Model Details */}
      <div
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          onTsViewChange({ viewActiveTs: "maxilla-view" });
        }}>
        <div>
          <img
            src={
              `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${tsOptions.showLower  ? "upper_arch_active.svg" : "upper_arch.svg"}`
            }
            alt=""
            data-tip="Maxilla"
            className={styles.lowerNav_icon}
          />
        </div>
      </div>

      {/* Mandible */}
      <div
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          onTsViewChange({ viewActiveTs: "mandible-view" });
        }}>
        <div>
          <img
            src={
              `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${tsOptions.showUpper ? "lower_arch_active.svg" : "lower_arch.svg"}`
            }
            alt=""
            data-tip="Mandible"
            className={styles.lowerNav_icon}
          />
        </div>
      </div>

      {/* Attachments */}

      <div
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          onTsViewChange({ viewActiveTs: "attachments-view" });
          setViewAttachments(!viewAttachments)
        }}>
        <div>
          <img
            src={
              `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${tsOptions.isAttachment ? "attachments_active.svg" : "attachments.svg"}`
            }
            alt=""
            data-tip="Attachments"
            className={styles.lowerNav_icon}
          />
        </div>
      </div>

      {/* Super Impose */}

      <div
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          onTsViewChange({ viewActiveTs: "superImpose-view" });
          setViewLayers(!viewLayers);
        }}
        style={isDrawerOpen ? { gridTemplateColumns: "1fr 1.4fr" } : {}}
      >
        <div>
          <img
            src={
                `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${tsOptions.isSuperImpose ? "superimpose_active.svg" : "superimpose.svg"}`
            }
            alt=""
            data-tip="Superimpose"
            className={styles.lowerNav_icon}
          />
        </div>
      </div>
      <div className={styles.empty_div_onMobile} />
    </div>
  );
}
