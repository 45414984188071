import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View, Image, Link } from "@react-pdf/renderer";
import eonAccessLogo from "../../../assets/images/eonAccessLogo.png";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "gray",
    paddingBottom:10,
    alignItems:"center"
  },
  image: {
    width: "15%",
  },
  text: {
    fontSize: 14,
    textAlign: "center",
    width: "60%",
    color: "#00c7b1",
  },

  link: {
    fontSize: 11,
    textAlign: "right",
    width: "25%",
    color: "gray",
    textDecoration: "none",
  },
});

export default class PDFHeader extends Component {
  render() {
    return (
      <Fragment>
        <View style={styles.row}>
          <Image style={styles.image} src={`${eonAccessLogo}`}></Image>
          <Text style={styles.text}>Treatment Submission Form</Text>
          <Link style={styles.link} src="https://www.eonaligner.com/">
            www.eonaligner.com
          </Link>
        </View>
      </Fragment>
    );
  }
}
