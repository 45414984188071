import React, { Component } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Card, CardTitle
} from "reactstrap";
import classnames from "classnames";

import Inbox from "../../inbox";
import {
  approveTreatmentSetup,
  disApproveTreatmentSetup,
  getTretmentSetupById,
} from "../../../api/api";
import { successToaster } from "../../../services/toast";
import { caseType } from "../../../helpers/caseType";
import Gallery from "../Gallery";
import { ipr } from "../../../services/ipr_example";
import { mapTeethFDI } from "../../../services/mapTeethFDI";
import CustomeTeeth2 from "../../Teeth/CustomeTeeth2";
import Movement from "./Movement";
import IPRDetailes from "./IPRDetailes";
import ToothMovement from "./ToothMovement";
import { connect } from "react-redux";
import { setTeethIPRData, setSavedData, setProtocolFeedback } from "../../../redux/Tooth_IPR_Options/action";
import { mapIPRTeethOptionsToApi } from "../../../services/mapIPRTeethOptionsToApi";
import { newMapDataWithReducerTS } from "../../../services/newDataMapperTS";
import { clearAdvancedModules, updateAllAdvancedModules } from "../../../redux/CaseSubmission/action"
import { allModules } from "../../../redux/advanced/action";
import {
  Clear_Teeth_Data,
  Case_Extraction,
  Case_Attachments,
  Case_IPR,
  Case_Pontics,
  Case_Overcorrections,
  Case_Torque_Enhancers,
  Case_Arch_Expansion,
  Case_Elastics,
  Case_Elastics_Buttons,
  Case_Elastics_Cuts
} from "../../../redux/customeTeeth/action";
import {
  availableModules,
  activateModules,
} from "../../../redux/advanced/action";

import ArchExpansion from "./TSModules/ModulesComponents/ArchExpansion";
import Attachments from "./TSModules/ModulesComponents/Attachments";
import BiteRamps from "./TSModules/ModulesComponents/BiteRamps";
import Elastics from "./TSModules/ModulesComponents/Elastics";
import Extraction from "./TSModules/ModulesComponents/Extraction";
import IPR from "./TSModules/ModulesComponents/IPR";
import OverCorrection from "./TSModules/ModulesComponents/OverCorrection";
import PassiveAligner from "./TSModules/ModulesComponents/PassiveAligner";
import Pontics from "./TSModules/ModulesComponents/Pontics";
import ToothSizeDiscrepancy from "./TSModules/ModulesComponents/ToothSizeDiscrepancy";
import TorqueEnhancers from "./TSModules/ModulesComponents/TorqueEnhancers";
import active_table from "../../../assets/images/caseSubmission/table_active.svg"

import tringle from "../../../assets/images/caseSubmission/triangle-2.svg"
import rectangle from "../../../assets/images/caseSubmission/rectangle.svg"

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setTeethIPRData: (payload) => dispatch(setTeethIPRData(payload)),
  setSavedData: (payload) => dispatch(setSavedData(payload)),
  setProtocolFeedback: (payload) => dispatch(setProtocolFeedback(payload)),

  availableModules: (payload) => dispatch(availableModules(payload)),
  activateModules: (payload) => dispatch(activateModules(payload)),
  clearAdvancedModules: (payload) => dispatch(clearAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),
  Case_Extraction: (payload) => dispatch(Case_Extraction(payload)),
  Case_Attachments: (payload) => dispatch(Case_Attachments(payload)),
  Case_IPR: (payload) => dispatch(Case_IPR(payload)),
  Case_Pontics: (payload) => dispatch(Case_Pontics(payload)),
  Case_Overcorrections: (payload) => dispatch(Case_Overcorrections(payload)),
  Case_Torque_Enhancers: (payload) => dispatch(Case_Torque_Enhancers(payload)),
  Case_Arch_Expansion: (payload) => dispatch(Case_Arch_Expansion(payload)),
  Case_Elastics: (payload) => dispatch(Case_Elastics(payload)),
  Case_Elastics_Buttons: (payload) => dispatch(Case_Elastics_Buttons(payload)),
  Case_Elastics_Cuts: (payload) => dispatch(Case_Elastics_Cuts(payload)),
  updateAllAdvancedModules: (payload) =>
    dispatch(updateAllAdvancedModules(payload)),
  allModules: (payload) => dispatch(allModules(payload)),
});

class NewViewerDetails extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /*init state */
    this.state = {
      activeTab: 1,
      case: props.data,
      details: props.details,
      id: props.doctorId,
      Approve: false,
      DisApprove: false,
      RejectionReason: "",
      loading1: false,
      loading2: false,
      showToothData: false,
      showIPRData: false,
      iprdata: {},
      movementData: {},
      showToothMovement: false,
      current_ipr_position: "",
      current_ipr_tooth: "",
      advanced_modules: props.details?.advanced_modules?.advanced_modules || {},
      showClinicProtocol: false,
      protocol_feedback: "",
      finished: false,
      width: window.innerWidth,
      height: document.getElementById("container")?.offsetHeight
    };
  }
  componentDidMount() {
    this.setState({ case: this.props.data, details: this.props.details });
    let newData = newMapDataWithReducerTS(this.props.details.advanced_modules);
    this.props.updateAllAdvancedModules(newData.advanced_modules);
    this.props.allModules(this.props.details.mapped_advanced_modules);

    this.props.Case_Extraction(newData.advanced_modules.extraction.teeth);
    this.props.Case_Attachments(
      newData.advanced_modules.attachments.attachmentsRestriction
    );
    this.props.Case_IPR(newData.advanced_modules.ipr.iprRestriction);
    this.props.Case_Pontics(newData.advanced_modules.pontics.teeth);

    this.props.Case_Torque_Enhancers(
      newData.advanced_modules.torqueEnhancers.teeth
    );
    this.props.Case_Elastics(newData.advanced_modules.elastics.teeth);
    this.props.Case_Elastics_Buttons(newData.advanced_modules.elastics.buttons_teeth);
    this.props.Case_Elastics_Cuts(newData.advanced_modules.elastics.cuts_teeth);

    this.setState({ finished: true })
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillMount() {
    this.setState({ finished: false })
  }

  /* --------------------------------------------------------------------------------*/
  /* update Dimensions after get all data ,get treatment setup by id before render   */
  /* --------------------------------------------------------------------------------*/
  // componentDidMount() {
  //   window.addEventListener("load", this.updateDimensions);
  //   window.addEventListener("resize", this.updateDimensions);

  // }
  // /*--------------------------------------------------------------------------------*/
  // /* update Dimensions if screen size changed   */
  // /*--------------------------------------------------------------------------------*/
  // componentDidUpdate() {
  //   window.addEventListener("load", this.updateDimensions);
  //   window.addEventListener("resize", this.updateDimensions);
  // }
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    window.removeEventListener("load", this.updateDimensions);
    window.removeEventListener("resize", this.updateDimensions);
  }
  /* --------------------------------------------------------------------------------*/
  /* update Dimensions  */
  /* --------------------------------------------------------------------------------*/
  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* Toggle tabs                                                                    */
  /*--------------------------------------------------------------------------------*/
  toggleTabs = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /* Get Tretment Setup By Id                                                     */
  /*--------------------------------------------------------------------------------*/
  GetTretmentSetupById = async () => {
    await getTretmentSetupById(
      this.state.id,
      this.state.case.id,
      this.state.details.id
    ).then((res) => {
      this.setState({ details: res });
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* Confirmation Approve                                                           */
  /*--------------------------------------------------------------------------------*/
  Approve = () => {
    this.setState({ Approve: !this.state.Approve });
  };
  /*--------------------------------------------------------------------------------*/
  /* Confirmation DisApprove                                                        */
  /*--------------------------------------------------------------------------------*/
  DisApprove = () => {
    this.setState({ DisApprove: !this.state.DisApprove });
  };
  /*--------------------------------------------------------------------------------*/
  /* Approve Treatment Setup                                                     */
  /*--------------------------------------------------------------------------------*/
  ApproveTreatmentSetup = () => {
    this.setState({ loading1: !this.state.loading1 });
    approveTreatmentSetup(
      this.props.doctorId,
      this.state.case.uid,
      this.state.details.id
    )
      .then(async (res) => {
        await this.GetTretmentSetupById();
        await this.props.GetAllTs();
        await this.props.GetTretmentSetupById()

        await this.props.GetAlignerCase();
        this.Approve();
        this.setState({ loading1: !this.state.loading1 });

        successToaster("Treatment Setup has been approved");
      })
      .catch(async (error) => {
        await this.GetTretmentSetupById();
        await this.props.GetTretmentSetupById()
        await this.props.GetAlignerCase();
        await this.props.GetAllTs();

        this.setState({ loading1: !this.state.loading1 });
      });
  };
  /*--------------------------------------------------------------------------------*/
  /* DisApprove Treatment Setup                                                     */
  /*--------------------------------------------------------------------------------*/
  DisApproveTreatmentSetup = (mappeddata) => {
    this.setState({ loading2: !this.state.loading2 });

    let data = {
      revised_data: mappeddata
    };
    disApproveTreatmentSetup(
      this.props.doctorId,
      this.state.case.uid,
      this.state.details.id,
      JSON.stringify(data)
    )
      .then(async (res) => {
        this.GetTretmentSetupById();
        await this.props.GetAllTs();
        await this.props.GetTretmentSetupById()
        await this.props.GetAlignerCase();
        //  this.DisApprove();


        this.setState({ loading2: !this.state.loading2 });
        successToaster("Treatment Setup has been disapproved");
        await this.props.ToggleOptions();
        this.setState({ showClinicProtocol: !this.state.showClinicProtocol })

      })
      .catch(async (error) => {
        this.GetTretmentSetupById();

        await this.props.GetAlignerCase();
        await this.props.GetTretmentSetupById()
        await this.props.GetAllTs();

        this.setState({ loading2: !this.state.loading2 });
      });
  };
  /*--------------------------------------------------------------------------------*/
  /* change Tooth Data                                                    */
  /*--------------------------------------------------------------------------------*/
  changeToothData = (data, current_ipr_position, current_ipr_tooth) => {
    this.setState({ showToothMovement: !this.state.showToothMovement, movementData: data.movement, current_ipr_position, current_ipr_tooth })
  }
  /*--------------------------------------------------------------------------------*/
  /* change ipr Data                                                    */
  /*--------------------------------------------------------------------------------*/
  changeIPRData = (data, current_ipr_position, current_ipr_tooth) => {
    this.setState({ showIPRData: !this.state.showIPRData, iprdata: data, current_ipr_position, current_ipr_tooth })

  }

  saveIPRData = (data) => {
    const { current_ipr_position, current_ipr_tooth } = this.state;

    let new_data = {
      ...this.props.teethIPROptions.teeth_ipr_data,
      [current_ipr_position]: {
        ...this.props.teethIPROptions.teeth_ipr_data[current_ipr_position],
        [current_ipr_tooth]: {
          ...this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][current_ipr_tooth],
          ...data
        }
      }
    }
    this.props.setTeethIPRData(new_data)
    this.setState({ showIPRData: !this.state.showIPRData, current_ipr_position: "", current_ipr_tooth: "" })

  }


  saveToothMovementData = (data) => {
    const { current_ipr_position, current_ipr_tooth } = this.state;
    let new_data = {
      ...this.props.teethIPROptions.teeth_ipr_data,
      [current_ipr_position]: {
        ...this.props.teethIPROptions.teeth_ipr_data[current_ipr_position],
        [current_ipr_tooth]: {
          ...this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][current_ipr_tooth],
          value: false,
          image: this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][current_ipr_tooth].hover,
          movement: {
            ...this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][current_ipr_tooth].movement,
            ...data,

          }

        }
      }
    }
    this.props.setTeethIPRData(new_data)
    this.setState({ showToothMovement: !this.state.showToothMovement, current_ipr_position: "", current_ipr_tooth: "" })
  }
  /*--------------------------------------------------------------------------------*/
  /* cancel Changes On IPR                                                    */
  /*--------------------------------------------------------------------------------*/
  cancelChangesOnIPR = () => {
    this.setState({ showIPRData: !this.state.showIPRData })

  }
  /*--------------------------------------------------------------------------------*/
  /* cancel Changes On Tooth Movement                                                    */
  /*--------------------------------------------------------------------------------*/
  cancelChangesOnToothMovement = () => {

    const { current_ipr_position, current_ipr_tooth } = this.state;
    let new_data = {
      ...this.props.teethIPROptions.teeth_ipr_data,
      [current_ipr_position]: {
        ...this.props.teethIPROptions.teeth_ipr_data[current_ipr_position],
        [current_ipr_tooth]: {
          ...this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][current_ipr_tooth],
          value: false,
          image:
            this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][current_ipr_tooth].movement.updated ?
              this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][current_ipr_tooth].hover :
              this.props.teethIPROptions.teeth_ipr_data[current_ipr_position][current_ipr_tooth].not_active,
        }
      }
    }
    this.props.setTeethIPRData(new_data)
    this.setState({ showToothMovement: !this.state.showToothMovement })

  }

  SaveAllData = () => {
    let mapped_data =
      mapIPRTeethOptionsToApi(this.props.teethIPROptions.teeth_ipr_data, this.props.teethIPROptions.saved_data)
  }
  ToggleBitsRecord = () => {
    this.props.ToggleBitsRecord()
  }

  /*--------------------------------------------------------------------------------*/
  /*toggle modals                                          */
  /*--------------------------------------------------------------------------------*/
  toggleModals = (id) => {
    this.setState({ [`${id}_modal`]: !this.state[`${id}_modal`], active_module: id });
  };
  toggleModalsClose = (id) => {
    this.setState({ [`${id}_modal`]: !this.state[`${id}_modal`], active_module: "" });
  };

  toggleClinicProtocol = () => {
    this.setState({ showClinicProtocol: !this.state.showClinicProtocol })
  }

  ToggleOptions = () => {

    this.props.setProtocolFeedback(this.state.protocol_feedback);
    let mapped_data =
      mapIPRTeethOptionsToApi(this.props.teethIPROptions.teeth_ipr_data, this.props.teethIPROptions.saved_data, this.state.protocol_feedback, this.props.teethIPROptions.rejection_reason)


    this.DisApproveTreatmentSetup(mapped_data)


  }

  ExiteOptions = () => {
    this.props.ToggleOptions();
    this.setState({ showClinicProtocol: false })
  }

  getallts = () => {
    this.props.GetAllTs()
  }
  /* --------------------------------------------------------------------------------*/
  /* clear state if go out from component   */
  /* --------------------------------------------------------------------------------*/
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    const { iprdata, showIPRData, showToothData, showToothMovement, movementData, height } = this.state
    return (
      <div className="w-100">
        {/*--------------------------------------------------------------------------------*/}
        {/* Approve Treatment Setup Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.Approve}
          toggle={this.Approve}
        // className="modalx4"
        >
          <ModalHeader toggle={this.Approve}>
            Approve Treatment Setup
          </ModalHeader>
          <ModalBody>
            Patient Name: {this.props.data.patient.full_name}
            <br></br>
            Case Type:{" "}
            {caseType[this.props.data.case_type] || this.props.data.case_type}
            <br></br>
            Invoice will be based on case type
            <hr></hr>
            By approving this Treatment Setup, you hereby authorize Eon Aligner
            to fabricate the aligners as per the specifications provided by you
            in the Treatment Form and as contained in this Treatment Setup. You
            will be invoiced according to the approved case type unless the
            required treatment plan exceeds it.
          </ModalBody>
          <ModalFooter>
            <Row className=" w-100 m-1 justify-content-end">
              <Col xs="4" md="4" lg="4" className="p-2 w-100">
                <Button
                  className="btn red-btn w-100 "
                  size="md"
                  color="danger"
                  disabled={this.state.loading1}
                  onClick={() =>
                    this.setState({ Approve: !this.state.Approve })
                  }
                >
                  Cancel
                </Button>
              </Col>
              <Col xs="8" md="6" lg="6" className="p-2 w-100">
                <Button
                  className="btn green-btn w-100"
                  size="md"
                  disabled={this.state.loading1}
                  onClick={() => this.ApproveTreatmentSetup()}
                >
                  Submit Approval
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Approve Treatment Setup Modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* DisApprove Treatment Setup Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.DisApprove}
          toggle={this.DisApprove}
        // className="modalx4"
        >
          <ModalHeader toggle={this.DisApprove}>
            Disapprove Treatment Setup{" "}
          </ModalHeader>
          <ModalBody>
            Patient Name: {this.props.data.patient.full_name}
            <br></br>
            Case Type:{" "}
            {caseType[this.props.data.case_type] || this.props.data.case_type}
            <hr></hr>
            <div className="form-group content form-block-holder">
              <label className="control-label title_active ">
                Rejection Reason{" "}
              </label>
              <div>
                <Input
                  autoComplete="off"
                  type="textarea"
                  rows={3}
                  className="green_input"
                  defaultValue={this.state.RejectionReason}
                  onChange={(e) => {
                    this.setState({
                      RejectionReason: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Row className=" w-100 m-1 justify-content-end">
              <Col xs="4" md="4" lg="4" className="p-2 w-100">
                <Button
                  className="btn red-btn w-100 "
                  size="md"
                  color="danger"
                  onClick={() =>
                    this.setState({ DisApprove: !this.state.DisApprove })
                  }
                  disabled={this.state.loading2}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs="8" md="6" lg="6" className="p-2 w-100">
                <Button
                  className="btn green-btn w-100"
                  size="md"
                  disabled={this.state.loading2}
                  onClick={() => this.DisApproveTreatmentSetup()}
                >
                  Disapprove
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/*end  DisApprove Treatment Setup Modal */}
        {/*--------------------------------------------------------------------------------*/}
        {this.props.showToothIPROptions && !this.state.showClinicProtocol ?
          <div className="viewer_case_details mt-3"
            style={{
              height:
                height - 50 ||
                "100%",
              overflow: "auto",
            }}>
            <div><span className="arrows-icon-ts">Bite Record<i className="fas fa-angle-right pl-1"></i> </span>  <span className="active-step-tooth-options">Tooth & IPR Options</span></div>
            <div className="actions-grid  text-right mb-3 mr-2">

              <div ><img src={tringle} alt="tringle" className="options-example-1 pr-1" /> <span className="active-step-tooth-options">IPR Step</span></div>
              <div><img src={rectangle} alt="rectangle" className="options-example pr-1" /> <span className="active-step-tooth-options">Revision</span></div>
            </div>

            <div className="custom-teeth2 mb-3">
              <CustomeTeeth2
                teeth={this.props.teethIPROptions.teeth_ipr_data}
                changeToothData={this.changeToothData}
                changeIPRData={this.changeIPRData}
                disableChanges={showIPRData || showToothMovement}
              /></div>
            {
              !showIPRData && !showToothData && !showToothMovement && <div

                style={{
                  height:
                    height - 440 ||
                    "100%",
                  overflow: "auto",
                }}
                className="mb-2"
              ><Movement movement={this.state?.details?.teeth_movements_extracted || []} /></div>
            }
            {
              showIPRData && <div><IPRDetailes data={iprdata} cancelChangesOnIPR={this.cancelChangesOnIPR} saveIPRData={this.saveIPRData} /></div>
            }

            {
              showToothMovement && <div> <ToothMovement data={movementData} cancelChangesOnToothMovement={this.cancelChangesOnToothMovement} saveToothMovementData={this.saveToothMovementData} /></div>
            }

            {
              !showIPRData && !showToothMovement && <><div className="actions-btn-grid">
                <Button
                  className={`btn  ${"preference-btn"} w-auto  float-left text-center  btn-radius`}
                  size="sm"
                  type="button"
                  onClick={() => { this.ToggleBitsRecord() }}  >
                  <i className="fas fa-angle-left arrows-icon pr-1"></i>    Bite Record </Button>
                <div></div>
                <Button
                  className={`btn  ${"preference-btn"} w-auto float-right text-center  btn-radius`}
                  size="sm"
                  type="button"
                  onClick={() => { this.toggleClinicProtocol() }}  >
                  Clinical Protocol  <i className="fas fa-angle-right arrows-icon pl-1"></i></Button>
              </div>

                <div className="mt-2 ">
                  <div
                    className="exit-options"
                    onClick={() => { this.ExiteOptions() }}  >
                    Exit revision flow </div>
                </div>
              </>
            }


          </div>



          : this.state.showClinicProtocol ? <div

            className="viewer_case_details mt-3"
            style={{
              height:
                height - 100 ||
                "100%",
              overflow: "auto",
            }}>
            <div className="mb-5"><span className="arrows-icon-ts">Bite Record<i className="fas fa-angle-right pl-1"></i> Tooth & IPR Options<i className="fas fa-angle-right pl-1"></i> </span>  <span className="active-step-tooth-options">Clinical Protocol</span> </div>

            <div className=" pb-5">
              <div className="control-label title_active-gco pb-2">
                Clinical Protocol feedback                                </div>

              <div className="">
                <Input type="textarea" rows={8} value={this.state.protocol_feedback} onChange={(e) => {
                  this.setState({ protocol_feedback: e.target.value })
                }} />
              </div>

            </div>
            <div>
              <Button
                className={`btn  actions-btn ${"preference-btn"} w-auto  float-left text-center  btn-radius`}
                size="sm"
                type="button"
                disabled={this.state.loading2}

                onClick={() => { this.toggleClinicProtocol() }}  >
                <i className="fas fa-angle-left arrows-icon pr-1"></i>  Tooth & IPR Options </Button>

              <Button
                className={`btn  actions-btn ${"preference-btn"} w-auto float-right text-center  btn-radius`}
                size="sm"
                type="button"
                disabled={this.state.loading2}

                onClick={() => { this.ToggleOptions() }}  >
                Submit Modifications <i className="fas fa-angle-right arrows-icon pl-1"></i> </Button>
            </div>

          </div> : <>   <Nav tabs className="justify-content-center">
            {/* Case Details Tab */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === 1,
                })}
                onClick={() => {
                  this.toggleTabs(1);
                }}
              >
                {`Case Details`}
              </NavLink>
            </NavItem>
            {/* Inbox Tab */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === 2,
                })}
                onClick={() => {
                  this.toggleTabs(2);
                }}
              >
                {`Inbox`}
              </NavLink>
            </NavItem>
            {/* Records Tab */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === 3,
                })}
                onClick={() => {
                  this.toggleTabs(3);
                }}
              >
                {`Records`}
              </NavLink>
            </NavItem>
          </Nav>
            <TabContent activeTab={this.state.activeTab}>
              {/* Case Details Tab content */}
              <TabPane tabId={1}>
                <div
                  className="viewer_case_details mt-3"
                  style={{
                    height:
                      height - 150 ||
                      "100%",
                    overflow: "auto",
                  }}
                >
                  <div className="ts-details-grid">
                    <div className="patient-img-ts">
                      <img src={this.state.case.patient.photo} />
                    </div>
                    <div className="rows-grid">
                      <div>{this.state.case.patient.full_name}</div>
                      <div>#{this.state.details.id}</div>
                      <div>{caseType[this.state.case.case_type] ||
                        this.state.case.case_type}</div>
                    </div>
                    <div className="rows-grid">
                      <div> {this.state.details.front_title}</div>
                      <div></div>
                      <div>{this.state.details.state}</div>
                    </div>
                    {
                      this.state.details.can_approve_or_disapprove &&
                      !this.state.case.is_archived &&
                      <div className="actions-grid">
                        <div><Button
                          className={`btn  w-action ${"preference-btn"}  float-right  text-center  btn-radius`}
                          size="sm"
                          type="button"
                          disabled={!this.props.finish_loading_ts}

                          onClick={() => { this.Approve() }}  >
                          Approve Treatment Plan { }  </Button></div>

                        <div><Button
                          className={`btn w-action  ${"preference-btn"} float-right  text-center  btn-radius`}
                          size="sm"
                          type="button"
                          disabled={!this.props.finish_loading_ts}

                          onClick={() => { this.ToggleBitsRecord() }}  >
                          Revise Treatment Plan  </Button>

                        </div>
                      </div>

                    }
                  </div>
                  <div className="rows-grid mt-3 mb-3">
                    <div><span className="note-header-ts">Technician’s Notes:</span>{` ${this.state.details.technician_notes}`}</div>
                    <div><span className="note-header-ts">Manufacturing Notes:</span>{` ${this.state.details.manufacturing_notes}`}</div>
                  </div>
                  {/* <div className="custom-teeth2">
                <CustomeTeeth2
                  teeth={this.props.teethIPROptions.teeth_ipr_data}
                  changeToothData={this.changeToothData}
                  changeIPRData={this.changeIPRData}
                  disableChanges={showIPRData || showToothMovement}
                /></div> */}

                  {/* <Button onClick={() => {
                this.SaveAllData()
              }}>Save Data</Button> */}

                  <div className="active-advanced-module-ts mb-3">
                    {
                      this.state?.details?.teeth_movements_extracted.length > 0 && <Card

                        className={`p-1 custom_shadow align-items-center mb-0 not_active_advanced_module_card_ts active_advanced_module_card_ts`}
                      >
                        <div
                          onClick={() => {
                            // this.toggleModals(values.key);
                          }}
                          style={{ cursor: "pointer", width: "100%" }}
                        >

                          {
                            <img
                              src={active_table}
                              className="modules-img-ts"
                              alt="Aligner Treatment"
                            />}

                          <CardTitle className="pt-2 m-0 ">
                            Movement Table
                    </CardTitle>
                        </div>

                      </Card>


                    }


                    {this.state.finished && this.props.details.advanced_modules // 👈 null and undefined check
                      && Object.keys(this.props.details.advanced_modules).length !== 0 && this.props?.details?.mapped_advanced_modules
                        .filter((value, index) => {
                          return value.active === true && (value.key !== "archExpansion" && value.key !== "overCorrection" && value.key !== "malocclusion");
                        })
                        .map((values, index) => (
                          <Card
                            key={index}
                            className={`p-1 custom_shadow align-items-center not_active_advanced_module_card_ts ${this.state.active_module === values.key ? "active_advanced_module_card_ts" : ""}  `}
                          >

                            <div
                              onClick={() => {
                                this.toggleModals(values.key);
                              }}
                              style={{ cursor: "pointer", width: "100%" }}
                            >

                              {
                                values.key ===
                                  'ipr' ? <div className={` ${this.state.active_module === "ipr" ? "ipr-title-active-ts" : "ipr-title-ts"}`}>IPR</div>
                                  : <img
                                    src={this.state.active_module === values.key ? values.active_img : values.img}
                                    className="modules-img-ts"
                                    alt="Aligner Treatment"
                                  />}

                              <CardTitle className="pt-2 m-0  ">
                                {values.title}
                              </CardTitle>
                            </div>
                          </Card>
                        ))}

                  </div>
                  {
                    !showIPRData && !showToothData && !showToothMovement && <div><Movement movement={this.state?.details?.teeth_movements_extracted || []} /></div>
                  }
                  {
                    showIPRData && <div><IPRDetailes data={iprdata} cancelChangesOnIPR={this.cancelChangesOnIPR} saveIPRData={this.saveIPRData} /></div>
                  }

                  {
                    showToothMovement && <div> <ToothMovement data={movementData} cancelChangesOnToothMovement={this.cancelChangesOnToothMovement} saveToothMovementData={this.saveToothMovementData} /></div>
                  }
                </div>
              </TabPane>
              {/* Inbox Tab content*/}
              <TabPane tabId={2}>
                <div
                  style={{
                    height:
                      height - 150 ||
                      "100%",
                    overflow: "auto",
                  }}
                // className="mt-5"
                >
                  {this.state.activeTab === 2 && (
                    <Inbox caseId={this.state.case.uid} formKey={"viewer"} />
                  )}
                </div>
              </TabPane>
              {/* Records Tab content */}
              <TabPane tabId={3}>
                <div
                  style={{
                    height:
                      height - 100 ||
                      "100%",
                    overflow: "auto",
                  }}
                >
                  {" "}
                  <Gallery
                    CaseDetails={this.props.details}
                    data={this.props.props}
                  />
                </div>
              </TabPane>
            </TabContent>
          </>}

        {/*--------------------------------------------------------------------------------*/}
        {/* malocclusion Modal */}
        {/*--------------------------------------------------------------------------------*/}
        {/* <Modal
          isOpen={this.state.malocclusion_modal}
          toggle={() => this.toggleModals("malocclusion")}
          className="malocclusion-modal"
        >
          <ModalHeader toggle={() => this.toggleModals("malocclusion")}>
            Malocclusion
          </ModalHeader>

          <ModalBody>
            <Malocclusion save={() => this.activateModules("malocclusion")} handleSubmit={this.handleSubmit} justToggle={() => this.justToggle("malocclusion")} />
          </ModalBody>
        </Modal> */}
        {/*--------------------------------------------------------------------------------*/}
        {/* end malocclusion Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* extraction Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.extraction_modal}
          toggle={() => this.toggleModalsClose("extraction")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModalsClose("extraction")}>
            Extraction
          </ModalHeader>

          <ModalBody className="m-2">
            <Extraction save={() => this.activateModules("extraction")} handleSubmit={this.handleSubmit} summary={true} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end extraction Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* ipr Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.ipr_modal}
          toggle={() => this.toggleModalsClose("ipr")}
          className="modalx7"
        >
          <ModalHeader toggle={() => this.toggleModalsClose("ipr")}>IPR</ModalHeader>

          <ModalBody className="m-2">
            <IPR save={() => this.activateModules("ipr")} handleSubmit={this.handleSubmit} summary={true} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end ipr Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* attachments Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.attachments_modal}
          toggle={() => this.toggleModalsClose("attachments")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModalsClose("attachments")}>
            Attachments
          </ModalHeader>

          <ModalBody className="m-2">
            <Attachments save={() => this.activateModules("attachments")} handleSubmit={this.handleSubmit} summary={true} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end attachments Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* pontics Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.pontics_modal}
          toggle={() => this.toggleModalsClose("pontics")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModalsClose("pontics")}>
            Pontics
          </ModalHeader>

          <ModalBody className="m-2">
            <Pontics save={() => this.activateModules("pontics")} handleSubmit={this.handleSubmit} summary={true} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end pontics Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* overCorrection Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.overCorrection_modal}
          toggle={() => this.toggleModalsClose("overCorrection")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModalsClose("overCorrection")} >
            Overcorrection
          </ModalHeader>

          <ModalBody className="m-2">
            <OverCorrection
              save={() => this.activateModules("overCorrection")} handleSubmit={this.handleSubmit} summary={true}
            />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end overCorrection Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* passiveAligners Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.passiveAligners_modal}
          toggle={() => this.toggleModalsClose("passiveAligners")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModalsClose("passiveAligners")}>
            Passive Aligner
          </ModalHeader>

          <ModalBody className="m-2">
            <PassiveAligner
              save={() => this.activateModules("passiveAligners")} handleSubmit={this.handleSubmit} summary={true}
            />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end passiveAligners Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* toothSizeDiscrepancy Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.toothSizeDiscrepancy_modal}
          toggle={() => this.toggleModalsClose("toothSizeDiscrepancy")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModalsClose("toothSizeDiscrepancy")}>
            Tooth size Discrepancy
            <span
              className="icon-container tooltip1"
              id="ToothsizeDiscrepancyInfo"
            >
              <i className="fas fa-exclamation-circle "></i>
              <div className="tooltip-bottom custom_shadow">
                <div className="tooltip-title"></div>
                <div className="tooltip-body">
                  allows you to indicate how you want to address differences in
                  the sizes of the upper and lower teeth.
                </div>
                <i></i>
              </div>
            </span>
          </ModalHeader>

          <ModalBody className="m-2">
            <ToothSizeDiscrepancy
              save={() => this.activateModules("toothSizeDiscrepancy")} handleSubmit={this.handleSubmit} summary={true}
            />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end toothSizeDiscrepancy Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* torqueEnhancers Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.torqueEnhancers_modal}
          toggle={() => this.toggleModalsClose("torqueEnhancers")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModalsClose("torqueEnhancers")}>
            Torque Enhancers{" "}
            <span className="icon-container tooltip1" id="TorqueEnhancersInfo">
              <i className="fas fa-exclamation-circle "></i>
              <div className="tooltip-bottom custom_shadow">
                <div className="tooltip-title"></div>
                <div className="tooltip-body">
                  adding the torque enhancers feature to the aligners that
                  deliver force on a tooth at a specified position, this feature
                  is used to correct the inclination of the front teeth to their
                  ideal position.
                </div>
                <i></i>
              </div>
            </span>
          </ModalHeader>

          <ModalBody className="m-2">
            <TorqueEnhancers
              save={() => this.activateModules("torqueEnhancers")} handleSubmit={this.handleSubmit} summary={true}
            />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end torqueEnhancers Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* biteRamps Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.biteRamps_modal}
          toggle={() => this.toggleModalsClose("biteRamps")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModalsClose("biteRamps")}>
            Bite Ramps
          </ModalHeader>

          <ModalBody className="m-2">
            <BiteRamps save={() => this.activateModules("biteRamps")} handleSubmit={this.handleSubmit} summary={true} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end biteRamps Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* archExpansion Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.archExpansion_modal}
          toggle={() => this.toggleModalsClose("archExpansion")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModalsClose("archExpansion")}>
            Arch expansion
          </ModalHeader>

          <ModalBody className="m-2">
            <ArchExpansion save={() => this.activateModules("archExpansion")} handleSubmit={this.handleSubmit} summary={true} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end archExpansion Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* elastics Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.elastics_modal}
          toggle={() => this.toggleModalsClose("elastics")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModalsClose("elastics")}>
            Elastics
          </ModalHeader>

          <ModalBody className="m-2">
            <Elastics save={() => this.activateModules("elastics")} handleSubmit={this.handleSubmit} summary={true} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end elastics Modal*/}
        {/*--------------------------------------------------------------------------------*/}

      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewViewerDetails);
