import { teeth } from "./teeth";
export const upper_ipr_teeth = {
  ...initRightTeeth("upper_r_", 18, false,"UR"),
  ...initLeftTeeth("upper_l_", 21, true,"UL"),
};

export const lower_ipr_teeth = {
  ...initRightTeeth("lower_r_", 48, false,"LR"),
  ...initLeftTeeth("lower_l_", 31, true,"LL"),
};

function initRightTeeth(key, counter, increment,position) {
  let num = 8;
  let count = counter
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]: {
        active: teeth[`${key}${num}_active`],
        not_active: teeth[`${key}${num}`],
        hover: teeth[`${key}${num}_hover`],
        image: teeth[`${key}${num}`],
        value: false,
        index: num,
        amount: "",
        before_step: "",
        divider: true,
        use_ipr: false,
        do_not_perform_ipr:false,
        fdi_key: increment ? count++ : count--,
        tooth_key:`${position}_${num} / ${position}_${num-1}`
      },
    };
    num--;
  }
  return rightTeeth;
}

function initLeftTeeth(key, counter, increment,position) {
  let num = 1;
  let count = counter
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]: {
        active: teeth[`${key}${num}_active`],
        not_active: teeth[`${key}${num}`],
        hover: teeth[`${key}${num}_hover`],
        image: teeth[`${key}${num}`],
        value: false,
        index: num,
        ...x < 8 && {
          amount: "",
          before_step: "",
          divider: true,
          use_ipr: false,
          do_not_perform_ipr:false,
          tooth_key:`${position}_${num} / ${position}_${num+1}`

        },
        fdi_key: increment ? count++ : count--
      },
    };
    num++;
  }
  return rightTeeth;
}
