import { USER_INFO } from "../constants/";

const INIT_STATE = {
  currentUser: JSON.parse(localStorage.getItem("currentUser")),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_INFO:
      return {
        ...state,
        currentUser: action.payload,
      };
    default:
      return state;
  }
};
