import React, { Component } from "react";
import { Row, Col, Input } from "reactstrap";
import { connect } from "react-redux";

import {
  Case_Crossbites,
  Case_Attachments,
  Case_Elastics,
  Case_Extraction,
  Case_IPR,
  Case_Overcorrections,
  Case_Passive_Aligners,
  Case_Pontics,
  Case_Tooth_Movement_Restrictions,
  Case_Torque_Enhancers,
  Case_Arch_Expansion,
  Doctor_Preferences_Attachments,
  Doctor_Preferences_IPR,
  Case_Elastics_Buttons,
  Case_Elastics_Cuts,
} from "../../redux/customeTeeth/action";
import { mapTeeth_ipr } from "../../services/mapTeeth_ipr";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  Case_Tooth_Movement_Restrictions: (payload) =>
    dispatch(Case_Tooth_Movement_Restrictions(payload)),

  Case_Extraction: (payload) => dispatch(Case_Extraction(payload)),

  Case_Crossbites: (payload) => dispatch(Case_Crossbites(payload)),

  Case_Attachments: (payload) => dispatch(Case_Attachments(payload)),
  Case_Arch_Expansion: (payload) => dispatch(Case_Arch_Expansion(payload)),
  Case_IPR: (payload) => dispatch(Case_IPR(payload)),
  Case_Pontics: (payload) => dispatch(Case_Pontics(payload)),
  Case_Overcorrections: (payload) => dispatch(Case_Overcorrections(payload)),
  Case_Passive_Aligners: (payload) => dispatch(Case_Passive_Aligners(payload)),
  Case_Torque_Enhancers: (payload) => dispatch(Case_Torque_Enhancers(payload)),
  Case_Elastics: (payload) => dispatch(Case_Elastics(payload)),
  Case_Elastics_Buttons: (payload) => dispatch(Case_Elastics_Buttons(payload)),
  Case_Elastics_Cuts: (payload) => dispatch(Case_Elastics_Cuts(payload)),

  Doctor_Preferences_Attachments: (payload) =>
    dispatch(Doctor_Preferences_Attachments(payload)),
  Doctor_Preferences_IPR: (payload) =>
    dispatch(Doctor_Preferences_IPR(payload)),
});
class CustomeTeeth3 extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {

    super(props);
    /* init state */
    this.state = {
      upper_teeth: this.props.customeTeethReducer[this.props.action_key][
        "upper_teeth"
      ],
      lower_teeth: this.props.customeTeethReducer[this.props.action_key][
        "lower_teeth"
      ],
      numbering: "universal"
    };

  }

  render() {

    let upper_teeth = this.props.customeTeethReducer[this.props.action_key][
      "upper_teeth"
    ];
    let lower_teeth = this.props.customeTeethReducer[this.props.action_key][
      "lower_teeth"
    ];
    return (
      <div>
        <Row className="align-items-center justify-content-center pt-3 pb-3">
          {/* border2 */}
          {(
            <Col
              xs="1"
              md="1"
              lg="1"
              className={`text-center side ${this.props.disabled ? "disabled-teeth" : "title_active-gco "
                }`}
            >
              R
            </Col>
          )}
          <Col
            xs={`${this.state.numbering === "palmer" ? "10" : "10"}`}
            md={`${this.state.numbering === "palmer" ? "10" : "10"}`}
            lg={`${this.state.numbering === "palmer" ? "10" : "10"}`}
            className="pr-0  pl-0 text-center"
          >
            {/*--------------------------------------------------------------------------------*/}
            {/* Upper Teeth */}
            {/*--------------------------------------------------------------------------------*/}
            <div className="grid_ipr">
              {Object.keys(upper_teeth).map((prop, key) => {
                return (
                  <div key={key}
                    className="ipr_teeth_upper" >
                    <img
                      // className={this.props.disabled ? "disabled-teeth" : ""}
                      style={{
                        pointerEvents: this.props.summary ? "none" : "",
                        filter: this.props.cuts && upper_teeth[prop].value ? "invert(65%)" : "",
                        //  position: "relative",
                        cursor: "pointer"
                      }}
                      // className="teeth"
                      alt={prop}
                      src={upper_teeth[prop].image}
                      //onMouseOver
                      onMouseOver={(e) => {
                        // if tooth is active show active tooth on Mouse Over
                        // else show hovered tooth
                        e.currentTarget.src = upper_teeth[prop].value
                          ? upper_teeth[prop].hover
                          : upper_teeth[prop].hover;
                      }}
                      //onMouseOut
                      onMouseOut={(e) => {
                        // if tooth is active show active tooth on Mouse Out
                        // else show not active tooth
                        e.currentTarget.src = upper_teeth[prop].value
                          ? upper_teeth[prop].not_active
                          : upper_teeth[prop].not_active;
                      }}
                      //onclick
                      onClick={(e) => {
                        // if tooth is active change value to not active and show not active tooth
                        // else change value to active and show active tooth
                        let newdata = !upper_teeth[prop].value;
                        let mapped_data = mapTeeth_ipr({
                          upper_teeth: {
                            ...upper_teeth,
                            [prop]: {
                              ...upper_teeth[prop],
                              image: newdata
                                ? upper_teeth[prop].not_active
                                : upper_teeth[prop].not_active,
                              value: !upper_teeth[prop].value,
                              do_not_perform_ipr: !upper_teeth[prop].do_not_perform_ipr
                            },
                          },
                          lower_teeth: lower_teeth,
                        });
                        // change values in reducer
                        this.props[this.props.action_key](mapped_data);
                        if (this.props?.key === "malocclusion") {
                          this.props.UpdateCrossBiteReducer();
                        }
                      }}
                    ></img>
                    {
                      upper_teeth[prop].do_not_perform_ipr &&
                      <div className={`${upper_teeth[prop].index < 6 ? "do-not-perform-ipr_upper_big" : "do-not-perform-ipr_upper_small"}`}
                        style={{ pointerEvents: "none" }}
                        onClick={(e) => {
                          // if tooth is active change value to not active and show not active tooth
                          // else change value to active and show active tooth
                          let newdata = !upper_teeth[prop].value;
                          let mapped_data = mapTeeth_ipr({
                            upper_teeth: {
                              ...upper_teeth,
                              [prop]: {
                                ...upper_teeth[prop],
                                image: newdata
                                  ? upper_teeth[prop].not_active
                                  : upper_teeth[prop].not_active,
                                value: !upper_teeth[prop].value,
                                do_not_perform_ipr: !upper_teeth[prop].do_not_perform_ipr

                              },
                            },
                            lower_teeth: lower_teeth,
                          });
                          // change values in reducer
                          this.props[this.props.action_key](mapped_data);
                          if (this.props?.key === "malocclusion") {
                            this.props.UpdateCrossBiteReducer();
                          }
                        }}>
                        {
                          upper_teeth[prop].index < 6 ? String.fromCharCode(8212) : "-"
                        }
                      </div>

                    }
                    {
                      upper_teeth[prop].divider && <div className="num-container2">
                        <div className="v-line-upper2"></div>

                        <div className="form-material ipr-amount2 text-center">
                          <div>
                            <Input
                              type="number"
                              placeholder=""
                              className="custom-number-ipr"
                              onClick={e => {
                                e.target.focus();
                              }}
                              onMouseUp={e => {
                                e.target.blur();
                              }}
                              min="0"
                              step="0.1"
                              value={upper_teeth[prop].amount}
                              disabled={this.props.disabled}

                              onChange={(e) => {
                                e.persist();

                                let mapped_data = mapTeeth_ipr({
                                  upper_teeth: {
                                    ...upper_teeth,
                                    [prop]: {
                                      ...upper_teeth[prop],
                                      amount: Number(e.target.value),
                                      use_ipr: e.target.value ? true : false
                                    },
                                  },
                                  lower_teeth: lower_teeth,
                                });

                                // change values in reducer
                                this.props[this.props.action_key](mapped_data);
                              }}

                            />
                          </div>

                          <div>
                            <Input
                              type="number"
                              placeholder=""
                              className="custom-number-ipr"
                              onClick={e => {
                                e.target.focus();
                              }}
                              onMouseUp={e => {
                                e.target.blur();
                              }}
                              min="0"
                              step="1"
                              value={upper_teeth[prop].before_step}
                              disabled={this.props.disabled}
                              onChange={(e) => {
                                e.persist();

                                let mapped_data = mapTeeth_ipr({
                                  upper_teeth: {
                                    ...upper_teeth,
                                    [prop]: {
                                      ...upper_teeth[prop],
                                      before_step: Number(e.target.value),
                                      use_ipr: e.target.value ? true : false
                                    },
                                  },
                                  lower_teeth: lower_teeth,
                                });

                                // change values in reducer
                                this.props[this.props.action_key](mapped_data);
                              }}

                            />
                          </div>
                        </div>


                      </div>
                    }
                  </div>
                );
              })}
            </div>
            {/*--------------------------------------------------------------------------------*/}
            {/* End Upper Teeth */}
            {/*--------------------------------------------------------------------------------*/}
            {/*--------------------------------------------------------------------------------*/}
            {/* Plamer tooth numbering system  */}
            {/*--------------------------------------------------------------------------------*/}
            {this.state.numbering === "palmer" && (
              <div
                className={`grid text-center ${this.props.disabled ? "disabled-teeth" : "title_active-gco"
                  }`}
              >
                <div className="text-center">8</div>
                <div className="text-center">7</div>
                <div className="text-center">6</div>
                <div className="text-center">5</div>
                <div className="text-center">4</div>
                <div className="text-center">3</div>
                <div className="text-center">2</div>
                <div className="text-center">1</div>
                <div className="text-center">1</div>
                <div className="text-center">2</div>
                <div className="text-center">3</div>
                <div className="text-center">4</div>
                <div className="text-center">5</div>
                <div className="text-center">6</div>
                <div className="text-center">7</div>
                <div className="text-center">8</div>
              </div>
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* End Plamer tooth numbering system  */}
            {/*--------------------------------------------------------------------------------*/}
            {/*--------------------------------------------------------------------------------*/}
            {/* FDI tooth numbering system  */}
            {/*--------------------------------------------------------------------------------*/}
            {this.state.numbering === "fdi" && (
              <>
                {" "}
                <div className="grid text-center title_active-gco">
                  <div className="text-center">18</div>
                  <div className="text-center">17</div>
                  <div className="text-center">16</div>
                  <div className="text-center">15</div>
                  <div className="text-center">14</div>
                  <div className="text-center">13</div>
                  <div className="text-center">12</div>
                  <div className="text-center">11</div>
                  <div className="text-center">21</div>
                  <div className="text-center">22</div>
                  <div className="text-center">23</div>
                  <div className="text-center">24</div>
                  <div className="text-center">25</div>
                  <div className="text-center">26</div>
                  <div className="text-center">27</div>
                  <div className="text-center">28</div>
                </div>
                <hr className="divider"></hr>
                <div className="grid text-center title_active-gco">
                  <div className="text-center">48</div>
                  <div className="text-center">47</div>
                  <div className="text-center">46</div>
                  <div className="text-center">45</div>
                  <div className="text-center">44</div>
                  <div className="text-center">43</div>
                  <div className="text-center">42</div>
                  <div className="text-center">41</div>
                  <div className="text-center">31</div>
                  <div className="text-center">32</div>
                  <div className="text-center">33</div>
                  <div className="text-center">34</div>
                  <div className="text-center">35</div>
                  <div className="text-center">36</div>
                  <div className="text-center">37</div>
                  <div className="text-center">38</div>
                </div>
              </>
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* End FDI tooth numbering system  */}
            {/*--------------------------------------------------------------------------------*/}
            {/*--------------------------------------------------------------------------------*/}
            {/* Universal tooth numbering system  */}
            {/*--------------------------------------------------------------------------------*/}
            {this.state.numbering === "universal" && (
              <>
                {" "}
                <div className="grid text-center title_active-gco">
                  <div className="text-center">1</div>
                  <div className="text-center">2</div>
                  <div className="text-center">3</div>
                  <div className="text-center">4</div>
                  <div className="text-center">5</div>
                  <div className="text-center">6</div>
                  <div className="text-center">7</div>
                  <div className="text-center">8</div>
                  <div className="text-center">9</div>
                  <div className="text-center">10</div>
                  <div className="text-center">11</div>
                  <div className="text-center">12</div>
                  <div className="text-center">13</div>
                  <div className="text-center">14</div>
                  <div className="text-center">15</div>
                  <div className="text-center">16</div>
                </div>
                <hr className="divider"></hr>
                <div className="grid text-center title_active-gco">
                  <div className="text-center">32</div>
                  <div className="text-center">31</div>
                  <div className="text-center">30</div>
                  <div className="text-center">29</div>
                  <div className="text-center">28</div>
                  <div className="text-center">27</div>
                  <div className="text-center">26</div>
                  <div className="text-center">25</div>
                  <div className="text-center">24</div>
                  <div className="text-center">23</div>
                  <div className="text-center">22</div>
                  <div className="text-center">21</div>
                  <div className="text-center">20</div>
                  <div className="text-center">19</div>
                  <div className="text-center">18</div>
                  <div className="text-center">17</div>
                </div>
              </>
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* End Universal tooth numbering system  */}
            {/*--------------------------------------------------------------------------------*/}
            {/*--------------------------------------------------------------------------------*/}
            {/* Lower Teeth */}
            {/*--------------------------------------------------------------------------------*/}
            <div className="grid_ipr">
              {Object.keys(lower_teeth).map((prop, key) => {
                return (
                  <div key={key} className="ipr_teeth_lower">
                    <img
                      //  className={this.props.disabled ? "disabled-teeth" : ""}
                      style={{
                        pointerEvents: this.props.summary ? "none" : "",
                        filter: this.props.cuts && lower_teeth[prop].value ? "invert(65%)" : "",
                        position: "relative",
                        cursor: "pointer"
                      }}
                      // className="teeth"
                      alt={prop}
                      src={lower_teeth[prop].image}
                      //onMouseOver
                      onMouseOver={(e) => {
                        e.currentTarget.src = lower_teeth[prop].value
                          ? lower_teeth[prop].hover
                          : lower_teeth[prop].hover;
                      }}
                      //onMouseOut
                      onMouseOut={(e) => {
                        e.currentTarget.src = lower_teeth[prop].value
                          ? lower_teeth[prop].not_active
                          : lower_teeth[prop].not_active;
                      }}
                      //onClick
                      onClick={(e) => {
                        let newdata = !lower_teeth[prop].value;
                        let mapped_data = mapTeeth_ipr({
                          upper_teeth: upper_teeth,
                          lower_teeth: {
                            ...lower_teeth,
                            [prop]: {
                              ...lower_teeth[prop],
                              image: newdata
                                ? lower_teeth[prop].not_active
                                : lower_teeth[prop].not_active,
                              value: !lower_teeth[prop].value,
                              do_not_perform_ipr: !lower_teeth[prop].do_not_perform_ipr

                            },
                          },
                        });

                        this.props[this.props.action_key](mapped_data);
                        if (this.props?.key === "malocclusion") {
                          this.props.UpdateCrossBiteReducer();
                        }
                      }}
                    ></img>
                    {
                      lower_teeth[prop].do_not_perform_ipr &&
                      <div className={`${lower_teeth[prop].index < 6 ? "do-not-perform-ipr_lower_big" : "do-not-perform-ipr_lower_small"}`}
                        style={{ pointerEvents: "none" }}

                        onClick={(e) => {
                          let newdata = !lower_teeth[prop].value;
                          let mapped_data = mapTeeth_ipr({
                            upper_teeth: upper_teeth,
                            lower_teeth: {
                              ...lower_teeth,
                              [prop]: {
                                ...lower_teeth[prop],
                                image: newdata
                                  ? lower_teeth[prop].not_active
                                  : lower_teeth[prop].not_active,
                                value: !lower_teeth[prop].value,
                                do_not_perform_ipr: !lower_teeth[prop].do_not_perform_ipr

                              },
                            },
                          });

                          this.props[this.props.action_key](mapped_data);
                          if (this.props?.key === "malocclusion") {
                            this.props.UpdateCrossBiteReducer();
                          }
                        }}> {
                          lower_teeth[prop].index < 6 ? String.fromCharCode(8212) : "-"
                        }</div>

                    }
                    {
                      lower_teeth[prop].divider && <div className="num-container2"><div className="v-line-lower2"></div>
                        <div className="form-material ipr-amount-lower2 text-center">
                          <div>
                            <Input
                              type="number"
                              placeholder=""
                              className="custom-number-ipr"
                              onClick={e => {
                                e.target.focus();
                              }}
                              onMouseUp={e => {
                                e.target.blur();
                              }}
                              min="0"
                              step="0.1"
                              value={lower_teeth[prop].amount}
                              disabled={this.props.disabled}
                              onChange={(e) => {
                                e.persist();

                                let mapped_data = mapTeeth_ipr({
                                  lower_teeth: {
                                    ...lower_teeth,
                                    [prop]: {
                                      ...lower_teeth[prop],
                                      amount: Number(e.target.value),
                                      use_ipr: e.target.value ? true : false
                                    },
                                  },
                                  upper_teeth: upper_teeth,
                                });

                                // change values in reducer
                                this.props[this.props.action_key](mapped_data);
                              }}
                            />
                          </div>

                          <div>
                            <Input
                              type="number"
                              placeholder=""
                              className="custom-number-ipr"
                              onClick={e => {
                                e.target.focus();
                              }}
                              onMouseUp={e => {
                                e.target.blur();
                              }}
                              min="0"
                              step="1"
                              value={lower_teeth[prop].before_step}
                              disabled={this.props.disabled}

                              onChange={(e) => {
                                e.persist();

                                let mapped_data = mapTeeth_ipr({
                                  lower_teeth: {
                                    ...lower_teeth,
                                    [prop]: {
                                      ...lower_teeth[prop],
                                      before_step: Number(e.target.value),
                                      use_ipr: e.target.value ? true : false
                                    },
                                  },
                                  upper_teeth: upper_teeth,
                                });

                                // change values in reducer
                                this.props[this.props.action_key](mapped_data);
                              }}
                            />
                          </div>
                        </div>


                      </div>
                    }
                  </div>
                );
              })}
            </div>
            {/*--------------------------------------------------------------------------------*/}
            {/* End Lower Teeth */}
            {/*--------------------------------------------------------------------------------*/}
          </Col>
          {(
            <Col
              xs="1"
              md="1"
              lg="1"
              className={`text-center side ${this.props.disabled ? "disabled-teeth" : "title_active-gco "
                }`}
            >
              L
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomeTeeth3);
