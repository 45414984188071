import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View, Link } from "@react-pdf/renderer";
import moment from "moment";

const styles = StyleSheet.create({
  row: {
    position: "absolute",
    bottom: 50,
    left: 0,
    right: 0,
    paddingTop: 10,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flexWrap:"nowrap"

  },

  header: {
    fontSize: 12,
    textAlign: "left",
    color: "#00c7b1",
    width: "100%",
  },
  container: {
    flexDirection: "row",
    padding: 3,
  },
  field: {
    flexDirection: "row",
  },
  col: {
    flexDirection: "column",
    alignItems: "center",
    width: "33%",
  },
  title: {
    fontSize: 8,
    textAlign: "left",
    color: "#00c7b1",
  },
  info: {
    fontSize: 10,
    textAlign: "left",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 50,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  footer_text: {
    fontSize: 8,
    color: "gray",
    textDecoration: "none",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: -20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      doctor: props.doctorInfo,
    };
  }
  render() {
    const { doctor } = this.state;
    return (
      <Fragment>
        <View style={styles.row} fixed>
          <View style={styles.container}>
            <View style={styles.col}>
              <Link
                style={styles.footer_text}
                src="https://www.eonaligner.com/"
              >
                www.eonaligner.com
              </Link>
            </View>
            <View style={styles.col}>
              <View style={styles.field}>
                <Text style={styles.title}>Doctor Signature: </Text>
                <Text style={styles.footer_text}>{doctor.full_name}</Text>
              </View>
            </View>
            <View style={styles.col}>
              <View style={styles.field}>
                <Text style={styles.title}>Date: </Text>
                <Text style={styles.footer_text}>
                  {moment(new Date()).format("D MMM YYYY")}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.container}>
            <View style={styles.col}>
              <Text style={styles.footer_text}>info@eonaligner.com</Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.footer_text}>
                P.O. Box 3658 Minneapolis, MN. 55403 USA
              </Text>
            </View>
            <View style={styles.col}>
              <Text style={styles.footer_text}> Tel. +1 888 206 5955</Text>
            </View>
          </View>
          <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
        </View>
      </Fragment>
    );
  }
}
