import React from "react";
import styles from "../eonAccess-style/eonAccess.module.scss";

export default function ProgressBar(props) {
  const { loading, progress, countProgress, isTsPrepared } = props;
  let howManyFiles = countProgress <= 2 ? 1 : countProgress;
  return (
    <React.Fragment>
      <div
        className={styles.Progress_Status}
        style={!loading ? { display: "none" } : {}}
      >
        <div
          className={styles.myprogressBar}
          style={{ width: `${Math.floor(progress / howManyFiles)}%` }}
        >
          {Math.floor(progress / howManyFiles)}%
        </div>
      </div>

      <div
        className={styles.spinner}
        style={isTsPrepared ? { display: "block" } : { display: "none" }}
      >
        <div className={styles.double_bounce1}></div>
        <div className={styles.double_bounce2}></div>
      </div>
      <div
        className={styles.loading_title}
        style={isTsPrepared ? { display: "block" } : { display: "none" }}
      >
        Preparing treatment plan
      </div>
    </React.Fragment>
  );
}
