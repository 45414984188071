import { meshesForStep } from "../../../TS-VIEWER/Scene";
import viewer from "../../../TS-VIEWER/viewer";

export const updateMeshesAccordingToStepNumber = function (
  currentStep = 0,
  options = {}
) {
  const {
    showUpperArc,
    showUpper,
    showLowerArc,
    showLower,
    isSuperImpose,
    isAttachment,
  } = options;
  const currentMeshes = meshesForStep[currentStep];
  const meshesForFirstStep = meshesForStep[0];
  const firstStepMeshes = [];
  const visibleMeshes = [];
  if (showUpperArc && showUpper) {
    currentMeshes[0] && visibleMeshes.push(currentMeshes[0]);
    currentMeshes[1] && visibleMeshes.push(currentMeshes[1]);
    if (isAttachment) {
      currentMeshes[2] && visibleMeshes.push(currentMeshes[2]);
    }
  }
  if (showLowerArc && showLower) {
    currentMeshes[3] && visibleMeshes.push(currentMeshes[3]);
    currentMeshes[4] && visibleMeshes.push(currentMeshes[4]);
    if (isAttachment) {
      currentMeshes[5] && visibleMeshes.push(currentMeshes[5]);
    }
  }

  if (showUpperArc && showUpper && isSuperImpose) {
    meshesForFirstStep[1] && firstStepMeshes.push(meshesForFirstStep[6]);
  }
  if (showLowerArc && showLower && isSuperImpose) {
    meshesForFirstStep[7] && firstStepMeshes.push(meshesForFirstStep[7]);
  }

  viewer.setMeshes(visibleMeshes, isSuperImpose, firstStepMeshes, currentStep);
};
