import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CardTitle,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  CardFooter,
} from "reactstrap";
import { Formik, Form } from "formik";
import { updateAlignerCase } from "../../../api/api";
import { availableModules, activateModules } from "../../../redux/advanced/action";
import { clearAdvancedModules } from "../../../redux/CaseSubmission/action"
import {
  Clear_Teeth_Data,
  Case_Extraction,
  Case_Attachments,
  Case_IPR,
  Case_Pontics,
  Case_Overcorrections,
  Case_Torque_Enhancers,
  Case_Arch_Expansion,
  Case_Elastics,
  Case_Elastics_Buttons,
  Case_Elastics_Cuts
} from "../../../redux/customeTeeth/action"
import ArchExpansion from "./ModulesComponents/ArchExpansion";
import Attachments from "./ModulesComponents/Attachments";
import BiteRamps from "./ModulesComponents/BiteRamps";
import Elastics from "./ModulesComponents/Elastics";
import Extraction from "./ModulesComponents/Extraction";
import IPR from "./ModulesComponents/IPR";
import Malocclusion from "./ModulesComponents/Malocclusion";
import OverCorrection from "./ModulesComponents/OverCorrection";
import PassiveAligner from "./ModulesComponents/PassiveAligner";
import Pontics from "./ModulesComponents/Pontics";
import ToothSizeDiscrepancy from "./ModulesComponents/ToothSizeDiscrepancy";
import TorqueEnhancers from "./ModulesComponents/TorqueEnhancers";
import { mapTeethToApi } from "../../../services/mapTeethToApi";
import { mapAdvancedToApi } from "../../../services/mapAdvancedToApi";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  availableModules: (payload) => dispatch(availableModules(payload)),
  activateModules: (payload) => dispatch(activateModules(payload)),
  clearAdvancedModules: (payload) => dispatch(clearAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),
  Case_Extraction: (payload) => dispatch(Case_Extraction(payload)),
  Case_Attachments: (payload) => dispatch(Case_Attachments(payload)),
  Case_IPR: (payload) => dispatch(Case_IPR(payload)),
  Case_Pontics: (payload) => dispatch(Case_Pontics(payload)),
  Case_Overcorrections: (payload) => dispatch(Case_Overcorrections(payload)),
  Case_Torque_Enhancers: (payload) => dispatch(Case_Torque_Enhancers(payload)),
  Case_Arch_Expansion: (payload) => dispatch(Case_Arch_Expansion(payload)),
  Case_Elastics: (payload) => dispatch(Case_Elastics(payload)),
  Case_Elastics_Buttons: (payload) => dispatch(Case_Elastics_Buttons(payload)),
  Case_Elastics_Cuts: (payload) => dispatch(Case_Elastics_Cuts(payload)),
});
class AdvancedModules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      malocclusion_modal: false,
      extraction_modal: false,
      ipr_modal: false,
      attachments_modal: false,
      pontics_modal: false,

      overCorrection_modal: false,
      passiveAligners_modal: false,
      toothSizeDiscrepancy_modal: false,
      torqueEnhancers_modal: false,
      biteRamps_modal: false,

      elastics_modal: false,
      archExpansion_modal: false,
      caseId:
        this.props.caseId ||
        (window.location.search.match(/id=([^&]+)/) || [])[1],
    };
  }
  /*--------------------------------------------------------------------------------*/
  /*activateModules                                          */
  /*--------------------------------------------------------------------------------*/
  activateModules = (key) => {
    this.props.activateModules(key);
    this.toggleModals(key);
  };
  /*--------------------------------------------------------------------------------*/
  /*deActivate Modules   & clear data in modules                                       */
  /*--------------------------------------------------------------------------------*/
  deActivateModules = (key) => {
    this.props.availableModules(key);
    this.props.clearAdvancedModules(key)
    this.ClearTeethValues(key)
  };

  /*--------------------------------------------------------------------------------*/
  /*Clear Teeth Values  in modules                                        */
  /*--------------------------------------------------------------------------------*/
  ClearTeethValues = (key) => {
    if (key === "malocclusion") {
      this.props.Clear_Teeth_Data("Case_Crossbites")
      this.props.Clear_Teeth_Data("Case_ClassII")
      this.props.Clear_Teeth_Data("Case_ClassIII")
      this.props.Clear_Teeth_Data("Case_Crowding")
    }
    if (key === "ipr") {
      this.props.Clear_Teeth_Data("Case_IPR")
    }
    if (key === "extraction") {
      this.props.Clear_Teeth_Data("Case_Extraction")

    }
    if (key === "attachments") {
      this.props.Clear_Teeth_Data("Case_Attachments")

    }
    if (key === "pontics") {
      this.props.Clear_Teeth_Data("Case_Pontics")

    }
    if (key === "overCorrection") {
      this.props.Clear_Teeth_Data("Case_Overcorrections")

    }
    if (key === "torqueEnhancers") {
      this.props.Clear_Teeth_Data("Case_Torque_Enhancers")

    }
    if (key === "archExpansion") {
      this.props.Clear_Teeth_Data("Case_Arch_Expansion")

    }
    if (key === "elastics") {
      this.props.Clear_Teeth_Data("Case_Elastics")
      this.props.Clear_Teeth_Data("Case_Elastics_Buttons")
      this.props.Clear_Teeth_Data("Case_Elastics_Cuts")
    }
  }
  /*--------------------------------------------------------------------------------*/
  /*fill teeth data if module is active                                       */
  /*--------------------------------------------------------------------------------*/
  FillTeethValues = (key) => {
    if (key === "ipr") {
      this.props.Case_IPR(this.props.mappedData.advanced_modules.ipr.iprRestriction);
    }
    if (key === "extraction") {
      this.props.Case_Extraction(this.props.mappedData.advanced_modules.extraction.teeth);
    }
    if (key === "attachments") {
      this.props.Case_Attachments(
        this.props.mappedData.advanced_modules.attachments.attachmentsRestriction
      );
    }
    if (key === "pontics") {
      this.props.Case_Pontics(this.props.mappedData.advanced_modules.pontics.teeth);
    }
    if (key === "overCorrection") {
      this.props.Case_Overcorrections(
        this.props.mappedData.advanced_modules.overCorrection.teeth
      );
    }
    if (key === "torqueEnhancers") {
      this.props.Case_Torque_Enhancers(
        this.props.mappedData.advanced_modules.torqueEnhancers.teeth
      );
    }
    if (key === "archExpansion") {
      this.props.Case_Arch_Expansion(
        this.props.mappedData.advanced_modules.archExpansion.teeth
      );
    }
    if (key === "elastics") {
      this.props.Case_Elastics(this.props.mappedData.advanced_modules.elastics.teeth);
      this.props.Case_Elastics_Buttons(this.props.mappedData.advanced_modules.elastics.buttons_teeth);
      this.props.Case_Elastics_Cuts(this.props.mappedData.advanced_modules.elastics.cuts_teeth);

    }
  }
  /*--------------------------------------------------------------------------------*/
  /*toggle modals                                          */
  /*--------------------------------------------------------------------------------*/
  toggleModals = (id) => {
    let modalData = this.props.advancedModules.modules
      .filter((value) => {
        return value.key === id;
      })
    if (this.state[`${id}_modal`] === false) {
      if (modalData[0].active === false) {
        this.ClearTeethValues(id)
      }
      else {
        // this.FillTeethValues(id)
      }
    }

    this.setState({ [`${id}_modal`]: !this.state[`${id}_modal`] });
  };

  justToggle = (key) => {
    this.toggleModals(key);
    this.deActivateModules(key)
  }
  /*--------------------------------------------------------------------------------*/
  /*handel Advanced Modules                                       */
  /*--------------------------------------------------------------------------------*/
  handleSubmit = (values, { setSubmitting }) => {
    const { doctorId } = this.props;
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    setSubmitting(true);
    let data = {
      aligner_case: {
        step: "advanced_modules",
        advanced_modules: {
          ...this.props.caseSubmission.advanced_modules,
          ipr: {
            ...this.props.caseSubmission.advanced_modules.ipr,
            iprRestriction: mapTeethToApi(this.props.customeTeethReducer.Case_IPR)
          },
          archExpansion: {
            ...this.props.caseSubmission.advanced_modules.archExpansion,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Arch_Expansion
            )
          },
          attachments: {
            ...this.props.caseSubmission.advanced_modules.attachments,
            attachmentsRestriction: mapTeethToApi(
              this.props.customeTeethReducer.Case_Attachments
            )
          },
          elastics: {
            ...this.props.caseSubmission.advanced_modules.elastics,
            teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics),
            buttons_teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics_Buttons),
            cuts_teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics_Cuts),

          },
          extraction: {
            ...this.props.caseSubmission.advanced_modules.extraction,
            teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Extraction),
          },
          overCorrection: {
            ...this.props.caseSubmission.advanced_modules.overCorrection,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Overcorrections
            ),
          },
          pontics: {
            ...this.props.caseSubmission.advanced_modules.pontics,
            teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Pontics),
          },
          torqueEnhancers: {
            ...this.props.caseSubmission.advanced_modules.torqueEnhancers,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Torque_Enhancers
            ),
          },
          malocclusion: {
            ...this.props.caseSubmission.advanced_modules.malocclusion,
            crossbite: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .crossbite,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Crossbites
              ),
            },
            crowding: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .crowding,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Crowding
              ),
            },
            classII: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .classII,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_ClassII
              ),
            },
            classIII: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .classIII,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_ClassIII
              ),
            },
          },
          all_modules: mapAdvancedToApi(this.props.advancedModules.modules) ,
        },
      },
    };
    updateAlignerCase(doctorId, caseId, JSON.stringify(data))
      .then((res) => {
        this.props.GetAlignerCaseById()
        this.props.NextStep();
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };
  render() {
    return (
      <div>
        <CardTitle className="border-bottom pb-3 case-submission-title">

          <div className="step-header-title">
            <span>Advanced Treatment Options</span>
            <span className="patient_info">
              <div> {this.props.patient.full_name}
              </div>
              <div> Case {this.state.caseId}
              </div>
            </span>
          </div>



        </CardTitle>
        <Formik initialValues={{}} onSubmit={this.handleSubmit}>
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="step-progress-new-case upload-container">
                {isSubmitting && (
                  <div className={`loading2`}>
                    <div className="spinner_2"></div>
                  </div>
                )}
              </div>
              <div className="main_advanced_module_grid">
                <div>
                  {/* <div className="main_modules_title pb-2 pt-2">
                    Available Treatment Options
                  </div> */}
                  <div className="advanced_module_grid mt-3">
                    {this.props.advancedModules.modules
                      // .filter((value, index) => {
                      //   return value.active === false;
                      // })
                      .map((values, index) => (
                        <Card
                          key={index}
                          className={`p-1 custom_shadow align-items-center advanced_module-card`}


                          id="Treatment_Type"
                          className={`${(values.active) ? `p-1 custom_shadow align-items-center active_advanced_module_card` : `p-1 custom_shadow align-items-center advanced_module-card`}`}
                        >
                          {
                            values.active && <span
                              className="closebtn close-active-module"
                              onClick={() => {
                                this.deActivateModules(values.key);
                              }}
                            >
                              &times;
                               </span>
                          }
                          <div
                            style={{ cursor: "pointer", width: "100%" }}
                            onClick={() => {
                              this.toggleModals(values.key);
                            }}>{
                              values.key ===
                                'ipr' ? <div className={`${values.active ? "ipr-title-active" : "ipr-title"} `}>IPR</div>
                                : <img
                                  src={`${(values.active) ? values.active_img : values.img}`}
                                  className="modules-img"
                                  alt="Aligner Treatment"
                                />
                            }

                            <CardTitle className={`${(values.active) ? "pt-2 m-0 active_module_title " : "pt-2 m-0 module_title "}`}>
                              {values.title}
                            </CardTitle>
                          </div>
                        </Card>
                      ))}
                  </div>
                </div>

                <div>
                  {/* <div className="main_modules_title pb-2">
                    Active Treatment Options
                  </div> */}
                  <div className="active_advanced_module_grid mt-3">
                    {this.props.advancedModules.modules
                      // .filter((value, index) => {
                      //   return value.active === true;
                      // })
                      .map((values, index) => (
                        console.log()
                        //     <Card
                        //       key={index}
                        //       className={`p-1 custom_shadow align-items-center active_advanced_module_card`}
                        //     >
                        //       {/* <i
                        //   onClick={() => {
                        //     this.deActivateModules(values);
                        //   }}
                        //   className="fa fa-times close-active-module"
                        // /> */}
                        //       <span
                        //         className="closebtn close-active-module"
                        //         onClick={() => {
                        //           this.deActivateModules(values.key);
                        //         }}
                        //       >
                        //         &times;
                        //       </span>
                        //       <div
                        //         onClick={() => {
                        //           this.toggleModals(values.key);
                        //         }}
                        //         style={{ cursor: "pointer", width: "100%" }}
                        //       >

                        //         {
                        //           values.key ===
                        //             'ipr' ? <div className="ipr-title-active">IPR</div>
                        //             : <img
                        //               src={values.active_img}
                        //               className="modules-img"
                        //               alt="Aligner Treatment"
                        //             />}

                        //         <CardTitle className="pt-2 m-0 active_module_title ">
                        //           {values.title}
                        //         </CardTitle>
                        //       </div>
                        //     </Card>
                      ))}
                  </div>
                </div>
              </div>
              <CardFooter className="p-3 mt-5">
                <Button
                  className={`btn  ${"preference-btn"} float-left text-center mb-2 btn-radius`}
                  size="sm"
                  type="button"
                  onClick={() => this.props.PrevStep()}
                  disabled={isSubmitting}
                >
                  <i className="fas fa-angle-left arrows-icon pr-1"></i>     Prescription
                </Button>{" "}
                <Button
                  className={`btn  ${"preference-btn"} float-right text-center mb-2 btn-radius`}
                  size="sm"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Next<i className="fas fa-angle-right arrows-icon pl-1"></i>
                </Button>{" "}
              </CardFooter>{" "}
            </Form>
          )}
        </Formik>
        {/*--------------------------------------------------------------------------------*/}
        {/* malocclusion Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.malocclusion_modal}
          toggle={() => this.toggleModals("malocclusion")}
          className="malocclusion-modal"
        >
          <ModalHeader toggle={() => this.toggleModals("malocclusion")}>
            Malocclusion
          </ModalHeader>

          <ModalBody>
            <Malocclusion save={() => this.activateModules("malocclusion")} justToggle={() => this.justToggle("malocclusion")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end malocclusion Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* extraction Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.extraction_modal}
          toggle={() => this.toggleModals("extraction")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModals("extraction")}>
            Extraction
          </ModalHeader>

          <ModalBody className="m-2">
            <Extraction save={() => this.activateModules("extraction")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end extraction Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* ipr Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.ipr_modal}
          toggle={() => this.toggleModals("ipr")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModals("ipr")}>IPR</ModalHeader>

          <ModalBody className="m-2">
            <IPR save={() => this.activateModules("ipr")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end ipr Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* attachments Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.attachments_modal}
          toggle={() => this.toggleModals("attachments")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModals("attachments")}>
            Attachments
          </ModalHeader>

          <ModalBody className="m-2">
            <Attachments save={() => this.activateModules("attachments")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end attachments Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* pontics Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.pontics_modal}
          toggle={() => this.toggleModals("pontics")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModals("pontics")}>
            Pontics
          </ModalHeader>

          <ModalBody className="m-2">
            <Pontics save={() => this.activateModules("pontics")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end pontics Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* overCorrection Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.overCorrection_modal}
          toggle={() => this.toggleModals("overCorrection")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModals("overCorrection")}>
            Overcorrection
          </ModalHeader>

          <ModalBody className="m-2">
            <OverCorrection
              save={() => this.activateModules("overCorrection")}
            />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end overCorrection Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* passiveAligners Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.passiveAligners_modal}
          toggle={() => this.toggleModals("passiveAligners")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModals("passiveAligners")}>
            Passive Aligner
          </ModalHeader>

          <ModalBody className="m-2">
            <PassiveAligner
              save={() => this.activateModules("passiveAligners")}
            />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end passiveAligners Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* toothSizeDiscrepancy Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.toothSizeDiscrepancy_modal}
          toggle={() => this.toggleModals("toothSizeDiscrepancy")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModals("toothSizeDiscrepancy")}>
            Tooth size Discrepancy
            <span
              className="icon-container tooltip1"
              id="ToothsizeDiscrepancyInfo"
            >
              <i className="fas fa-exclamation-circle "></i>
              <div className="tooltip-bottom custom_shadow">
                <div className="tooltip-title"></div>
                <div className="tooltip-body">
                  allows you to indicate how you want to address differences in
                  the sizes of the upper and lower teeth.
                </div>
                <i></i>
              </div>
            </span>
          </ModalHeader>

          <ModalBody className="m-2">
            <ToothSizeDiscrepancy
              save={() => this.activateModules("toothSizeDiscrepancy")}
            />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end toothSizeDiscrepancy Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* torqueEnhancers Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.torqueEnhancers_modal}
          toggle={() => this.toggleModals("torqueEnhancers")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModals("torqueEnhancers")}>
            Torque Enhancers{" "}
            <span className="icon-container tooltip1" id="TorqueEnhancersInfo">
              <i className="fas fa-exclamation-circle "></i>
              <div className="tooltip-bottom custom_shadow">
                <div className="tooltip-title"></div>
                <div className="tooltip-body">
                  adding the torque enhancers feature to the aligners that
                  deliver force on a tooth at a specified position, this feature
                  is used to correct the inclination of the front teeth to their
                  ideal position.
                </div>
                <i></i>
              </div>
            </span>
          </ModalHeader>

          <ModalBody className="m-2">
            <TorqueEnhancers
              save={() => this.activateModules("torqueEnhancers")}
            />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end torqueEnhancers Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* biteRamps Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.biteRamps_modal}
          toggle={() => this.toggleModals("biteRamps")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModals("biteRamps")}>
            Bite Ramps
          </ModalHeader>

          <ModalBody className="m-2">
            <BiteRamps save={() => this.activateModules("biteRamps")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end biteRamps Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* archExpansion Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.archExpansion_modal}
          toggle={() => this.toggleModals("archExpansion")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModals("archExpansion")}>
            Arch expansion
          </ModalHeader>

          <ModalBody className="m-2">
            <ArchExpansion save={() => this.activateModules("archExpansion")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end archExpansion Modal*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* elastics Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.elastics_modal}
          toggle={() => this.toggleModals("elastics")}
          className="modalx6"
        >
          <ModalHeader toggle={() => this.toggleModals("elastics")}>
            Elastics
          </ModalHeader>

          <ModalBody className="m-2">
            <Elastics save={() => this.activateModules("elastics")} />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end elastics Modal*/}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedModules);
