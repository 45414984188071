import axios from "axios";
const token = localStorage.getItem("token") || "";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${token}`,
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json; charset=utf-8",
  },
});

const fetchTSUrls = (caseId, tsId, doctorId) => {
  return Axios.get(
    `/doctors/${doctorId}/aligner_cases/${caseId}/treatment_setups/${tsId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    }
  );
};
// const fetchTSUrls = (viewerId) => {
//   return axios.get(`http://10.1.0.205:3002/getTreatmentSetup/${viewerId}`);
// };

export { fetchTSUrls };
