import React, { Component } from "react";
import { Table } from "reactstrap";
export default class Movement extends Component {
    constructor(props) {
        super(props);

    }



    render() {
        return (
            <div className="movement-table">
                {
                    this.props.movement.length > 0 &&
                     <Table className="p-0" responsive height="100" striped size="sm">
                        <thead>
                            <tr>
                                <th>Tooth #</th>
                                <th>Rotation</th>
                                <th>Angulation</th>
                                <th>Torque</th>
                                <th>Mesial<br></br>/Distal</th>
                                <th>Intrusion<br></br>/Extrusion</th>
                                <th>Out/In</th>
                                <th>IPR Distel</th>
                                <th>IPR Mesial</th>

                            </tr>
                        </thead>
                        <tbody>
                            {this.props.movement.map((value, index) => (
                                <tr key={index}>
                                    <td>{value.ToothNumber}</td>
                                    <td>{value.Rotation}</td>
                                    <td>{value.Angulation}</td>
                                    <td>{value.Inclination}</td>
                                    <td>{value.LeftRight}</td>
                                    <td>{value.ExtrusionIntrusion}</td>
                                    <td>{value.ForwardBackward}</td>
                                    <td>{value.IprDistal}</td>
                                    <td>{value.IprMesial}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                }
            </div>
        );
    }
}