import React from "react";
import styles from "../eonAccess-style/eonAccess.module.scss";

export default function TSNotFound(props) {
  const { isTSViewerFound } = props;
  return (
    <div
      className={styles.viewer_not_found}
      style={isTSViewerFound ? { display: "none" } : { display: "block" }}
    >
      <div className={styles.viewer_not_found_text}>
        Treatment plan not found
      </div>
    </div>
  );
}
