import { modules } from "./modules";
import { upper_teeth, lower_teeth } from "../newCase/init_teeth";
export const advanced_modules_data = {
  all_modules: modules,
  treatment_type: {
    type: "",
    hybrid_treatment: {},
  },
  malocclusion: {
    crossbite_btn: false,
    spacing_btn: false,
    classII_btn: false,
    classIII_btn: false,
    openbite_btn: false,
    deepbite_btn: false,
    midline_btn: false,
    crowding_btn: false,
    crossbite: {
      useElasticWhenCorrecting: "eonToDecide",
      teeth: {
        upper_teeth: Object.assign({}, upper_teeth),
        lower_teeth: Object.assign({}, lower_teeth),
      },
      treatment_Options: {
        expansion: false,
        constriction: false,
        combination: false,
        elastics: false,
      },
    },
    crowding: {
      use_extraction: "eonToDecide",
      teeth: {
        upper_teeth: Object.assign({}, upper_teeth),
        lower_teeth: Object.assign({}, lower_teeth),
      },
      treatment_Options: {
        proclination: false,
        expansion: false,
        ipr: false,
        distalisation: false
      }
    },
    spacing: {
      closeAllSpacesToggle: false,

      closeAllSpaces: {
        byRetractionOfAnteriors: false,
        byMesialisation: false,
        both: false,
      },
      partiallyClose: false,
      IPRopposing: false,
    },
    classII: {
      useElasticWhenCorrecting: "eonToDecide",
      teeth: {
        upper_teeth: Object.assign({}, upper_teeth),
        lower_teeth: Object.assign({}, lower_teeth),
      },
      extractionTwoUpperBicuspids: false,
      extractionUpperLowerBicuspids: false,
      upperArchDistalization: false,
      lowerArchMesialization: false,
      leavingMolars: false,
      maintain: false,
    },
    classIII: {
      useElasticWhenCorrecting: "eonToDecide",
      teeth: {
        upper_teeth: Object.assign({}, upper_teeth),
        lower_teeth: Object.assign({}, lower_teeth),
      },
      advanceUppers: false,
      retractLowersExtraction: false,
      retractLowersIPR: false,
      estheticAlignmentOnly: false,
      preSurgicalAlignment: false,
    },
    openbite: {
      extrudeAnterior: false,
      intrudePosterior: false,
      both: false,
    },
    deepbite: {
      intrudeUpperAnteriors: false,
      intrudeLowerAnteriors: false,
      combination: false,
      extrudePremolars: false,
    },
    midline: {
      upperMidline: {
        upperMidline: "",
        upperMidline_displacement: "",
      },
      lowerMidline: {
        lowerMidline: "",
        lowerMidline_displacement: "",
      },
      treatMidline: {
        resultMidlineAlignment: false,
        maintain: false,
        improveIPR: false,
        improveExtraction: false,
      },
    },
  },
  extraction: {
    use_extraction: "eonToDecide",
    teeth: {
      upper_teeth: Object.assign({}, upper_teeth),
      lower_teeth: Object.assign({}, lower_teeth),
    },
  },
  ipr: {
    useIpr: "eonToDecide",
    iprTiming: "",
    iprRestriction: {
      upper_teeth: Object.assign({}, upper_teeth),
      lower_teeth: Object.assign({}, lower_teeth),
    },
  },
  attachments: {
    useAttachments: "eonToDecide",
    attachmentsTiming: "",
    attachmentsRestriction: {
      upper_teeth: Object.assign({}, upper_teeth),
      lower_teeth: Object.assign({}, lower_teeth),
    },
  },
  pontics: {
    usePontics: "eonToDecide",
    teeth: {
      upper_teeth: Object.assign({}, upper_teeth),
      lower_teeth: Object.assign({}, lower_teeth),
    },
  },
  overCorrection: {
    useOverCorrection: "eonToDecide",
    teeth: {
      upper_teeth: Object.assign({}, upper_teeth),
      lower_teeth: Object.assign({}, lower_teeth),
    },

    details: "",
  },
  passiveAligners: {
    usePassiveAligners: "eonToDecide",
    add_passive_aligners_at_the_end: false,
    add_passive_aligners_at_the_beginning: false,
    details: "",
  },
  toothSizeDiscrepancy: {
    use_address_discrepancy: "eonToDecide",
    leaveSpaceAroundLaterals: false,
    leaveSpaceDistalLaterals: false,
    iprOpposing: false,
    other: false,
    other_details: "",
  },
  torqueEnhancers: {
    useTorqueEnhancers: "eonToDecide",
    teeth: {
      upper_teeth: Object.assign({}, upper_teeth),
      lower_teeth: Object.assign({}, lower_teeth),
    },

    details: "",
  },
  biteRamps: {
    useBiteRamps: "eonToDecide",
    incisors: false,
    incisors_and_canines: false,
    details: "",
  },
  archExpansion: {
    use_arch_expansion: "eonToDecide",
    increaseCaninesPremolarsMolars: false,
    increaseCaninesPremolars: false,
    increasePremolarsMolars: false,
    teeth: {
      upper_teeth: Object.assign({}, upper_teeth),
      lower_teeth: Object.assign({}, lower_teeth),
    },
  },
  elastics: {
    useElastics: false,
    teeth: {
      upper_teeth: Object.assign({}, upper_teeth),
      lower_teeth: Object.assign({}, lower_teeth),
    },
    buttons_teeth: {
      upper_teeth: Object.assign({}, upper_teeth),
      lower_teeth: Object.assign({}, lower_teeth),
    },
    cuts_teeth: {
      upper_teeth: Object.assign({}, upper_teeth),
      lower_teeth: Object.assign({}, lower_teeth),
    },
    use_buttons: false,
    use_cuts: false,
    details: "",
    type: "",
  },
};
