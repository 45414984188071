import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Row, Col } from "reactstrap";
import CustomeTeeth from "../../../../Teeth/CustomeTeeth";

import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../../redux/customeTeeth/action"
import ToggleSwitch from "../../../../../components/Switch/Switch";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Pontics extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_pontics: this.props.caseSubmission.advanced_modules.pontics
        .usePontics,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "pontics",
      data: {
        usePontics: this.state.use_pontics,
        teeth: this.props.customeTeethReducer.Case_Pontics,
      },
    });
    this.props.save();
  };


  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const { use_pontics } = this.state;

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">
{/* 
          <div>
            <div className="control-label title_active-gco text-center mb-2">
              Used pontics for this case                  </div>
            <div className="toggle-slider form-group content form-block-holder custome-toggle m-auto mt-2 ">
              <input
                type="range"
                step="50"
                onChange={(e) => {
                  this.slider(e, "use_pontics")

                  if (e?.target?.value === "0" || e?.target?.value === "50") {
                    this.props.Clear_Teeth_Data("Case_Pontics")

                  }
                }}
                value={this.sliderValue("use_pontics")}
                style={{ pointerEvents: this.props.summary ? "none" : "" }}
              />
              <Row className="align-items-center ">
                <Col xs="4" md="4" lg="4" className="text-left">
                  <label className="title_active">No</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-center">
                  <label className="title_active">EON to Decide</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-right">
                  <label className="title_active">Yes</label>
                </Col>
              </Row>
            </div>

          </div>
 */}

          <div className="auto_grid4  pb-2">
            <div className="control-label title_active-gco">
              Used pontics for this case{" "}
            </div>
            <div>
              <ToggleSwitch
                id="use-pontics"
                disabled={this.props.summary}

                checked={use_pontics}
                onChange={() => {
                  this.setState({ use_pontics: !use_pontics });
                  if(use_pontics){
                    this.props.Clear_Teeth_Data("Case_Pontics")
                  }
                }}
              />
            </div>
          </div> 

          <div className="grid_container">
            {" "}
            <div className="">
              <div className="control-label title_active-gco text-center pb-2 ">
                Placement
              </div>
              <div className="teeth_selector">
                <CustomeTeeth
                  action_key="Case_Pontics"
                  disabled={!use_pontics || use_pontics === "eonToDecide"}
                  summary={this.props.summary}
                />
              </div>
            </div>
          </div>
        </div>

        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            Save
        </Button>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Pontics);
