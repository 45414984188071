import React, { Component } from "react";
import {
  Collapse,
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Table
} from "reactstrap";
import TSViewer from "./TSViewer/TSViewer";
import ViewerDetails from "./TSViewer/ViewerDetails";
import {
  getTretmentSetupById,
  getAlignerCase,
  disApproveTreatmentSetup,
  approveTreatmentSetup,
  getViewerId,
  generateURL,
} from "../../api/api";
import { successToaster, infoToaster } from "../../services/toast";
import { caseType } from "../../helpers/caseType";
import NewViewerDetails from "./TSViewer/NewViewerDetails";
import { connect } from "react-redux";
import { setTeethIPRData, setSavedData } from "../../redux/Tooth_IPR_Options/action";
import { ipr } from "../../services/ipr_example";
import { mapTeethFDI } from "../../services/mapTeethFDI";
import { mapModules } from "../../services/mapAdvancedModules";
import { updateAllAdvancedModules } from "../../redux/CaseSubmission/action"
import { mapIPRTeethFromApi } from "../../services/mapIPRTeethFromApi";
import { iprTeethTS } from "../../services/iprTeethTS";
import RevisedTable from "./RevisedTable";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setTeethIPRData: (payload) => dispatch(setTeethIPRData(payload)),
  setSavedData: (payload) => dispatch(setSavedData(payload)),
  updateAllAdvancedModules: (payload) =>
    dispatch(updateAllAdvancedModules(payload)),
});
class TreatmentSetup extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /*init state */
    this.state = {
      collapse: false,
      collapseViewerDetails: false,
      viewer: false,
      setupDetails: props.value,
      case: props.case,
      doctorId: props.doctorId,
      details: {},
      width: window.innerWidth,
      Approve: false,
      DisApprove: false,
      RejectionReason: "",
      loading1: false,
      loading2: false,
      copyLink: false,
      data: props.data,
      viewerId: null,
      url: null,
      showBiteRecord: false,
      showToothIPROptions: false,
      mapped_advanced_modules: [],
      finish_loading_ts: false,
      showRevisedData: false, revised_data: {}
    };
    this.uid = this.props.case.uid;
  }

  /* --------------------------------------------------------------------------------*/
  /* update Dimensions after get all data ,get treatment setup by id before render   */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    this.GetTretmentSetupById();
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);

  }
  /* --------------------------------------------------------------------------------*/
  /* update Dimensions  */
  /* --------------------------------------------------------------------------------*/
  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
  };
  /* --------------------------------------------------------------------------------*/
  /* toggle ts data   */
  /* --------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };
  /* --------------------------------------------------------------------------------*/
  /* show / hide viewer modal   */
  /* --------------------------------------------------------------------------------*/
  toggleViewerModal = () => {
    this.setState({ viewer: !this.state.viewer, finish_loading_ts: false });
    this.props.updateAllAdvancedModules({});
    this.setState({ collapseViewerDetails: !this.state.collapseViewerDetails, collapse4: !this.state.collapse4 });

  };
  /* --------------------------------------------------------------------------------*/
  /* show / hide copy link modal   */
  /* --------------------------------------------------------------------------------*/
  CopyLinkModal = () => {
    this.setState({ copyLink: !this.state.copyLink });
  };
  /* --------------------------------------------------------------------------------*/
  /* show / hide viewer details modal   */
  /* --------------------------------------------------------------------------------*/
  toggleViewerDetailsModal = () => {
    this.GetTretmentSetupById();
    this.GetAlignerCase();
    this.setState({ collapseViewerDetails: !this.state.collapseViewerDetails, collapse4: !this.state.collapse4 });

  }
  UNSAFE_componentWillMount() {
    this.GetTretmentSetupById();
  }
  /*--------------------------------------------------------------------------------*/
  /* Get Treatment Setup                                                    */
  /*--------------------------------------------------------------------------------*/
  GetTretmentSetupById = () => {
    getTretmentSetupById(
      this.state.doctorId,
      this.state.case.id,
      this.state.setupDetails.id
    ).then((res) => {
      let mapped_advanced_modules = mapModules(res.advanced_modules?.advanced_modules?.all_modules)
      let data = res;
      let revised_data = res.revised_data
      data["mapped_advanced_modules"] = mapped_advanced_modules
      this.setState({ details: data, mapped_advanced_modules: mapped_advanced_modules, revised_data: revised_data });

    });
  };
  /*--------------------------------------------------------------------------------*/
  /* Get Aligner Case                                                     */
  /*--------------------------------------------------------------------------------*/
  GetAlignerCase = () => {
    getAlignerCase(this.state.doctorId, this.state.case.id).then((res) => {
      this.setState({ case: res });
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* find Reason in adjustemtn list to shoe it in ts by id                                          */
  /*--------------------------------------------------------------------------------*/
  findReason = (id) => {
    if (this.state.case.adjustments) {
      const found = this.state.case.adjustments.filter(
        (el) => el.treatment_setup_ids[0] === id
      );
      return found[0] ? found[0].reason : null;
    } else {
      return null;
    }
  };

  /*--------------------------------------------------------------------------------*/
  /* show / hide confirmation for approve treatment setup                                              */
  /*--------------------------------------------------------------------------------*/
  Approve = () => {
    this.setState({ Approve: !this.state.Approve });
  };
  /*--------------------------------------------------------------------------------*/
  /*hide show confirmantio for disapprove traetment setup                                                       */
  /*--------------------------------------------------------------------------------*/
  DisApprove = () => {
    this.setState({ DisApprove: !this.state.DisApprove });
  };
  /*--------------------------------------------------------------------------------*/
  /* Approve Treatment Setup                                                     */
  /*--------------------------------------------------------------------------------*/
  ApproveTreatmentSetup = () => {
    this.setState({ loading1: !this.state.loading1 });
    approveTreatmentSetup(
      this.props.doctorId,
      this.state.case.uid,
      this.state.details.id
    )
      .then((res) => {
        this.props.GetAlignerCase();
        this.GetTretmentSetupById();
        this.Approve();
        this.setState({ loading1: !this.state.loading1 });
        successToaster("Treatment Setup has been approved");
      })
      .catch((error) => {
        this.setState({ loading1: !this.state.loading1 });
      });
  };
  /*--------------------------------------------------------------------------------*/
  /* DisApprove Treatment Setup                                                     */
  /*--------------------------------------------------------------------------------*/
  DisApproveTreatmentSetup = () => {
    this.setState({ loading2: !this.state.loading2 });

    let data = {
      ts_rejection_reasons: this.state.RejectionReason,
    };
    disApproveTreatmentSetup(
      this.props.doctorId,
      this.state.case.uid,
      this.state.details.id,
      JSON.stringify(data)
    )
      .then((res) => {
        this.props.GetAlignerCase();
        this.GetTretmentSetupById();
        this.DisApprove();
        this.setState({ loading2: !this.state.loading2 });

        successToaster("Treatment Setup has been disapproved");
      })
      .catch((error) => {
        this.setState({ loading2: !this.state.loading2 });
      });
  };
  /* --------------------------------------------------------------------------------*/
  /* Get url link from viewer details to copy   */
  /* --------------------------------------------------------------------------------*/
  GetLink = () => {
    getViewerId(
      this.uid,
      this.state.details.id,
      this.state.details.upper_steps,
      this.state.details.lower_steps
    ).then((res) => {
      this.setState({ viewerId: res.viewer, url: generateURL(res.viewer) });

      this.CopyLinkModal();
    });
  };


  SaveDataInReducer = () => {
    let ipr = {}
    if (this.state.details?.advanced_modules?.advanced_modules?.ipr?.iprRestriction) {
      if (this.state.details?.advanced_modules?.advanced_modules?.ipr?.iprRestriction.hasOwnProperty("upper_teeth")) {
        ipr = mapIPRTeethFromApi(this.state.details?.advanced_modules?.advanced_modules?.ipr?.iprRestriction)
      } else {
        ipr = iprTeethTS()
      }
    } else {
      ipr = iprTeethTS()
    }
    this.props.setSavedData(mapTeethFDI(ipr, this.state.details?.teeth_movements_extracted || [], this.state.details?.advanced_modules?.advanced_modules || {}))
    this.props.setTeethIPRData(mapTeethFDI(ipr, this.state.details?.teeth_movements_extracted || [], this.state.details?.advanced_modules?.advanced_modules || {}))
    this.props.updateAllAdvancedModules({});

  }
  /* --------------------------------------------------------------------------------*/
  /* clear state if go out from component   */
  /* --------------------------------------------------------------------------------*/
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  ToggleBitsRecord = () => {
    this.setState({ showBiteRecord: !this.state.showBiteRecord, })
  }

  saveRejectionReason = (data) => {
    this.setState({ showBiteRecord: !this.state.showBiteRecord, showToothIPROptions: true })

  }
  ToggleOptions = () => {
    this.setState({ showToothIPROptions: !this.state.showToothIPROptions })

  }
  finishLoadingTs = () => {
    this.setState({ finish_loading_ts: true })
  }

  RevisedDataModal = () => {
    this.setState({ showRevisedData: !this.state.showRevisedData })
  }
  render() {
    // console.log(
    //   "this.props.doctorId, this.state.case.uid,",
    //   this.state.collapse,
    //   this.state
    // );
    const { revised_data } = this.state
    return (

      <div className="ts-modal">
        {/*--------------------------------------------------------------------------------*/}
        {/* revise data Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.showRevisedData}
          toggle={this.RevisedDataModal}
          className="modal_revised_data"
        >
          <ModalHeader toggle={this.RevisedDataModal}>
            Revised Data
          </ModalHeader>
          <ModalBody className=" pt-0">

            <RevisedTable revised_data={revised_data} />

          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end revise data Modal */}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* Copy Link Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.copyLink}
          toggle={this.CopyLinkModal}
          className="modal-copy-link"
        >
          <ModalHeader toggle={this.CopyLinkModal}>
            Copy Link to Share
          </ModalHeader>
          <ModalBody>
            <div className="content form-block-holder copy-grid">
              <div>
                <Input
                  autoComplete="off"
                  type="textarea"
                  rows={6}
                  className="green_input h-100"
                  defaultValue={this.state.url}
                />
              </div>
              <div>
                <Button
                  className="btn green-btn w-100"
                  size="md"
                  onClick={() => {
                    navigator.clipboard.writeText(this.state.url);
                    this.CopyLinkModal();
                    infoToaster("Link is copied ");
                  }}
                >
                  Copy
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Copy Link Modal */}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* Approve treatment setup Modal  */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.Approve}
          toggle={this.Approve}
        // className="modalx4"
        >
          <ModalHeader toggle={this.Approve}>
            Approve Treatment Setup
          </ModalHeader>
          <ModalBody>
            Patient Name: {this.state.case.patient.full_name}
            <br></br>
            Case Type:{" "}
            {caseType[this.state.case.case_type] || this.state.case.case_type}
            <br></br>
            Invoice will be based on case type
            <hr></hr>
            By approving this Treatment Setup, you hereby authorize Eon Aligner
            to fabricate the aligners as per the specifications provided by you
            in the Treatment Form and as contained in this Treatment Setup. You
            will be invoiced according to the approved case type unless the
            required treatment plan exceeds it.
          </ModalBody>
          <ModalFooter>
            <Row className=" w-100 m-1 justify-content-end">
              <Col xs="4" md="4" lg="4" className="p-2 w-100">
                <Button
                  className="btn red-btn w-100 "
                  size="md"
                  color="danger"
                  disabled={this.state.loading1}
                  onClick={() =>
                    this.setState({ Approve: !this.state.Approve })
                  }
                >
                  Cancel
                </Button>
              </Col>
              <Col xs="8" md="6" lg="6" className="p-2 w-100">
                <Button
                  className="btn green-btn w-100"
                  size="md"
                  disabled={this.state.loading1}
                  onClick={() => this.ApproveTreatmentSetup()}
                >
                  Submit Approval
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Approve treatment setup Modal  */}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* DisApprove treatment setup Modal  */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.DisApprove}
          toggle={this.DisApprove}
        // className="modalx4"
        >
          <ModalHeader toggle={this.DisApprove}>
            Disapprove Treatment Setup{" "}
          </ModalHeader>
          <ModalBody>
            Patient Name: {this.state.case.patient.full_name}
            <br></br>
            Case Type:
            {caseType[this.state.case.case_type] || this.state.case.case_type}
            <hr></hr>
            <div className="form-group content form-block-holder">
              <label className="control-label title_active ">
                Rejection Reason{" "}
              </label>
              <div>
                <Input
                  autoComplete="off"
                  type="textarea"
                  rows={3}
                  className="green_input"
                  defaultValue={this.state.RejectionReason}
                  onChange={(e) => {
                    this.setState({
                      RejectionReason: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Row className=" w-100 m-1 justify-content-end">
              <Col xs="4" md="4" lg="4" className="p-2 w-100">
                <Button
                  className="btn red-btn w-100 "
                  size="md"
                  color="danger"
                  onClick={() =>
                    this.setState({ DisApprove: !this.state.DisApprove })
                  }
                  disabled={this.state.loading2}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs="8" md="6" lg="6" className="p-2 w-100">
                <Button
                  className="btn green-btn w-100"
                  size="md"
                  disabled={this.state.loading2}
                  onClick={() => this.DisApproveTreatmentSetup()}
                >
                  Disapprove
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end DisApprove treatment setup Modal  */}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* Viewer Modal  */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.viewer}
          toggle={this.toggleViewerModal}
          className="modalx"
        >
          {this.state.width < 840 && (
            <ModalHeader
              toggle={this.toggleViewerModal}
              className="w-100 align-items-center"
            >
              <Row className="w-100">
                <Col xs="10" md="10" lg="6">
                  TS Viewer
                </Col>
                {/* <Col xs="2" md="2" lg="6" className="text-right">
                  {this.state.details.can_approve_or_disapprove && (
                    <div className="ts-mobile-grid">
                      <div className="icon-md-new2" onClick={this.Approve}>
                        <i className="far fa-check-circle green-icon"></i>
                      </div>
                      <div className="icon-md-new2" onClick={this.DisApprove}>
                        <i className="far fa-times-circle red-icon"></i>
                      </div>
                    </div>
                  )}
                </Col> */}
              </Row>
            </ModalHeader>
          )}
          <ModalBody className="p-0">

            {
              this.state.showBiteRecord ?
                <div className="row h-100">
                  <div className="col pr-0">
                    <TSViewer
                      details={this.state.details}
                      doctorId={this.props.doctorId}
                      caseId={this.props.case.uid}
                      collapse={this.state.collapse4}
                      case_data={this.state.case}
                      showBiteRecord={this.state.showBiteRecord}
                      saveRejectionReason={this.saveRejectionReason}
                      finishLoadingTs={this.finishLoadingTs}
                    />
                  </div>
                </div>
                // :
                // this.state.showToothIPROptions ? <div>
                //   <div className="row m-2">
                //     <NewViewerDetails doctorId={this.state.doctorId}
                //       data={this.props.case}
                //       details={this.state.details}
                //       props={this.state.data}
                //       GetAlignerCase={this.props.GetAlignerCase}
                //       ToggleBitsRecord={this.ToggleBitsRecord}
                //       mapped_advanced_modules={this.state.mapped_advanced_modules}
                //       showToothIPROptions={this.state.showToothIPROptions}
                //     />

                //   </div>
                // </div>
                :
                <div className="row h-100">
                  <div className="col pr-0">
                    <TSViewer
                      details={this.state.details}
                      doctorId={this.props.doctorId}
                      caseId={this.props.case.uid}
                      collapse={this.state.collapse4}
                      case_data={this.state.case}
                      showBiteRecord={this.state.showBiteRecord}
                      saveRejectionReason={this.saveRejectionReason}
                      finishLoadingTs={this.finishLoadingTs}

                    />
                  </div>
                  {!this.state.collapseViewerDetails && !this.state.showToothIPROptions && this.state.width > 839 && (
                    <div className="col-auto ">
                      <div>
                        <span
                          onClick={this.toggleViewerModal}
                          className="pull-right p-2"
                        >
                          <i className="fa fa-times icon-md2" />
                        </span>
                        <span
                          onClick={this.toggleViewerDetailsModal}
                          className="pull-left pt-2"
                        >
                          <i className="fa fa-outdent icon-md" />
                        </span>
                      </div>
                    </div>
                  )}
                  {this.state.collapseViewerDetails && this.state.width > 839 && (
                    <div className=" col-6  ">
                      {  !this.state.showToothIPROptions && <div className="row m-2">
                        <span
                          onClick={this.toggleViewerDetailsModal}
                          className="col pull-left text-left p-0"
                        >
                          <i className="fa fa-indent icon-md" />
                        </span>
                        <span
                          onClick={this.toggleViewerModal}
                          className=" col pull-right text-right p-0"
                        >
                          <i className="fa fa-times icon-md2" />
                        </span>
                      </div>}
                      <div className="row m-2">
                        <NewViewerDetails doctorId={this.state.doctorId}
                          data={this.props.case}
                          details={this.state.details}
                          props={this.state.data}
                          GetAlignerCase={this.props.GetAlignerCase}
                          ToggleBitsRecord={this.ToggleBitsRecord}
                          mapped_advanced_modules={this.state.mapped_advanced_modules}
                          showToothIPROptions={this.state.showToothIPROptions}
                          ToggleOptions={this.ToggleOptions}
                          finish_loading_ts={this.state.finish_loading_ts}
                          GetAllTs={this.props.GetTreatmentSetups}
                          GetTretmentSetupById={this.GetTretmentSetupById}
                        />

                      </div>
                    </div>
                  )}
                </div>

            }
            <div className="row h-100">
              <div className="col pr-0">
                <TSViewer
                  details={this.state.details}
                  doctorId={this.props.doctorId}
                  caseId={this.props.case.uid}
                  collapse={this.state.collapse4}
                  case_data={this.state.case}
                  showBiteRecord={this.state.showBiteRecord}
                  saveRejectionReason={this.saveRejectionReason}
                  finishLoadingTs={this.finishLoadingTs}

                />
              </div>
              {!this.state.collapseViewerDetails && this.state.width > 839 && (
                <div className="col-auto ">
                  <div>
                    <span
                      onClick={this.toggleViewerModal}
                      className="pull-right p-2"
                    >
                      <i className="fa fa-times icon-md2" />
                    </span>
                    <span
                      onClick={this.toggleViewerDetailsModal}
                      className="pull-left pt-2"
                    >
                      <i className="fa fa-outdent icon-md" />
                    </span>
                  </div>
                </div>
              )}
              {this.state.collapseViewerDetails && this.state.width > 839 && (
                <div className=" col-6  ">
                  <div className="row m-2">
                    <span
                      onClick={this.toggleViewerDetailsModal}
                      className="col pull-left text-left p-0"
                    >
                      <i className="fa fa-indent icon-md" />
                    </span>
                    <span
                      onClick={this.toggleViewerModal}
                      className=" col pull-right text-right p-0"
                    >
                      <i className="fa fa-times icon-md2" />
                    </span>
                  </div>
                  <div className="row m-2">
                    <NewViewerDetails doctorId={this.state.doctorId}
                      data={this.props.case}
                      details={this.state.details}
                      props={this.state.data}
                      GetAlignerCase={this.props.GetAlignerCase}
                      ToggleBitsRecord={this.ToggleBitsRecord}
                      mapped_advanced_modules={this.state.mapped_advanced_modules}
                      GetAllTs={this.props.GetTreatmentSetups}
                      GetTretmentSetupById={this.GetTretmentSetupById}

                      
                      />

                  </div>
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Viewer Modal   */}
        {/*--------------------------------------------------------------------------------*/}
        {this.state.details && (
          <Row>
            <Col className="treatment-setups">
              {/* ts details toggle  button */}
              <Button
                className={`w-100 m-1 ${this.state.details.state === "Inactive"
                  ? "light-gray-btn"
                  : "light-green-btn"
                  } text-left p-3`}
              //   onClick={this.toggle}
              >
                <Row className="align-items-center justify-content-center">
                  <Col xs="5" md="8" lg="8" className="ts_title">
                    {this.state.details.detailed_front_title ? (
                      this.state.details.detailed_front_title
                    ) : (
                      <div className="spinner_3"></div>
                    )}
                  </Col>
                  <Col xs="7" md="4" lg="4">
                    <Row className="align-items-center justify-content-center">
                      <Col
                        xs="7"
                        md="8"
                        lg="8"
                        className="text-center ts_viewer pl-1 pr-1"
                      >
                        <div>
                          {" "}
                          <div
                            className={`pull-right btn ${this.state.details.state === "Inactive"
                              ? "white-gray-btn"
                              : "white-btn"
                              }  ${this.state.details.detailed_front_title
                                ? ""
                                : "disabled-btn"
                              }`}
                            onClick={() => {
                              this.SaveDataInReducer()
                              this.setState({
                                viewer: true,
                              });
                            }}
                          >
                            <i className="fas fa-eye " /> 3D Viewer
                          </div>
                        </div>
                      </Col>
                      <Col xs="2" md="2" lg="2" className="text-center">
                        <span
                          className={` ${this.state.details.detailed_front_title
                            ? ""
                            : "disabled-btn"
                            } `}
                          onClick={() => this.GetLink()}
                          title="share link"
                        >
                          <i className="fas fa-share-alt icon-md2"></i>{" "}
                        </span>
                      </Col>
                      <Col xs="2" md="2" lg="2" className="text-center">
                        {" "}
                        <span
                          className={` ${this.state.details.detailed_front_title
                            ? ""
                            : "disabled-btn"
                            } `}
                          onClick={this.toggle}
                        >
                          {this.state.collapse ? (
                            <i className="fas fa-angle-up icon-md" />
                          ) : (
                            <i className="fas fa-angle-down icon-md" />
                          )}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* {this.state.details.detailed_front_title} */}
              </Button>

              <Collapse isOpen={this.state.collapse}>
                <Card className="border m-1">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col xs="12" md="4" lg="4">
                        <div>
                          <label className="control-label title_active">
                            Title
                          </label>
                          {" : "}
                          <label className="control-label ">
                            <p className="text-muted">
                              {this.state.details.front_title}
                            </p>
                          </label>
                        </div>
                        <div>
                          <label className="control-label title_active">
                            Start Together
                          </label>
                          {" : "}
                          <label className="control-label ">
                            <p className="text-muted">
                              {this.state.details.start_together + ""}
                            </p>
                          </label>
                        </div>
                        {this.state.details &&
                          this.findReason(this.state.details.id) && (
                            <Row>
                              <Col xs="12" md="12" lg="12">
                                {" "}
                                <label className="control-label title_active">
                                  Adjustment Reason
                                </label>
                                {" : "}
                              </Col>

                              <Col xs="12" md="12" lg="12">
                                {" "}
                                <div className="control-label ">
                                  <p className="text-muted">
                                    {this.findReason(this.state.details.id)}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          )}
                        <Row>
                          <Col xs="12" md="12" lg="12">
                            {" "}
                            <label className="control-label title_active">
                              Technician Notes
                            </label>
                            {" : "}
                          </Col>

                          <Col xs="12" md="12" lg="12">
                            {" "}
                            <div className="control-label ">
                              <p className="text-muted">
                                {this.state.details.technician_notes}
                              </p>
                            </div>
                          </Col>
                        </Row>

                        {this.state.details.ts_rejection_reasons && (
                          <Row>
                            <Col xs="12" md="12" lg="12">
                              {" "}
                              <label className="control-label title_active">
                                Rejection Reasons
                              </label>
                              {" : "}
                            </Col>

                            <Col xs="12" md="12" lg="12">
                              {" "}
                              <div className="control-label ">
                                <p className="text-muted">
                                  {this.state.details.ts_rejection_reasons}
                                </p>
                              </div>
                            </Col>
                          </Row>
                        )}

                        {/* <div>
                          <label className="control-label ">
                            <p className="text-muted">
                              <strong>{this.state.details.front_title}</strong>
                            </p>
                          </label>
                        </div>*/}

                        <Row className="mb-3 pt-2">
                          {this.state.details.ipr && (
                            <Col xs="12" md="12" lg="6" className="w-100 mb-2">
                              {this.state.details.ipr.match(/http([^&]+)/) ? (
                                <a
                                  href={this.state.details.ipr}
                                  target="blank"
                                  download={`${this.state.case.uid} ipr`}
                                >
                                  <Button
                                    className="btn green-btn w-100 h-100"
                                    size="md"
                                  >
                                    IPR File
                                  </Button>
                                </a>
                              ) : (
                                <Button
                                  className="btn green-btn w-100 h-100"
                                  size="md"
                                  disabled
                                >
                                  IPR File
                                </Button>
                              )}
                            </Col>
                          )}

                          {this.state.details.sps && (
                            <Col xs="12" md="12" lg="6" className="w-100 mb-2">
                              {this.state.details.sps.match(/http([^&]+)/) ? (
                                <a
                                  href={this.state.details.sps}
                                  target="blank"
                                  download={`${this.state.case.uid} sps`}
                                >
                                  <Button
                                    className="btn green-btn w-100 h-100"
                                    size="md"
                                  >
                                    SPS File
                                  </Button>
                                </a>
                              ) : (
                                <Button
                                  className="btn green-btn w-100 h-100"
                                  size="md"
                                  disabled
                                >
                                  SPS File
                                </Button>
                              )}
                            </Col>
                          )}

                          {
                            revised_data && Object.keys(revised_data).length !== 0 &&
                            <Col xs="12" md="12" lg="8" className="w-100  m-auto mb-2">
                              <Button
                                className="btn green-btn w-100 h-100"
                                size="md"
                                onClick={() => {
                                  this.setState({ showRevisedData: !this.state.showRevisedData })
                                }}
                              >
                                Show revised data
                                  </Button>
                            </Col>
                          }
                        </Row>
                      </Col>
                      <Col xs="12" md="1" lg="1"></Col>
                      <Col xs="12" md="7" lg="7">
                        <Row className="text-center">
                          {/* ts upper steps */}
                          {this.state.details.upper_steps > 0 && this.state.details.upper_steps !== null && (
                            <Col xs="12" md="6" lg="6">
                              <Card className="p-2 mb-0 mt-3">
                                <label className="control-label title_card_active">
                                  {this.state.details.upper_steps}
                                </label>

                                <div>
                                  <p className="text-muted">
                                    {" "}
                                    <strong>Upper Steps</strong>
                                  </p>
                                </div>
                              </Card>
                            </Col>
                          )}
                          {/* ts lower steps */}
                          {this.state.details.lower_steps > 0 && this.state.details.lower_steps !== null && (
                            <Col xs="12" md="6" lg="6">
                              <Card className="p-2 mb-0 mt-3">
                                <label className="control-label title_card_active">
                                  {this.state.details.lower_steps}
                                </label>

                                <div>
                                  <p className="text-muted">
                                    <strong>Lower Steps</strong>{" "}
                                  </p>
                                </div>
                              </Card>
                            </Col>
                          )}
                          {/* ts upper overcorrection steps */}
                          {this.state.details.upper_overcorrection_steps > 0 && this.state.details.upper_overcorrection_steps !== null && (
                            <Col xs="12" md="6" lg="6">
                              <Card className="p-2 mb-0 mt-3">
                                <label className="control-label title_card_active">
                                  {
                                    this.state.details
                                      .upper_overcorrection_steps
                                  }
                                </label>

                                <div>
                                  <p className="text-muted">
                                    <strong>
                                      Upper Over Correction Steps{" "}
                                    </strong>
                                  </p>
                                </div>
                              </Card>
                            </Col>
                          )}
                          {/* ts lower overcorrection steps */}
                          {this.state.details.lower_overcorrection_steps > 0 && this.state.details.lower_overcorrection_steps !== null && (
                            <Col xs="12" md="6" lg="6">
                              <Card className="p-2 mb-0 mt-3">
                                <label className="control-label title_card_active">
                                  {
                                    this.state.details
                                      .lower_overcorrection_steps
                                  }
                                </label>

                                <div>
                                  <p className="text-muted">
                                    <strong>
                                      {" "}
                                      Lower Over Correction Steps
                                    </strong>
                                  </p>
                                </div>
                              </Card>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Collapse>
            </Col>
          </Row>
        )}{" "}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TreatmentSetup);
