import { meshesForStep } from "../TS-VIEWER/Scene";

export const generateStepsForMeshes = function (lowerSteps, upperSteps) {
  const howMany = Math.max(lowerSteps, upperSteps);
  // console.log(howMany);
  //we populate the array of arrays for meshes here too
  // let i = meshesForStep.length insted of 0 to fix duplicate steps bug
  for (let i = meshesForStep.length; i < howMany; i++) {
    meshesForStep.push([]);
  }
};
