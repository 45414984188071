import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View, Image } from "@react-pdf/renderer";
import TeethPDF from "../TeethPDF";
import checked from "../../../../assets/images/checkbox/checked.png"
import disabled from "../../../../assets/images/checkbox/disabled.png"
import unchecked from "../../../../assets/images/checkbox/unchecked.png"

const styles = StyleSheet.create({
    row: {
        paddingTop: 10,
        alignItems: "flex-start",
        justifyContent: "flex-start",
        paddingBottom: 10,
    },

    header: {
        fontSize: 12,
        textAlign: "left",
        color: "#00a78c",
        width: "100%",
        marginTop: 5
    },
    container: {
        flexDirection: "row",
        justifyContent: "flex-start",
        borderBottomWidth: 1,
        borderColor: "#E6E6FA",
        paddingBottom: 15,
        // paddingTop: 10,
    },
    container2: {
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: 5,
    },
    text_area: {
        flexGrow: 1,
        borderColor: "#a0f0e7",
        borderWidth: 1,
        borderRadius: 2,
        fontSize: 8,
        padding: 2,
        margin: 3,
        minHeight: "70px",
        flexDirection: "column",
    },
    field: {
        flexDirection: "row",
        paddingTop: 10,
    },
    col: {
        flexDirection: "column",
        width: "50%",
    },
    col_4: {
        flexDirection: "column",
        width: "40%",
    },
    col_5: {
        flexDirection: "column",
        width: "50%",
    },
    col_55: {
        flexDirection: "column",
        width: "55%",
    },
    col_6: {
        flexDirection: "column",
        width: "60%",
    },
    col_1: {
        flexDirection: "column",
        width: "10%",
    },
    col_3: {
        flexDirection: "column",
        width: "30%",
    },
    col_7: {
        flexDirection: "column",
        width: "70%",
    },
    col_15: {
        flexDirection: "column",
        width: "15%",
    },
    col_25: {
        flexDirection: "column",
        width: "25%",
    },
    col_05: {
        flexDirection: "column",
        width: "5%",
    },
    title: {
        fontSize: 10,
        textAlign: "left",
        color: "#00a78c",
        padding: "10px 0 5px 0",
    },
    title2: {
        fontSize: 10,
        textAlign: "left",
        color: "#00a78c",
    },
    info: {
        fontSize: 10,
        textAlign: "left",
    },
    image: {
        width: 10,
        height: 10,        // backgroundColor: "red",
        // borderRadius: 4,
    },

    green_btn_not_active: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        borderColor: "#d7f5f2",
        borderWidth: 1,
        backgroundColor: "white",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    hybrid_green_btn_not_active: {
        width: "auto",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        borderColor: "#d7f5f2",
        borderWidth: 1,
        backgroundColor: "white",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    col_title: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
        fontSize: 8,
        padding: "0 5px 0 0",
        // margin: 1,
        flexDirection: "column",
        width: "10%",
    },
    green_btn_active: {
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00a78c",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    hybrid_green_btn_active: {
        width: "auto",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00a78c",
        borderRadius: 4,
        fontSize: 8,
        padding: 5,
        margin: 3,
        flexDirection: "column",
    },
    emplty_col: {
        width: "30%",
    },
    text_active: {
        fontSize: 8,
        color: "white",
        padding: 1,
    },
    text_not_active: {
        fontSize: 8,
        color: "#00a78c",
        padding: 1,
    },
    teeth_border: {
        borderWidth: 1,
        borderColor: "#00a78c",
    },
    text_area: {
        flexGrow: 1,
        borderColor: "#a0f0e7",
        borderWidth: 1,
        borderRadius: 2,
        fontSize: 8,
        padding: 2,
        margin: 3,
        minHeight: "50px",
        flexDirection: "column",
    },
    photo_header: {
        flexDirection: "column",
        width: "100px",
        margin: 5,
        fontSize: 8,
        color: "#00a78c",
        textAlign: "center",
    },
    value: {
        padding: "10px",
    },
    photo: {
        flexDirection: "column",
        width: "100px",
        height: "auto",
        margin: 5,
        zIndex: 99,
    },
    space: {
        flexDirection: "column",
        width: "10%",
    },
    active_card: {
        flexDirection: "column",
        width: "100px",
        height: "auto",
        margin: 5,
    },
    card: {
        flexDirection: "column",
        width: "100px",
        height: "auto",
        margin: 5,
        backgroundColor: "white",
    },
    hybrid: {
        flexDirection: "column",
        width: "90%",
        height: "auto",
        padding: "50px",
    },
    hybrid_header: {
        flexDirection: "column",
        width: "100%",
        margin: 5,
        fontSize: 8,
        color: "#00a78c",
        textAlign: "left",
        marginTop: 20
    },
    buttons_container: {
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: 5,
    },
    hybrid_subTitle: {
        fontSize: 8,
        color: "#00a78c",
        width: "30px",
        flexDirection: "column",
        margin: 5,
    },
    row_space: {
        flexDirection: "row",
        height: "10px",
    },

    row_options: {
        flexDirection: "row",
        margin: 5,
        // alignItems: "center"

    },
    col_check: {
        flexDirection: "column",
        width: "10%",
    }
    , col_option: {
        flexDirection: "column",
        width: "90%",
    },
    option_text: {
        fontSize: 8,
        color: "gray",
        textDecoration: "none",
    },
    option_sub_text: {
        marginTop: 3,
        fontSize: 7,
        color: "gray",
        textDecoration: "none",
    }
});

export default class ClassII extends Component {

    /* --------------------------------------------------------------------------------*/
    /* constructor */
    /* --------------------------------------------------------------------------------*/
    constructor(props) {
        super(props);

        this.extractionTwoUpperBicuspids =
            this.props.malocclusion?.classII
                ?.extractionTwoUpperBicuspids || false;

        this.extractionUpperLowerBicuspids =
            this.props.malocclusion?.classII
                ?.extractionUpperLowerBicuspids || false;

        this.upperArchDistalization =
            this.props.malocclusion?.classII
                ?.upperArchDistalization || false;

        this.lowerArchMesialization =
            this.props.malocclusion?.classII
                ?.lowerArchMesialization || false;

        this.leavingMolars =
            this.props.malocclusion?.classII
                ?.leavingMolars || false;

        this.maintain =
            this.props.malocclusion?.classII
                ?.maintain || false;
    }
    render() {
        const { classII } = this.props.malocclusion;

        const {
            extractionTwoUpperBicuspids,
            extractionUpperLowerBicuspids,
            upperArchDistalization,
            lowerArchMesialization,
            leavingMolars,
            maintain,
        } = this;
        return (
            <Fragment>
                <View>
                    <Text style={styles.header}>ClassII</Text>
                    <View style={styles.container}>
                        <View style={styles.col_4}>
                            <Text style={styles.hybrid_header}>{`Treatment options :`}
                            </Text>
                            <View style={styles.row_options}>
                                <View style={styles.col_check}>
                                    <Image
                                        style={styles.image}
                                        source={{
                                            uri: `${extractionTwoUpperBicuspids ? checked : maintain ||
                                                leavingMolars ||
                                                upperArchDistalization ||
                                                extractionUpperLowerBicuspids ? disabled : unchecked}`,
                                            headers: {
                                                Pragma: "no-cache",
                                                "Cache-Control": "no-cache",
                                            },
                                        }}
                                    /></View>
                                <View style={styles.col_option}>
                                    <Text style={styles.option_text}>Extraction of two upper bicuspids</Text>
                                </View>
                            </View>


                            <View style={styles.row_options}>
                                <View style={styles.col_check}>
                                    <Image
                                        style={styles.image}
                                        source={{
                                            uri: `${extractionUpperLowerBicuspids ? checked : maintain ||
                                                leavingMolars ||
                                                upperArchDistalization ||
                                                extractionTwoUpperBicuspids ? disabled : unchecked}`,
                                            headers: {
                                                Pragma: "no-cache",
                                                "Cache-Control": "no-cache",
                                            },
                                        }}
                                    /></View>
                                <View style={styles.col_option}>
                                    <Text style={styles.option_text}>Extraction of upper and lower bicuspids</Text>
                                </View>
                            </View>



                            <View style={styles.row_options}>
                                <View style={styles.col_check}>
                                    <Image
                                        style={styles.image}
                                        source={{
                                            uri: `${upperArchDistalization ? checked : maintain ||
                                                leavingMolars ||
                                                extractionUpperLowerBicuspids ||
                                                extractionTwoUpperBicuspids ? disabled : unchecked}`,
                                            headers: {
                                                Pragma: "no-cache",
                                                "Cache-Control": "no-cache",
                                            },
                                        }}
                                    /></View>
                                <View style={styles.col_option}>
                                    <Text style={styles.option_text}>Upper arch distalization</Text>
                                    <Text style={styles.option_sub_text}>
                                        sequential distalization- may increase number of steps - elastics
                                           using" , May necessitate extraction of wisdoms if present</Text>
                                </View>
                            </View>
                            <View style={styles.row_options}>
                                <View style={styles.col_check}>
                                    <Image
                                        style={styles.image}
                                        source={{
                                            uri: `${lowerArchMesialization ? checked : leavingMolars || maintain ? disabled : unchecked}`,
                                            headers: {
                                                Pragma: "no-cache",
                                                "Cache-Control": "no-cache",
                                            },
                                        }}
                                    /></View>
                                <View style={styles.col_option}>
                                    <Text style={styles.option_text}>Lower arch mesialization </Text>
                                    <Text style={styles.option_sub_text}>
                                        sequential mesialization- may increase number of steps - elastics using</Text>
                                </View>
                            </View>


                            <View style={styles.row_options}>
                                <View style={styles.col_check}>
                                    <Image
                                        style={styles.image}
                                        source={{
                                            uri: `${leavingMolars ? checked : maintain ||
                                                upperArchDistalization ||
                                                extractionUpperLowerBicuspids ||
                                                extractionTwoUpperBicuspids || lowerArchMesialization ? disabled : unchecked}`,
                                            headers: {
                                                Pragma: "no-cache",
                                                "Cache-Control": "no-cache",
                                            },
                                        }}
                                    /></View>
                                <View style={styles.col_option}>
                                    <Text style={styles.option_text}>Leaving the molars in Class II and doing posterior reproximation (IPR distal of canine to molar) as needed to improve the canine relationship. </Text>
                                </View>
                            </View>


                            <View style={styles.row_options}>
                                <View style={styles.col_check}>
                                    <Image
                                        style={styles.image}
                                        source={{
                                            uri: `${maintain ? checked : leavingMolars ||
                                                upperArchDistalization ||
                                                extractionUpperLowerBicuspids ||
                                                extractionTwoUpperBicuspids || lowerArchMesialization ? disabled : unchecked}`,
                                            headers: {
                                                Pragma: "no-cache",
                                                "Cache-Control": "no-cache",
                                            },
                                        }}
                                    /></View>
                                <View style={styles.col_option}>
                                    <Text style={styles.option_text}>Maintain Class II with esthetic anterior alignment only </Text>
                                </View>
                            </View>

                        </View>

                        <View style={styles.col_6}>
                            <Text style={styles.hybrid_header}>
                                Placement   </Text>
                            <TeethPDF teeth={classII.teeth} />
                        </View>
                    </View>


                </View>
            </Fragment>
        );
    }
}
