import React, { useState } from 'react'
import styles from "../eonAccess-style/eonAccess.module.scss";


function RejectModel(props) {
    
    const handleChange = (e) => {
        props.setDescription(e.target.value)
    }
    const handleClick = () => {
        props.setViewTextArea(true)
        props.setViewModel(!props.viewModel)
    }
   
    return (
        <div className={styles.model}>
            <div className={styles.model_content}>
            <textarea className={styles.textarea_box} autoFocus rows={25} cols={40} placeholder={"Reason for rejection"} 
                onChange={(e) => handleChange(e)}  value={props.description}></textarea>
                <div className={styles.btn_grid}>
                    <button onClick={handleClick} className={styles.submit_btn}>Save</button>
                </div>
            </div>
        </div>
    )
}

export default RejectModel