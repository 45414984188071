import {
  CASE_STEPS,
  CURRENT_STEP,
  PREV_STEPS,
  SHOW_ADVANCED,
  UPDATE_ADVANCED_MODULES,
  UPDATE_ALL_ADVANCED_MODULES,
  UPDATE_PHOTOS_XRAYS,
  UPDATE_IMPRESSIONS,
  UPDATE_PRESCRIPTION,
  UPDATE_MALOCCLUSION,
  CLEAR_MALOCCLUSION_DATA,
  CLEAR_ADVANCED_MODULES_DATA,
  CLEAR_STEPS,
  UPDATE_PATIENT_INFO
} from "../constants";

export const activateStep = (payload) => {
  return {
    type: CASE_STEPS,
    payload,
  };
};
export const prevStep = (payload) => {
  return {
    type: PREV_STEPS,
    payload,
  };
};
export const changeCurrentStep = (payload) => {
  return {
    type: CURRENT_STEP,
    payload,
  };
};

export const showAdvancedModule = (payload) => {
  return {
    type: SHOW_ADVANCED,
    payload,
  };
};
export const updatePatientInfo = (payload) => {
  return {
    type: UPDATE_PATIENT_INFO,
    payload
  }
}
export const updateAdvancedModules = (payload) => {
  return {
    type: UPDATE_ADVANCED_MODULES,
    payload,
  };
};

export const updateAllAdvancedModules = (payload) => {
  return {
    type: UPDATE_ALL_ADVANCED_MODULES,
    payload,
  };
};
export const updatePhotosXrays = (payload) => {
  return {
    type: UPDATE_PHOTOS_XRAYS,
    payload,
  };
};
export const updateImpressions = (payload) => {
  return {
    type: UPDATE_IMPRESSIONS,
    payload,
  };
};

export const updatePrescription = (payload) => {
  return {
    type: UPDATE_PRESCRIPTION,
    payload,
  };
};
export const updateMalocclusion = (payload) => {
  return {
    type: UPDATE_MALOCCLUSION,
    payload,
  };
};
export const clearMalocclusionData = (payload) => {
  return {
    type: CLEAR_MALOCCLUSION_DATA,
    payload,
  };
};
export const clearAdvancedModules = (payload) => {
  return {
    type: CLEAR_ADVANCED_MODULES_DATA,
    payload,
  };
};
export const clearSteps = (payload) => {
  return {
    type: CLEAR_STEPS,
    payload,
  };
};