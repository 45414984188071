import React, { Fragment } from "react";

/*
Treatment Options Component
Note: name, value and onChange are required for TreatmentOptions component to function. 

Usage: <TreatmentOptions name="name" value={value} onChange={value => setValue(value)}} />
*/

const TreatmentOptions = ({
    name,
    onChange,
    value,
    summary
}) => {
    const onChangeValue = (e) => {
        let value = ""
        if (e.target.value === "true") {
            value = true
        }
        else if (e.target.value === "false") {
            value = false
        } else {
            value = "eonToDecide"
        }
        onChange(value)
    }
    return (
        <Fragment   >

            {name ? (<div >
                <div className="radio-item" >
                    <input type="radio" id={`eonToDecide_${name}`} onChange={onChangeValue} checked={value === "eonToDecide"} name={name} value={"eonToDecide"} />
                    <label htmlFor={`eonToDecide_${name}`} style={{ pointerEvents: summary ? "none" : "" }}>eon to recommend</label>
                </div>
                <div className="radio-item" >
                    <input type="radio" id={`true_${name}`} onChange={onChangeValue} checked={value === true} name={name} value={"true"} />
                    <label htmlFor={`true_${name}`} style={{ pointerEvents: summary ? "none" : "" }}>Yes</label>
                </div>
                <div className="radio-item" >
                    <input type="radio" id={`false_${name}`} onChange={onChangeValue} checked={value === false} name={name} value={"false"} />
                    <label htmlFor={`false_${name}`} style={{ pointerEvents: summary ? "none" : "" }}>No</label>
                </div>
            </div>
            ) : null}
        </Fragment>
    );
};

export default TreatmentOptions;
