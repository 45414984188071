import * as yup from "yup";
export const address_schema = yup.object({
  city: yup.string().required("country is required"),
  country: yup.string().required("country is required"),
  line1: yup.string(),
  line2: yup.string(),
  state: yup.string(),
  zip: yup.string(),
  phone: yup.string(),
});
