import React from "react";
import styles from "../eonAccess-style/eonAccess.module.scss";
import {
  moveToFirstStep,
  moveBackword,
  moveForward,
  moveToLastStep,
} from "../../shared-functionalities";
let intervalId;

export default function AnimateStepsForLandScape(props) {
  const {
    tsOptions,
    setTSOptions,
    currentStep,
    setCurrentStep,
    meshesForStep,
  } = props;
  let animateStep = () => {
    let num = currentStep === meshesForStep.length - 1 ? 0 : currentStep;
    let previousStep = 0;
    intervalId = setInterval(() => {
      if (num === meshesForStep.length - 1) {
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          isAnimateStart: !prevOptions.isAnimateStart,
        }));
        num = 0;
        clearInterval(intervalId);
        return;
      }
      if (num === 0) {
        setCurrentStep((prevStep) => {
          previousStep = prevStep;
          return num;
        });
      }
      setCurrentStep((pervStep) => {
        if (pervStep !== num) {
          // console.log("chcek nummmmm ", pervStep, num);
          setTSOptions((prevOptions) => ({
            ...prevOptions,
            isAnimateStart: !prevOptions.isAnimateStart,
          }));
          clearInterval(intervalId);
          return pervStep;
        }
        num += previousStep === meshesForStep.length - 1 ? 0 : 1;
        previousStep = num === 0 ? num : num - 1;
        return num;
      });
    }, tsOptions.STEPS_PAUSE || 500);
  };
  return (
    <div
      className={[styles.play_pause_container_landScape]}
      style={
        tsOptions.loading ||
        tsOptions.isTsPrepared ||
        !tsOptions.isTSViewerFound
          ? { zIndex: "-1" }
          : { zIndex: "1" }
      }
    >
      <img
        src={
          tsOptions.isDarkMode
            ? `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/baseline_skip_previous_black.png`
            : `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/baseline_skip_previous_white.png`
        }
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          moveToFirstStep(currentStep, setCurrentStep);
        }}
        alt=""
      />
      <img
        src={
          tsOptions.isDarkMode
            ? `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/baseline_keyboard_arrow_left_black.png`
            : `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/baseline_keyboard_arrow_left_white.png`
        }
        alt=""
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          moveBackword(currentStep, setCurrentStep, meshesForStep.length - 1);
        }}
      />
      <img
        src={
          tsOptions.isDarkMode
            ? tsOptions.isAnimateStart
              ? `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/baseline_pause_black.png`
              : `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/baseline_play_arrow_black.png`
            : tsOptions.isAnimateStart
            ? `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/baseline_pause_white.png`
            : `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/baseline_play_arrow_white.png`
        }
        alt=""
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          let isAnimateStart = !tsOptions.isAnimateStart;
          if (isAnimateStart) {
            animateStep();
          } else {
            clearInterval(intervalId);
          }
          setTSOptions((prevOptions) => ({
            ...prevOptions,
            isAnimateStart: isAnimateStart,
          }));
        }}
      />
      <img
        src={
          tsOptions.isDarkMode
            ? `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/baseline_keyboard_arrow_right_black.png`
            : `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/baseline_keyboard_arrow_right_white.png`
        }
        alt=""
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          moveForward(currentStep, meshesForStep.length - 1, setCurrentStep);
        }}
      />
      <img
        src={
          tsOptions.isDarkMode
            ? `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/baseline_skip_next_black.png`
            : `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/baseline_skip_next_white.png`
        }
        alt=""
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          moveToLastStep(currentStep, meshesForStep.length - 1, setCurrentStep);
        }}
      />
    </div>
  );
}
