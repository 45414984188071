import Starterkit from "../views/sample-pages/starter-kit";
import Profile from "../views/profile";
import Searchresult from "../views/sample-pages/search-result";
import Gallery from "../views/sample-pages/gallery";
import Helperclass from "../views/sample-pages/helperclass";
import AuthRoutes from "./authroutes";

import HomePage from "../views/home";
import Cases from "../views/cases";
import CaseDetails from "../views/case-details";
import NewCase from "../views/cases/newCase";
import TSViewer from "../views/case-details/TSViewer/TSViewer";
import Notifications from "../views/home/Notifications";
import DoctorPreferences from "../views/doctor-preferences";
import CaseSubmission from "../views/case-submission";
var auths = [].concat(AuthRoutes);

var ThemeRoutes = [
  {
    navlabel: true,
    name: "Personal",
    icon: "mdi mdi-dots-horizontal",
  },
  {
    path: "/home",
    name: "Home Page",
    icon: "mdi mdi-alert",
    component: HomePage,
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "mdi mdi-alert",
    component: Notifications,
  },
  {
    path: "/cases",
    name: "cases",
    icon: "mdi mdi-alert",
    component: Cases,
  },
  {
    path: "/case/new",
    name: "New Case",
    mini: "B",
    icon: "mdi mdi-adjust",
    component: NewCase,
  },
  {
    path: "/case/:id",
    name: "Case Details",
    mini: "B",
    icon: "mdi mdi-adjust",
    component: CaseDetails,
  },
  {
    path: "/ts-viewer",
    name: "Case Details",
    mini: "B",
    icon: "mdi mdi-adjust",
    component: TSViewer,
  },

  {
    navlabel: true,
    name: "APPS",
    icon: "mdi mdi-dots-horizontal",
  },

  {
    path: "/authentication",
    name: "Authentication",
    state: "openAuthentication",
    icon: "mdi mdi-account-circle",
    child: auths,
    collapse: true,
  },
  {
    navlabel: true,
    name: "Forms",
    icon: "mdi mdi-dots-horizontal",
  },

  {
    navlabel: true,
    name: "Tables",
    icon: "mdi mdi-dots-horizontal",
  },

  {
    navlabel: true,
    name: "Extra",
    icon: "mdi mdi-dots-horizontal",
  },

  {
    path: "/profile",
    name: "Profile",
    icon: "mdi mdi-account-network",
    component: Profile,
  },
  {
    path: "/doctor-preferences",
    name: "Doctor Preferences",
    icon: "mdi mdi-account-network",
    component: DoctorPreferences,
  },
  {
    path: "/case-submission",
    name: "Case Submission",
    icon: "mdi mdi-account-network",
    component: CaseSubmission,
  },
  {
    collapse: true,
    path: "/sample-pages",
    name: "Sample Pages",
    state: "samplepages",
    icon: "mdi mdi-book-open-variant",
    child: [
      {
        path: "/sample-pages/starter-kit",
        name: "Starter Kit",

        icon: "mdi mdi-crop-free",
        component: Starterkit,
      },
      {
        path: "/sample-pages/profile",
        name: "Profile",

        icon: "mdi mdi-account-network",
        component: Profile,
      },
      {
        path: "/sample-pages/search-result",
        name: "Search Result",

        icon: "mdi mdi-search-web",
        component: Searchresult,
      },
      {
        path: "/sample-pages/gallery",
        name: "Gallery",

        icon: "mdi mdi-camera-iris",
        component: Gallery,
      },
      {
        collapse: true,
        name: "Extra Pages",
        icon: "mdi mdi-file",
        subchild: [
          {
            path: "/sample-pages/helperclass",
            name: "Helper Classes",

            icon: "mdi mdi-star",
            component: Helperclass,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    pathTo: "/home",
    name: "Home Page",
    redirect: true,
  },
];
export default ThemeRoutes;
