import React, { Component } from "react";
import { CardBody, Card, CardTitle, Row, Col } from "reactstrap";
import { getNotifications, markNotificationsAsRead } from "../../api/api";
import moment from "moment";

export default class Notifications extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      Notifications: this.props.location.state.data,
      id: this.props.location.state.id,
    };
  }
  /* --------------------------------------------------------------------------------*/
  /* componentDidMount */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    getNotifications(this.state.id).then((res) => {
      let FilteredNotification = res.action_items.filter((key, index) => {
        return key.actionable_type !== "RestorativeCase";
      });
      FilteredNotification.sort((a, b) => (new Date(a) < new Date(b) ? 1 : -1));
      this.setState({
        Notifications: FilteredNotification,
      });
    });
  }
  /*--------------------------------------------------------------------------------*/
  /*KeyDictionary                                                            */
  /*--------------------------------------------------------------------------------*/
  KeyDictionary(key) {
    return key.split(".")[1].split("_").join(" ");
  }
  /*--------------------------------------------------------------------------------*/
  /*MarkNotificationsAsRead                                                         */
  /*--------------------------------------------------------------------------------*/
  MarkNotificationsAsRead() {
    markNotificationsAsRead(this.state.id).then((res) => {
      this.setState({
        Notifications: [],
      });
    });
  }
  /*--------------------------------------------------------------------------------*/
  /*componentWillUnmount                                                         */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    return (
      <div>
        <style>
          {`
              .fa-trash-alt{
                color:red;
                cursor:pointer;
                font-size:18px
              },
            
              `}
        </style>
        <Card>
          <CardTitle className="mb-0 p-3 border-bottom">
            <span>Notifications ({this.state.Notifications.length})</span>{" "}
            <span
              className="pull-right"
              title="Mark All Notifications As Read"
              onClick={() => this.MarkNotificationsAsRead()}
            >
              <i className="fas fa-trash-alt " />
            </span>
          </CardTitle>
          <CardBody className="notifications-card">
            {this.state.Notifications.map((notification, key) => {
              return (
                <div
                  key={key}
                  onClick={() => {
                    this.props.history.push(`/case/${notification.case}`);
                  }}
                  className="notification_list"
                >
                  <Row className="align-items-center p-1">
                    <Col className="text-left control-label title_active">
                      {`${notification.case_uid} -    ${notification.patient?.first_name} ${notification.patient?.middle_name} ${notification.patient?.last_name}`}
                    </Col>
                  </Row>
                  <Row className="align-items-center p-1 mt-2">
                    <Col className="text-left">
                      {this.KeyDictionary(notification.key)}
                    </Col>
                    <Col className="text-right">
                      {moment(new Date(notification.created_at)).format(
                        "DD MMM YYYY"
                      )}
                    </Col>
                  </Row>
                </div>
              );
            })}
          </CardBody>
        </Card>
      </div>
    );
  }
}
