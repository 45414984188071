import React, { Component } from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";
import { history } from "../../helpers/history";
import { ExportCSV } from "./ExportCSV";
export default class History extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /*init state */
    this.state = {
      History: props.CaseDetails.history,
    };
  }
  render() {
    let data = this.props.data.currentCase.state.history.map((prop, key) => {
      return {
        "Created At": moment(new Date(prop.created_at)).format("YYYY-MM-DD"),
        Comment: `${history[prop.key]} by ${
          prop.done_by ? prop.done_by : "Doctor "
        } `,
      };
    });
    return (
      <div className="m-3 mt-5 ">
        <Row>
          <Col xs="12" md="8" lg="8">
            <label className="control-label title_active ">History</label>
          </Col>
          {/* <Col xs="12" md="4" lg="4">
            <ExportCSV
              csvData={data}
              fileName={`${this.props.CaseDetails.uid}_History`}
            />
          </Col> */}
        </Row>

        <br />
        <br />

        {/*--------------------------------------------------------------------------------*/}
        {/* History Table*/}
        {/*--------------------------------------------------------------------------------*/}
        {this.props.data.currentCase.state.history.map((prop, key) => {
          return (
            <div key={key}>
              <Row key={key} className="align-items-center">
                <Col className="text-left" xs="5" md="2" lg="2">
                  {moment(new Date(prop.created_at)).format("YYYY-MM-DD")}
                </Col>
                <Col className="text-left" xs="7" md="10" lg="10">
                  {`${history[prop.key]} by ${
                    prop.done_by ? prop.done_by : "Doctor "
                  } `}
                </Col>
              </Row>
              <hr></hr>
            </div>
          );
        })}
        {/*--------------------------------------------------------------------------------*/}
        {/* End [History Table*/}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}
