import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input, Row, Col } from "reactstrap";
import ToggleSwitch from "../../../../components/Switch/Switch";
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
});
class BiteRamps extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_bite_ramps: this.props.caseSubmission.advanced_modules.biteRamps
        .useBiteRamps,
      incisors: this.props.caseSubmission.advanced_modules.biteRamps.incisors,
      incisors_and_canines: this.props.caseSubmission.advanced_modules.biteRamps
        .incisors_and_canines,
      bite_ramps_details: this.props.caseSubmission.advanced_modules.biteRamps
        .details,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "biteRamps",
      data: {
        useBiteRamps: this.state.use_bite_ramps,
        incisors: this.state.incisors,
        incisors_and_canines: this.state.incisors_and_canines,
        details: this.state.bite_ramps_details,
      },
    });
    this.props.save();
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }

  clearState = () => {
    this.setState({
      incisors: false,
      incisors_and_canines: false,
      bite_ramps_details: ""
    })
  }
  render() {
    const {
      use_bite_ramps,
      incisors,
      incisors_and_canines,
      bite_ramps_details,
    } = this.state;

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">

          <div className="text-center">
            <div className="control-label title_active-gco text-center mb-2">
              Use bite ramps for this case
            </div>
            <TreatmentOptions value={use_bite_ramps} name="use_bite_ramps" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_bite_ramps")
              if (value !== true) {
                this.clearState()
              }
            }} />
          </div>
          <div className="address_discrepancy">
            <div></div>
            <Button
              onClick={() => {
                this.setState({
                  incisors: true,
                  incisors_and_canines: false,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${incisors ? "active_discrepancy-btn" : "discrepancy-btn"
                } `}
              disabled={!use_bite_ramps || use_bite_ramps === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              Incisors
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  incisors: false,
                  incisors_and_canines: true,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${incisors_and_canines
                ? "active_discrepancy-btn"
                : "discrepancy-btn"
                } `}
              disabled={!use_bite_ramps || use_bite_ramps === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              Incisors and canines
            </Button>
            <div></div>
          </div>

          <div>
            <Input
              className="teeth_selector"
              name="bite_ramps_details"
              id="bite_ramps_details"
              type="textarea"
              placeholder="Please add details"
              value={bite_ramps_details}
              rows={5}
              onChange={(e) => {
                this.setState({ bite_ramps_details: e.target.value });
              }}
              disabled={!use_bite_ramps || use_bite_ramps === "eonToDecide" || this.props.summary}
            />{" "}
          </div>
        </div>

        { !this.props.summary && <Button
          className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
          size="sm"
          type="button"
          onClick={() => this.UpdateAdvancedModules()}
        >
          Save
        </Button>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BiteRamps);
