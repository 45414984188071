import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input, Row, Col } from "reactstrap";
import CustomeTeeth from "../../../Teeth/CustomeTeeth";
import ToggleSwitch from "../../../../components/Switch/Switch";
import { mapTeethToApi } from "../../../../services/mapTeethToApi";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Overcorrection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useOverCorrection: this.props.caseSubmission.advanced_modules
        .overCorrection.useOverCorrection,
      overCorrection_details: this.props.caseSubmission.advanced_modules
        .overCorrection.details,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "overCorrection",
      data: {
        useOverCorrection: this.state.useOverCorrection,
        teeth:
          this.props.customeTeethReducer.Case_Overcorrections
        ,
        details: this.state.overCorrection_details,
      },
    });
    this.props.save();
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/

  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  render() {
    const { useOverCorrection, overCorrection_details } = this.state;
    return (
      <div>

        <div className="text-center">
          <div className="control-label title_active-gco text-center mb-2">
            Use Overcorrection for this case
            </div>

          <TreatmentOptions value={useOverCorrection} name="useOverCorrection" summary={this.props.summary} onChange={(value) => {
            this.handelTreatmentOptionsChanges(value, "useOverCorrection")
            if (value !== true) {
              this.setState({
                overCorrection_details: ""
              })
              this.props.Clear_Teeth_Data("Case_Overcorrections")
            }
          }} />
        </div>


        <div>
          <div className="control-label title_active-gco text-center pb-2 mt-2 ">
            Placement
              </div>
          <div className="teeth_selector"> <CustomeTeeth
            action_key="Case_Overcorrections"
            disabled={!useOverCorrection || useOverCorrection === "eonToDecide"}
            summary={this.props.summary}
          /></div>
        </div>


        <div>
          {" "}
          <Input
            className="mt-2 teeth_selector"
            name="overCorrection_details"
            id="overCorrection_details"
            type="textarea"
            placeholder="Please add details"
            rows={5}
            value={overCorrection_details}
            onChange={(e) => {
              this.setState({ overCorrection_details: e.target.value });
            }}
            disabled={!useOverCorrection || useOverCorrection === "eonToDecide" || this.props.summary}
          />{" "}
        </div>
        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            Save
        </Button>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Overcorrection);
