import front_smiling from "../assets/images/photos/new_front_smiling.png";
import front_pose from "../assets/images/photos/new_front_posing.png";
import profile from "../assets/images/photos/new_side.png";

export const Photos = {
  front_smiling: front_smiling,
  front_pose: front_pose,
  profile: profile,
  panoramic_xray: `https://sandbox.access.eonaligner.com/assets/panoramic-xray-f36c05dc286e42b86873b33354d3af20c41b8aeb2ddde6e26755187a619f3174.jpg`,

  upper_occlusal: `https://sandbox.access.eonaligner.com/assets/upper_occlusal-0a36811d5ff03557d250aef5992d608e3e86e608a9139baa6f5a3d75ea423b1a.png`,
  lower_occlusal: `https://sandbox.access.eonaligner.com/assets/lower_occlusal-62eab7310407931bc084dd262b0984d979c39d3bad35eaf32b473574b0e27193.png`,
  left_buccal: `https://sandbox.access.eonaligner.com/assets/left_buccal-3f38360956e9f6ea1e8cd8201c7be711988bc6dcb7eab22ca239b87c41dba4a7.png`,

  cephalometric_xray: `https://sandbox.access.eonaligner.com/assets/cephalometric-xray-e9fb803c4d1929288c953447c89cc6e59b120d155dfab2dea936bb02dc91641b.jpg`,

  frontal: `https://sandbox.access.eonaligner.com/assets/frontal-96de170d4720e7d5630d14a55839356c2279d54ad69a9ad00026bdf9df10cdb7.png`,
  space: "",

  right_buccal: `https://sandbox.access.eonaligner.com/assets/right_buccal-00932b39e06fc508f1356955bce568a3576b7481f4becd77fe67491bcb664b81.png`,
};

export const Xrays = {
  panoramic_xray: `https://sandbox.access.eonaligner.com/assets/panoramic-xray-f36c05dc286e42b86873b33354d3af20c41b8aeb2ddde6e26755187a619f3174.jpg`,

  cephalometric_xray: `https://sandbox.access.eonaligner.com/assets/cephalometric-xray-e9fb803c4d1929288c953447c89cc6e59b120d155dfab2dea936bb02dc91641b.jpg`,
};

export const PhotosXraysData = [
  {
    img: front_smiling,
    title: "Front Smiling",
    key: "front_smiling",
    uploadKey: "UploadFrontSmiligPhoto",
  },

  {
    img: front_pose,
    title: "Front Pose",
    key: "front_pose",
    uploadKey: "UploadFrontPosePhoto",
  },
  {
    img: profile,
    title: "Side Profile",
    key: "profile",
    uploadKey: "UploadProfilePhoto",
  },
  {
    img: "",
    title: "",
    key: "",
  },
  {
    img: `https://sandbox.access.eonaligner.com/assets/panoramic-xray-f36c05dc286e42b86873b33354d3af20c41b8aeb2ddde6e26755187a619f3174.jpg`,
    title: "Panoramic Xray",
    key: "panoramic_xray",
    uploadKey: "UploadPanoramicPhoto",
  },
  {
    img: `https://sandbox.access.eonaligner.com/assets/upper_occlusal-0a36811d5ff03557d250aef5992d608e3e86e608a9139baa6f5a3d75ea423b1a.png`,
    title: "Upper Occlusal",
    key: "upper_occlusal",
    uploadKey: "UploadUpperOcclusalPhoto",
  },
  {
    img: `https://sandbox.access.eonaligner.com/assets/frontal-96de170d4720e7d5630d14a55839356c2279d54ad69a9ad00026bdf9df10cdb7.png`,
    title: "Frontal",
    key: "frontal",
    uploadKey: "UploadFrontalPhoto",
  },
  {
    img: `https://sandbox.access.eonaligner.com/assets/lower_occlusal-62eab7310407931bc084dd262b0984d979c39d3bad35eaf32b473574b0e27193.png`,
    title: " Lower Occlusal",
    key: "lower_occlusal",
    uploadKey: "UploadLowerOcclusalPhoto",
  },
  {
    img: "",
    title: "",
    key: "",
  },
  {
    img: `https://sandbox.access.eonaligner.com/assets/cephalometric-xray-e9fb803c4d1929288c953447c89cc6e59b120d155dfab2dea936bb02dc91641b.jpg`,
    title: "Cephalometric Xray",
    key: "cephalometric_xray",
    uploadKey: "UploadCephalometricPhoto",
  },
  {
    img: `https://sandbox.access.eonaligner.com/assets/right_buccal-00932b39e06fc508f1356955bce568a3576b7481f4becd77fe67491bcb664b81.png`,
    title: "Right Buccal",
    key: "right_buccal",
    uploadKey: "UploadRightBuccalPhoto",
  },
  {
    img: "",
    title: "",
    key: "",
  },
  {
    img: `https://sandbox.access.eonaligner.com/assets/left_buccal-3f38360956e9f6ea1e8cd8201c7be711988bc6dcb7eab22ca239b87c41dba4a7.png`,
    title: "Left Buccal",
    key: "left_buccal",
    uploadKey: "UploadLeftBuccalPhoto",
  },
  {
    img: "",
    title: "",
    key: "",
  },
  {
    img: "",
    title: "",
    key: "",
  },
];

export const PhotosData = [
  {
    img: front_smiling,
    title: "Front Smiling",
    key: "front_smiling",
    uploadKey: "UploadFrontSmiligPhoto",
  },

  {
    img: front_pose,
    title: "Front Pose",
    key: "front_pose",
    uploadKey: "UploadFrontPosePhoto",
  },
  {
    img: profile,
    title: "Profile",
    key: "profile",
    uploadKey: "UploadProfilePhoto",
  }
  ,

  {
    img: `https://sandbox.access.eonaligner.com/assets/upper_occlusal-0a36811d5ff03557d250aef5992d608e3e86e608a9139baa6f5a3d75ea423b1a.png`,
    title: "Upper Occlusal",
    key: "upper_occlusal",
    uploadKey: "UploadUpperOcclusalPhoto",
  },
  {
    img: `https://sandbox.access.eonaligner.com/assets/frontal-96de170d4720e7d5630d14a55839356c2279d54ad69a9ad00026bdf9df10cdb7.png`,
    title: "Frontal",
    key: "frontal",
    uploadKey: "UploadFrontalPhoto",
  },
  {
    img: `https://sandbox.access.eonaligner.com/assets/lower_occlusal-62eab7310407931bc084dd262b0984d979c39d3bad35eaf32b473574b0e27193.png`,
    title: " Lower Occlusal",
    key: "lower_occlusal",
    uploadKey: "UploadLowerOcclusalPhoto",
  },


  {
    img: `https://sandbox.access.eonaligner.com/assets/right_buccal-00932b39e06fc508f1356955bce568a3576b7481f4becd77fe67491bcb664b81.png`,
    title: "Right Buccal",
    key: "right_buccal",
    uploadKey: "UploadRightBuccalPhoto",
  },
  {
    img: "",
    title: "",
    key: "",
  },
  {
    img: `https://sandbox.access.eonaligner.com/assets/left_buccal-3f38360956e9f6ea1e8cd8201c7be711988bc6dcb7eab22ca239b87c41dba4a7.png`,
    title: "Left Buccal",
    key: "left_buccal",
    uploadKey: "UploadLeftBuccalPhoto",
  },

];
export const XraysData = [

  {
    img: `https://sandbox.access.eonaligner.com/assets/panoramic-xray-f36c05dc286e42b86873b33354d3af20c41b8aeb2ddde6e26755187a619f3174.jpg`,
    title: "Panoramic Xray",
    key: "panoramic_xray",
    uploadKey: "UploadPanoramicPhoto",
  },


  {
    img: `https://sandbox.access.eonaligner.com/assets/cephalometric-xray-e9fb803c4d1929288c953447c89cc6e59b120d155dfab2dea936bb02dc91641b.jpg`,
    title: "Cephalometric Xray",
    key: "cephalometric_xray",
    uploadKey: "UploadCephalometricPhoto",
  },

];