import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input, Row, Col } from "reactstrap";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import ToggleSwitch from "../../../../../components/Switch/Switch";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
});
class PassiveAligner extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_passive_aligners: this.props.caseSubmission.advanced_modules
        .passiveAligners.usePassiveAligners,
      passive_aligners_details: this.props.caseSubmission.advanced_modules
        .passiveAligners.details,
      use_arch_upper: this.props.caseSubmission.advanced_modules
        .passiveAligners.use_arch_upper,
      use_arch_lower: this.props.caseSubmission.advanced_modules
        .passiveAligners.use_arch_lower,
      num_upper_passive_aligner: this.props.caseSubmission.advanced_modules
        .passiveAligners.num_upper_passive_aligner,
      num_lower_passive_aligner: this.props.caseSubmission.advanced_modules
        .passiveAligners.num_lower_passive_aligner,
      synchronize: this.props.caseSubmission.advanced_modules
        .passiveAligners.synchronize
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "passiveAligners",
      data: {
        usePassiveAligners: this.state.use_passive_aligners,
        use_arch_upper: this.state.use_arch_upper,
        use_arch_lower: this.state.use_arch_lower,
        num_upper_passive_aligner: this.state.num_upper_passive_aligner,
        num_lower_passive_aligner: this.state.num_lower_passive_aligner,
        synchronize: this.state.synchronize,
        details: this.state.passive_aligners_details,
      },
    });
    this.props.save();
  };

  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const { use_passive_aligners,
      passive_aligners_details,
      use_arch_upper,
      use_arch_lower,
      num_upper_passive_aligner,
      num_lower_passive_aligner,
      synchronize } = this.state;

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">

          {/* <div>
            <div className="control-label title_active-gco text-center mb-2">
              Used Passive aligners for this case{" "}
            </div>
            <div className="toggle-slider form-group content form-block-holder custome-toggle m-auto mt-2 ">
              <input
                type="range"
                step="50"
                onChange={(e) => {
                  this.slider(e, "use_passive_aligners")

                  if (e?.target?.value === "0" || e?.target?.value === "50") {
                    this.setState({
                      passive_aligners_details: "",
                      use_arch_upper: false,
                      use_arch_lower: false,
                      num_upper_passive_aligner: "",
                      num_lower_passive_aligner: "",
                      synchronize: ""
                    })

                  }
                }}
                value={this.sliderValue("use_passive_aligners")}
                style={{ pointerEvents: this.props.summary ? "none" : "" }}
              />
              <Row className="align-items-center ">
                <Col xs="4" md="4" lg="4" className="text-left">
                  <label className="title_active">No</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-center">
                  <label className="title_active">EON to Decide</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-right">
                  <label className="title_active">Yes</label>
                </Col>
              </Row>
            </div>

          </div>
 */}

          <div className="auto_grid4  pb-2">
            <div className="control-label title_active-gco">
              Used Passive aligners for this case{" "}
            </div>
            <div>
              <ToggleSwitch
                id="use-pontics"
                disabled={this.props.summary}

                checked={use_passive_aligners}
                onChange={() => {
                  this.setState({
                    use_passive_aligners: !use_passive_aligners,
                    ...use_passive_aligners && {
                      passive_aligners_details: "",
                      use_arch_upper: false,
                      use_arch_lower: false,
                      num_upper_passive_aligner: "",
                      num_lower_passive_aligner: "",
                      synchronize: ""
                    }
                  });
                }}
              />
            </div>
          </div>

          {/* <div>
            {" "}
            <Input
              className="teeth_selector"
              name="passive_aligners_details"
              id="passive_aligners_details"
              type="textarea"
              placeholder="Please add details"
              rows={5}
              value={passive_aligners_details}
              onChange={(e) => {
                this.setState({ passive_aligners_details: e.target.value });
              }}
              disabled={!use_passive_aligners || use_passive_aligners === "eonToDecide" || this.props.summary}
            />{" "}
          </div> */}

          <div className="passive-aligner-grid">
            <div>
              <div className="control-label title_active-gco  mb-2">
                Arch{" "}
              </div>
              <div className="arch_passive_num mb-2">    <Button
                onClick={() => {
                  this.setState({
                    use_arch_upper: !use_arch_upper,
                    ...use_arch_upper && {
                      num_upper_passive_aligner: "",
                    }
                  });
                }}
                className={`btn btn-radius text-center w-8 custom_shadow ${use_arch_upper ? "active_discrepancy-btn" : "discrepancy-btn"
                  } `}
                disabled={!use_passive_aligners || use_passive_aligners === "eonToDecide"}
                style={{ pointerEvents: this.props.summary ? "none" : "" }}

              >
                Upper
            </Button>
                <div className="control-label title_active-gco custom-label">
                  Number of passive aligners
                </div>
                <div className="form-material">
                  <Input
                    type="number"
                    placeholder=""
                    className="custom-number"
                    value={num_upper_passive_aligner
                    }
                    onClick={e => {
                      e.target.focus();
                    }}
                    onMouseUp={e => {
                      e.target.blur();
                    }}

                    min="1"
                    onChange={(e) => {
                      this.setState({
                        num_upper_passive_aligner: e.target.value,
                      });
                    }}
                    disabled={!use_passive_aligners || use_passive_aligners === "eonToDecide" || this.props.summary || !use_arch_upper}

                  />
                </div>
              </div>

              <div className="arch_passive_num mb-2">     <Button
                onClick={() => {
                  this.setState({
                    use_arch_lower: !use_arch_lower,
                    ...use_arch_lower && {
                      num_lower_passive_aligner: "",
                    }
                  });
                }}
                className={`btn btn-radius text-center w-8 custom_shadow ${use_arch_lower ? "active_discrepancy-btn" : "discrepancy-btn"
                  } `}
                disabled={!use_passive_aligners || use_passive_aligners === "eonToDecide"}
                style={{ pointerEvents: this.props.summary ? "none" : "" }}

              >
                Lower
            </Button>
                <div className="control-label title_active-gco custom-label">
                  Number of passive aligners
                </div>
                <div className="form-material">
                  <Input
                    type="number"
                    placeholder=""
                    className="custom-number"
                    value={num_lower_passive_aligner
                    }
                    onClick={e => {
                      e.target.focus();
                    }}
                    onMouseUp={e => {
                      e.target.blur();
                    }}
                    onChange={(e) => {
                      this.setState({
                        num_lower_passive_aligner: e.target.value,
                      });
                    }}
                    disabled={!use_passive_aligners || use_passive_aligners === "eonToDecide" || this.props.summary || use_arch_lower}

                  />
                </div>
              </div>

            </div>
            <div>
              <div className="control-label title_active-gco  mb-2">
                Synchronize
              </div>

              <Button
                onClick={() => {
                  this.setState({
                    synchronize: "start"
                  });
                }}
                className={`btn btn-radius text-center mr-2 custom_shadow ${synchronize === "start" ? "active_discrepancy-btn" : "discrepancy-btn"
                  } `}
                disabled={!use_passive_aligners || use_passive_aligners === "eonToDecide"}
                style={{ pointerEvents: this.props.summary ? "none" : "" }}

              >
                Start Together
            </Button>
              <Button
                onClick={() => {
                  this.setState({
                    synchronize: "end"
                  });
                }}
                className={`btn btn-radius text-center mr-2  custom_shadow ${synchronize === "end" ? "active_discrepancy-btn" : "discrepancy-btn"
                  } `}
                disabled={!use_passive_aligners || use_passive_aligners === "eonToDecide"}
                style={{ pointerEvents: this.props.summary ? "none" : "" }}

              >
                End Together
            </Button>
            </div>
          </div>
        </div>

        {!this.props.summary && <Button
          className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
          size="sm"
          type="button"
          onClick={() => this.UpdateAdvancedModules()}
        >
          Save
        </Button>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PassiveAligner);
