//1
import malocclusion from "../../assets/images/caseSubmission/malocclusion.svg";
import malocclusion_active from "../../assets/images/caseSubmission/malocclusion_active.svg";
//2
import extraction from "../../assets/images/caseSubmission/extraction.svg";
import extraction_active from "../../assets/images/caseSubmission/extraction_active.svg";
//3
import ipr from "../../assets/images/caseSubmission/IPR.svg";
import ipr_active from "../../assets/images/caseSubmission/ipr_active.svg";
//4
import attachments from "../../assets/images/caseSubmission/attachments.svg";
import attachments_active from "../../assets/images/caseSubmission/attachments_active.svg";
//5
import pontics from "../../assets/images/caseSubmission/pontics.svg";
import pontics_active from "../../assets/images/caseSubmission/pontics_active.svg";
//6
import overCorrection from "../../assets/images/caseSubmission/overCorrection.svg";
import overCorrection_active from "../../assets/images/caseSubmission/overCorrection_active.svg";
//7
import passiveAligners from "../../assets/images/caseSubmission/passiveAligners.svg";
import passiveAligners_active from "../../assets/images/caseSubmission/passiveAligners_active.svg";
//8
import toothSizeDiscrepancy from "../../assets/images/caseSubmission/toothSizeDiscrepancy.svg";
import toothSizeDiscrepancy_active from "../../assets/images/caseSubmission/toothSizeDiscrepancy_active.svg";
//9
import torqueEnhancers from "../../assets/images/caseSubmission/torqueEnhancers.svg";
import torqueEnhancers_active from "../../assets/images/caseSubmission/torqueEnhancers_active.svg";
//10
import biteRamps from "../../assets/images/caseSubmission/biteRamps.svg";
import biteRamps_active from "../../assets/images/caseSubmission/biteRamps_active.svg";
//11
import archExpansion from "../../assets/images/caseSubmission/archExpansion.svg";
import archExpansion_active from "../../assets/images/caseSubmission/archExpansion_active.svg";
//12
import elastics from "../../assets/images/caseSubmission/elastics.svg";
import elastics_active from "../../assets/images/caseSubmission/elastics_active.svg";
//import { upper_teeth, lower_teeth } from "../newCase/init_teeth";

export const modules = [
  {
    id: 1,
    key: "malocclusion",
    title: "Malocclusion",
    img: malocclusion,
    active_img: malocclusion_active,
    active: false,
  },
  {
    id: 2,
    key: "extraction",
    title: "Extraction",
    img: extraction,
    active_img: extraction_active,
    active: false,
  },
  {
    id: 3,
    key: "ipr",
    title: "Inter Proximal Reduction",
    img: ipr,
    active_img: ipr_active,
    active: false,
  },
  {
    id: 4,
    key: "attachments",
    title: "Attachments",
    img: attachments,
    active_img: attachments_active,
    active: false,
  },
  {
    id: 5,
    key: "pontics",
    title: "Pontics",
    img: pontics,
    active_img: pontics_active,
    active: false,
  },
  {
    id: 6,
    key: "overCorrection",
    title: "Overcorrection",
    img: overCorrection,
    active_img: overCorrection_active,
    active: false,
  },
  {
    id: 7,
    key: "passiveAligners",
    title: "Passive Aligner",
    img: passiveAligners,
    active_img: passiveAligners_active,
    active: false,
  },
  {
    id: 8,
    key: "toothSizeDiscrepancy",
    title: "Tooth size Discrepancy",
    img: toothSizeDiscrepancy,
    active_img: toothSizeDiscrepancy_active,
    active: false,
  },
  {
    id: 9,
    key: "torqueEnhancers",
    title: "Torque Enhancers",
    img: torqueEnhancers,
    active_img: torqueEnhancers_active,
    active: false,
  },

  {
    id: 10,
    key: "biteRamps",
    title: "Bite Ramps",
    img: biteRamps,
    active_img: biteRamps_active,
    active: false,
  },
  {
    id: 11,
    key: "archExpansion",
    title: "Arch expansion",
    img: archExpansion,
    active_img: archExpansion_active,
    active: false,
  },
  {
    id: 12,
    key: "elastics",
    title: "Elastics",
    img: elastics,
    active_img: elastics_active,
    active: false,
  },
];
