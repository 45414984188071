import { CURRENT_CASE } from "../constants/";
const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CURRENT_CASE:
      return {
        state: action.payload,
      };

    default:
      return state;
  }
};
