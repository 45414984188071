import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input, Row, Col } from "reactstrap";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../../redux/customeTeeth/action"
import ToggleSwitch from "../../../../../components/Switch/Switch";
import CustomeTeeth3 from "../../../../Teeth/CustomeTeeth3";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class IPR extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_ipr: this.props.caseSubmission.advanced_modules.ipr.useIpr,
      ipr_timing_1: false,
      ipr_timing_3: false,
      iprTiming: this.props.caseSubmission.advanced_modules.ipr.iprTiming,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "ipr",
      data: {
        useIpr: this.state.use_ipr,
        iprTiming: this.state.iprTiming,
        iprRestriction: this.props.customeTeethReducer.Case_IPR,
      },
    });
    this.props.save();
  };

  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const { use_ipr, iprTiming } = this.state;
    return (
      <div>
        <div className="form-group content form-block-holder grid_container mb-6 ">


          <div className="auto_grid4  pb-2">
            <div className="control-label title_active-gco">
              Use IPR for this case{" "}
            </div>
            <div>
              <ToggleSwitch
                id="use-ipr"
                disabled={this.props.summary}

                checked={use_ipr}
                onChange={() => {
                  this.setState({
                    use_ipr: !use_ipr,
                    ...use_ipr && {
                      iprTiming: ""
                    }

                  });
                  if (use_ipr) {
                    this.props.Clear_Teeth_Data("Case_IPR")
                  }
                }}
              />
            </div>
          </div>
          <div className="example">
            <div className="example-container">
              <div className="control-label title_active-gco pl-4 text-center">
                Example
            </div>
              <div></div><div></div>
            </div>


            <div className="example-container">
              <div>
                <div className="example-text">IPR amount</div>
                <div className="example-text">Before this step</div>
              </div>
              <div>
                <div className=" bb"></div>
                <div className=" bb mt-3"></div>
              </div>
              <div className="num-container">
                <div className="form-material text-center">
                  <div>
                    <Input
                      type="number"
                      placeholder=""
                      className="custom-number-ipr"
                      min="0"
                      step="1"
                      value={0.1}
                      disabled={true}
                    />
                  </div>

                  <div>
                    <Input
                      type="number"
                      placeholder=""
                      className="custom-number-ipr"
                      min="0"
                      step="1"
                      value={1}
                      disabled={true}

                    />
                  </div>
                </div>


              </div></div>

            <div className="text-center pt-2">
              <span className="do-not-perform-ipr">{String.fromCharCode(8212)} </span>  This symbol means do not perform IPR on this tooth.
              </div>
          </div>
          <div className="grid_container">
            {" "}
            <div className="">
              <div className="control-label title_active-gco text-center mb-6 ">
                Placement
              </div>
              <div className="teeth_selector">
                <CustomeTeeth3 action_key="Case_IPR" disabled={!use_ipr || use_ipr === "eonToDecide" || this.props.summary}
                  summary={this.props.summary}
                />
              </div>
            </div>
          </div>
        </div>

        {!this.props.summary && <Button
          className={`btn mt-6 ${"preference-btn"}  float-right text-center  btn-radius`}
          size="sm"
          type="button"
          onClick={() => {
            this.UpdateAdvancedModules();
          }}
        >
          Save
        </Button>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(IPR);
