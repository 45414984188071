import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { updateAlignerCase } from "../../../api/api";
import { uploadFile, keyToUrl } from "../../../helpers/s3";
import { FillXrays } from "../../../services/fillForm";
import { infoToaster } from "../../../services/toast";
import Resizer from "react-image-file-resizer";

export default class Xrays extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      // panaromic state
      panaromic_photo: props.getStore().panaromic_photo,
      panaromic: props.getStore().panaromic,
      panaromic_image: props.getStore().panaromic_image,

      // cephalometric state
      cephalometric_photo: props.getStore().cephalometric_photo,
      cephalometric: props.getStore().cephalometric,
      cephalometric_image: props.getStore().cephalometric_image,
      cd: false,
    };

    this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms

    this.caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    this.fix = this.props.fix;
    this.rejectable_id = this.props.rejectable_id;
  }
  /* --------------------------------------------------------------------------------*/
  /* scroll to top after render */
  /* --------------------------------------------------------------------------------*/
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  /* --------------------------------------------------------------------------------*/
  /* check validation on submit   */
  /* --------------------------------------------------------------------------------*/
  isValidated = () => {
    const XraysInput = this._grabXraysInput(); // grab user entered vals
    const validateNewInput = this._validateData(XraysInput); // run the new input against the validator
    let isDataValid = false;
    // if full validation passes then save to store and pass as valid
    if (
      Object.keys(validateNewInput).every((k) => {
        return validateNewInput[k] === true;
      })
    ) {
      if (
        this.props.getStore().panaromic !== XraysInput.panaromic ||
        this.props.getStore().cephalometric !== XraysInput.cephalometric
      ) {
        let xrays = {
          panoramic_url: XraysInput.panaromic,
          cephalometric_url: XraysInput.cephalometric,
        };
        let filtered_xrays = {};

        for (var key2 in xrays) {
          if (xrays[key2].match(/http([^&]+)/)) {
            filtered_xrays[key2] = xrays[key2];
          }
        }
        let data = {
          aligner_case: {
            step: "xrays",
            xrays: filtered_xrays,
          },
          ...(this.rejectable_id && {
            resolve_rejection_id: this.rejectable_id,
          }),
        };
        this.props.sendingData();

        return updateAlignerCase(
          this.props.data.userReducer.currentUser.id,
          this.caseId,
          JSON.stringify(data)
        )
          .then((res) => {
            this.props.sendingData();
            // only update store of something changed

            this.props.updateStore({
              ...XraysInput,
              savedToCloud: false,
            });
            if (this.fix) {
              this.props.data.FixAlignerCase();
            }
            isDataValid = true;
          })
          .catch((error) => {
            this.props.sendingData();
            return (isDataValid = false);
          });
      } else {
        if (this.props.data.newCase.userInfo.express_api_v2_case) {
          let data = {
            aligner_case: {
              step: "xrays",
              xrays: {},
            },
            ...(this.rejectable_id && {
              resolve_rejection_id: this.rejectable_id,
            }),
          };
          this.props.sendingData();

          return updateAlignerCase(
            this.props.data.userReducer.currentUser.id,
            this.caseId,
            JSON.stringify(data)
          )
            .then((res) => {
              this.props.sendingData();
              // only update store of something changed

              this.props.updateStore({
                ...XraysInput,
                savedToCloud: false,
              });
              if (this.fix) {
                this.props.data.FixAlignerCase();
              }
              isDataValid = true;
            })
            .catch((error) => {
              this.props.sendingData();
              return (isDataValid = false);
            });
        } else {
          infoToaster("nothing change .......");
          isDataValid = true;
        }
      }
    } else {
      // if anything fails then update the UI validation state but NOT the UI Data State
      this.setState(
        Object.assign(
          XraysInput,
          validateNewInput,
          this._validationErrors(validateNewInput)
        )
      );
    }

    return isDataValid;
  };
  /* --------------------------------------------------------------------------------*/
  /* validation Check on submit  */
  /* --------------------------------------------------------------------------------*/
  validationCheck = () => {
    if (!this._validateOnDemand) return;

    const XraysInput = this._grabXraysInput(); // grab user entered vals
    const validateNewInput = this._validateData(XraysInput); // run the new input against the validator

    this.setState(
      Object.assign(
        XraysInput,
        validateNewInput,
        this._validationErrors(validateNewInput)
      )
    );
  };
  /* --------------------------------------------------------------------------------*/
  /* check validation for each element on submit   */
  /* --------------------------------------------------------------------------------*/
  _validateData = (data) => {
    return {
      panaromicVal:
        this.props.data.newCase.userInfo.express_api_v2_case ||
        this.props.data.newCase.impressions.Impression_Type !== "courier" ||
        !this.state.cd
          ? data.panaromic !== "" ||
            this.props.data.newCase.userInfo.express_api_v2_case
          : true, // required: anything besides N/A
    };
  };
  /* --------------------------------------------------------------------------------*/
  /* validation Errors on submit   */
  /* --------------------------------------------------------------------------------*/
  _validationErrors = (val) => {
    const errMsgs = {
      panaromicValMsg: val.panaromicVal ? "" : "panaromic is required ",
    };
    return errMsgs;
  };
  /* --------------------------------------------------------------------------------*/
  /* grab xrays Input data on submit  */
  /* --------------------------------------------------------------------------------*/
  _grabXraysInput = () => {
    return {
      panaromic: this.state.panaromic,
      panaromic_image: this.state.panaromic_image,
      panaromic_photo: this.state.panaromic_photo,

      cephalometric: this.state.cephalometric,
      cephalometric_image: this.state.cephalometric_image,
      cephalometric_photo: this.state.cephalometric_photo,
    };
  };
  /* --------------------------------------------------------------------------------*/
  /* Upload image to s3  */
  /* --------------------------------------------------------------------------------*/
  uploadImage = async (image, id, file) => {
    return uploadFile({
      name: `case-${
        this.caseId
      }/patient-photos/${id}/${new Date().valueOf()}.JPEG`,
      contentType: image.type,
      file: image,
    });
  };
  /* --------------------------------------------------------------------------------*/
  /* upload Panaromic photo */
  /* --------------------------------------------------------------------------------*/
  PanaromicOnUpload = async (e, id) => {
    e.persist();

    if (e.target.files[0]) {
      this.setState({ loading1: true });

      let reader = new FileReader();
      reader.addEventListener("load", (evt) =>
        this.setState({
          panaromic_photo: evt.currentTarget.result,
        })
      );
      reader.readAsDataURL(e.target.files[0]);
      Resizer.imageFileResizer(
        e.target.files[0],
        720,
        1080,
        "JPEG",
        100,
        0,
        async (uri) => {
          const { key } = await this.uploadImage(uri, id, e.target.files[0]);
          this.setState({
            panaromic: keyToUrl(key),
            panaromic_image: false,
            loading1: false,
          });
        },
        "blob"
      );
      // const file = e.target ? e.target.files[0] : e.files[0];
      // const { key } = await this.uploadImage(file, id);
      // this.setState({
      //   panaromic: keyToUrl(key),
      //   panaromic_image: false,
      //   loading1: false,
      // });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* upload Cephalometric photo */
  /* --------------------------------------------------------------------------------*/
  CephalometricOnUpload = async (e, id) => {
    e.persist();

    if (e.target.files[0]) {
      this.setState({ loading2: true });

      let reader = new FileReader();
      reader.addEventListener("load", (evt) =>
        this.setState({
          cephalometric_photo: evt.currentTarget.result,
        })
      );
      reader.readAsDataURL(e.target.files[0]);
      Resizer.imageFileResizer(
        e.target.files[0],
        720,
        1080,
        "JPEG",
        100,
        0,
        async (uri) => {
          const { key } = await this.uploadImage(uri, id, e.target.files[0]);
          this.setState({
            cephalometric: keyToUrl(key),
            cephalometric_image: false,
            loading2: false,
          });
        },
        "blob"
      );
      // const file = e.target ? e.target.files[0] : e.files[0];
      // const { key } = await this.uploadImage(file, id);
      // this.setState({
      //   cephalometric: keyToUrl(key),
      //   cephalometric_image: false,
      //   loading2: false,
      // });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /* Fill Form with init data   */
  /* --------------------------------------------------------------------------------*/
  FillForm = () => {
    this.setState(FillXrays());
  };

  render() {
    // explicit class assigning based on validation
    let notValidClasses = {};
    // panaromic photo validation
    if (typeof this.state.panaromic === "undefined" || this.state.panaromic) {
      notValidClasses.panaromicCls = "";
    } else {
      notValidClasses.panaromicCls = "is-invalid";
      notValidClasses.panaromicValGrpCls = "text-danger";
    }
    return (
      <div className="step step2 mt-5 mb-2">
        {/*--------------------------------------------------------------------------------*/}
        {/* Fill Form With init values button   */}
        {/*--------------------------------------------------------------------------------*/}
        {/* {!this.fix && (
          <div className="row p-3">
            <button
              type="button"
              id="finish-button"
              className="btn btn-next btn-primary btn-lg pull-left"
              onClick={() => {
                this.FillForm();
              }}
            >
              Fill Form
            </button>
          </div>
        )} */}
        {/*--------------------------------------------------------------------------------*/}
        {/*End  Fill Form With init values button   */}
        {/*--------------------------------------------------------------------------------*/}
        <div className="row justify-content-md-center">
          <div className="col">
            <div className="">
              <form id="xrays" className="form-horizontal mt-2 mb-2">
                <Row className="m-auto justify-content-center">
                  {/*--------------------------------------------------------------------------------*/}
                  {/* Upload Panaromic Photo */}
                  {/*--------------------------------------------------------------------------------*/}
                  <Col
                    xs="12"
                    md="6"
                    lg="4"
                    className="text-center title_active mb-2"
                  >
                    <div className="buttons-container ">
                      <input
                        type="file"
                        id="panaromic"
                        onChange={(e) => this.PanaromicOnUpload(e, "panaromic")}
                        multiple={false}
                        className="visually-hidden"
                        accept="image/x-png,image/gif,image/jpeg"
                      />
                      {
                        <div className="upload-container m-auto">
                          {
                            <label htmlFor="panaromic" className="upload">
                              <img
                                src={
                                  this.state.panaromic
                                    ? this.state.panaromic.match(/http([^&]+)/)
                                      ? this.state.panaromic
                                      : `https://sandbox.access.eonaligner.com/assets/panoramic-xray-f36c05dc286e42b86873b33354d3af20c41b8aeb2ddde6e26755187a619f3174.jpg`
                                    : `https://sandbox.access.eonaligner.com/assets/panoramic-xray-f36c05dc286e42b86873b33354d3af20c41b8aeb2ddde6e26755187a619f3174.jpg`
                                }
                                width="100%"
                                alt="panaromic"
                              />
                              {this.state.loading1 && (
                                <center>
                                  <div className={` ${"loading"}`}></div>
                                </center>
                              )}
                            </label>
                          }
                        </div>
                      }
                      <div className="title_active mt-3 mb-3 center">
                        PANAROMIC
                      </div>
                      {this.state.panaromic === "" && (
                        <div className={notValidClasses.panaromicValGrpCls}>
                          {this.state.panaromicValMsg}
                        </div>
                      )}
                    </div>
                  </Col>
                  {/*--------------------------------------------------------------------------------*/}
                  {/*End Upload Panaromic Photo */}
                  {/*--------------------------------------------------------------------------------*/}

                  {/*--------------------------------------------------------------------------------*/}
                  {/* Upload Cephalometric Photo */}
                  {/*--------------------------------------------------------------------------------*/}
                  <Col
                    xs="12"
                    md="6"
                    lg="4"
                    className="text-center title_active"
                  >
                    <div className="buttons-container">
                      <input
                        type="file"
                        id="cephalometric"
                        onChange={(e) =>
                          this.CephalometricOnUpload(e, "cephalometric")
                        }
                        multiple={false}
                        className="visually-hidden"
                        accept="image/x-png,image/gif,image/jpeg"
                      />
                      {
                        <div className="upload-container m-auto">
                          {
                            <label htmlFor="cephalometric" className="upload">
                              <img
                                src={
                                  this.state.cephalometric
                                    ? this.state.cephalometric.match(
                                        /http([^&]+)/
                                      )
                                      ? this.state.cephalometric
                                      : `https://sandbox.access.eonaligner.com/assets/cephalometric-xray-e9fb803c4d1929288c953447c89cc6e59b120d155dfab2dea936bb02dc91641b.jpg`
                                    : `https://sandbox.access.eonaligner.com/assets/cephalometric-xray-e9fb803c4d1929288c953447c89cc6e59b120d155dfab2dea936bb02dc91641b.jpg`
                                }
                                width="100%"
                                alt="cephalometric"
                              />
                              {this.state.loading2 && (
                                <center>
                                  <div className={` ${"loading"}`}></div>
                                </center>
                              )}
                            </label>
                          }
                        </div>
                      }
                      <div className="title_active mt-3 mb-3 center">
                        CEPHALOMETRIC
                      </div>
                    </div>
                  </Col>

                  {/*--------------------------------------------------------------------------------*/}
                  {/*End Upload Cephalometric Photo */}
                  {/*--------------------------------------------------------------------------------*/}
                </Row>
              </form>
              <br></br>
              <strong>
                {this.props.data.newCase.impressions.Impression_Type ===
                "courier" ? (
                  <h5 className="title_active right">
                    <input
                      type="checkbox"
                      checked={this.state.cd}
                      onChange={() => {
                        this.setState({ cd: !this.state.cd });
                      }}
                    />
                    Send on CD with impressions
                  </h5>
                ) : (
                  <h5 className="title_active right">
                    Not allowed to send on CD because no courier was requested
                  </h5>
                )}
              </strong>
            </div>
          </div>
        </div>
        {/*--------------------------------------------------------------------------------*/}
        {/* Fix xrays step  */}
        {/*--------------------------------------------------------------------------------*/}
        {this.fix && (
          <button
            type="button"
            id="finish-button"
            className="btn btn-prev btn-primary btn-lg pull-right"
            onClick={() => {
              this.isValidated();
            }}
          >
            Finish
          </button>
        )}
        {/*--------------------------------------------------------------------------------*/}
        {/*End Fix xrays step  */}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}
