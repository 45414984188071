import * as yup from "yup";
export const schema = yup.object({
  impressions_method: yup
    .string()
    .required("Please choose one of Impression Methodes "),
  lower_arch_url: yup.string(),
  upper_arch_url: yup.string(),
  chose_one:yup.string().required("Please choose one to upload ")
});

export const schema2 = yup.object({
  impressions_method: yup
    .string()
    .required("Please choose one of Impression Methodes "),
  lower_arch_url: yup.string().required("Lower Arch is Required"),
  upper_arch_url: yup.string().required("Upper Arch is Required"),

});
export const schema3 = yup.object({
  impressions_method: yup
    .string()
    .required("Please choose one of Impression Methodes "),
  lower_arch_url: yup.string(),
  upper_arch_url: yup.string(),
});