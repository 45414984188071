import React from "react";
import { connect } from "react-redux";
import {
  Nav,
  // NavItem,
  // NavLink,
  Modal, ModalBody, ModalHeader,
  Button,
  Navbar,
  NavbarBrand,
  Collapse,
  Col, Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
//import * as data from "./data.js";

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
// import logodarkicon from "../../../assets/images/logo-icon.png";
// import logolighticon from "../../../assets/images/logo-light-icon.png";
// import logodarktext from "../../../assets/images/logo-text.png";
// import logolighttext from "../../../assets/images/logo-light-text.png";
// import profilephoto from "../../../assets/images/users/1.jpg";
import eonAccessLogo from "../../../assets/images/eon-access-logo.png";
//import { getDoctors, getDoctorInfo } from "../../../api/api.js";
import { authenticationService } from "../../../jwt/_services";
import { Redirect } from "react-router-dom";
import { getNotifications, markNotificationsAsRead } from "../../../api/api.js";
import moment from "moment";

const mapStateToProps = (state) => ({
  ...state,
});

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      doctor: {},
      doctorInfo: {},
      Notifications: [],
      toggleNotification: false,
      logout: false,
      logingout: false,
      cancelSubmission: false
    };
  }
  UNSAFE_componentWillMount() {
    getNotifications(this.props.userReducer.currentUser.id).then((res) => {
      // console.log(res.action_items, "notification");
      let FilteredNotification = res.action_items.filter((key, index) => {
        return key.actionable_type !== "RestorativeCase";
      });
      FilteredNotification.sort((a, b) => (new Date(a) < new Date(b) ? 1 : -1));
      this.setState({
        Notifications: FilteredNotification,
      });
    });
  }

  MarkNotificationsAsRead = () => {
    markNotificationsAsRead(this.props.userReducer.currentUser.id).then(
      (res) => {
        this.setState({
          Notifications: [],
        });
      }
    );
  };
  /*--------------------------------------------------------------------------------*/
  /*To open Search Bar                                                              */
  /*--------------------------------------------------------------------------------*/
  toggleMenu = () => {
    document.getElementById("search").classList.toggle("show-search");
  };
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  showMobilemenu = () => {
    document.getElementById("main-wrapper").classList.toggle("show-sidebar");
  };
  sidebarHandler = () => {
    let element = document.getElementById("main-wrapper");
    switch (this.props.settings.activeSidebarType) {
      case "full":
      case "iconbar":
        element.classList.toggle("mini-sidebar");
        if (element.classList.contains("mini-sidebar")) {
          element.setAttribute("data-sidebartype", "mini-sidebar");
        } else {
          element.setAttribute(
            "data-sidebartype",
            this.props.settings.activeSidebarType
          );
        }
        break;

      case "overlay":
      case "mini-sidebar":
        element.classList.toggle("full");
        if (element.classList.contains("full")) {
          element.setAttribute("data-sidebartype", "full");
        } else {
          element.setAttribute(
            "data-sidebartype",
            this.props.settings.activeSidebarType
          );
        }
        break;

      default:
    }
  };

  KeyDictionary(key) {
    return key.split(".")[1].split("_").join(" ");
  }
  ToggleNotifaction = () => {
    this.setState({ toggleNotification: !this.state.toggleNotification });
  };
  toggleLogoutModal = () => {
    this.setState({ logout: !this.state.logout })
  }
  toggleCancelConfirmation = () => {
    this.setState({ cancelSubmission: !this.state.cancelSubmission })
  }
  render() {
    //console.log(this.props, "state........");
    if (this.state.setting) {
      return (
        <Redirect
          to={{
            pathname: "/profile",
            state: {
              activeTab: "3",
              id: this.props.userReducer.currentUser.id,
            },
          }}
        />
      );
    } else if (this.state.profile) {
      return (
        <Redirect
          to={{
            pathname: "/profile",
            state: {
              activeTab: "1",
              id: this.props.userReducer.currentUser.id,
            },
          }}
        />
      );
    }
    return (
      <header
        className="topbar navbarbg"
        data-navbarbg={this.props.settings.activeNavbarBg}
      >

        {/*--------------------------------------------------------------------------------*/}
        {/* Cancel Submission modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.cancelSubmission}
          toggle={this.toggleCancelConfirmation}
        >
          <ModalHeader toggle={this.toggleCancelConfirmation}>
            Cancel Submission
          </ModalHeader>
          <ModalBody>
            <div className="form-group content form-block-holder h-100">
              <label className="control-label mt-3 ml-3 mb-1">
                Are you sure you would like to cancel this submission?
              </label>
              <Row className="m-1">
                <Col xs="4" md="4" lg="4" className="p-2 w-100">
                  <Button
                    className="btn green-btn w-100"
                    size="md"
                    disabled={this.state.completingCase}
                    onClick={() => window.location.href = "/home"}
                  >
                    Yes
                  </Button>
                </Col>
                <Col xs="4" md="4" lg="4" className="p-2 w-100">
                  <Button
                    className="btn red-btn w-100 "
                    size="md"
                    color="danger"
                    disabled={this.state.completingCase}
                    onClick={() => this.toggleCancelConfirmation()}
                  >
                    No
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Cancel Submission modal */}
        {/*--------------------------------------------------------------------------------*/}

        <Navbar
          className={
            "top-navbar " +
            (this.props.settings.activeNavbarBg === "skin6"
              ? "navbar-light"
              : "navbar-dark")
          }
          expand="md"
        >
          <div
            className="navbar-header"
            id="logobg"
            data-logobg={this.props.settings.activeLogoBg}
          >
            {/*--------------------------------------------------------------------------------*/}
            {/* Mobile View Toggler  [visible only after 768px screen]                         */}
            {/*--------------------------------------------------------------------------------*/}
            {/* <span
              className="nav-toggler d-block d-md-none text-white"
              onClick={this.showMobilemenu}
            >
              <i className="ti-menu ti-close" />
            </span> */}
            {/*--------------------------------------------------------------------------------*/}
            {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
            {/*--------------------------------------------------------------------------------*/}
            <NavbarBrand style={{ cursor: "pointer" }} onClick={() => {
              if (window.location.pathname === "/case-submission" && !window.location.search) {
                this.toggleCancelConfirmation()
              } else {
                window.location.href = "/home"
              }

            }}>
              <b className="logo-icon">
                <img
                  src={eonAccessLogo}
                  alt="homepage"
                  className="dark-logo"
                  height="54"
                  width="181"
                />
                {/* <img
                  src={logolighticon}
                  alt="homepage"
                  className="light-logo"
                /> */}
              </b>
              {/* <span className="logo-text">
                <img src={logodarktext} alt="homepage" className="dark-logo" />
                <img
                  src={logolighttext}
                  className="light-logo"
                  alt="homepage"
                />
              </span> */}
            </NavbarBrand>
            {/*--------------------------------------------------------------------------------*/}
            {/* Mobile View Toggler  [visible only after 768px screen]                         */}
            {/*--------------------------------------------------------------------------------*/}
            <span
              className="topbartoggler d-block d-md-none text-white"
              onClick={this.toggle}
            >
              <i className="ti-more" />
            </span>
          </div>
          <Collapse
            className="navbarbg"
            isOpen={this.state.isOpen}
            navbar
            data-navbarbg={this.props.settings.activeNavbarBg}
          >
            {/* <Nav className="float-left" navbar>
              <NavItem>
                <NavLink
                  href="#"
                  className="d-none d-md-block"
                  onClick={this.sidebarHandler}
                >
                  <i className="ti-menu" />
                </NavLink>
              </NavItem>
            </Nav> */}


            <div className="nav-grid">

              <div className="pr-1 pl-1 doctor-name-nav"> {this.props.userReducer.currentUser.full_name}</div>

              <div>      <Nav className="ml-auto float-right align-items-center" navbar>
                <UncontrolledDropdown
                  nav
                  inNavbar
                  onClick={() => {
                    window.location.href = "/profile";
                    // this.setState({
                    //   setting: true,
                    // });
                  }}
                >
                  <i className="ti-settings pr-1 pl-1 setting-icon" title="setting" />
                </UncontrolledDropdown>
                {/*--------------------------------------------------------------------------------*/}
                {/* Start Notifications Dropdown                                                   */}
                {/*--------------------------------------------------------------------------------*/}
                <UncontrolledDropdown
                  nav
                  inNavbar
                  isOpen={this.state.toggleNotification}
                  toggle={this.ToggleNotifaction}
                >
                  <DropdownToggle nav caret>
                    <i className="far fa-envelope message-icon" title="Notifactions"></i>
                    {/* <i className="mdi mdi-message" /> */}
                    {this.state.Notifications.length !== 0 && (
                      <div className="notify">
                        <span className="heartbit" /> <span className="point" />
                      </div>
                    )}
                  </DropdownToggle>
                  <DropdownMenu right className="mailbox">
                    <div className="p-4 text-dark border-bottom">
                      <h6
                        className="mb-0 font-medium"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          //window.location.href = "/notifications";
                          this.props.data.history.push({
                            pathname: "/notifications",
                            state: {
                              data: this.state.Notifications,
                              id: this.props.userReducer.currentUser.id,
                            },
                          });
                          this.ToggleNotifaction();
                        }}
                      >
                        Notifications ({this.state.Notifications.length})
                    </h6>
                    </div>
                    <div className="message-center notifications">
                      {/*<!-- Message -->*/}
                      {this.state.Notifications.map((notification, index) => {
                        return (
                          <span
                            className="message-item"
                            key={index}
                            onClick={() => {
                              // this.props.data.history.push(
                              //   `/case/${notification.case}`
                              // );
                              //  console.log("in notification ....");
                              window.location.href = `/case/${notification.case}`;
                            }}
                          >
                            {notification.case_type === "NightGuardCase" ? (
                              <span className={"btn btn-circle btn-success"}>
                                <i className={"fa fa-link"} />
                              </span>
                            ) : (
                              <span className={"btn btn-circle btn-danger"}>
                                <i className={"fa fa-link"} />
                              </span>
                            )}
                            <div className="mail-contnet">
                              <h5 className="message-title">
                                {this.KeyDictionary(notification.key)}
                                {/* {notification.actionable_type} */}
                              </h5>
                              <span className="mail-desc">
                                Case {notification.case_uid}
                              </span>
                              <span className="mail-desc">
                                {` ${notification.patient &&
                                  ` ${notification.patient.first_name} ${notification.patient.middle_name} ${notification.patient.last_name}`
                                  }  `}
                              </span>
                              <span className="mail-desc" >
                                {moment(notification.created_at).format(
                                  "DD MMM YYYY"
                                )}

                              </span>
                              {/* <span className="time">{notification.time}</span> */}
                            </div>
                          </span>
                        );
                      })}
                    </div>
                    <div
                      className={
                        this.state.Notifications.length !== 0
                          ? `nav-link text-center mb-1 text-muted`
                          : `nav-link text-center mb-1 text-muted disable_notifications`
                      }
                      onClick={() => this.MarkNotificationsAsRead()}
                    >
                      <strong>Mark All Notifications As Read</strong>
                      {/* <i className="fa fa-angle-right" /> */}
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/*--------------------------------------------------------------------------------*/}
                {/* End Notifications Dropdown                                                     */}
                {/*--------------------------------------------------------------------------------*/}
                <UncontrolledDropdown
                  nav
                  inNavbar
                  onClick={() => {
                    this.toggleLogoutModal()
                    // authenticationService.logout();
                  }}
                >
                  <i className="fas fa-sign-out-alt  pr-1 pl-1 setting-icon" title="Logout"></i>
                </UncontrolledDropdown>
                {/*--------------------------------------------------------------------------------*/}
                {/* Start Profile Dropdown                                                         */}
                {/*--------------------------------------------------------------------------------*/}

                <UncontrolledDropdown inNavbar>
                  <DropdownToggle
                    nav
                    caret
                    className="pro-pic"
                    style={{ cursor: "auto" }}
                  >
                    <img
                      src={
                        this.props.userReducer.currentUser.avatar.match(
                          /http([^&]+)/
                        )
                          ? this.props.userReducer.currentUser.avatar
                          : "https://muratselek.com.tr/wp-content/uploads/2019/01/yorum-icon-avatar-men-768x918.png"
                      }
                      alt={this.props.userReducer.currentUser.full_name}
                      className="rounded-circle pr-1 pl-1"
                      width="31"
                    />
                  </DropdownToggle>

                  {/* <DropdownMenu right className="user-dd">
                  <div className="d-flex no-block align-items-center p-3 mb-2 border-bottom">
                    <div className="">
                      <img
                        src={
                          this.props.userReducer.currentUser.avatar.match(
                            /http([^&]+)/
                          )
                            ? this.props.userReducer.currentUser.avatar
                            : "https://muratselek.com.tr/wp-content/uploads/2019/01/yorum-icon-avatar-men-768x918.png"
                        }
                        alt={this.props.userReducer.currentUser.first_name}
                        className="rounded"
                        width="80"
                      />
                    </div>
                    <div className="ml-3">
                      <h4 className="mb-0">
                        {this.props.userReducer.currentUser.full_name}
                      </h4>
                      <p className="text-muted mb-0">
                        {this.props.userReducer.currentUser.email}
                      </p>
                      <Button
                        color="danger"
                        className="btn-rounded mt-2"
                        onClick={() => {
                          window.location.href = "/sample-pages/profile";
                          this.setState({
                            profile: true,
                          });
                        }}
                      >
                        View Profile
                      </Button>
                    </div>
                  </div>
                  <DropdownItem>
                    <i className="ti-user mr-1 ml-1" /> My Account
                  </DropdownItem>
                  <DropdownItem>
                    <i className="ti-wallet mr-1 ml-1" /> My Balance
                  </DropdownItem>
                  <DropdownItem className="border-bottom">
                    <i className="ti-email mr-1 ml-1" /> Inbox
                  </DropdownItem>
                  <DropdownItem
                    className="border-bottom"
                    onClick={() => {
                      window.location.href = "/profile";
                      this.setState({
                        setting: true,
                      });
                    }}
                  >
                    <i className="ti-settings mr-1 ml-1" /> Account Settings
                  </DropdownItem>
                  <DropdownItem
                     href="https://sandbox.access.eonaligner.com/doctors/sign_out"
                    onClick={() => {
                      authenticationService.logout();
                    }}
                  >
                    <i className="fa fa-power-off mr-1 ml-1" /> Logout
                  </DropdownItem>
                </DropdownMenu>
             */}
                </UncontrolledDropdown>
                {/*--------------------------------------------------------------------------------*/}
                {/* End Profile Dropdown                                                           */}
                {/*--------------------------------------------------------------------------------*/}
              </Nav>

              </div>
            </div>
            {/*--------------------------------------------------------------------------------*/}
            {/* Mark case as completed Modal */}
            {/*--------------------------------------------------------------------------------*/}
            <Modal isOpen={this.state.logout} toggle={this.toggleLogoutModal}>
              <ModalHeader toggle={this.toggleLogoutModal}>
                Confirm Logout
          </ModalHeader>

              <ModalBody className="p-0">
                <div className="form-group content form-block-holder h-100">
                  <label className="control-label mt-3 ml-3 mb-1">
                    Are You Sure you want to Logout ?
              </label>
                  <Row className="m-1">
                    <Col xs="4" md="4" lg="4" className="p-2 w-100">
                      <Button
                        className="btn green-btn w-100"
                        size="md"
                        disabled={this.state.logingout}
                        onClick={() => {
                          this.setState({ logingout: true })
                          authenticationService.logout()
                        }
                        }
                      >
                        Yes
                  </Button>
                    </Col>
                    <Col xs="4" md="4" lg="4" className="p-2 w-100">
                      <Button
                        className="btn red-btn w-100 "
                        size="md"
                        color="danger"
                        disabled={this.state.logingout}
                        onClick={() => this.setState({ logout: !this.state.logout })}
                      >
                        Cancel                  </Button>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
            </Modal>
            {/*--------------------------------------------------------------------------------*/}
            {/* end Mark case as completed Modal */}
            {/*--------------------------------------------------------------------------------*/}

          </Collapse>
        </Navbar>
      </header>
    );
  }
}
export default connect(mapStateToProps)(Header);
