import React, { Component } from "react";
import { connect } from "react-redux";

import {
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  Label,
  Input,
  FormGroup,
  Card,
  ModalBody, ModalHeader, Modal
} from "reactstrap";

import CustomeTeeth from "../Teeth/CustomeTeeth";
import { caseType } from "../../helpers/caseType";
import { updateAlignerCase, SummaryStep } from "../../api/api";
import { Formik, Form } from "formik";
import { countries } from "../../helpers/countries";
import Malocclusion from "./AdvancedModules/ModulesComponents/Malocclusion";
import Extraction from "./AdvancedModules/ModulesComponents/Extraction";
import IPR from "./AdvancedModules/ModulesComponents/IPR"
import Attachments from "./AdvancedModules/ModulesComponents/Attachments";
import Pontics from "./AdvancedModules/ModulesComponents/Pontics";
import Overcorrection from "./AdvancedModules/ModulesComponents/OverCorrection"
import PassiveAligner from "./AdvancedModules/ModulesComponents/PassiveAligner"
import TorqueEnhancers from "./AdvancedModules/ModulesComponents/TorqueEnhancers"
import ToothSizeDiscrepancy from "./AdvancedModules/ModulesComponents/ToothSizeDiscrepancy"
import BiteRamps from "./AdvancedModules/ModulesComponents/BiteRamps"
import ArchExpansion from "./AdvancedModules/ModulesComponents/ArchExpansion";
import Elastics from "./AdvancedModules/ModulesComponents/Elastics";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseId:
        this.props.caseId ||
        (window.location.search.match(/id=([^&]+)/) || [])[1],
      country: {},
      detailedCaseSubmission: false
    };
  }
  /*--------------------------------------------------------------------------------*/
  /*Summery step submit  */
  /*--------------------------------------------------------------------------------*/
  handleSubmit = (values, { setSubmitting }) => {
    const { doctorId } = this.props;
    const caseId =
      this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1];
    setSubmitting(true);

    let data = {
      aligner_case: {
        step: "summary",
        // advanced_modules: {
        //   ...this.props.caseSubmission.advanced_modules,
        //   all_modules: this.props.advancedModules.modules,
        // },
      },
    };

    SummaryStep(doctorId, caseId)
      .then((res) => {
        this.props.NextStep();
        setSubmitting(false);
        // this.props.ShowAdvancedModule(showAdvancedModule);
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };

  componentDidMount = () => {
    let countryData = countries.filter(
      (country) => country.value === this.props.caseData.address.country
    );
    this.setState({
      country: countryData[0],
    });
  };
  DetailedCaseSubmission = () => {
    this.setState({ detailedCaseSubmission: !this.state.detailedCaseSubmission })
  }
  render() {
    const { prescription } = this.props.caseSubmission;
    const { address } = this.props.caseData;
    const { country } = this.state;
    const wear_cycle = {
      regular: "Two week cycle",
      express: "One week cycle",
    };
    const treat_arches = {
      upper_only: "Upper",
      lower_only: "Lower",
      both: "Upper and Lower",
    };
    let advancedModules = [];

    advancedModules = this.props.advancedModules.modules.filter(
      (value, index) => {
        return value.active === true;
      }
    );
    return (
      <div className="summery">
        <CardTitle className="border-bottom pb-3 title_active-gco case-title">
          <div className="step-header-title">
            <span>Summary</span>
            <span className="patient_info">
              <div> {this.props.patient.full_name}
              </div>
              <div> Case {this.state.caseId}
              </div>
            </span>
          </div>
          <div className="patient-info-grid">
            <div>{this.props.patient.gender}</div>
            <div> Born {this.props.patient.dob}</div>
            <div>{this.props.patient.email}</div>
            <div>
              {address.line1}, {address.zip}, {address.city}, {country.label}
            </div>
            <div></div>
          </div>
        </CardTitle>

        <Formik
          enableReinitialize
          initialValues={{
            chief_complaint: prescription.chief_complaint,
            summary_and_special_instructions:
              prescription.summary_and_special_instructions,
            treat_arches: prescription.treat_arches,
            wear_cycle: prescription.wear_cycle,
            case_type: this.props.caseData.case_type,
          }}
          onSubmit={this.handleSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="step-progress-new-case upload-container">
                {isSubmitting && (
                  <div className={`loading2`}>
                    <div className="spinner_2"></div>
                  </div>
                )}
              </div>
              <CardBody className="prescription_summery_grid mb-5">
                <div>
                  <FormGroup>
                    <Label className="title_active-gco ">Chief Complaint</Label>
                    <Input
                      name="chief_complaint"
                      id="chief_complaint"
                      type="textarea"
                      rows={5}
                      readOnly
                      defaultValue={values.chief_complaint}
                    />{" "}
                  </FormGroup>
                  <FormGroup>
                    <Label className="title_active-gco mt-2">
                      Tooth Movement Restrictions
                    </Label>
                    <div className="teeth_selector">   <CustomeTeeth
                      action_key="Case_Tooth_Movement_Restrictions"
                      summary={true}
                    /></div>

                  </FormGroup>

                  {advancedModules.length > 0 && <FormGroup>
                    <Label className="title_active-gco mt-2">
                      Active Treatment Options{" "}
                    </Label>

                    <div>
                      <div className="active_advanced_module_grid mt-3">
                        {this.props.advancedModules?.modules &&
                          this.props.advancedModules.modules
                            .filter((value, index) => {
                              return value.active === true;
                            })
                            .map((values, index) => (
                              <Card
                                key={index}
                                className={`p-1 custom_shadow align-items-center active_advanced_module_card`}
                              >
                                <div style={{ width: "100%" }}>
                                  {
                                    values.key ===
                                      'ipr' ? <div className="ipr-title-active">IPR</div>
                                      : <img
                                        src={values.active_img}
                                        className="modules-img"
                                        alt="Aligner Treatment"
                                      />}
                                  <CardTitle className="pt-2 m-0 active_module_title ">
                                    {values.title}
                                  </CardTitle>
                                </div>
                              </Card>
                            ))}
                      </div>
                    </div>
                  </FormGroup>}
                </div>
                <div>
                  <FormGroup>
                    <Label className="title_active-gco ">
                      Treatment summary and Specific instructions
                    </Label>
                    <Input
                      name="summary_and_special_instructions"
                      id="summary_and_special_instructions"
                      type="textarea"
                      rows={5}
                      readOnly
                      defaultValue={values.summary_and_special_instructions}
                    />{" "}
                  </FormGroup>
                  <FormGroup>
                    <Label className="title_active-gco mt-2">
                      Treat Arch : {` ${treat_arches[values.treat_arches]}`}
                    </Label>
                  </FormGroup>

                  <FormGroup>
                    <Label className="title_active-gco ">
                      Case Type : {` ${caseType[values.case_type]}`}
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Label className="title_active-gco ">
                      Wear Cycle : {` ${wear_cycle[values.wear_cycle]}`}
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Label className="title_active-gco ">Treatment Type : {this.props.caseSubmission.advanced_modules?.treatment_type?.type || ""}</Label>
                  </FormGroup>

                  {advancedModules.length > 0 && <FormGroup>
                    <Button className={`btn  active-btn text-center mb-2  btn-radius`} size="sm"
                      type="button"
                      onClick={this.DetailedCaseSubmission}
                    >

                     Active Advanced Options
                    </Button>
                  </FormGroup>}


                </div>
              </CardBody>
              <CardFooter className="p-3">
                <Button
                  className={`btn  ${"preference-btn"} float-left text-center mb-2  btn-radius`}
                  size="sm"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => this.props.PrevStep()}
                >
             <i className="fas fa-angle-left arrows-icon pr-1"></i>     Prescription{" "}
                </Button>{" "}
                <Button
                  className={`btn  ${"preference-btn"} float-right text-center mb-2  btn-radius`}
                  size="sm"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit<i className="fas fa-angle-right arrows-icon pl-1"></i>
                </Button>{" "}
              </CardFooter>{" "}
            </Form>
          )}
        </Formik>


        {/*--------------------------------------------------------------------------------*/}
        {/* Detailed Case Submission Modal /}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.detailedCaseSubmission}
          toggle={this.DetailedCaseSubmission}
          className="malocclusion-modal"

        >
          <ModalHeader toggle={this.DetailedCaseSubmission}>
            Detailed Case Submission
          </ModalHeader>
          <ModalBody>
            {this.props.advancedModules?.modules &&
              this.props.advancedModules.modules

                .map((values, index) => (
                  <div key={index}>

                    {
                      values.active && values.key === "malocclusion" && <> <Label className="summary_sub_title mt-3 pl-3 ">Malocclusion</Label>
                        <hr></hr>
                        <Malocclusion summary={true} /> </>
                    }

                    {
                      values.active && values.key === "extraction" && <>    <Label className="summary_sub_title mt-3 pl-3">Extraction</Label>
                        <hr></hr>
                        <div className="malocclusion-main-grid">
                          <div></div><Extraction summary={true} />
                          <div></div>
                        </div></>
                    }

                    {
                      values.active && values.key === "ipr" && <>     <Label className="summary_sub_title mt-3 pl-3">IPR</Label>
                        <hr></hr>
                        <div className="malocclusion-main-grid">
                          <div></div> <IPR summary={true} />
                          <div></div>
                        </div></>
                    }


                    {
                      values.active && values.key === "attachments" && <>     <Label className="summary_sub_title mt-3 pl-3">Attachments</Label>
                        <hr></hr>
                        <div className="malocclusion-main-grid">
                          <div></div> <Attachments summary={true} />
                          <div></div>
                        </div></>
                    }



                    {
                      values.active && values.key === "pontics" && <>
                        <Label className="summary_sub_title mt-3 pl-3">Pontics</Label>
                        <hr></hr>
                        <div className="malocclusion-main-grid">
                          <div></div><Pontics summary={true} />
                          <div></div>
                        </div></>
                    }


                    {
                      values.active && values.key === "overCorrection" && <>    <Label className="summary_sub_title mt-3 pl-3">Overcorrection</Label>
                        <hr></hr>
                        <div className="malocclusion-main-grid">
                          <div></div> <Overcorrection summary={true} />
                          <div></div>
                        </div></>
                    }


                    {
                      values.active && values.key === "passiveAligners" && <>
                        <Label className="summary_sub_title mt-3 pl-3">Passive Aligner</Label>
                        <hr></hr>
                        <div className="malocclusion-main-grid">
                          <div></div> <PassiveAligner summary={true} />
                          <div></div>
                        </div></>
                    }


                    {
                      values.active && values.key === "toothSizeDiscrepancy" && <>
                        <Label className="summary_sub_title mt-3 pl-3">ToothSizeDiscrepancy</Label>
                        <hr></hr>
                        <div className="malocclusion-main-grid">
                          <div></div><ToothSizeDiscrepancy summary={true} />
                          <div></div>
                        </div></>
                    }


                    {
                      values.active && values.key === "torqueEnhancers" && <>
                        <Label className="summary_sub_title mt-3 pl-3">Torque Enhancers</Label>
                        <hr></hr>
                        <div className="malocclusion-main-grid">
                          <div></div><TorqueEnhancers summary={true} />
                          <div></div>
                        </div></>
                    }


                    {
                      values.active && values.key === "biteRamps" && <>
                        <Label className="summary_sub_title mt-3 pl-3">Bite Ramps</Label>
                        <hr></hr>
                        <div className="malocclusion-main-grid">
                          <div></div> <BiteRamps summary={true} />
                          <div></div>
                        </div></>
                    }


                    {
                      values.active && values.key === "archExpansion" && <>
                        <Label className="summary_sub_title mt-3 pl-3">Arch Expansion</Label>
                        <hr></hr>
                        <div className="malocclusion-main-grid">
                          <div></div><ArchExpansion summary={true} />
                          <div></div>
                        </div></>
                    }
                    {
                      values.active && values.key === "elastics" && <>    <Label className="summary_sub_title mt-3 pl-3">Elastics</Label>
                        <hr></hr>
                        <div className="malocclusion-main-grid">
                          <div></div> <Elastics summary={true} />
                          <div></div>
                        </div></>
                    }
                  </div>


                ))}




















            <FormGroup>
              <Button
                className="btn red-btn pull-right m-1 "
                size="md"
                color="danger"
                onClick={this.DetailedCaseSubmission}
              >
                Cancel
              </Button>

            </FormGroup>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Detailed Case Submission Modal /}
        {/*--------------------------------------------------------------------------------*/}

      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Summary);
