import React, { Component } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
} from "reactstrap";
import { uploadFile, keyToUrl } from "../../helpers/s3";
import { postComment } from "../../api/api";
import { connect } from "react-redux";
import { pushMsg, updateAttachment } from "../../redux/inbox/action";
import MicRecorder from "mic-recorder-to-mp3";
import Select from "react-select";
// import Resizer from "react-image-file-resizer";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  pushMsg: (payload) => dispatch(pushMsg(payload)),
  updateAttachment: (payload) => dispatch(updateAttachment(payload)),
});
const Mp3Recorder = new MicRecorder({ bitRate: 128 });
class MsgForm extends Component {
  /*--------------------------------------------------------------------------------*/
  /* constructor   */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state   */
    this.state = {
      msg: "",
      attachment: "",
      attachment_name: "",
      id: props.userReducer.currentUser.id,
      caseId: props.caseId,
      test: null,
      isRecording: false,
      blobURL: "",
      isBlocked: false,
      width: window.innerWidth,
      devices: [],
      modal: false,
      toggled: false,
      selectedDevice: "",
      sending: false,
      sec: 0,
      min: 0,
      intervalID: 0,
      intervalID2: 0,
      loadingFile: false,
      currentLoadingFile: {},
      loading_percentage: 0,
    };
  }
  /*--------------------------------------------------------------------------------*/
  /* resize screen after render all element   */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);
  }
  /*--------------------------------------------------------------------------------*/
  /* show / hide loader     */
  /*--------------------------------------------------------------------------------*/
  sendingData = () => {
    this.setState({ sending: !this.state.sending });
  };
  /*--------------------------------------------------------------------------------*/
  /* update Dimensions    */
  /*--------------------------------------------------------------------------------*/
  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* message value  */
  /*--------------------------------------------------------------------------------*/
  handleChatMsgChange = (e) => {
    this.setState({
      msg: e.target.value,
    });
    this.props.updateAttachment({
      ...this.props.inbox.message,
      msg: e.target.value,
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* save message data   */
  /*--------------------------------------------------------------------------------*/
  onChatMsgSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.pushMsg({
      body: this.props.inbox.message.msg,
      attachment: "",
      submitted_at: Date.now(),
      commenter_type: "Doctor",
    });
    this.setState({
      msg: "",
      attachment: "",
      attachment_name: "",
      blobURL: "",
    });
    this.props.updateAttachment({
      attachment_name: "",
      attachment_size: 0,
      attachment_url: "",
      loading_percentage: 0,
      msg: "",
    });
  };

  /*--------------------------------------------------------------------------------*/
  /* Upload file to s3                                                   */
  /*--------------------------------------------------------------------------------*/
  UploadFile = async (image, id, file) => {
    let extention = image.name.split(".");
    return uploadFile({
      name: `msg/${id}/${new Date().valueOf()}.${
        image.name.split(".")[extention.length - 1]
      }`,
      contentType: image.type,
      file: image,
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* format File Size                                                 */
  /*--------------------------------------------------------------------------------*/
  formatFileSize = (bytes, decimalPoint) => {
    if (bytes === 0) return "0 Bytes";
    var k = 1000,
      dm = decimalPoint || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  /*--------------------------------------------------------------------------------*/
  /* uploading file                                               */
  /*--------------------------------------------------------------------------------*/
  FileOnUpload = async (e) => {
    e.persist();
    this.setState({ loading_percentage: 0 });
    this.props.updateAttachment({
      ...this.props.inbox.message,
      loading_percentage: 0,
    });
    if (e.target.files[0]) {
      const intervalID2 = setInterval(this.timer2, 1000);
      this.setState({ loadingFile: true, intervalID2 });
      this.props.updateAttachment({
        ...this.props.inbox.message,
        attachment_name: e.target.files[0].name,
        attachment_size: e.target.files[0].size,
        msg: "attached file",
      });
      this.setState({
        msg: "attached file",
        currentLoadingFile: e.target.files[0],
      });
      let reader = new FileReader();
      reader.addEventListener("load", (evt) => {
        this.setState({
          msg1: evt.currentTarget.result,
        });
      });

      reader.readAsDataURL(e.target.files[0]);

      const file = e.target ? e.target.files[0] : e.files[0];
      const { key } = await this.UploadFile(file, "file", e.target.files[0]);

      clearInterval(this.state.intervalID2);
      this.setState({
        attachment: keyToUrl(key),
        loading_percentage: 100,
      });
      this.props.updateAttachment({
        ...this.props.inbox.message,
        attachment_url: keyToUrl(key),
        loading_percentage: 100,
      });
      e.target.value = "";
    }
  };
  /*--------------------------------------------------------------------------------*/
  /* Upload audio to s3                                                   */
  /*--------------------------------------------------------------------------------*/
  UploadAudio = async (audio, id) => {
    return uploadFile({
      name: `msg/${id}/${new Date().valueOf()}.mp3`,
      contentType: audio.type,
      file: audio,
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* uploading audio                                               */
  /*--------------------------------------------------------------------------------*/
  AudioOnUpload = async (blob) => {
    this.setState({
      msg: "attached audio",
    });
    this.props.updateAttachment({
      ...this.props.inbox.message,
      msg: "attached audio",
    });
    const { key } = await this.UploadAudio(blob, "audio");
    this.setState({
      attachment: keyToUrl(key),
    });
    this.props.updateAttachment({
      ...this.props.inbox.message,
      attachment_url: keyToUrl(key),
    });
  };

  /*--------------------------------------------------------------------------------*/
  /* get mediaDevices                                                 */
  /*--------------------------------------------------------------------------------*/

  start = async () => {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;

    let stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    if (stream.active) {
      this.stream = stream;
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        devices = devices.filter((d) => d.kind === "audioinput");
        this.setState({
          devices: devices,
          modal: true,
        });
        this.stopAllTracks();
      });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /* recoder timer                                               */
  /*--------------------------------------------------------------------------------*/
  timer = () => {
    if (this.state.sec <= 60) {
      this.setState({
        sec: this.state.sec + 1,
      });
    } else {
      this.setState({
        sec: 0,
        min: this.state.min + 1,
      });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /* uploading file timer                                             */
  /*--------------------------------------------------------------------------------*/
  timer2 = () => {
    this.setState({
      loading_percentage: this.state.loading_percentage + 10,
    });
    this.props.updateAttachment({
      ...this.props.inbox.message,
      loading_percentage: this.state.loading_percentage + 10,
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* start recording                                            */
  /*--------------------------------------------------------------------------------*/
  record = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: this.state.selectedDevice,
      },
    });

    if (stream.active) {
      this.setState({
        blobURL: "",
      });
      this.stream = stream;

      Mp3Recorder.start()
        .then(() => {
          this.setState({
            modal: !this.state.modal,
            isRecording: true,
          });
          const intervalID = setInterval(this.timer, 1000);
          this.setState({ intervalID });
        })
        .catch((e) => {
          this.setState({ sec: 0, min: 0 });
          console.error(e);
        });
    } else {
      this.stopAllTracks();
    }
  };
  /*--------------------------------------------------------------------------------*/
  /* stop recording                                                */
  /*--------------------------------------------------------------------------------*/
  stop = async () => {
    this.stopAllTracks();
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        this.AudioOnUpload(blob);
        clearInterval(this.state.intervalID);
        this.setState({ blobURL, isRecording: false });
      })
      .catch((e) => {
        this.stopAllTracks();
        console.log(e);
      });
  };
  /*--------------------------------------------------------------------------------*/
  /* delete recording                                                */
  /*--------------------------------------------------------------------------------*/
  deleteRecording = async () => {
    this.stopAllTracks();

    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        clearInterval(this.state.intervalID);
        this.setState({ blobURL: "", isRecording: false });
      })
      .catch((e) => {
        this.stopAllTracks();

        console.log(e);
      });
  };
  /*--------------------------------------------------------------------------------*/
  /* post comment                                                 */
  /*--------------------------------------------------------------------------------*/
  sendComment = () => {
    let data = {
      comment: {
        body: this.props.inbox.message.msg,
        attachment: this.props.inbox.message.attachment_url,
      },
    };
    this.sendingData();
    postComment(
      this.state.id,
      this.state.caseId.toString(),
      JSON.stringify(data)
    )
      .then((res) => {
        this.props.pushMsg({
          body: this.props.inbox.message.msg,
          attachment: this.props.inbox.message.attachment_url,
          submitted_at: Date.now(),
          commenter_type: "Doctor",
        });
        this.setState({
          msg: "",
          attachment: "",
          attachment_name: "",
          blobURL: "",
          loadingFile: false,
        });
        this.props.updateAttachment({
          attachment_name: "",
          attachment_size: 0,
          attachment_url: "",
          loading_percentage: 0,
          msg: "",
        });
        this.sendingData();
      })
      .catch((error) => {
        this.sendingData();
      });
  };
  /*--------------------------------------------------------------------------------*/
  /* show / hide devices                                               */
  /*--------------------------------------------------------------------------------*/
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  onHandleDropDownList = () => {
    this.setState({
      toggled: !this.state.toggled,
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* set Selected Device                                            */
  /*--------------------------------------------------------------------------------*/
  setSelectedDevice = (device) => {
    this.setState({
      selectedDevice: device,
      toggled: !this.state.toggled,
    });
    this.stopAllTracks();
    this.record();
  };
  /*--------------------------------------------------------------------------------*/
  /* clear Data                                         */
  /*--------------------------------------------------------------------------------*/
  clearData = () => {
    this.setState({
      msg: "",
      attachment: "",
      attachment_name: "",
      blobURL: "",
      loadingFile: false,
      loading_percentage: 0,
    });
    this.props.updateAttachment({
      attachment_name: "",
      attachment_size: 0,
      attachment_url: "",
      loading_percentage: 0,
      msg: "",
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* Stop All tracks                                       */
  /*--------------------------------------------------------------------------------*/
  stopAllTracks = () => {
    this.stream
      .getTracks() // get all tracks from the MediaStream
      .forEach((track) => track.stop()); // stop each of them
    this.setState({ sec: 0, min: 0, intervalID: 0 });
    clearInterval(this.state.intervalID);
  };
  /*--------------------------------------------------------------------------------*/
  /* remove event listener whin leave component                                     */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    window.removeEventListener("load", this.updateDimensions);
    window.removeEventListener("resize", this.updateDimensions);
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/* Devices Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>choose you device </ModalHeader>

          <ModalBody className="device">
            <Row>
              <Col>
                {this.state.devices.length !== 0 && (
                  <div>
                    <Select
                      onChange={(item) => {
                        this.setSelectedDevice(item.value);
                      }}
                      options={this.state.devices}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/*End  Devices Modal */}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*message Form*/}
        {/*--------------------------------------------------------------------------------*/}
        <Form
          onSubmit={this.onChatMsgSubmit}
          className="card-body " // border-top
          id={`msgForm_${this.props.formKey}`}
        >
          <Row>
            <Col xs="12" md="12" lg="12" className="pl-0 pr-0 m-0">
              <Input
                type="textarea"
                rows={4}
                className="form-control mr-2"
                placeholder="Type your message"
                onChange={this.handleChatMsgChange}
                value={this.props.inbox.message.msg}
                required
              />
            </Col>
          </Row>
          {this.props.inbox.message.attachment_name && (
            <Row className="mt-2 upload-file-progress align-items-center">
              <Col xs="7" md="7" lg="7" className="pl-0">
                {` ${
                  this.props.inbox.message.attachment_name
                }  (${this.formatFileSize(
                  this.props.inbox.message.attachment_size
                )})`}
              </Col>
              <Col xs="5" md="5" lg="5" className="h-100 loading-progress">
                <Progress
                  animated
                  color="info"
                  value={this.props.inbox.message.loading_percentage}
                />{" "}
                <span id="clearFile" onClick={this.clearData}>
                  <i className="fas fa-times"></i>{" "}
                </span>
              </Col>
            </Row>
          )}

          {this.state.blobURL !== "" && (
            <Row>
              <Col className="p-0">
                <div className="title_active p-1 w-80">
                  <audio
                    src={this.state.blobURL}
                    style={{ width: "80%" }}
                    controls="controls"
                    controlsList="nodownload"
                  />
                  <span
                    id="audioclear"
                    onClick={() => {
                      this.clearData();
                    }}
                  >
                    <i className="fas fa-times"></i>{" "}
                  </span>
                </div>
              </Col>
            </Row>
          )}
          <Row className="mt-3 mb-3">
            <Col className="inbox-grid">
              <div>
                <input
                  type="file"
                  name="file"
                  id={`file_${this.props.formKey}`}
                  className="inputfile"
                  onChange={(e) => this.FileOnUpload(e)}
                  multiple={false}
                  accept="image/*,.doc,.docx,.pdf"
                  disabled={
                    this.state.blobURL !== "" ||
                    this.state.isRecording ||
                    this.state.sending
                  }
                />
                <label htmlFor={`file_${this.props.formKey}`} className="w-100">
                  <div
                    className={
                      this.state.blobURL !== "" || this.state.isRecording
                        ? ` icon-md-new w-100 disable_attachment`
                        : ` icon-md-new w-100`
                    }
                  >
                    <i className="fa fa-paperclip" />
                  </div>
                </label>
              </div>

              <div>
                {this.state.isRecording ? (
                  <>
                    <div className="recording-grid">
                      {" "}
                      <button className="recButton Rec"></button>{" "}
                      <div>
                        {this.state.min.toString().length < 2
                          ? "0" + this.state.min
                          : this.state.min}
                        :
                        {this.state.sec.toString().length < 2
                          ? "0" + this.state.sec
                          : this.state.sec}
                      </div>
                      <div className="icon-md-new2" onClick={this.stop}>
                        <i className="far fa-check-circle green-icon"></i>
                      </div>
                      <div
                        className="icon-md-new2"
                        onClick={() => {
                          this.deleteRecording();
                        }}
                      >
                        <i className="far fa-times-circle red-icon"></i>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    disabled={
                      this.props.inbox.message.attachment_name !== "" ||
                      this.state.sending
                    }
                    className={`w-100 ${
                      this.props.inbox.message.attachment_name !== "" ||
                      this.state.sending
                        ? "disable-icon "
                        : ""
                    } `}
                  >
                    <i
                      className="fa fa-microphone icon-md-new"
                      onClick={this.start}
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col xs="3" md="3" lg="3" className="p-0">
              <button
                onClick={() => {
                  this.sendComment();
                }}
                className="btn green-btn w-100"
                type="button"
                disabled={!this.props.inbox.message.msg || this.state.sending}
              >
                Send
              </button>
            </Col>
          </Row>

          <Row>
            <Col className="p-0">
              <div className="title_active p-1">
                *Allowed file types are word documents, images, and PDFs.
              </div>
            </Col>
          </Row>
        </Form>
        {/*--------------------------------------------------------------------------------*/}
        {/*end message Form*/}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MsgForm);
