import * as yup from "yup";
export const schema = yup.object({
  front_smiling: yup.string().required("Front Smiling is Required"),
  front_pose: yup.string().required("Front Pose Image is Required"),
  profile: yup.string().required("Profile Image is Required"),

  upper_occlusal: yup.string().required("Upper Occlusal Image is Required"),
  lower_occlusal: yup.string().required("Lower Occlusal Image is Required"),
  left_buccal: yup.string().required("Left Buccal Image is Required"),

  frontal: yup.string().required("Frontal Image is Required"),
  right_buccal: yup.string().required("Right Buccal Image is Required"),
  panoramic_xray: yup.string().required("Panoramic Xray is Required"),
  cephalometric_xray: yup.string()
  //.required("Cephalometric Xray is Required"),
});


export const PhotosSchema = yup.object({
  front_smiling: yup.string().required("Front Smiling is Required"),
  front_pose: yup.string().required("Front Pose Image is Required"),
  profile: yup.string().required("Profile Image is Required"),

  upper_occlusal: yup.string().required("Upper Occlusal Image is Required"),
  lower_occlusal: yup.string().required("Lower Occlusal Image is Required"),
  left_buccal: yup.string().required("Left Buccal Image is Required"),

  frontal: yup.string().required("Frontal Image is Required"),
  right_buccal: yup.string().required("Right Buccal Image is Required"),
});

export const XraysSchema = yup.object({

  panoramic_xray: yup.string().required("Panoramic Xray is Required"),
  cephalometric_xray: yup.string()
  //.required("Cephalometric Xray is Required"),
});