import React, { Component } from "react";
import {
  Button,
  Card,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardSubtitle,
  Table,
  Input,
  FormGroup,
} from "reactstrap";
import classnames from "classnames";
import {
  requestRetainer,
  downloadTreatmentPDF,
  pauseAlignerCase,
  resumeAlignerCase,
  submitReplacement,
  getRetainers,
  getReplacements,
  getTretmentSetup,
  getAlignerCase,
  markCaseAsComplete,
  archiveCase,
  unArchiveCase,
  //getDoctorInfo,
} from "../../api/api";
import CaseProgress from "./CaseProgress";
import Adjustment from "./Adjustment/Index";
import { connect } from "react-redux";
import moment from "moment";
import TreatmentSetup from "./TreatmentSetup";
import { successToaster, errorToaster } from "../../services/toast";
import NewCase from "../cases/newCase";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "../newPDF/MyDocument";
import { caseType } from "../../helpers/caseType";
import { resetAdjustment } from "../../redux/adjutment/action";
import FixPhotos from "../case-submission/FixStep/FixPhotos";
import FixXrays from "../case-submission/FixStep/FixXrays";
import Impressions from "../case-submission/Impressions";
import Prescription from "../case-submission/Prescription";
import Terms from "../case-submission/Terms"
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  resetAdjustment: (payload) => dispatch(resetAdjustment(payload)),
});
class Patient extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /*init state */
    this.state = {
      case: props.CaseDetails,
      doctorId: props.doctorId,
      retainer: false,
      sets: 1,
      pause: props.CaseDetails.status === "Paused" ? true : false,
      confirmPause: false,
      confirmResume: false,
      addReplacments: false,
      addReplacment: false,
      adjustment: false,
      Replacements: [],
      Replacement: {
        step_number: 1,
        upper: true,
        lower: true,
      },

      retainers_List: [],
      replacements_List: [],
      setups: [],
      fix: false,
      activeRetainerTab: 1,
      activeReplacementTab: 1,
      stepToFix: "",
      stepToFixName: "",
      loading: true,
      doctorInfo: {},
      mappedData: props.mappedData,
      sendingData: false,
      sendingRetainer: false,
      pusingCase: false,
      mark: false,
      completingCase: false,
      archiveModal: false,
      unArchiveModal: false,
    };
    this.loaderpdf = null;
  }
  /*--------------------------------------------------------------------------------*/
  /*get treatment setups & loading pdf  */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    this.loaderpdf = setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
    this.GetTreatmentSetups()
  }
  GetTreatmentSetups = async() => {
   await getTretmentSetup(this.state.doctorId, this.state.case.id).then((res) => {
      // sort treatment setups by id desc
      let sortedSetups = [].concat(res).sort((a, b) => (a.id < b.id ? 1 : -1));
      this.setState({ setups: sortedSetups });
    });
  }
  /*--------------------------------------------------------------------------------*/
  /*show / hide retainer modal   */
  /*--------------------------------------------------------------------------------*/
  toggleRetainerModal = () => {
    this.setState({ retainer: !this.state.retainer, activeRetainerTab: 1 });
  };
  /*--------------------------------------------------------------------------------*/
  /*show / hide confirmation Pause aligner case modal  */
  /*--------------------------------------------------------------------------------*/
  toggleConfirmationPauseModal = () => {
    this.setState({ confirmPause: !this.state.confirmPause });
  };
  /*--------------------------------------------------------------------------------*/
  /*show / hide confirmation Resume aligner case modal   */
  /*--------------------------------------------------------------------------------*/
  toggleConfirmationResumeModal = () => {
    this.setState({ confirmResume: !this.state.confirmResume });
  };
  /*--------------------------------------------------------------------------------*/
  /*show / hide add or review replacments in case modal   */
  /*--------------------------------------------------------------------------------*/
  toggleReplacementsModal = () => {
    this.setState({
      addReplacments: !this.state.addReplacments,
      activeReplacementTab: 1,
      Replacements: [],
    });
  };
  /*--------------------------------------------------------------------------------*/
  /*show / hide  add new replacment modal   */
  /*--------------------------------------------------------------------------------*/
  toggleReplacementModal = () => {
    this.setState({
      addReplacment: !this.state.addReplacment,
      Replacement: {
        step_number: 1,
        upper: true,
        lower: true,
      },
    });
  };
  /*--------------------------------------------------------------------------------*/
  /*show / hide  add new Adjustment modal */
  /*--------------------------------------------------------------------------------*/
  toggleAdjustmentModal = () => {
    this.props.resetAdjustment();
    this.setState({ adjustment: !this.state.adjustment });
  };
  /*--------------------------------------------------------------------------------*/
  /*show / hide  fix aligner case modal */
  /*--------------------------------------------------------------------------------*/
  toggleFixCaseModal = () => {
    this.setState({ fix: !this.state.fix });
  };
  /*--------------------------------------------------------------------------------*/
  /*set Active Retainer Tab */
  /*--------------------------------------------------------------------------------*/
  setActiveRetainerTab = (tab) => {
    if (this.state.activeRetainerTab !== tab) {
      this.setState({
        activeRetainerTab: tab,
      });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*mark */
  /*--------------------------------------------------------------------------------*/
  toggleMarkCaseModal = () => {
    this.setState({ mark: !this.state.mark });
  };
  /*--------------------------------------------------------------------------------*/
  /*set Active Replacement Tab  */
  /*--------------------------------------------------------------------------------*/
  setActiveReplacementTab = (tab) => {
    if (this.state.activeReplacementTab !== tab) {
      this.setState({
        activeReplacementTab: tab,
      });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /* Get Aligner Case                                                     */
  /*--------------------------------------------------------------------------------*/
  GetAlignerCase = () => {
    getAlignerCase(this.state.doctorId, this.state.case.id).then((res) => {
      this.setState({ case: res });
      this.props.UpdateCaseReducer(res);
    });
  };

  /*--------------------------------------------------------------------------------*/
  /* Reques Retainer                                                       */
  /*--------------------------------------------------------------------------------*/
  RequestRetainer = () => {
    this.setState({ sendingRetainer: !this.state.sendingRetainer });
    let data = {
      retainer: {
        sets: this.state.sets.toString(),
      },
    };

    requestRetainer(
      this.state.doctorId,
      this.state.case.uid,
      JSON.stringify(data)
    )
      .then((res) => {
        this.setState({
          retainer: !this.state.retainer,
          sets: 1,
          sendingRetainer: !this.state.sendingRetainer,
        });
        successToaster("Request new retainer has been submitted");
      })
      .catch((err) => {
        this.setState({ sendingRetainer: !this.state.sendingRetainer });
      });
  };
  /*--------------------------------------------------------------------------------*/
  /* Reques Retainer                                                       */
  /*--------------------------------------------------------------------------------*/
  GetRetainers = () => {
    getRetainers(this.state.doctorId, this.state.case.uid).then((res) => {
      this.setState({ retainers_List: res, retainer: !this.state.retainer });
    });
  };
  /*--------------------------------------------------------------------------------*/
  /* Download Treatment PDF                                                       */
  /*--------------------------------------------------------------------------------*/
  downloadTreatment = () => {
    downloadTreatmentPDF(this.state.doctorId, this.state.case.uid).then(
      (response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "Treatment.json";
          a.click();
        });
      }
    );
  };
  /*--------------------------------------------------------------------------------*/
  /* Pause Aligner Case                                                     */
  /*--------------------------------------------------------------------------------*/
  PauseAlignerCase = () => {
    this.setState({ pusingCase: !this.state.pusingCase });
    pauseAlignerCase(this.state.doctorId, this.state.case.uid)
      .then((res) => {
        this.setState({
          pause: !this.state.pause,
          confirmPause: !this.state.confirmPause,
          pusingCase: !this.state.pusingCase,
        });
        this.GetAlignerCase();
        successToaster("Aligner case has been paused");
      })
      .catch((err) => {
        this.setState({ pusingCase: !this.state.pusingCase });
      });
  };
  /*--------------------------------------------------------------------------------*/
  /* Resume Aligner Case                                                       */
  /*--------------------------------------------------------------------------------*/
  ResumeAlignerCase = () => {
    this.setState({ resumingCase: !this.state.resumingCase });

    resumeAlignerCase(this.state.doctorId, this.state.case.uid)
      .then((res) => {
        this.setState({
          pause: !this.state.pause,
          confirmResume: !this.state.confirmResume,
          resumingCase: !this.state.resumingCase,
        });
        this.GetAlignerCase();
        successToaster("Aligner case has been resumed");
      })
      .then((err) => {
        this.setState({ resumingCase: !this.state.resumingCase });
      });
  };
  /*--------------------------------------------------------------------------------*/
  /* Replacment validation each replacment step number > 0 & upper or lower = true  */
  /*--------------------------------------------------------------------------------*/
  checkReplacment = () => {
    let found = false;
    for (var x = 0; x < this.state.Replacements.length; x++) {
      if (
        (!this.state.Replacements[x]["upper"] &&
          !this.state.Replacements[x]["lower"]) ||
        this.state.Replacements[x]["step_number"] === "0" ||
        this.state.Replacements[x]["step_number"] === ""
      ) {
        found = true;
        break;
      }
    }
    return found;
  };
  /*--------------------------------------------------------------------------------*/
  /* Submit Replacement                                                     */
  /*--------------------------------------------------------------------------------*/
  SubmitReplacement = () => {
    if (this.checkReplacment()) {
      errorToaster("please check values ");
    } else {
      this.setState({ sendingData: !this.state.sendingData });
      let data = {
        replacement: {
          replacement_steps_attributes: this.state.Replacements,
        },
      };
      submitReplacement(
        this.state.doctorId,
        this.state.case.uid,
        JSON.stringify(data)
      )
        .then((res) => {
          this.setState({
            addReplacments: !this.state.addReplacments,
            Replacements: [],
            sendingData: !this.state.sendingData,
          });
          successToaster("Request new replacment has been submitted");
        })
        .catch((err) => {
          this.setState({ sendingData: !this.state.sendingData });
        });
    }
  };
  /*--------------------------------------------------------------------------------*/
  /* Get Replacements                                                   */
  /*--------------------------------------------------------------------------------*/
  GetReplacements = () => {
    getReplacements(this.state.doctorId, this.state.case.uid).then((res) => {
      this.setState({
        replacements_List: res,
        addReplacments: !this.state.addReplacments,
      });
    });
  };
  /*--------------------------------------------------------------------------------*/
  /*Fix Aligner Case                                                    */
  /*--------------------------------------------------------------------------------*/
  FixAlignerCase = (data) => {
    this.setState({ fix: !this.state.fix });
    this.GetAlignerCase();
  };
  /*--------------------------------------------------------------------------------*/
  /*Submit New Adjustment                                                   */
  /*--------------------------------------------------------------------------------*/
  SubmitNewAdjustment = () => {
    this.setState({
      adjustment: !this.state.adjustment,
    });
    this.GetAlignerCase();
    successToaster("new Adjustment has been submitted");
  };

  /*--------------------------------------------------------------------------------*/
  /*Mark Case As Complete                                                 */
  /*--------------------------------------------------------------------------------*/
  MarkCaseAsComplete = () => {
    this.setState({ completingCase: !this.state.completingCase });
    markCaseAsComplete(this.state.doctorId, this.state.case.uid)
      .then((res) => {
        this.setState({
          mark: !this.state.mark,
          completingCase: !this.state.completingCase,
        });
        this.GetAlignerCase();
        successToaster("Aligner case has been marked as completed");
      })
      .catch((err) => {
        this.setState({ completingCase: !this.state.completingCase });
      });
  };

  /* --------------------------------------------------------------------------------*/
  /* status where doctor can archive a case  */
  /* --------------------------------------------------------------------------------*/
  archivedStatus = (status) => {
    let statuses = [
      "Incomplete Submission",
      "Pending Courier Notification",
      "Pending Record Approval",
      "Pending TS Approval",
      "Generating TS",
      "Rejected TS",
      "Records Rejected",
      "Complete",
      "Awaiting Impressions",
      "Generating Adjustment TS",
      "Awaiting completion",
    ];
    return statuses.includes(status);
  };
  /* --------------------------------------------------------------------------------*/
  /* toggle Archive Modal */
  /* --------------------------------------------------------------------------------*/
  toggleArchiveModal = () => {
    this.setState({ archiveModal: !this.state.archiveModal });
  };
  /* --------------------------------------------------------------------------------*/
  /* Archive a Case */
  /* --------------------------------------------------------------------------------*/
  ArchiveCase = () => {
    archiveCase(this.state.doctorId, this.state.case.uid).then((res) => {
      successToaster("archived case successfully");
      this.toggleArchiveModal();
      this.GetAlignerCase();
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* toggle UnArchive Modal */
  /* --------------------------------------------------------------------------------*/
  toggleUnArchiveModal = () => {
    this.setState({ unArchiveModal: !this.state.unArchiveModal });
  };
  /* --------------------------------------------------------------------------------*/
  /* UnArchive a Case */
  /* --------------------------------------------------------------------------------*/
  UnArchiveCase = () => {
    unArchiveCase(this.state.doctorId, this.state.case.uid).then((res) => {
      successToaster("Restored case successfully");
      this.toggleUnArchiveModal();
      this.GetAlignerCase();
    });
  };
  /* --------------------------------------------------------------------------------*/
  /* clear state if go out from component   */
  /* --------------------------------------------------------------------------------*/

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    clearTimeout(this.loaderpdf);
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const wearCycle = {
      express: "1 Week",
      regular: "2 Week",
    };

    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/* Retainer Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.retainer}
          toggle={this.toggleRetainerModal}
          className="modalx2"
        >
          <ModalHeader toggle={this.toggleRetainerModal}>
            Request Retainer
          </ModalHeader>

          <ModalBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeRetainerTab === 1,
                  })}
                  onClick={() => {
                    this.setActiveRetainerTab(1);
                  }}
                >
                  New Retainers
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeRetainerTab === 2,
                  })}
                  onClick={() => {
                    this.setActiveRetainerTab(2);
                  }}
                >
                  Requested Retainers
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeRetainerTab}>
              <TabPane tabId={1}>
                <div className="form-group content form-block-holder h-100">
                  <label className="control-label title_active mt-3 ml-3 mb-1">
                    Sets Number
                  </label>
                  <Row className="m-1">
                    <Col xs="12" md="12" lg="12" className="p-2">
                      <input
                        autoComplete="off"
                        type="number"
                        // className={`m-3 mt-1 `}
                        defaultValue={this.state.sets}
                        onChange={(e) => {
                          this.setState({ sets: e.target.value });
                        }}
                      />
                    </Col>

                    <Col xs="12" md="12" lg="12" className="p-2">
                      <Button
                        className="btn green-btn "
                        size="md"
                        disabled={this.state.sendingRetainer}
                        onClick={() => this.RequestRetainer()}
                      >
                        submit
                      </Button>
                    </Col>
                  </Row>
                </div>
              </TabPane>

              <TabPane tabId={2}>
                {this.state.retainers_List.length !== 0 && (
                  <div>
                    <label className="control-label title_active mt-3 ml-3 mb-1">
                      The following retainers have been requested
                    </label>
                    <ul className={`modal-list`}>
                      {this.state.retainers_List.map((value, index) => {
                        return (
                          <li key={index}>
                            {`${value.sets} Sets on ${moment(
                              new Date(value.created_at)
                            ).format("DD MMMM YYYY")}`}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </TabPane>
            </TabContent>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Retainer Modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Mark case as completed Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal isOpen={this.state.mark} toggle={this.toggleMarkCaseModal}>
          <ModalHeader toggle={this.toggleMarkCaseModal}>
            Mark Aligner Case as Completed
          </ModalHeader>

          <ModalBody className="p-0">
            <div className="form-group content form-block-holder h-100">
              <label className="control-label mt-3 ml-3 mb-1">
                Are You Sure you want to Mark this Aligner Case as Completed ?
              </label>
              <Row className="m-1">
                <Col xs="4" md="4" lg="4" className="p-2 w-100">
                  <Button
                    className="btn green-btn w-100"
                    size="md"
                    disabled={this.state.completingCase}
                    onClick={() => this.MarkCaseAsComplete()}
                  >
                    Yes
                  </Button>
                </Col>
                <Col xs="4" md="4" lg="4" className="p-2 w-100">
                  <Button
                    className="btn red-btn w-100 "
                    size="md"
                    color="danger"
                    disabled={this.state.completingCase}
                    onClick={() => this.setState({ mark: !this.state.mark })}
                  >
                    No
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Mark case as completed Modal */}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* Confirmation Pause Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.confirmPause}
          toggle={this.toggleConfirmationPauseModal}
        >
          <ModalHeader toggle={this.toggleConfirmationPauseModal}>
            Pause Aligner Case
          </ModalHeader>

          <ModalBody className="p-0">
            <div className="form-group content form-block-holder h-100">
              <label className="control-label mt-3 ml-3 mb-1">
                Are You Sure you want to Pause this Aligner Case ?
              </label>
              <Row className="m-1">
                <Col xs="4" md="4" lg="4" className="p-2 w-100">
                  <Button
                    className="btn green-btn w-100"
                    size="md"
                    disabled={this.state.pusingCase}
                    onClick={() => this.PauseAlignerCase()}
                  >
                    Yes
                  </Button>
                </Col>
                <Col xs="4" md="4" lg="4" className="p-2 w-100">
                  <Button
                    className="btn red-btn w-100 "
                    size="md"
                    color="danger"
                    disabled={this.state.pusingCase}
                    onClick={() =>
                      this.setState({ confirm: !this.state.confirmPause })
                    }
                  >
                    No
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/*end Confirmation Pause Modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Confirmation Resume Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.confirmResume}
          toggle={this.toggleConfirmationResumeModal}
        >
          <ModalHeader toggle={this.toggleConfirmationResumeModal}>
            Resume Aligner Case
          </ModalHeader>

          <ModalBody className="p-0">
            <div className="form-group content form-block-holder h-100">
              <label className="control-label mt-3 ml-3 mb-1">
                Are You Sure you want to Resume this Aligner Case ?
              </label>
              <Row className="m-1">
                <Col xs="4" md="4" lg="4" className="p-2 w-100">
                  <Button
                    className="btn green-btn w-100"
                    size="md"
                    disabled={this.state.resumingCase}
                    onClick={() => this.ResumeAlignerCase()}
                  >
                    Yes
                  </Button>
                </Col>
                <Col xs="4" md="4" lg="4" className="p-2 w-100">
                  <Button
                    className="btn red-btn w-100 "
                    size="md"
                    disabled={this.state.resumingCase}
                    color="danger"
                    onClick={() =>
                      this.setState({
                        confirmResume: !this.state.confirmResume,
                      })
                    }
                  >
                    No
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Confirmation Resume Modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Replacements Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.addReplacments}
          toggle={this.toggleReplacementsModal}
          className="modalx2"
        >
          <ModalHeader toggle={this.toggleReplacementsModal}>
            <span className="title_active">Replacement Aligners Request</span>
          </ModalHeader>

          <ModalBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeReplacementTab === 1,
                  })}
                  onClick={() => {
                    this.setActiveReplacementTab(1);
                  }}
                >
                  New Replacements
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeReplacementTab === 2,
                  })}
                  onClick={() => {
                    this.setActiveReplacementTab(2);
                  }}
                >
                  Requested Replacement
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent
              activeTab={this.state.activeReplacementTab}
              className="replacment-tabs"
            >
              <TabPane tabId={1}>
                <div className="form-group content form-block-holder h-100">
                  {/* <Row className="m-1 mt-3 justify-content-end ">
                    <Col xs="6" md="3" lg="3">
                      <Button
                        className="btn green-btn w-100"
                        onClick={() => {
                          this.setState({
                            addReplacment: !this.state.addReplacment,
                          });
                        }}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row> */}

                  <Row className="mt-3 mb-3 ml-1 mr-1  ">
                    <Col
                      xs="1"
                      md="1"
                      lg="1"
                      className="d-flex align-items-end"
                    >
                      <i
                        className="fas fa-plus-circle add-replacment"
                        onClick={() => {
                          let newRep = this.state.Replacements.concat({
                            step_number: 1,
                            upper: true,
                            lower: true,
                          });
                          this.setState({
                            Replacements: newRep,
                          });

                          // this.setState({
                          //   addReplacment: !this.state.addReplacment,
                          // });
                        }}
                      />{" "}
                    </Col>
                    <Col xs="10" md="8" lg="8" className="replacements">
                      {this.state.Replacements.length > 0 && (
                        <div id="replacment-table">
                          <Table className="w-100 p-0" size="sm">
                            <thead>
                              <tr>
                                <th>Step Number</th>
                                <th className="text-center">Upper</th>
                                <th className="text-center">Lower</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.Replacements.map((value, index) => (
                                <tr
                                  key={index}
                                  className="align-items-center justify-content-center"
                                >
                                  <td>
                                    {" "}
                                    <div>
                                      <input
                                        autoComplete="off"
                                        type="number"
                                        className={`steps_num`}
                                        defaultValue={value.step_number}
                                        onChange={(e) => {
                                          e.persist();
                                          this.setState((state) => {
                                            const Replacements = state.Replacements.map(
                                              (item, j) => {
                                                if (j === index) {
                                                  return {
                                                    ...item,
                                                    step_number: e.target.value,
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                            );

                                            return {
                                              Replacements,
                                            };
                                          });
                                        }}
                                        min={1}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="content form-block-holder ">
                                      <div
                                        className={`custom-control custom-checkbox ${value.upper
                                          ? "replacment-checkbox"
                                          : ""
                                          }`}
                                      >
                                        <Input
                                          id={`upper${index}`}
                                          name={`upper${index}`}
                                          className={`custom-control-input `}
                                          type="checkbox"
                                          data-toggle="checkbox"
                                          checked={value.upper}
                                          onChange={(e) => {
                                            e.persist();
                                            this.setState((state) => {
                                              const Replacements = state.Replacements.map(
                                                (item, j) => {
                                                  if (j === index) {
                                                    return {
                                                      ...item,
                                                      upper: !value.upper,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              );

                                              return {
                                                Replacements,
                                              };
                                            });
                                          }}
                                        />
                                        <label
                                          className="custom-control-label title_active"
                                          htmlFor={`upper${index}`}
                                        ></label>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {" "}
                                    <div className="content form-block-holder ">
                                      <div
                                        className={`custom-control custom-checkbox ${value.lower
                                          ? "replacment-checkbox"
                                          : ""
                                          }`}
                                      >
                                        <input
                                          id={`lower${index}`}
                                          name={`lower${index}`}
                                          type="checkbox"
                                          className={`custom-control-input `}
                                          data-toggle="checkbox"
                                          checked={value.lower}
                                          onChange={(e) => {
                                            e.persist();
                                            this.setState((state) => {
                                              const Replacements = state.Replacements.map(
                                                (item, j) => {
                                                  if (j === index) {
                                                    return {
                                                      ...item,
                                                      lower: !value.lower,
                                                    };
                                                  } else {
                                                    return item;
                                                  }
                                                }
                                              );

                                              return {
                                                Replacements,
                                              };
                                            });
                                          }}
                                        />
                                        <label
                                          className="custom-control-label  title_active"
                                          htmlFor={`lower${index}`}
                                        ></label>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-times "
                                      onClick={() => {
                                        this.setState((state) => {
                                          const Replacements = state.Replacements.filter(
                                            (item, j) => index !== j
                                          );
                                          return {
                                            Replacements,
                                          };
                                        });
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      )}

                      {/* {this.state.Replacements.map((value, index) => (
                        <>
                          <Card key={index}>
                            <CardTitle>
                              <div className=" text-right p-0 pr-3 pt-3">
                                <i
                                  className="fa fa-times delete"
                                  onClick={() => {
                                    this.setState((state) => {
                                      const Replacements = state.Replacements.filter(
                                        (item, j) => index !== j
                                      );
                                      return {
                                        Replacements,
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </CardTitle>
                            <CardBody className="pt-0">
                              <Row>
                                <Col xs="12" md="4" lg="4">
                                  <div className="form-group content form-block-holder ">
                                    <label className="control-label title_active">
                                      Step number
                                    </label>
                                    <div>
                                      <input
                                        autoComplete="off"
                                        type="number"
                                        className={`steps_num`}
                                        defaultValue={value.step_number}
                                        onChange={(e) => {
                                          e.persist();
                                          this.setState((state) => {
                                            const Replacements = state.Replacements.map(
                                              (item, j) => {
                                                if (j === index) {
                                                  return {
                                                    ...item,
                                                    step_number: e.target.value,
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                            );

                                            return {
                                              Replacements,
                                            };
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col xs="12" md="4" lg="4">
                                  {" "}
                                  <div className="form-group content form-block-holder ">
                                    <label className="control-label title_active">
                                      {""}
                                    </label>
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        id={`upper${index}`}
                                        name={`upper${index}`}
                                        className={`custom-control-input `}
                                        type="checkbox"
                                        data-toggle="checkbox"
                                        checked={value.upper}
                                        onChange={(e) => {
                                          e.persist();
                                          this.setState((state) => {
                                            const Replacements = state.Replacements.map(
                                              (item, j) => {
                                                if (j === index) {
                                                  return {
                                                    ...item,
                                                    upper: !value.upper,
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                            );

                                            return {
                                              Replacements,
                                            };
                                          });
                                        }}
                                      />
                                      <label
                                        className="custom-control-label title_active"
                                        htmlFor={`upper${index}`}
                                      >
                                        Upper
                                      </label>
                                    </div>
                                  </div>
                                </Col>
                                <Col xs="12" md="4" lg="4">
                                  <div className="form-group content form-block-holder ">
                                    <label className="control-label title_active">
                                      {""}
                                    </label>
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        id={`lower${index}`}
                                        name={`lower${index}`}
                                        type="checkbox"
                                        className={`custom-control-input `}
                                        data-toggle="checkbox"
                                        checked={value.lower}
                                        onChange={(e) => {
                                          e.persist();
                                          this.setState((state) => {
                                            const Replacements = state.Replacements.map(
                                              (item, j) => {
                                                if (j === index) {
                                                  return {
                                                    ...item,
                                                    lower: !value.lower,
                                                  };
                                                } else {
                                                  return item;
                                                }
                                              }
                                            );

                                            return {
                                              Replacements,
                                            };
                                          });
                                        }}
                                      />
                                      <label
                                        className="custom-control-label  title_active"
                                        htmlFor={`lower${index}`}
                                      >
                                        Lower
                                      </label>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </>
                      ))} */}
                    </Col>
                  </Row>
                  <Row className="m-1 mb-4  p-0">
                    <Col xs="12" md="4" lg="4">
                      <Button
                        disabled={
                          this.state.Replacements.length === 0 ||
                          this.state.sendingData ||
                          this.checkReplacment()
                        }
                        className="btn green-btn pull-right w-100"
                        onClick={() => this.SubmitReplacement()}
                      >
                        Submit Request
                      </Button>
                    </Col>
                  </Row>
                </div>
              </TabPane>
              <TabPane tabId={2}>
                {this.state.replacements_List.length !== 0 && (
                  <div>
                    <label className="control-label title_active mt-3 ml-3 mb-1">
                      The following replacement aligners have been requested
                    </label>
                    <ul>
                      {this.state.replacements_List.map((value, index) => {
                        return this.state.replacements_List[
                          index
                        ].replacement_steps.map((value, index) => {
                          return (
                            <li key={index}>
                              {` Step ${value.step_number}  (${value.upper ? `Upper` : ``
                                }${value.lower && value.upper ? `,` : ``}${value.lower ? ` Lower` : ``
                                } ) on ${moment(
                                  new Date(value.created_at)
                                ).format("DD MMMM YYYY")}`}
                            </li>
                          );
                        });
                      })}
                    </ul>
                  </div>
                )}
              </TabPane>
            </TabContent>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Replacements Modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* add Replacement Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.addReplacment}
          toggle={this.toggleReplacementModal}
          className="modalx3"
        >
          <ModalHeader toggle={this.toggleReplacementModal}>
            <span className="title_active">
              Add Replacement Aligners Request
            </span>
          </ModalHeader>

          <ModalBody className="p-0">
            <div className="form-group content align-items-center form-block-holder h-100">
              <Row className="m-3 mt-4 ">
                <Col xs="12" md="4" lg="4">
                  <div className="form-group content form-block-holder ">
                    <label className="control-label title_active">
                      Step number
                    </label>
                    <div>
                      <input
                        autoComplete="off"
                        type="number"
                        className={`steps_num`}
                        // className={`m-3 mt-1 `}
                        defaultValue={this.state.Replacement.step_number}
                        onChange={(e) => {
                          this.setState({
                            Replacement: {
                              ...this.state.Replacement,
                              step_number: e.target.value,
                            },
                          });
                        }}
                        min={1}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="4" lg="4">
                  <div className="form-group content form-block-holder">
                    <label className="control-label title_active">{""}</label>
                    <div
                      className={`custom-control custom-checkbox ${this.state.Replacement.upper
                        ? "replacment-checkbox"
                        : ""
                        }`}
                    >
                      <input
                        id="add-upper"
                        name="add-upper"
                        type="checkbox"
                        className={`custom-control-input `}
                        data-toggle="checkbox"
                        defaultValue={this.state.Replacement.upper}
                        onChange={(e) => {
                          this.setState({
                            Replacement: {
                              ...this.state.Replacement,
                              upper: !this.state.upper,
                            },
                          });
                        }}
                      />
                      <label
                        className="custom-control-label title_active"
                        htmlFor="add-upper"
                      >
                        Upper
                      </label>
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="4" lg="4">
                  <div className="form-group content form-block-holder">
                    <label className="control-label title_active">{""}</label>
                    <div
                      className={`custom-control custom-checkbox ${this.state.Replacement.lower
                        ? "replacment-checkbox"
                        : ""
                        }`}
                    >
                      <input
                        id="add-lower"
                        name="add-lower"
                        type="checkbox"
                        className={`custom-control-input `}
                        data-toggle="checkbox"
                        defaultValue={this.state.Replacement.lower}
                        onChange={(e) => {
                          this.setState({
                            Replacement: {
                              ...this.state.Replacement,
                              lower: !this.state.lower,
                            },
                          });
                        }}
                      />
                      <label
                        className="custom-control-label title_active"
                        htmlFor="add-lower"
                      >
                        Lower
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="m-1 justify-content-end">
                <Col xs="4" md="4" lg="4" className="p-2 w-100">
                  <Button
                    className="btn green-btn w-100"
                    size="md"
                    disabled={
                      !this.state.Replacement.upper &&
                      !this.state.Replacement.lower
                    }
                    onClick={() => {
                      let newRep = this.state.Replacements.concat(
                        this.state.Replacement
                      );
                      this.setState({
                        Replacements: newRep,
                        Replacement: {
                          step_number: 1,
                          upper: true,
                          lower: true,
                        },
                        addReplacment: !this.state.addReplacment,
                      });
                    }}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end add Replacement Modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Adjustment Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.adjustment}
          toggle={this.toggleAdjustmentModal}
          className="modalx4"
        >
          <ModalHeader toggle={this.toggleAdjustmentModal}>
            {" "}
            New Adjustment{" "}
          </ModalHeader>
          <ModalBody>
            <Adjustment
              DoctorId={this.state.doctorId}
              UID={this.props.CaseDetails.uid}
              SubmitNewAdjustment={this.SubmitNewAdjustment}
            />
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Adjustment Modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Fix Aligner Case Modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.fix}
          toggle={this.toggleFixCaseModal}
          className={this.state.stepToFix?.reason?.step === "xrays" ?
            "FixXrayStepModal" : this.state.stepToFix?.reason?.step === "clinical_findings" ?
              "fix-preferances-modal" : "FixStepModal"}
        >
          <ModalHeader toggle={this.toggleFixCaseModal}>
            Fix {this.state.stepToFixName}
          </ModalHeader>
          <ModalBody>
            {this.state.stepToFix?.reason?.step === "photos" ?
              <FixPhotos FixAlignerCase={this.FixAlignerCase}
                fix={this.state.stepToFix.reason}
                caseId={this.props.CaseDetails.id}
                rejectable_id={this.state.stepToFix.id}
                rejectable_type={this.state.stepToFix.rejectable_type}
                stepToFix_id={this.state.stepToFix.rejectable_id} /> :
              this.state.stepToFix?.reason?.step === "xrays" ?
                <FixXrays
                  FixAlignerCase={this.FixAlignerCase}
                  fix={this.state.stepToFix.reason}
                  caseId={this.props.CaseDetails.id}
                  rejectable_id={this.state.stepToFix.id}
                  rejectable_type={this.state.stepToFix.rejectable_type}
                  stepToFix_id={this.state.stepToFix.rejectable_id} />
                :
                this.state.stepToFix?.reason?.step === "impressions" ?
                  <Impressions
                    case={this.state.case}
                    FixAlignerCase={this.FixAlignerCase}
                    fix={this.state.stepToFix.reason}
                    caseId={this.props.CaseDetails.uid}
                    rejectable_id={this.state.stepToFix.id}
                    rejectable_type={this.state.stepToFix.rejectable_type}
                    stepToFix_id={this.state.stepToFix.rejectable_id}
                  />
                  :
                  this.state.stepToFix?.reason?.step === "clinical_findings" ?
                    <Prescription
                      FixAlignerCase={this.FixAlignerCase}
                      fix={this.state.stepToFix.reason}
                      caseId={this.props.CaseDetails.id}
                      rejectable_id={this.state.stepToFix.id}
                      rejectable_type={this.state.stepToFix.rejectable_type}
                      stepToFix_id={this.state.stepToFix.rejectable_id}
                    />
                    :
                    <Terms
                      FixAlignerCase={this.FixAlignerCase}
                      fix={this.state.stepToFix.reason}
                      caseId={this.props.CaseDetails.id}
                      rejectable_id={this.state.stepToFix.id}
                      rejectable_type={this.state.stepToFix.rejectable_type}
                      stepToFix_id={this.state.stepToFix.rejectable_id}
                    />
              // <NewCase
              //   FixAlignerCase={this.FixAlignerCase}
              //   fix={this.state.stepToFix.reason}
              //   caseId={this.props.CaseDetails.id}
              //   rejectable_id={this.state.stepToFix.id}
              //   rejectable_type={this.state.stepToFix.rejectable_type}
              //   stepToFix_id={this.state.stepToFix.rejectable_id}
              // />

            }
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* end Fix Aligner Case Modal */}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* Archive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.archiveModal}
          toggle={this.toggleArchiveModal}
        >
          <ModalHeader toggle={this.toggleArchiveModal}>
            Archive a Case
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <h5>Are you sure you want to archive this case?</h5>
            </FormGroup>
            <FormGroup>
              <Button
                className="btn red-btn pull-right m-1 "
                size="md"
                color="danger"
                onClick={this.toggleArchiveModal}
              >
                Cancel
              </Button>
              <Button
                className="btn green-btn pull-right m-1"
                size="md"
                type="submit"
                onClick={this.ArchiveCase}
              >
                Archive
              </Button>
            </FormGroup>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Archive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* unArchive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.unArchiveModal}
          toggle={this.toggleUnArchiveModal}
        >
          <ModalHeader toggle={this.toggleUnArchiveModal}>
            Restore a Case
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <h5>Are you sure you want to restore this case?</h5>
            </FormGroup>
            <FormGroup>
              <Button
                className="btn red-btn pull-right m-1 "
                size="md"
                color="danger"
                onClick={this.toggleUnArchiveModal}
              >
                Cancel
              </Button>
              <Button
                className="btn green-btn pull-right m-1"
                size="md"
                type="submit"
                onClick={this.UnArchiveCase}
              >
                Restore
              </Button>
            </FormGroup>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End unArchive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}
        <Row className="m-3 mt-5 mb-5 ">
          <Col xs="11" md="11" lg="11" className="m-auto pl-0 pr-0">
            {this.props.CaseDetails.case_type === "Retainer" ? (
              <Row className="text-center">
                <Col xs="12" md="3" lg="3" className="retiainer-border">
                  <label className="control-label title_active">
                    Case Number
                  </label>
                  <hr></hr>
                  <div>{this.props.CaseDetails.uid}</div>
                </Col>
                <Col xs="12" md="3" lg="3" className="retiainer-border">
                  <label className="control-label title_active">
                    Case Type
                  </label>
                  <hr></hr>
                  <div>{this.props.CaseDetails.case_type}</div>
                </Col>
                <Col xs="12" md="3" lg="3" className="retiainer-border">
                  <label className="control-label title_active">
                    Patient Name
                  </label>
                  <hr></hr>
                  <div>{this.props.CaseDetails.patient.full_name}</div>
                </Col>
                <Col xs="12" md="3" lg="3" className="retiainer-border">
                  <label className="control-label title_active">
                    Case Status
                  </label>
                  <hr></hr>
                  <div> {this.props.CaseDetails.status}</div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs="12" md="7" lg="7">
                  <Row className="align-items-center">
                    <Col xs="12" md="12" lg="4" className="case-info">
                      <img
                        src={this.props.CaseDetails.patient.photo}
                        width="100%"
                        style={{ maxWidth: "200px" }}
                        height="168"
                        alt=""
                      />
                    </Col>
                    <Col
                      xs="12"
                      md="12"
                      lg="8"
                      className="mobile-padding case-info"
                    >
                      <Row>
                        <Col xs="12" md="4" lg="4">
                          <strong>Full Name</strong>
                        </Col>
                        <Col xs="12" md="8" lg="8" className="text-start">
                          <p className="text-muted">
                            {this.props.CaseDetails.patient.full_name}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="4" lg="4">
                          <strong>UUID</strong>
                        </Col>

                        <Col xs="12" md="8" lg="8">
                          <p className="text-muted">
                            {this.props.CaseDetails.uid}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="4" lg="4">
                          <strong>Case Type</strong>
                        </Col>

                        <Col xs="12" md="8" lg="8">
                          <p className="text-muted">
                            {caseType[this.props.CaseDetails.case_type] ||
                              this.props.CaseDetails.case_type}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="4" lg="4">
                          <strong>Wear Cycle</strong>
                        </Col>
                        <Col xs="12" md="8" lg="8">
                          <p className="text-muted">
                            {wearCycle[this.props.CaseDetails.wear_cycle] ||
                              this.props.CaseDetails.wear_cycle}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" md="4" lg="4">
                          <strong>Case Status</strong>
                        </Col>
                        <Col xs="12" md="8" lg="8">
                          <p className="text-muted">
                            {this.props.CaseDetails.status}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col xs="12" md="5" lg="5" className="case-info">
                  {!this.state.loading && (
                    <Row>
                      {/* Download Treatment PDF */}
                      <PDFDownloadLink
                        document={
                          <MyDocument
                            case={this.props.CaseDetails}
                            mappedData={this.state.mappedData}
                            doctorInfo={this.state.doctorInfo}
                          />
                        }
                        fileName={`treatment form_${this.props.CaseDetails.uid}.pdf`}
                        className="btn green-btn w-100 m-1"
                      >
                        {({ blob, url, loading, error }) =>
                          loading
                            ? "Loading document..."
                            : "Download Treatment PDF"
                        }
                      </PDFDownloadLink>{" "}
                    </Row>
                  )}

                  {(this.props.CaseDetails.status === "Active" ||
                    this.props.CaseDetails.status === "Awaiting completion") &&
                    !this.props.CaseDetails.is_archived && (
                      <div>
                        <Row>
                          {/* Adjustment */}
                          <Button
                            className="btn green-btn w-100 m-1"
                            size="md"
                            onClick={() => {
                              this.setState({
                                adjustment: !this.state.adjustment,
                              });
                            }}
                          >
                            Adjustment
                          </Button>
                        </Row>
                      </div>
                    )}

                  {(this.props.CaseDetails.status === "Active" ||
                    this.props.CaseDetails.status === "Awaiting completion" ||
                    this.props.CaseDetails.status === "Paused") &&
                    !this.props.CaseDetails.is_archived && (
                      <div>
                        <Row>
                          {/* Request Replacement Aligners */}
                          <Button
                            className="btn green-btn w-100 m-1"
                            size="md"
                            onClick={() => {
                              this.GetReplacements();
                            }}
                          >
                            Request Replacement Aligners
                          </Button>
                        </Row>
                      </div>
                    )}

                  {(this.props.CaseDetails.status === "Active" ||
                    this.props.CaseDetails.status === "Awaiting completion") &&
                    !this.props.CaseDetails.is_archived && (
                      <div>
                        <Row>
                          {/* Request Retainer */}
                          <Button
                            className="btn green-btn w-100 m-1"
                            size="md"
                            onClick={() => {
                              this.GetRetainers();
                            }}
                          >
                            Request Retainer
                          </Button>
                        </Row>
                        <Row>
                          {/* Pause aligner case */}
                          <Button
                            className="btn green-btn w-100 m-1"
                            size="md"
                            onClick={() =>
                              this.setState({
                                confirmPause: !this.state.confirmPause,
                              })
                            }
                          >
                            Pause
                          </Button>
                        </Row>
                      </div>
                    )}

                  {this.props.CaseDetails.status === "Paused" &&
                    !this.props.CaseDetails.is_archived && (
                      <Row>
                        {/* Resume aligner case */}
                        <Button
                          className="btn green-btn w-100 m-1"
                          size="md"
                          onClick={() =>
                            this.setState({
                              confirmResume: !this.state.confirmResume,
                            })
                          }
                        >
                          Resume
                        </Button>
                      </Row>
                    )}
                  {this.props.CaseDetails.status === "Awaiting completion" &&
                    !this.props.CaseDetails.is_archived && (
                      <Row className="mb-2">
                        {/* mark case as Completed*/}
                        <Button
                          className="btn green-btn w-100 m-1"
                          size="md"
                          onClick={() =>
                            this.setState({ mark: !this.state.mark })
                          }
                        >
                          Mark as completed
                        </Button>
                      </Row>
                    )}


                  {/*--------------------------------------------------------------------------------*/}
                  {/* Archive a Case*/}
                  {/*--------------------------------------------------------------------------------*/}
                  {this.archivedStatus(this.props.CaseDetails.status) &&
                    !this.props.CaseDetails.is_archived && (
                      <div>
                        <Row>
                          {/* Archive a Case */}
                          <Button
                            className="btn green-btn w-100 m-1"
                            size="md"
                            onClick={() => {
                              this.setState({
                                archiveModal: !this.state.archiveModal,
                              });
                            }}
                          >
                            Archive a Case
                          </Button>
                        </Row>
                      </div>
                    )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* End Archive a Case*/}
                  {/*--------------------------------------------------------------------------------*/}

                  {/*--------------------------------------------------------------------------------*/}
                  {/* UnArchive a Case*/}
                  {/*--------------------------------------------------------------------------------*/}
                  {this.props.CaseDetails.is_archived && (
                    <div>
                      <Row>
                        {/* UnArchive a Case */}
                        <Button
                          className="btn green-btn w-100 m-1"
                          size="md"
                          onClick={() => {
                            this.setState({
                              unArchiveModal: !this.state.unArchiveModal,
                            });
                          }}
                        >
                          Restore a Case
                        </Button>
                      </Row>
                    </div>
                  )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* End Archive a Case*/}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* Rejection reasons*/}
                  {/*--------------------------------------------------------------------------------*/}

                  {this.props.CaseDetails.status === "Records Rejected" && (
                    <div>
                      <label className="control-label title_active p-1">
                        Case rejected for the following reasons:
                      </label>
                      <Row>
                        <Col>
                          {this.props.CaseDetails.rejections
                            .filter((value) => {
                              return !value.fixed;
                            })
                            .map((value, index) => {
                              return (
                                <Card key={index} className="text-center p-3">
                                  <CardTitle className="">
                                    {" "}
                                    {value.reason.name}
                                  </CardTitle>

                                  <CardSubtitle>
                                    {value.reason.description}
                                  </CardSubtitle>

                                  {!this.props.CaseDetails.is_archived && (
                                    <Button
                                      className="btn red-btn w-100"
                                      size="md"
                                      color="danger"
                                      onClick={() => {
                                        this.setState({
                                          fix: !this.state.fix,
                                          stepToFix: value,
                                          stepToFixName: value.reason.name,
                                        });
                                        //window.location.href = `/case/new?id=${this.props.CaseDetails.id}&fix=${value.reason.step}`;
                                        // this.props.history.push(
                                        //   `/case/new?id=${this.props.CaseDetails.id}&fix=${value.reason.step}`
                                        // );
                                      }}
                                    >
                                      Fix
                                    </Button>
                                  )}
                                </Card>
                              );
                            })}
                          {this.props.CaseDetails.adjustment_rejections &&
                            this.props.CaseDetails.adjustment_rejections
                              .filter((value) => {
                                return !value.fixed;
                              })
                              .map((value, index) => {
                                return (
                                  <Card key={index} className="text-center p-3">
                                    <CardTitle className="">
                                      {" "}
                                      {value.reason.name}
                                    </CardTitle>

                                    <CardSubtitle>
                                      {value.reason.description}
                                    </CardSubtitle>

                                    {!this.props.CaseDetails.is_archived && (
                                      <Button
                                        className="btn red-btn w-100"
                                        size="md"
                                        color="danger"
                                        onClick={() => {
                                          this.setState({
                                            fix: !this.state.fix,
                                            stepToFix: value,
                                            stepToFixName: value.reason.name,
                                          });
                                          //window.location.href = `/case/new?id=${this.props.CaseDetails.id}&fix=${value.reason.step}`;
                                          // this.props.history.push(
                                          //   `/case/new?id=${this.props.CaseDetails.id}&fix=${value.reason.step}`
                                          // );
                                        }}
                                      >
                                        Fix
                                      </Button>
                                    )}
                                  </Card>
                                );
                              })}
                        </Col>
                      </Row>
                    </div>
                  )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* end Rejection reasons*/}
                  {/*--------------------------------------------------------------------------------*/}


                </Col>
              </Row>
            )}

            {/*--------------------------------------------------------------------------------*/}
            {/* Treatment Setups */}
            {/*--------------------------------------------------------------------------------*/}
            {this.state.setups.length !== 0 &&
              this.state.setups.map((value, index) => (
                <div key={index}>
                  <TreatmentSetup
                    value={value}
                    case={this.props.currentCase.state}
                    doctorId={this.state.doctorId}
                    data={this.props.data}
                    GetAlignerCase={this.GetAlignerCase}
                    GetTreatmentSetups={this.GetTreatmentSetups}
                  />
                </div>
              ))}
            {/*--------------------------------------------------------------------------------*/}
            {/* end Treatment Setups */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/* Stages */}
            {/*--------------------------------------------------------------------------------*/}
            {this.props.CaseDetails.stages && (
              <Row className="align-content-center m-auto">
                <Col className="progress-table p-0">
                  <CaseProgress
                    progress={this.props.CaseDetails.stages}
                    additional={
                      this.props.CaseDetails.additional_stages
                        ? this.props.CaseDetails.additional_stages
                        : []
                    }
                    CaseDetails={this.props.CaseDetails}
                    stagesLength={
                      this.props.CaseDetails.additional_stages
                        ? [this.props.CaseDetails.additional_stages].length +
                        this.props.CaseDetails.stages.length
                        : this.props.CaseDetails.stages.length
                    }
                  />
                </Col>
              </Row>
            )}

            {/*--------------------------------------------------------------------------------*/}
            {/* end Stages */}
            {/*--------------------------------------------------------------------------------*/}
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Patient);
