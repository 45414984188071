import React, { Component } from "react";
import { connect } from "react-redux";

import CustomeTeeth from "../../../Teeth/CustomeTeeth";
import { Button, Row, Col } from "reactstrap";
import ToggleSwitch from "../../../../components/Switch/Switch";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
import { mapTeethToApi } from "../../../../services/mapTeethToApi";
import { Clear_Teeth_Data } from "../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Extraction extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_extraction: this.props.caseSubmission.advanced_modules.extraction
        .use_extraction,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "extraction",
      data: {
        use_extraction: this.state.use_extraction,
        teeth: this.props.customeTeethReducer.Case_Extraction,
      },
    });
    this.props.save();
  };

  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  render() {
    const { use_extraction } = this.state;

    return (
      <div>

        <div className="text-center">
          <div className="control-label title_active-gco text-center mb-2">
            Use Extraction for this case
           </div>

          <TreatmentOptions value={use_extraction} name="use_extraction" summary={this.props.summary} onChange={(value) => {
            this.handelTreatmentOptionsChanges(value, "use_extraction")
            if (value !== true) {
              this.props.Clear_Teeth_Data("Case_Extraction")
            }
          }} />
        </div>



        <div>
          <div className="control-label title_active-gco text-center pb-2 mt-2  ">
            Extract
              </div>
          <div className="teeth_selector">

            <CustomeTeeth action_key="Case_Extraction" disabled={!use_extraction || use_extraction === "eonToDecide"} summary={this.props.summary} />
          </div>
        </div>

        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => {
              this.UpdateAdvancedModules();
            }}
          >
            Save
        </Button>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Extraction);
