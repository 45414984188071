import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input, Row, Col } from "reactstrap";
import ToggleSwitch from "../../../../components/Switch/Switch";
import CustomeTeeth from "../../../Teeth/CustomeTeeth";
import { mapTeethToApi } from "../../../../services/mapTeethToApi";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class TorqueEnhancers extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_torque_enhancers: this.props.caseSubmission.advanced_modules
        .torqueEnhancers.useTorqueEnhancers,
      torque_enhancers_details: this.props.caseSubmission.advanced_modules
        .torqueEnhancers.details,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "torqueEnhancers",
      data: {
        useTorqueEnhancers: this.state.use_torque_enhancers,
        teeth:
          this.props.customeTeethReducer.Case_Torque_Enhancers
        ,
        details: this.state.torque_enhancers_details,
      },
    });
    this.props.save();
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  render() {
    const { use_torque_enhancers, torque_enhancers_details } = this.state;

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">

          <div className="text-center">
            <div className="control-label title_active-gco text-center mb-2">
              Use torque enhancers for this case{" "}
            </div>
            <TreatmentOptions value={use_torque_enhancers} name="use_torque_enhancers" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_torque_enhancers")
              if (value !== true) {
                this.setState({
                  torque_enhancers_details: ""
                })
                this.props.Clear_Teeth_Data("Case_Torque_Enhancers")
              }
            }} />
          </div>

          <div>
            <Input
              className="teeth_selector"
              name="torque_enhancers_details"
              id="torque_enhancers_details"
              type="textarea"
              placeholder="Please add details"
              rows={5}
              value={torque_enhancers_details}
              onChange={(e) => {
                this.setState({ torque_enhancers_details: e.target.value });
              }}
              disabled={!use_torque_enhancers || use_torque_enhancers === "eonToDecide" || this.props.summary}
            />
          </div>

          <div>
            <div className="control-label title_active-gco text-center pb-2 mt-2 ">
              Placement
              </div>

            <div className="teeth_selector">  <CustomeTeeth
              action_key="Case_Torque_Enhancers"
              disabled={!use_torque_enhancers || use_torque_enhancers === "eonToDecide"}
              summary={this.props.summary}
            /></div>
          </div>


        </div>
        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            Save
        </Button>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TorqueEnhancers);
