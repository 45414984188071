import styles from "../eonAccess-style/eonAccess.module.scss";
export const stepsStyleValidation = (
  stepNumber,
  maxStepsNumber,
  lowerSteps,
  upperSteps,
  allStepsForUpperOrLower,
  startTogether,
  passiveAligners,
  overCorrectionStepsNumber
) => {
  // steps style
  let classList =
  allStepsForUpperOrLower - 1 < maxStepsNumber || lowerSteps === upperSteps
      ? styles.allSteps
      : styles.step;
  // passive aligners style
  let isPassiveAligners = passiveAligners
    ? styles.passiveAligners
    : styles.emptyStep;
    
  let styleArray = [];

  let passiveStartTogetherAndEndTogetherStepsStyle = () => {
    // if the number of the the steps for the upper or lower  = the max number of steps
    // add the normal step style
    if (allStepsForUpperOrLower - 1 === maxStepsNumber) {
      styleArray.push(classList);
    } else {
      if (startTogether) {
        // if the steps start together and the curent step number <
        // the number of the the steps for the upper or lower
        // add the normal step style
        if (stepNumber < allStepsForUpperOrLower) {
          styleArray.push(classList);
          // otherwise we check if we have passive aligner 
          // if true we add the passive aligner step style
          // otherwise we add the empty step style
        } else {
          styleArray.push(isPassiveAligners);
        }
      } else {
        // endTogather (startTogether = false)
        // we check if the curent step number is <= max stepsnumber - 
        // (upper or lower steps number) we add the passive steps style according 
        // to the passive state
        if (stepNumber <= maxStepsNumber - (allStepsForUpperOrLower - 1)) {
          styleArray.push(isPassiveAligners);
        } else {
          //otherwise we add the normal step style
          styleArray.push(classList);
        }
      }
  }
}

  let overCorrectionStartTogetherStepsStyle = () => {
    // if the upper or lower steps < the max steps number and the curent step > 
    // the number of step without the overCorrection we add the overCorrection step style to the remaining steps
    if (allStepsForUpperOrLower - 1 < maxStepsNumber) {
      let allStepsWithoutOC = allStepsForUpperOrLower - 1 - overCorrectionStepsNumber;
      if (stepNumber > allStepsWithoutOC && stepNumber < allStepsForUpperOrLower) {
        styleArray.push(styles.overCorrectionStepsNumber);
      }
    }
      // if the upper or lower steps = the max steps number and the curent step > 
      // (the number of max steps - number of steps without the overCorrection)
      // we add the overCorrection step style to the remaining steps
    if (allStepsForUpperOrLower - 1 === maxStepsNumber &&
      stepNumber > maxStepsNumber - overCorrectionStepsNumber) {
      styleArray.push(styles.overCorrectionStepsNumber);
    }
  }

  let overCorrectionEndTogetherStepsStyle = () => {
    // if the curent step number > (max steps number - the number of overCorrection steps)
    // we add the overCorrection step style
    if (stepNumber > maxStepsNumber - overCorrectionStepsNumber) {
      styleArray.push(styles.overCorrectionStepsNumber);
    }
  }

  let stepsStyle = () => {
    passiveStartTogetherAndEndTogetherStepsStyle();
    if(!passiveAligners && overCorrectionStepsNumber > 0) {
      if (startTogether) {
        overCorrectionStartTogetherStepsStyle();
      } else {
        overCorrectionEndTogetherStepsStyle();
      }
    }
    // add first step style to step indix 0(first step)
    if (stepNumber === 0) {
      styleArray.push(styles.firstStep);
    }
  }
  stepsStyle();
  
  return styleArray.join(" ");
};
