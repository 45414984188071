import React, { Component } from "react";
import { Button, Row, Col } from "reactstrap";
export default class Controles extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      RedirectToCases: false,
      user: props.props.userReducer.currentUser,
      cases: [],
      newCases: false,
    };
  }
  render() {
    return (
      <div>
        <div className="text-center loyalty">
          What would you like to do today?{" "}
        </div>
        <Row className="text-center">
          <Col xs="12" md="6" className="p-3 ">
            <Button
              className="btn green-btn h-100 w-100"
              size="lg"
              onClick={() => {
                this.props.props.history.push({
                  pathname: "/case/new",
                  state: { id: this.state.user.id },
                });
              }}
            >
              Submit a Case
            </Button>
          </Col>
          <Col xs="12" md="6" className="p-3 ">
            <Button
              className="btn green-btn h-100 w-100"
              size="lg"
              onClick={() => {
                this.props.props.history.push({
                  pathname: "/cases",
                  state: { id: this.state.user.id },
                });
              }}
            >
              Review a Case
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
