import { upper_teeth, lower_teeth } from "../redux/newCase/init_teeth";

export function mapTeeth(newData) {
  let RightTeethUpper = mapRightTeeth(newData, "upper_r_", upper_teeth, "R", "U");
  let RightTeethLower = mapRightTeeth(newData, "lower_r_", lower_teeth, "R", "L");

  let LeftTeethUpper = mapLeftTeeth(newData, "upper_l_", upper_teeth, "L", "U");
  let LeftTeethLower = mapLeftTeeth(newData, "lower_l_", lower_teeth, "L", "L");

  let teeth = {
    upper_teeth: { ...RightTeethUpper, ...LeftTeethUpper },
    lower_teeth: { ...RightTeethLower, ...LeftTeethLower },
  };
  return teeth;
}

function mapRightTeeth(newData, key, teeth, side, position) {
  let num = 8;
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]:
        newData !== undefined && newData[side] !== {} && newData[side] !== undefined
          ? {
            ...teeth[`${key}${num}`],
            image:
              newData[side][`${position}${num}`] === "true"
                ? teeth[`${key}${num}`].active
                : teeth[`${key}${num}`].not_active,
            value: newData[side][`${position}${num}`] === "true" ? true : false,
          }
          : {
            ...teeth[`${key}${num}`],
          },
    };
    num--;
  }
  return rightTeeth;
}

function mapLeftTeeth(newData, key, teeth, side, position) {
  let num = 1;
  let leftTeeth = {};
  for (var x = 1; x < 9; x++) {
    leftTeeth = {
      ...leftTeeth,

      [`${key}${num}`]:
        newData !== undefined && newData[side] !== {} && newData[side] !== undefined
          ? {
            ...teeth[`${key}${num}`],
            image:
              newData[side][`${position}${num}`] === "true"
                ? teeth[`${key}${num}`].active
                : teeth[`${key}${num}`].not_active,
            value: newData[side][`${position}${num}`] === "true" ? true : false,
          }
          : {
            ...teeth[`${key}${num}`],
          },
    };
    num++;
  }
  return leftTeeth;
}


