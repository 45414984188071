import React, { useEffect, useState } from "react";
import styles from "../eonAccess-style/eonAccess.module.scss";
import viewer from "../../../TS-VIEWER/viewer";
import PlayPauseAnimateForLandScapeMode from "./animate-steps-for-landScape";
export default function SideBar(props) {
  const {
    tsOptions,
    setTSOptions,
    currentStep,
    setCurrentStep,
    meshesForStep,
  } = props;

  const [viewActiveTs, setViewActiveTs] = useState("front");
  const [viewUpper, setViewUpper] = useState(true);
  const [viewLower, setViewLower] = useState(true);

  const onTsViewChange = (action) => {
    switch (action.viewActiveTs) {
      case "left-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("left");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: true,
        }));
        viewer.leftView();
        break;

      case "right-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("right");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: true,
        }));
        viewer.rightView();
        break;
      case "front-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("front");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: true,
          showUpper: true,
          showLower: true,
        }));
        viewer.frontView();
        break;
      case "upper-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("upper");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: false,
          showUpper: false,
          showLower: true,
        }));
        viewer.upperView();
        break;
      case "lower-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("lower");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: false,
          showUpperArc: true,
          showUpper: true,
          showLower: false,
        }));
        viewer.lowerView();
        break;

      case "maxilla-view":
        if (!tsOptions.isTSViewerFound) return;
        setTSOptions((prevOptions) => {
          return { ...prevOptions, showLower: !prevOptions.showLower };
        });
        break;
      case "mandible-view":
        if (!tsOptions.isTSViewerFound) return;
        setTSOptions((prevOptions) => {
          return { ...prevOptions, showUpper: !prevOptions.showUpper };
        });
        break;

      case "attachments-view":
        if (!tsOptions.isTSViewerFound) return;
        setTSOptions((prevOptions) => {
          return { ...prevOptions, isAttachment: !prevOptions.isAttachment };
        });
        break;

      case "superImpose-view":
        if (!tsOptions.isTSViewerFound) return;
        setTSOptions((prevOptions) => {
          return { ...prevOptions, isSuperImpose: !prevOptions.isSuperImpose };
        });
        break;
      default:
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("front");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: true,
        }));
        viewer.frontView();
    }
  };

  return (
    <div
      className={styles.sideBar_container}
    >
      <div
        className={styles.sideBar_content}
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          onTsViewChange({ viewActiveTs: "upper-view" });
        }}
      >
        <div>
          <img
            src={`${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${ viewActiveTs === "upper" ? "top_active.svg": "top.svg"}`}
            alt=""
            className={styles.sideBar_icon}
            data-tip="Upper View"
          />

        </div>
        <div
          className={[
          styles.upperNav_content_moblie,
          viewActiveTs === "upper" ? styles.upperNav_content_active : "",
        ].join(" ")}
        >
        </div>
      </div>

      <div
        className={styles.sideBar_content}
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          onTsViewChange({ viewActiveTs: "right-view" });
        }}
      >
        <div>
          <img
            src={`${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${ viewActiveTs === "right" ? "right_active.svg": "right.svg"}`}
            alt=""
            className={styles.sideBar_icon}
            data-tip="Right View"
          />
        </div>
      </div>

      <div
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          onTsViewChange({ viewActiveTs: "front-view" });
        }}
      >
        <div>
          <img
            src={`${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${ viewActiveTs === "front" ? "front_active.svg": "front.svg"}`}
            alt=""
            className={styles.sideBar_icon}
            data-tip="Front View"
          />
        </div>
      </div>
      <div
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          onTsViewChange({ viewActiveTs: "left-view" });
        }}
      >
        <div>
          <img
            src={`${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${ viewActiveTs === "left" ? "left_active.svg": "left.svg"}`}
            alt=""
            className={styles.sideBar_icon}
            data-tip="Left View"
          />
        </div>
        <div>
        </div>
      </div>

      <div
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          onTsViewChange({ viewActiveTs: "lower-view" });
        }}
      >
        <div>
          <img
              src={`${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${ viewActiveTs === "lower" ? "bottom_active.svg": "bottom.svg"}`}
            alt=""
            className={styles.sideBar_icon}
            data-tip="Lower View"
          />
        </div>
      </div>

      {/* Model Details */}
      <div
        className={styles.sideBar_content}
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          onTsViewChange({ viewActiveTs: "maxilla-view" });
          setViewUpper(!viewUpper)
        }}

      >
        <div>
          <img
              src={
              `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${viewUpper ? "upper_arch_active.svg" : "upper_arch.svg"}`
          }
            alt=""
            className={styles.sideBar_icon}
            data-tip="Maxilla"
          />
        </div>
        <div
          className={[
          styles.upperNav_content_moblie_upper,
          viewUpper ? styles.upperNav_content_active : "",
        ].join(" ")}
        >
        </div>
      </div>

      {/* Mandible */}
      <div
        className={styles.sideBar_content}
        onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared) return;
          onTsViewChange({ viewActiveTs: "mandible-view" });
          setViewLower(!viewLower)
        }}
      >
        <div>
          <img
            src={
              `${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${viewLower ? "lower_arch_active.svg" : "lower_arch.svg"}` 
          }
            alt=""
            className={styles.sideBar_icon}
            data-tip="Mandible"
          />
        </div>
      </div>

      {/* <!-- Play/Pause animate only for landscape mode--> */}
      <PlayPauseAnimateForLandScapeMode
        tsOptions={tsOptions}
        setTSOptions={setTSOptions}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        meshesForStep={meshesForStep}
      />
      <div className={styles.empty_div_onMobile} />
    </div>
  );
}
