import { TEETH_IPR_DATA, SAVED_DATA, REJECTION_REASON, PROTOCOL_FEEDBACK } from "../constants/";

const INIT_STATE = {
  teeth_ipr_data: {},
  saved_data: {},
  clinical_protocol_feedback: "",
  rejection_reason: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TEETH_IPR_DATA:
      return {
        ...state,
        teeth_ipr_data: action.payload,
      };

    case SAVED_DATA:
      return {
        ...state,
        saved_data: action.payload,
      };
    case REJECTION_REASON:
      return {
        ...state,
        rejection_reason: action.payload,
      };
    case PROTOCOL_FEEDBACK:
      return {
        ...state,
        clinical_protocol_feedback: action.payload,
      };
    default:
      return state;
  }
};
