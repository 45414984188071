import React, { Component } from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";
import { Table } from "reactstrap";

export default class CaseProgress extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /*init state */
    this.state = {
      stages: this.props.progress,
      additional: this.props.additional,
      stagesLength: this.props.stagesLength,
      CaseDetails: this.props.CaseDetails,
    };
  }
  render() {
    return (
      <div className="pt-3 w-100 p-0">
        <Row>
          <Col xs="12" md="6" lg="7">
            <label className="control-label title_active">Case Progress</label>
          </Col>
          <Col xs="12" md="6" lg="5"></Col>
        </Row>
        <Row className="stage_table w-100 p-0">
          <Col xs="12" md="12" lg="7" className="stage_table w-100 p-0">
            {/*--------------------------------------------------------------------------------*/}
            {/* Stages table*/}
            {/*--------------------------------------------------------------------------------*/}
            <Table className="stage_table w-100 p-0" responsive size="sm">
              <thead>
                <tr>
                  <th>Stage</th>
                  <th>Status</th>
                  <th>Expected Delivery Date</th>
                  <th>Airway Bill #</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.stages.map((value) => (
                  <tr
                    key={value.id}
                    style={{
                      backgroundColor: value.shipped_at ? "#d7f5f2" : "",
                      color: value.shipped_at ? "#00c7b1" : "",
                      fontWeight: value.shipped_at ? "400" : "400",
                    }}
                  >
                    <th scope="row">{value.stage}</th>
                    <td>
                      {value.shipped_at
                        ? "Shipped"
                        : `Pending ${value.adjustment ? " (Adjustment)" : ""}`}
                    </td>
                    <td>
                      {moment(value.expected_delivery_date).format(
                        "D MMM YYYY"
                      )}
                    </td>
                    <td>{value.shipped_at ? value.airway_number : ""}</td>
                    <td>{value.shipped_at ? "" : ""}</td>
                  </tr>
                ))}
                {this.state.additional.map((value, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: value.shipped_at ? "#d7f5f2" : "",
                      color: value.shipped_at ? "#00c7b1" : "",
                      fontWeight: value.shipped_at ? "400" : "400",
                    }}
                  >
                    <th scope="row">{value.stage}</th>
                    <td>
                      {value.shipped_at
                        ? "Shipped"
                        : `Pending ${value.adjustment ? " (Adjustment)" : ""}`}
                    </td>
                    <td>
                      {moment(value.expected_delivery_date).format(
                        "D MMM YYYY"
                      )}
                    </td>
                    <td>{value.shipped_at ? value.airway_number : ""}</td>
                    <td>{value.shipped_at ? "" : "Additional"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/*--------------------------------------------------------------------------------*/}
            {/* End Stages table*/}
            {/*--------------------------------------------------------------------------------*/}
          </Col>
        </Row>
      </div>
    );
  }
}
