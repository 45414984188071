import { teeth } from "./teeth";
export const upper_teeth = {
  ...initRightTeeth("upper_r_"),
  ...initLeftTeeth("upper_l_"),
};

export const lower_teeth = {
  ...initRightTeeth("lower_r_"),
  ...initLeftTeeth("lower_l_"),
};

function initRightTeeth(key) {
  let num = 8;
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]: {
        active: teeth[`${key}${num}_active`],
        not_active: teeth[`${key}${num}`],
        hover: teeth[`${key}${num}_hover`],
        image: teeth[`${key}${num}`],
        value: false,
      },
    };
    num--;
  }
  return rightTeeth;
}

function initLeftTeeth(key) {
  let num = 1;
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]: {
        active: teeth[`${key}${num}_active`],
        not_active: teeth[`${key}${num}`],
        hover: teeth[`${key}${num}_hover`],
        image: teeth[`${key}${num}`],
        value: false,
      },
    };
    num++;
  }
  return rightTeeth;
}
