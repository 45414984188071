import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Row, Col } from "reactstrap";
import CustomeTeeth from "../../../Teeth/CustomeTeeth";
import ToggleSwitch from "../../../../components/Switch/Switch";

import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
import { mapTeethToApi } from "../../../../services/mapTeethToApi";
import { Clear_Teeth_Data } from "../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Pontics extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_pontics: this.props.caseSubmission.advanced_modules.pontics
        .usePontics,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "pontics",
      data: {
        usePontics: this.state.use_pontics,
        teeth: this.props.customeTeethReducer.Case_Pontics,
      },
    });
    this.props.save();
  };
  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  render() {
    const { use_pontics } = this.state;

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">

          <div className="text-center">
            <div className="control-label title_active-gco text-center mb-2">
              Use pontics for this case
             </div>

            <TreatmentOptions value={use_pontics} name="use_pontics" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_pontics")
              if (value !== true) {
                this.props.Clear_Teeth_Data("Case_Pontics")
              }
            }} />
          </div>

          <div className="grid_container">
            {" "}
            <div className="">
              <div className="control-label title_active-gco text-center pb-2 mt-2 ">
                Placement
              </div>
              <div className="teeth_selector">
                <CustomeTeeth
                  action_key="Case_Pontics"
                  disabled={!use_pontics || use_pontics === "eonToDecide"}
                  summary={this.props.summary}
                />
              </div>
            </div>
          </div>
        </div>

        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            Save
        </Button>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Pontics);
