import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input } from "reactstrap";
import { CustomInput } from "reactstrap";
import {
  clearMalocclusionData
} from "../../../../../redux/CaseSubmission/action";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  clearMalocclusionData: (payload) => dispatch(clearMalocclusionData(payload)),

});
class Midline extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */

    this.upperMidline =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.upperMidline?.upperMidline || "";
    this.upperMidline_displacement =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.upperMidline?.upperMidline_displacement || "";
    this.lowerMidline =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.lowerMidline?.lowerMidline || "";
    this.lowerMidline_displacement =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.lowerMidline?.lowerMidline_displacement || "";

    this.upper_resultMidlineAlignment =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineUpper?.resultMidlineAlignment || "";
    this.upper_maintain =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineUpper?.maintain || "";
    this.upper_improveIPR =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineUpper?.improveIPR || "";
    this.upper_improveExtraction =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineUpper?.improveExtraction || "";

    this.lower_resultMidlineAlignment =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineLower?.resultMidlineAlignment || "";
    this.lower_maintain =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineLower?.maintain || "";
    this.lower_improveIPR =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineLower?.improveIPR || "";
    this.lower_improveExtraction =
      this.props.caseSubmission?.advanced_modules?.malocclusion?.midline
        ?.treatMidlineLower?.improveExtraction || "";
  }

  UpdateCrossBiteReducer = () => {
    let data = {
      upperMidline: {
        upperMidline: this.upperMidline,
        upperMidline_displacement: this.upperMidline_displacement,
      },
      lowerMidline: {
        lowerMidline: this.lowerMidline,
        lowerMidline_displacement: this.lowerMidline_displacement,
      },
      treatMidlineUpper: {
        resultMidlineAlignment: this.upper_resultMidlineAlignment,
        maintain: this.upper_maintain,
        improveIPR: this.upper_improveIPR,
        improveExtraction: this.upper_improveExtraction,
      },
      treatMidlineLower: {
        resultMidlineAlignment: this.lower_resultMidlineAlignment,
        maintain: this.lower_maintain,
        improveIPR: this.lower_improveIPR,
        improveExtraction: this.lower_improveExtraction,
      },
    };

    this.props.save("midline", data);
  };
  render() {
    const {
      upperMidline,
      upperMidline_displacement,
      lowerMidline,
      lowerMidline_displacement,

      upper_resultMidlineAlignment,
      upper_maintain,
      upper_improveIPR,
      upper_improveExtraction,

      lower_resultMidlineAlignment,
      lower_maintain,
      lower_improveIPR,
      lower_improveExtraction,
    } = this;
    return (
      <div>
        <div className="malocclusion-title">
          Midline
      {!this.props.summary && <span
            className="close-btn pull-right"
            onClick={() => {
              this.props.clearMalocclusionData("midline")

              this.props.toggleComponents();
            }}
          >
            &times;
          </span>}
        </div>
        <div>
          <div className="form-group content form-block-holder mt-3">
            <div className="midline-section-grid">
              <div>
                {" "}
                <label className="control-label title_active-gco">
                  <div className=" text-center title_active-gco p-1">
                    Upper Midline
                  </div>{" "}
                </label>
                <div className="midline_grid mb-1">
                  <div className="p-1">
                    <Button
                      style={{ pointerEvents: this.props.summary ? "none" : "" }}
                      className={`btn ${upperMidline === "centered"
                          ? `dark-green-btn-active`
                          : `dark-green-btn-not-active`
                        } text-center custom_shadow w-100`}
                      size="sm"
                      onClick={() => {
                        this.upperMidline = "centered";
                        this.UpdateCrossBiteReducer();
                      }}
                    >
                      Centered
                    </Button>
                  </div>
                  <div className="p-1">
                    <Button
                      style={{ pointerEvents: this.props.summary ? "none" : "" }}

                      className={`btn ${upperMidline === "shifted_left"
                          ? `dark-green-btn-active`
                          : `dark-green-btn-not-active`
                        } text-center custom_shadow w-100`}
                      size="sm"
                      onClick={() => {
                        this.upperMidline = "shifted_left";
                        this.UpdateCrossBiteReducer();
                      }}
                    >
                      Shifted Left
                    </Button>
                  </div>
                  <div className="p-1">
                    <Button
                      style={{ pointerEvents: this.props.summary ? "none" : "" }}

                      className={`btn ${upperMidline === "shifted_right"
                          ? `dark-green-btn-active`
                          : `dark-green-btn-not-active`
                        } text-center custom_shadow w-100`}
                      size="sm"
                      onClick={() => {
                        this.upperMidline = "shifted_right";
                        this.UpdateCrossBiteReducer();
                      }}
                    >
                      Shifted Right
                    </Button>
                  </div>
                </div>
                {(upperMidline === "shifted_right" ||
                  upperMidline === "shifted_left") && (
                    <div className="p-2 mt-2 align-items-center displacement">
                      <div>
                        <label className="control-label title_active-gco">
                          If shifted, displacement in mm
                      </label>
                      </div>
                      <div>
                        <Input
                          disabled={this.props.summary}
                          autoComplete="off"
                          type="number"
                          className={`green_input`}
                          defaultValue={upperMidline_displacement}
                          onChange={(e) => {
                            this.upperMidline_displacement = e.target.value;
                            this.UpdateCrossBiteReducer();
                          }}
                        />
                      </div>
                    </div>
                  )}
              </div>
              <div>
                <div className="p-2">
                  <div className="control-label title_active-gco">
                    Treat midline by{" "}
                  </div>
                  <CustomInput
                    className={`pt-1 ${upper_resultMidlineAlignment && "replacment-checkbox"
                      }`}
                    type="checkbox"
                    id="resultMidlineAlignment-upper"
                    label="Accept resulting midline"
                    checked={upper_resultMidlineAlignment}
                    disabled={this.props.summary}

                    onChange={() => {
                      this.upper_resultMidlineAlignment = !this
                        .upper_resultMidlineAlignment;
                      this.UpdateCrossBiteReducer();
                    }}
                  />
                  <CustomInput
                    className={`pt-1 ${upper_maintain && "replacment-checkbox"
                      }`}
                    type="checkbox"
                    id="upper_maintainr"
                    label="Maintain intial midline
            "
                    checked={upper_maintain} disabled={this.props.summary}

                    onChange={() => {
                      this.upper_maintain = !this.upper_maintain;
                      this.UpdateCrossBiteReducer();
                    }}
                  />
                  <CustomInput
                    className={`pt-1 ${upper_improveIPR && "replacment-checkbox"
                      }`}
                    type="checkbox"
                    id="improveIPR-upper"
                    label="Improve with IPR"
                    checked={upper_improveIPR} disabled={this.props.summary}

                    onChange={() => {
                      this.upper_improveIPR = !this.upper_improveIPR;
                      this.UpdateCrossBiteReducer();
                    }}
                  />

                  <CustomInput
                    className={`pt-1 ${upper_improveExtraction && "replacment-checkbox"
                      }`}
                    type="checkbox"
                    id="improveExtraction-upper"
                    label="Improve with extraction"
                    checked={upper_improveExtraction} disabled={this.props.summary}

                    onChange={() => {
                      this.upper_improveExtraction = !this
                        .upper_improveExtraction;
                      this.UpdateCrossBiteReducer();
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="midline-section-grid">
              <div>
                {" "}
                <label className="control-label title_active-gco">
                  <div className=" text-center title_active-gco p-1">
                    Lower Midline
                  </div>{" "}
                </label>
                <div className="midline_grid mb-1">
                  <div className="p-1">
                    <Button style={{ pointerEvents: this.props.summary ? "none" : "" }}

                      className={`btn ${lowerMidline === "centered"
                          ? `dark-green-btn-active`
                          : `dark-green-btn-not-active`
                        } text-center custom_shadow w-100`}
                      size="sm"
                      onClick={() => {
                        this.lowerMidline = "centered";
                        this.UpdateCrossBiteReducer();
                      }}
                    >
                      Centered
                    </Button>
                  </div>
                  <div className="p-1">
                    <Button style={{ pointerEvents: this.props.summary ? "none" : "" }}

                      className={`btn ${lowerMidline === "shifted_left"
                          ? `dark-green-btn-active`
                          : `dark-green-btn-not-active`
                        } text-center custom_shadow w-100`}
                      size="sm"
                      onClick={() => {
                        this.lowerMidline = "shifted_left";
                        this.UpdateCrossBiteReducer();
                      }}
                    >
                      Shifted Left
                    </Button>
                  </div>
                  <div className="p-1">
                    <Button style={{ pointerEvents: this.props.summary ? "none" : "" }}

                      className={`btn ${lowerMidline === "shifted_right"
                          ? `dark-green-btn-active`
                          : `dark-green-btn-not-active`
                        } text-center custom_shadow w-100`}
                      size="sm"
                      onClick={() => {
                        this.lowerMidline = "shifted_right";
                        this.UpdateCrossBiteReducer();
                      }}
                    >
                      Shifted Right
                    </Button>
                  </div>
                </div>
                {(lowerMidline === "shifted_right" ||
                  lowerMidline === "shifted_left") && (
                    <div className="p-2 mt-2 align-items-center displacement">
                      <div>
                        <label className="control-label title_active-gco">
                          If shifted, displacement in mm
                      </label>
                      </div>
                      <div>
                        <Input disabled={this.props.summary}

                          autoComplete="off"
                          type="number"
                          className={`green_input`}
                          defaultValue={lowerMidline_displacement}
                          onChange={(e) => {
                            this.lowerMidline_displacement = e.target.value;
                            this.UpdateCrossBiteReducer();
                          }}
                        />
                      </div>
                    </div>
                  )}
              </div>
              <div>
                <div className="p-2">
                  <div className="control-label title_active-gco">
                    Treat midline by{" "}
                  </div>
                  <CustomInput
                    className={`pt-1 ${lower_resultMidlineAlignment && "replacment-checkbox"
                      }`}
                    type="checkbox"
                    id="resultMidlineAlignment-lower"
                    label="Accept resulting midline"
                    checked={lower_resultMidlineAlignment} disabled={this.props.summary}

                    onChange={() => {
                      this.lower_resultMidlineAlignment = !this
                        .lower_resultMidlineAlignment;
                      this.UpdateCrossBiteReducer();
                    }}
                  />
                  <CustomInput
                    className={`pt-1 ${lower_maintain && "replacment-checkbox"
                      }`}
                    type="checkbox"
                    id="lower_maintainr"
                    label="Maintain intial midline
            "
                    checked={lower_maintain} disabled={this.props.summary}

                    onChange={() => {
                      this.lower_maintain = !this.lower_maintain;
                      this.UpdateCrossBiteReducer();
                    }}
                  />
                  <CustomInput
                    className={`pt-1 ${lower_improveIPR && "replacment-checkbox"
                      }`}
                    type="checkbox"
                    id="improveIPR-lower"
                    label="Improve with IPR"
                    checked={lower_improveIPR} disabled={this.props.summary}

                    onChange={() => {
                      this.lower_improveIPR = !this.lower_improveIPR;
                      this.UpdateCrossBiteReducer();
                    }}
                  />

                  <CustomInput
                    className={`pt-1 ${lower_improveExtraction && "replacment-checkbox"
                      }`}
                    type="checkbox"
                    id="improveExtraction-lower"
                    label="Improve with extraction"
                    checked={lower_improveExtraction} disabled={this.props.summary}

                    onChange={() => {
                      this.lower_improveExtraction = !this
                        .lower_improveExtraction;
                      this.UpdateCrossBiteReducer();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Midline);
