import { getParams } from "./getParams";

export const checkAttachments = (isAttachmentsFromParams = false) => {
  //isAttachmentsFromParams = false when isAttachmentsFromParams is undefined
  let params = getParams();
  if (isAttachmentsFromParams) {
    return params["attachments"] == "true";
  }
  return !isAttachmentsFromParams;
};
