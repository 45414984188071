import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import { getAlignerCase, getComments } from "../../api/api";
import Patient from "./Patient";
import Gallery from "./Gallery";
import History from "./History";
import Comments from "./Comments";
import { connect } from "react-redux";
import { updateInbox } from "../../redux/inbox/action";
//import { mapDataWithReducer } from "../../services/mapData";
import { getCase } from "../../redux/caseDetails/action";
import { updateCase } from "../../redux/newCase/action";
import { newMapDataWithReducer } from "../../services/newDataMapper";
import {

  updatePhotosXrays,
  updateImpressions,
  updatePrescription,
} from "../../redux/CaseSubmission/action";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateInbox: (payload) => dispatch(updateInbox(payload)),
  getCase: (payload) => dispatch(getCase(payload)),
  updateCase: (payload) => dispatch(updateCase(payload)),
  updatePhotosXrays: (payload) => dispatch(updatePhotosXrays(payload)),
  updateImpressions: (payload) => dispatch(updateImpressions(payload)),
  updatePrescription: (payload) => dispatch(updatePrescription(payload)),
});

class CaseDetails extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /*init state */
    this.state = {
      activeTab: "1",
      id: props.userReducer.currentUser.id,
      case: {},
      isLoading: true,
      commentsisLoading: false,
      comments: [],
      modal: false,
      mappedData: {},
      filters: this.props.location.state
        ? this.props.location.state.data
        : null,
    };
  }
  /*--------------------------------------------------------------------------------*/
  /*get aligner case  */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    this.GetAlignerCase();
  }
  /*--------------------------------------------------------------------------------*/
  /*get aligner case   */
  /*--------------------------------------------------------------------------------*/
  GetAlignerCase() {
    this.setState({ isLoading: true });
    getAlignerCase(this.state.id, this.props.match.params.id.toString()).then(
      (res) => {
        /* map data with reducer keys  */

        let newData = newMapDataWithReducer(res);
        /* update data in reducer  */
        this.props.updateCase({ ...this.props.newCase, ...newData });
        this.props.updatePhotosXrays(newData.photos_xrays);
        this.props.updateImpressions(newData.impressions_new);
        this.props.updatePrescription(newData.prescription);

        /* current case data vlues  */
        this.props.getCase(res);
        this.setState({ case: res, isLoading: false, mappedData: newData });

        if (res.case_type !== "Retainer") {
          if (res.uid) {
            /* get comments for this case by uid  */
            getComments(this.state.id, res.uid).then((res) => {
              this.setState({ comments: res, commentsisLoading: false });
              /* update data in inbox reducer   */
              this.props.updateInbox(res);
            });
          }
        }
      }
    );
  }
  /*--------------------------------------------------------------------------------*/
  /*update data in reducer    */
  /*--------------------------------------------------------------------------------*/
  UpdateCaseReducer = (data) => {
    setTimeout(() => {
      this.setState({ isLoading: false });
      this.props.getCase(data);
    }, 1000);
  };
  /*--------------------------------------------------------------------------------*/
  /*set active tab   */
  /*--------------------------------------------------------------------------------*/
  toggleTabs(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  /* --------------------------------------------------------------------------------*/
  /* clear state if go out from component   */
  /* --------------------------------------------------------------------------------*/
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    return (
      <div>
        <Card className="case-details-tabs">
          {this.state.isLoading ? (
            <div className="row spinner-container justify-content-center align-items-center">
              <Spinner className="green-spinner" type="grow" />
            </div>
          ) : (
              <>
                <Nav tabs>
                  {/*--------------------------------------------------------------------------------*/}
                  {/* back button */}
                  {/*--------------------------------------------------------------------------------*/}
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        this.props.history.push({
                          pathname: `/cases`,
                          state: { data: this.state.filters },
                        });
                      }}
                    >
                      <i className="fas fa-arrow-left" aria-hidden="true"></i>
                    </NavLink>
                  </NavItem>
                  {/*--------------------------------------------------------------------------------*/}
                  {/* Details tab */}
                  {/*--------------------------------------------------------------------------------*/}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggleTabs("1");
                      }}
                    >
                      Details
                  </NavLink>
                  </NavItem>
                  {/*--------------------------------------------------------------------------------*/}
                  {/* Records tab */}
                  {/*--------------------------------------------------------------------------------*/}
                  {this.props.currentCase.state.case_type !== "Retainer" && (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggleTabs("2");
                        }}
                      >
                        Records
                    </NavLink>
                    </NavItem>
                  )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* Inbox tab */}
                  {/*--------------------------------------------------------------------------------*/}
                  {this.props.currentCase.state.case_type !== "Retainer" &&
                    this.props.currentCase.state.uid && (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggleTabs("3");
                          }}
                        >
                          Inbox
                      </NavLink>
                      </NavItem>
                    )}
                  {/*--------------------------------------------------------------------------------*/}
                  {/* History tab */}
                  {/*--------------------------------------------------------------------------------*/}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "4",
                      })}
                      onClick={() => {
                        this.toggleTabs("4");
                      }}
                    >
                      History
                  </NavLink>
                  </NavItem>
                  {/*--------------------------------------------------------------------------------*/}
                  {/* full patient name  */}
                  {/*--------------------------------------------------------------------------------*/}
                  <NavItem>
                    <NavLink>{this.state.case.patient.full_name}</NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {/*--------------------------------------------------------------------------------*/}
                  {/* case details tab content  */}
                  {/*--------------------------------------------------------------------------------*/}
                  <TabPane
                    tabId="1"
                    className={this.state.isLoading ? "text-center p-3" : ""}
                  >
                    {this.state.isLoading ? (
                      <Spinner className="green-spinner" type="grow" />
                    ) : (
                        <Patient
                          CaseDetails={this.props.currentCase.state}
                          doctorId={this.state.id}
                          mappedData={this.state.mappedData}
                          UpdateCaseReducer={this.UpdateCaseReducer}
                          data={this.props}
                        />
                      )}
                  </TabPane>
                  {/*--------------------------------------------------------------------------------*/}
                  {/* end case details tab content  */}
                  {/*--------------------------------------------------------------------------------*/}

                  {/*--------------------------------------------------------------------------------*/}
                  {/*case gallery tab content  */}
                  {/*--------------------------------------------------------------------------------*/}
                  <TabPane tabId="2">
                    <Row>
                      <Col
                        xs="11"
                        md="11"
                        lg="11"
                        className={
                          this.state.isLoading ? "text-center p-3" : "m-auto"
                        }
                      >
                        {this.state.isLoading ? (
                          <Spinner className="green-spinner" type="grow" />
                        ) : (
                            <Gallery
                              CaseDetails={this.props.currentCase.state}
                              data={this.props}
                            />
                          )}
                      </Col>
                    </Row>
                  </TabPane>
                  {/*--------------------------------------------------------------------------------*/}
                  {/*end case gallery tab content  */}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*--------------------------------------------------------------------------------*/}
                  {/*case inbox tab content  */}
                  {/*--------------------------------------------------------------------------------*/}
                  <TabPane tabId="3">
                    <Row>
                      <Col
                        xs="12"
                        md="10"
                        lg="10"
                        className={
                          this.state.commentsisLoading
                            ? "text-center p-3"
                            : "m-auto"
                        }
                      >
                        {this.state.activeTab === "3" && (
                          <Comments caseId={this.props.currentCase.state.uid} />
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  {/*--------------------------------------------------------------------------------*/}
                  {/* end case inbox tab content */}
                  {/*--------------------------------------------------------------------------------*/}

                  {/*--------------------------------------------------------------------------------*/}
                  {/* case history tab content */}
                  {/*--------------------------------------------------------------------------------*/}
                  <TabPane tabId="4">
                    <Row>
                      <Col
                        xs="11"
                        md="11"
                        lg="11"
                        className={
                          this.state.isLoading
                            ? "text-center p-3"
                            : "m-auto pt-3 pb-3"
                        }
                      >
                        {this.state.isLoading ? (
                          <Spinner className="green-spinner" type="grow" />
                        ) : (
                            <History
                              CaseDetails={this.props.currentCase.state}
                              data={this.props}
                            />
                          )}
                      </Col>
                    </Row>
                  </TabPane>
                  {/*--------------------------------------------------------------------------------*/}
                  {/*end case history tab content */}
                  {/*--------------------------------------------------------------------------------*/}
                </TabContent>
              </>
            )}
        </Card>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseDetails);
