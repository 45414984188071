export const indicatorTracking = (
  currentStep = 0, //default is 1
  meshesForStep,
  currentWidthStepsRef
) => {
  let total = 0;

  if (currentWidthStepsRef.current) {
    let allStepsWidth = currentWidthStepsRef.current.clientWidth;
    let allStepsLength = meshesForStep.length || 1;
    let currentWidthStep = allStepsWidth / allStepsLength;
    let calcCurrentStep = currentWidthStep / 2-9 ;
    total = (currentWidthStep * currentStep) + calcCurrentStep;
  }
  return total;
};
