export const statusColors = {
  Active: {
    color: "#00c7b1",
    backgroundColor: "",
  },
  Complete: {
    color: "#4392F1",
    backgroundColor: "",
  },
  "Pending Courier Notification": {
    color: "#FFB30F",
    backgroundColor: "",
  },
  "Pending Record Approval": {
    color: "#FFB30F",
    backgroundColor: "",
  },
  "Pending TS Approval": {
    color: "#FFB30F",
    backgroundColor: "",
  },
  "Pending Accounting Clearance": {
    color: "#FFB30F",
    backgroundColor: "",
  },
  "Records Rejected": {
    color: "#FF5733",
    backgroundColor: "#f5c6cb",
  },
  "Rejected TS": {
    color: "#FF5733",
    backgroundColor: "#f5c6cb",
  },
  "Incomplete Submission": {
    color: "#D9534F",
    backgroundColor: "",
  },
  "Generating TS": {
    color: "#3C2CFA",
    backgroundColor: "",
  },
  "Generating Adjustment TS": {
    color: "#3C2CFA",
    backgroundColor: "",
  },
};
