import * as yup from "yup";
export const schema = yup.object({
  firstName: yup
    .string()
    // .max(10, "Must be 10 characters or less")
    .required("First Name is Required"),
  lastName: yup
    .string()
    // .max(10, "Must be 10 characters or less")
    .required(" Last Name is Required"),
  email: yup
    .string()
    .email("Invalid email address")
   ,
  gendar: yup.string().required("Gender is Required"),
  date_of_birth: yup
    .date()
    // // .max("01-01-2000", "Invalid Date of birth")
    // // .min("01-01-1960", "Invalid Date of birth")
   ,
  address_id: yup.number().required("Shipping Address is Required"),
  case_type: yup.string().required("Case Type is Required "),
});
