import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input, Row, Col } from "reactstrap";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import ToggleSwitch from "../../../../../components/Switch/Switch";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
});
class BiteRamps extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_bite_ramps: this.props.caseSubmission.advanced_modules.biteRamps
        .useBiteRamps,
      incisors: this.props.caseSubmission.advanced_modules.biteRamps.incisors,
      incisors_and_canines: this.props.caseSubmission.advanced_modules.biteRamps
        .incisors_and_canines,
      bite_ramps_details: this.props.caseSubmission.advanced_modules.biteRamps
        .details,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "biteRamps",
      data: {
        useBiteRamps: this.state.use_bite_ramps,
        incisors: this.state.incisors,
        incisors_and_canines: this.state.incisors_and_canines,
        details: this.state.bite_ramps_details,
      },
    });
    this.props.save();
  };
  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const {
      use_bite_ramps,
      incisors,
      incisors_and_canines,
      bite_ramps_details,
    } = this.state;

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">

          {/* <div>
            <div className="control-label title_active-gco text-center mb-2">
              Used bite ramps for this case
            </div>
            <div className="toggle-slider form-group content form-block-holder custome-toggle m-auto mt-2 ">
              <input
                type="range"
                step="50"
                onChange={(e) => {
                  this.slider(e, "use_bite_ramps")

                  if (e?.target?.value === "0" || e?.target?.value === "50") {
                    this.setState({
                      incisors: false,
                      incisors_and_canines: false,
                      bite_ramps_details: ""
                    })

                  }
                }}
                value={this.sliderValue("use_bite_ramps")}
                style={{ pointerEvents: this.props.summary ? "none" : "" }}

              />
              <Row className="align-items-center ">
                <Col xs="4" md="4" lg="4" className="text-left">
                  <label className="title_active">No</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-center">
                  <label className="title_active">EON to Decide</label>
                </Col>
                <Col xs="4" md="4" lg="4" className="text-right">
                  <label className="title_active">Yes</label>
                </Col>
              </Row>
            </div>

          </div>
 */}

          <div className="auto_grid4  pb-2">
            <div className="control-label title_active-gco">
              Used bite ramps for this case
            </div>
            <div>
              <ToggleSwitch
                id="use_bite_ramps"
                disabled={this.props.summary}

                checked={use_bite_ramps}
                onChange={() => {
                  this.setState({
                    use_bite_ramps: !use_bite_ramps,

                    ...use_bite_ramps && {
                      incisors: false,
                      incisors_and_canines: false,
                      bite_ramps_details: ""
                    }
                  });
                }}
              />
            </div>
          </div>


          <div className="address_discrepancy">
            <div></div>
            <Button
              onClick={() => {
                this.setState({
                  incisors: true,
                  incisors_and_canines: false,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${incisors ? "active_discrepancy-btn" : "discrepancy-btn"
                } `}
              disabled={!use_bite_ramps || use_bite_ramps === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              Incisors
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  incisors: false,
                  incisors_and_canines: true,
                });
              }}
              className={`btn btn-radius text-center custom_shadow ${incisors_and_canines
                ? "active_discrepancy-btn"
                : "discrepancy-btn"
                } `}
              disabled={!use_bite_ramps || use_bite_ramps === "eonToDecide"}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}

            >
              Incisors and canines
            </Button>
            <div></div>
          </div>

          <div className="readOnly-text">
            <Input
              className="teeth_selector"
              name="bite_ramps_details"
              id="bite_ramps_details"
              type="textarea"
              placeholder="Please add details"
              value={bite_ramps_details}
              rows={5}
              onChange={(e) => {
                this.setState({ bite_ramps_details: e.target.value });
              }}
              readOnly            />{" "}
          </div>
        </div>

        { !this.props.summary && <Button
          className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
          size="sm"
          type="button"
          onClick={() => this.UpdateAdvancedModules()}
        >
          Save
        </Button>}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BiteRamps);
