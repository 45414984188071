import React, { Component } from "react";
import {
  CardBody,
  CardTitle,
  CardFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Modal, ModalHeader, ModalBody
} from "reactstrap";
import { connect } from "react-redux";

import Select from "react-select";
import { gendar } from "../../helpers/gendar";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { schema } from "../../helpers/patientInfoValidation";
import { address_schema } from "../../helpers/addressValidations";
import { countries, GetCountryLabel } from "../../helpers/countries";
import { successToaster } from "../../services/toast";
import { mapCountry } from "../../helpers/countries";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { updateAdvancedModules, updatePatientInfo } from "../../redux/CaseSubmission/action";
import { mapTeethToApi } from "../../services/mapTeethToApi";
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
import { createAlignerCase, updateAddress, createAddress, getDoctorAddresses, updateAlignerCase } from "../../api/api";
import { mapAdvancedToApi } from "../../services/mapAdvancedToApi";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  updatePatientInfo: (payload) => dispatch(updatePatientInfo(payload))
});
const years = range(1940, 2006, 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
class PatientInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shippingAddress: "",
      addresses: this.props.addresses,
      gender: "",
      line1: "",
      line2: "",
      zip: "",
      phone: "",
      state: "",
      country: "",
      city: "",
      id: "",
      createUpdateAddressModal: false,
      date: "",
      cancelSubmission: false
    };
  }
  /*--------------------------------------------------------------------------------*/
  /* resize card height  */
  /*--------------------------------------------------------------------------------*/
  componentDidMount = () => {
    // window.addEventListener("resize", this.updateSize());
  };
  /*--------------------------------------------------------------------------------*/
  /* resize card  height if screen size update   */
  /*--------------------------------------------------------------------------------*/
  componentDidUpdate = () => {
    //window.addEventListener("resize", this.updateSize());
  };
  /*--------------------------------------------------------------------------------*/
  /* update Size   */
  /*--------------------------------------------------------------------------------*/
  updateSize = () => {
    let vh = window.innerHeight - 300;
    if (window.innerWidth < 840) {
      document
        .getElementById("case-submission")
        .style.setProperty("height", `${vh}px`);
    } else {
      document
        .getElementById("case-submission")
        .style.setProperty("height", `100`);
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*patient info step submit  */
  /*--------------------------------------------------------------------------------*/
  handleSubmit = (values, { setSubmitting }) => {
    const doctorId = this.props.userReducer.currentUser.id;
    const caseId = this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1]
    setSubmitting(true);
    // Create new Date instance
    var newdate = new Date(values.date_of_birth,)
    // Add a day
    newdate.setDate(newdate.getDate() + 1)
    let userInfo = {
      aligner_case: {
        step: "",
        patient_attributes: {
          date_of_birth: newdate,
          first_name: values.firstName,
          gender: values.gendar,
          last_name: values.lastName,
          email: values.email,
        },
        case_type: values.case_type,
      },
      address_id: values.address_id,
    };

    let patientInfo = {
      date_of_birth: newdate,
      first_name: values.firstName,
      gender: values.gendar,
      last_name: values.lastName,
      email: values.email,
      case_type: values.case_type,
      address_id: values.address_id,
    }
    if (caseId) {
      updateAlignerCase(doctorId, caseId, JSON.stringify(userInfo)).then((res) => {
        setSubmitting(false);
        this.props.updatePatientInfo(patientInfo)
        this.props.NextStep();
      }).catch((error) => {
        setSubmitting(false);
      });
    } else {
      createAlignerCase(
        doctorId,
        JSON.stringify(userInfo)
      )
        .then((res) => {
          if (res.case_type === "Retainer") {
            window.location.href = `/case/${res.id}`
          } else {
            this.props.props.history.replace(
              `${window.location.pathname}?id=${res.id}`
            );
            this.props.updatePatientInfo(patientInfo)
            this.UpdateTreatmentType(res.id, setSubmitting)
          }
        })
        .catch((error) => {
          setSubmitting(false);
        });
    }

  };
  UpdateTreatmentType = (caseId, setSubmitting) => {

    let data = {
      aligner_case: {
        step: "Treatment Type",
        advanced_modules: {
          ...this.props.caseSubmission.advanced_modules,
          ipr: {
            ...this.props.caseSubmission.advanced_modules.ipr,
            iprRestriction: mapTeethToApi(this.props.customeTeethReducer.Case_IPR)
          },
          archExpansion: {
            ...this.props.caseSubmission.advanced_modules.archExpansion,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Arch_Expansion
            )
          },
          attachments: {
            ...this.props.caseSubmission.advanced_modules.attachments,
            attachmentsRestriction: mapTeethToApi(
              this.props.customeTeethReducer.Case_Attachments
            )
          },
          elastics: {
            ...this.props.caseSubmission.advanced_modules.elastics,
            teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics),
            buttons_teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics_Buttons),
            cuts_teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics_Cuts),
          },
          extraction: {
            ...this.props.caseSubmission.advanced_modules.extraction,
            teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Extraction),
          },
          overCorrection: {
            ...this.props.caseSubmission.advanced_modules.overCorrection,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Overcorrections
            ),
          },
          pontics: {
            ...this.props.caseSubmission.advanced_modules.pontics,
            teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Pontics),
          },
          torqueEnhancers: {
            ...this.props.caseSubmission.advanced_modules.torqueEnhancers,
            teeth: mapTeethToApi(
              this.props.customeTeethReducer.Case_Torque_Enhancers
            ),
          },
          malocclusion: {
            ...this.props.caseSubmission.advanced_modules.malocclusion,
            crossbite: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .crossbite,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Crossbites
              ),
            },
            crowding: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .crowding,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Crowding
              ),
            },
            classII: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .classII,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_ClassII
              ),
            },
            classIII: {
              ...this.props.caseSubmission.advanced_modules.malocclusion
                .classIII,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_ClassIII
              ),
            },
          },
          all_modules: mapAdvancedToApi(this.props.advancedModules.modules),
          treatment_type: {
            type: "aligner",

          }
        },
      },
    };

    updateAlignerCase(this.props.userReducer.currentUser.id,
      caseId, JSON.stringify(data)).then((res) => {
        this.props.GetAlignerCaseById()
        this.props.NextStep();
        setSubmitting(false);
        this.props.updateAdvancedModules({
          key: "treatment_type",
          data: data.aligner_case.advanced_modules.treatment_type,
        });
      })
  }
  toggleCreateUpdateAddressModal = () => {
    this.setState({ createUpdateAddressModal: !this.state.createUpdateAddressModal })
  }
  GetDoctorAddresses = () => {
    getDoctorAddresses(this.props.userReducer.currentUser.id).then((res) => {
      let newAddress = mapCountry(res);
      this.setState({
        addresses: res,
        newAddressArr: newAddress,
        loadData: true,
      });
    });
  }
  /* --------------------------------------------------------------------------------*/
  /*  Create and  Update Address */
  /* --------------------------------------------------------------------------------*/
  handleSubmit2 = (values, { setSubmitting }) => {
    setSubmitting(true);
    const data = {
      address: {
        country: values.country.value,
        city: values.city,
        state: values.state,
        line1: values.line1,
        line2: values.line2,
        zip: values.zip,
        phone: values.phone,
      },
    };
    if (this.state.id) {
      //update address
      updateAddress(this.props.id, this.state.id, JSON.stringify(data)).then(
        (res) => {
          this.props.getDoctorAddresses();
          this.setState({
            createUpdateAddressModal: !this.state.createUpdateAddressModal,
            country: "",
            city: "",
            state: "",
            line1: "",
            line2: "",
            zip: "",
            phone: "",
          });
          setSubmitting(false);

          successToaster("Updated address successfully ");
        }
      );
    } else {
      // add new address
      createAddress(this.props.id, JSON.stringify(data)).then((res) => {
        this.GetDoctorAddresses();
        this.setState({
          createUpdateAddressModal: !this.state.createUpdateAddressModal,
          country: "",
          city: "",
          state: "",
          line1: "",
          line2: "",
          zip: "",
          phone: "",
        });
        setSubmitting(false);
        successToaster("Created new address successfully");
      });
    }
  };

  toggleCancelConfirmation = () => {
    this.setState({ cancelSubmission: !this.state.cancelSubmission })
  }
  render() {
    const { patient_info } = this.props.caseSubmission;
    const address = this.props.case?.address || {}
    const caseId = this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1] || ""
    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/* Create and Update Adress modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.createUpdateAddressModal}
          toggle={this.toggleCreateUpdateAddressModal}
        >
          <ModalHeader toggle={this.toggleCreateUpdateAddressModal}>
            {`New Address`}
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                id: "",
                country: this.state.country,
                city: this.state.city,
                line1: this.state.line1,
                line2: this.state.line2,
                zip: this.state.zip,
                phone: this.state.phone,
                state: this.state.state,
              }}
              validationSchema={address_schema}
              onSubmit={this.handleSubmit2}
            >
              {({
                touched,
                errors,
                isSubmitting,
                values,
                handleSubmit,
                handleBlur,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Input type="hidden" name="id" id="id" />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="country">
                          Country <span style={{ color: "red" }}>*</span>
                        </Label>

                        <Select

                          onBlur={handleBlur}
                          onChange={(country) => {
                            setFieldValue("country", country);
                          }}
                          options={countries}
                        />
                        {errors.country && touched.country ? (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: "#dc3545",
                            }}
                          >
                            {errors.country}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="city">
                          City
                          <span style={{ color: "red" }}>*</span>{" "}
                        </Label>
                        <Field
                          type="text"
                          name="city"
                          id="city"
                          onBlur={handleBlur}
                          className={`form-control ${touched.city && errors.city ? "is-invalid" : ""
                            }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="city"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="line1">Line 1</Label>
                        <Field
                          type="text"
                          name="line1"
                          id="line1"
                          className={`form-control ${touched.line1 && errors.line1 ? "is-invalid" : ""
                            }`}
                          onBlur={handleBlur}
                          value={values.line1}
                        />
                        <ErrorMessage
                          component="div"
                          name="line1"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="line2">Line 2</Label>
                        <Field
                          type="text"
                          name="line2"
                          id="line2"
                          className={`form-control ${touched.line2 && errors.line2 ? "is-invalid" : ""
                            }`}
                          onBlur={handleBlur}
                          value={values.line2}
                        />
                        <ErrorMessage
                          component="div"
                          name="line2"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="state">State</Label>
                        <Field
                          type="text"
                          name="state"
                          id="state"
                          className={`form-control ${touched.state && errors.state ? "is-invalid" : ""
                            }`}
                          onBlur={handleBlur}
                          value={values.state}
                        />
                        <ErrorMessage
                          component="div"
                          name="state"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="zip">Zip</Label>
                        <Field
                          type="text"
                          name="zip"
                          id="zip"
                          className={`form-control ${touched.zip && errors.zip ? "is-invalid" : ""
                            }`}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          component="div"
                          name="zip"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="phone">Phone</Label>
                        <Field
                          type="text"
                          name="phone"
                          id="phone"
                          className={`form-control ${touched.phone && errors.phone ? "is-invalid" : ""
                            }`}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          component="div"
                          name="phone"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Button
                      className="btn red-btn pull-right m-1 "
                      size="md"
                      color="danger"
                      onClick={this.toggleCreateUpdateAddressModal}
                    >
                      Cancel
                    </Button>{" "}
                    <Button
                      className="btn green-btn pull-right m-1"
                      size="md"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {`Add`}
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Create and Update Adress modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Cancel Submission modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.cancelSubmission}
          toggle={this.toggleCancelConfirmation}
        >
          <ModalHeader toggle={this.toggleCancelConfirmation}>
            Cancel Submission
          </ModalHeader>
          <ModalBody>
            <div className="form-group content form-block-holder h-100">
              <label className="control-label mt-3 ml-3 mb-1">
                Are you sure you would like to cancel this submission?
              </label>
              <Row className="m-1">
                <Col xs="4" md="4" lg="4" className="p-2 w-100">
                  <Button
                    className="btn green-btn w-100"
                    size="md"
                    disabled={this.state.completingCase}
                    onClick={() => window.location.href = "/cases"}
                  >
                    Yes
                  </Button>
                </Col>
                <Col xs="4" md="4" lg="4" className="p-2 w-100">
                  <Button
                    className="btn red-btn w-100 "
                    size="md"
                    color="danger"
                    disabled={this.state.completingCase}
                    onClick={() => this.toggleCancelConfirmation()}
                  >
                    No
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Cancel Submission modal */}
        {/*--------------------------------------------------------------------------------*/}

        <CardTitle className="border-bottom pb-2 title_active-gco case-title">
          Patient Information
        </CardTitle>
        <Formik
          initialValues={{
            firstName: patient_info.first_name,
            lastName: patient_info.last_name,
            email: patient_info.email,
            gendar: patient_info.gender,
            date_of_birth: patient_info.date_of_birth,
            address_id: patient_info.address_id,
            case_type: patient_info.case_type,
          }}
          validationSchema={schema}
          onSubmit={this.handleSubmit}
        >
          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <Form className="form-material" onSubmit={handleSubmit}>
              <div className="step-progress-new-case upload-container">
                {isSubmitting && (
                  <div className={`loading2`}>
                    <div className="spinner_2"></div>
                  </div>
                )}
              </div>
              <CardBody id="case-submission">

                <div className="patient_info_grid">

                  <FormGroup>
                    <Label className="title_active-gco mb-0">
                      First Name
                      </Label>
                    <Input
                      name="firstName"
                      id="firstName"
                      type="text"
                      onChange={(e) => {
                        setFieldValue("firstName", e.target.value);
                      }}
                      value={values.firstName}
                    />{" "}
                    <ErrorMessage
                      component="div"
                      name="firstName"
                      className="custom-invalid-feedback mt-1"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="title_active-gco mb-0">Last Name</Label>
                    <Input
                      name="lastName"
                      id="lastName"
                      type="text"
                      onChange={(e) => {
                        setFieldValue("lastName", e.target.value);
                      }}
                      value={values.lastName}

                    />
                    <ErrorMessage
                      component="div"
                      name="lastName"
                      className="custom-invalid-feedback mt-1"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="title_active-gco mb-0">
                      Patient Email Address
                      </Label>
                    <Input
                      name="email"
                      id="email"
                      type="email"
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                      }}
                      value={values.email}

                    />
                    <ErrorMessage
                      component="div"
                      name="email"
                      className="custom-invalid-feedback mt-1"
                    />
                  </FormGroup>
                  <div className={`${(this.state.addresses.length !== 0) ? "margin  mb-1" : "mb-1"}`}>
                    <Label className="title_active-gco">Gender</Label>
                    <Select

                      name="gendar"
                      id="gendar"
                      options={gendar}
                      onChange={(e) => {
                        setFieldValue("gendar", e.value);
                      }}
                      value={gendar.filter(v => v.value === values.gendar)}

                    ></Select>
                    <ErrorMessage
                      component="div"
                      name="gendar"
                      className="custom-invalid-feedback mt-1"
                    />
                  </div>
                  <FormGroup className={`${(this.state.addresses.length !== 0) ? "margin  mb-1" : ""}`}>
                    <Label className="title_active-gco2 ">
                      Date of Birth
                      </Label>

                    <div className="custom-date-picker"     >
                      <DatePicker
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                              {"<"}
                            </button>
                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) => changeYear(value)}
                            >
                              {years.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map(option => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                              {">"}
                            </button>
                          </div>
                        )}
                        placeholderText="dd/MM/yyyy"
                        dateFormat="dd/MM/yyyy"
                        selected={values.date_of_birth}
                        onChange={date => {
                          setFieldValue("date_of_birth", date)
                        }} />


                      {/* <DatePicker
                        selected={values.date_of_birth}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => {
                          setFieldValue("date_of_birth", date)
                        }}
                      /> */}

                    </div>
                    {/* <Input
                        type="date"
                        name="date_of_birth"
                        id="date_of_birth"
                        onChange={(e) => {
                          setFieldValue("date_of_birth", e.target.value);
                        }}
                      // min={"1960-01-01"}
                      // max={"2000-01-01"}
                      /> */}
                    <ErrorMessage
                      component="div"
                      name="date_of_birth"
                      className="custom-invalid-feedback mt-1"
                    />
                  </FormGroup>

                  {
                    <div className="mb-1">

                      {
                        caseId !== "" ?
                          <Label className="title_active-gco2 ">
                            Shipping Address</Label>
                          :
                          <Label className="title_active-gco2 ">
                            Shipping Address
                      {this.state.addresses.length === 0 ?
                              <div>
                                <span
                                  className=" pl-2"
                                  style={{ fontSize: "12px", color: "red" }}
                                >
                                  {`  *Please add your Shipping `}
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                    }}
                                    className="title_active"
                                    onClick={() =>
                                      // this.props.props.history.push({
                                      //   pathname: "/profile",
                                      //   state: {
                                      //     activeTab: "4",
                                      //     id: this.props.props.userReducer
                                      //       .currentUser.id,
                                      //   },
                                      // })
                                      this.toggleCreateUpdateAddressModal()
                                    }
                                  >
                                    {`Address`}
                                  </span>
                                </span>
                              </div>
                              : <span
                                className=" pl-2"
                                style={{ fontSize: "12px", color: "#00c7b1 ", textDecoration: "underline", cursor: "pointer" }}
                              >
                                {`  `}
                                <span
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",

                                  }}
                                  className="title_active"
                                  onClick={() =>
                                    // this.props.props.history.push({
                                    //   pathname: "/profile",
                                    //   state: {
                                    //     activeTab: "4",
                                    //     id: this.props.props.userReducer
                                    //       .currentUser.id,
                                    //   },
                                    // })
                                    this.toggleCreateUpdateAddressModal()
                                  }
                                >
                                  {` Add new ddress`}
                                </span>
                              </span>

                            }


                          </Label>



                      } <Select
                        options={this.state.addresses}
                        id="address_id"
                        name="address_id"
                        // style={{ paddingTop:"40px"  }}
                        onChange={(e) => {
                          setFieldValue("address_id", e.value);
                        }}
                        value={caseId !== "" ? {
                          value: "000",
                          label: ` ${GetCountryLabel(address.country)}, ${address.city}, ${address.state
                            }, ${address.line1}, ${address.line2}, ${address.zip}, ${address.phone}`
                        } : this.state.addresses.filter(v => v.id === values.address_id)}
                        isDisabled={caseId !== ""}
                      />
                      <ErrorMessage
                        component="div"
                        name="address_id"
                        className="custom-invalid-feedback mt-1"
                      />
                    </div>


                  }

                </div>


                <Row>
                  <Col xs="12" md="10" lg="7">
                    <FormGroup className="mt-3 mb-2">
                      <Label className="title_active-gco ">Case Type</Label>
                      <div className="case-type-grid">
                        <Button
                          name="case_type"
                          style={{ width: "auto" }}
                          className={`btn  ${values.case_type === "LiteII"
                            ? "preference-btn-active"
                            : "preference-btn"
                            } text-center  btn-radius`}
                          size="sm"
                          onClick={() => {
                            setFieldValue("case_type", "LiteII");
                          }}
                        >
                          Lite
                        </Button>
                        <Button
                          name="case_type"
                          className={`btn  ${values.case_type === "Lite Single Arch"
                            ? "preference-btn-active"
                            : "preference-btn"
                            } text-center  btn-radius`}
                          size="sm"
                          onClick={() => {
                            setFieldValue("case_type", "Lite Single Arch");
                          }}
                        >
                          Lite Single Arch
                        </Button>
                        <Button
                          name="case_type"
                          style={{ width: "auto" }}
                          className={`btn  ${values.case_type === "UnlimitedII"
                            ? "preference-btn-active"
                            : "preference-btn"
                            } text-center  btn-radius`}
                          size="sm"
                          onClick={() => {
                            setFieldValue("case_type", "UnlimitedII");
                          }}
                        >
                          Unlimited
                        </Button>
                        <Button
                          name="case_type"
                          style={{ width: "auto" }}
                          className={`btn  ${values.case_type === "Retainer"
                            ? "preference-btn-active"
                            : "preference-btn"
                            } text-center  btn-radius`}
                          size="sm"
                          onClick={() => {
                            setFieldValue("case_type", "Retainer");
                          }}
                          disabled={caseId !== ""}
                        >
                          Retainer
                        </Button>
                      </div>
                      <ErrorMessage
                        component="div"
                        name="case_type"
                        className="custom-invalid-feedback mt-1"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="p-3">
                {!window.location.search && <Button
                  className={`btn ${"preference-btn"} float-left text-center mb-2 btn-radius`}
                  size="sm"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => this.toggleCancelConfirmation()}
                >
                  <i className="fas fa-angle-left arrows-icon pr-1"></i> Cancel Submission
                </Button>}
                <Button
                  className={`btn  ${"preference-btn"} float-right text-center  btn-radius mb-2`}
                  size="sm"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {values.case_type === "Retainer" ? "Submit" : "Photos + X-rays"} <i className="fas fa-angle-right arrows-icon pl-1"></i>
                </Button>{" "}
              </CardFooter>{" "}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo);
