import React, { Component } from "react";
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Input,
  Row,
  Col,
  Spinner,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
} from "reactstrap";
import "react-table/react-table.css";
import {
  archiveCase,
  getFilteredAlignerCases,
  unArchiveCase,
} from "../../api/api";
import { connect } from "react-redux";
import { caseType } from "../../helpers/caseType";
import { statusColors } from "../../helpers/caseStatus";
import { successToaster } from "../../services/toast";
import { clearSteps } from "../../redux/CaseSubmission/action"
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  clearSteps: (payload) => dispatch(clearSteps(payload)),

})
class Cases extends Component {
  /*--------------------------------------------------------------------------------*/
  /*constructor */
  /*--------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /*inint state*/
    this.state = {
      new_cases_Date: "2021-01-25",
      id: props.userReducer.currentUser.id,
      cases: [],
      modal: false,
      obj: {},
      data: [],
      filteredData: [],
      searchInput: this.props.location.state?.data
        ? this.props.location.state?.data.filters.uid_or_patient_full_name_cont
        : "",
      filter: this.props.location.state?.data
        ? this.props.location.state?.data.filter
        : "All",
      filteredData2: [],
      Loading: false,
      filters: this.props.location.state?.data
        ? this.props.location.state?.data.filters
          .aligner_case_transition_to_state_in
        : null,
      pageSize: this.props.location.state?.data
        ? this.props.location.state?.data.per_page
        : 20,
      page: this.props.location.state?.data
        ? this.props.location.state?.data.page - 1
        : 0,
      pages: 1,
      sort: {
        id: "created_at",
        desc: true,
      },
      oldFilters: this.props.location.state?.data,
      loadingData: false,
      archiveModal: false,
      unArchiveModal: false,
    };
    this.reactTable = React.createRef();
  }
  /*--------------------------------------------------------------------------------*/
  /*get aligner cases with init filters                                             */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    this.GetAllCases();
  }

  GetAllCases = () => {
    this.setState({ Loading: true });
    let data = {
      ...(this.props.location.state?.data
        ? this.props.location.state?.data
        : {
          filters: {
            uid_or_patient_full_name_cont: this.state.searchInput,
            aligner_case_transition_to_state_in: this.state.filters,
            s: "created_at desc",
            archived_case_eq: false

          },
          page: this.state.page + 1,
          per_page: this.state.pageSize,
        }),
    };
    this.GetFilteredAlignerCase(data);
  };
  /*--------------------------------------------------------------------------------*/
  /*get aligner cases                                                               */
  /*--------------------------------------------------------------------------------*/
  GetFilteredAlignerCase(data) {
    window.scrollTo(0, 0);
    this.setState({ loadingData: !this.setState.loadingData });
    getFilteredAlignerCases(this.state.id, JSON.stringify(data))
      .then((res) => {
        this.setState({
          cases: res.aligner_cases,
          Loading: false,
          pages: res.total_pages,
          loadingData: false,
        });
      })
      .catch((error) => {
        this.setState({ loadingData: !this.setState.loadingData });
      });
  }
  /*--------------------------------------------------------------------------------*/
  /*search                                                                          */
  /*--------------------------------------------------------------------------------*/
  handleChange = () => {
    let data = {
      filters: {
        uid_or_patient_full_name_cont: this.state.searchInput,
        aligner_case_transition_to_state_in: this.state.filters,
        s: "created_at desc",
        archived_case_eq: false

      },
      page: 1,
      per_page: this.state.pageSize,
    };
    this.setState({ page: 0 });
    this.GetFilteredAlignerCase(data);
  };
  /*--------------------------------------------------------------------------------*/
  /*clear Data                                                                      */
  /*--------------------------------------------------------------------------------*/
  clearData = () => {
    this.setState({ searchInput: "" });

    let data = {
      filters: {
        uid_or_patient_full_name_cont: "",
        aligner_case_transition_to_state_in: this.state.filters,
        s: "created_at desc",
        archived_case_eq: false

      },
      page: this.state.page + 1,
      per_page: this.state.pageSize,
    };
    this.GetFilteredAlignerCase(data);
  };
  /*--------------------------------------------------------------------------------*/
  /*Filter                                                                          */
  /*--------------------------------------------------------------------------------*/
  Filter = (status, status2) => {
    // this.reactTable.current.state.page = 0;
    this.setState({
      filter: status,
      filters: status2,
      page: 0,
      searchInput: "",
    });
    let data = {
      filters: {
        uid_or_patient_full_name_cont: "",
        aligner_case_transition_to_state_in: status2,
        s: "created_at desc",
        archived_case_eq: false

      },
      page: 1,
      per_page: this.state.pageSize,
    };
    this.GetFilteredAlignerCase(data);
  };
  /*--------------------------------------------------------------------------------*/
  /*Filter archived cases                                                                       */
  /*--------------------------------------------------------------------------------*/
  Archived = (status, status2) => {
    this.setState({
      filter: status,
      filters: [],
      page: 0,
      searchInput: "",
    });
    let data = {
      filters: {
        uid_or_patient_full_name_cont: "",
        aligner_case_transition_to_state_in: [],
        s: `${this.state.sort.id} ${this.state.sort.desc ? "desc" : "asc"}`,
        archived_case_eq: status2,
      },
      page: 1,
      per_page: this.state.pageSize,
    };
    this.GetFilteredAlignerCase(data);
  };
  /*--------------------------------------------------------------------------------*/
  /*on Page Change                                                                  */
  /*--------------------------------------------------------------------------------*/
  onPageChange(page) {
    this.setState({ page });
    let data = {
      filters: {
        uid_or_patient_full_name_cont: this.state.searchInput,
        aligner_case_transition_to_state_in: this.state.filters,
        s: `${this.state.sort.id} ${this.state.sort.desc ? "desc" : "asc"}`,
        archived_case_eq: false,

      },
      page: page + 1,
      per_page: this.state.pageSize,
    };
    this.GetFilteredAlignerCase(data);
  }
  /*--------------------------------------------------------------------------------*/
  /*mobilePagination                                                                */
  /*--------------------------------------------------------------------------------*/
  mobilePagination = (page) => {
    if (page.target.value) {
      if (page.target.value < 1) {
        this.setState({ page: 0 });
        let data = {
          filters: {
            uid_or_patient_full_name_cont: this.state.searchInput,
            aligner_case_transition_to_state_in: this.state.filters,
            s: `${this.state.sort.id} ${this.state.sort.desc ? "desc" : "asc"}`,
            archived_case_eq: false,

          },
          page: 1,
          per_page: this.state.pageSize,
        };
        this.GetFilteredAlignerCase(data);
      } else if (page.target.value > this.state.pages) {
        this.setState({ page: Number(this.state.pages) - 1 });
        let data = {
          filters: {
            uid_or_patient_full_name_cont: this.state.searchInput,
            aligner_case_transition_to_state_in: this.state.filters,
            s: `${this.state.sort.id} ${this.state.sort.desc ? "desc" : "asc"}`,
            archived_case_eq: false,

          },
          page: this.state.pages,
          per_page: this.state.pageSize,
        };
        this.GetFilteredAlignerCase(data);
      } else {
        this.setState({ page: Number(page.target.value) - 1 });
        let data = {
          filters: {
            uid_or_patient_full_name_cont: this.state.searchInput,
            aligner_case_transition_to_state_in: this.state.filters,
            s: `${this.state.sort.id} ${this.state.sort.desc ? "desc" : "asc"}`,
            archived_case_eq: false,

          },
          page: page.target.value,
          per_page: this.state.pageSize,
        };
        this.GetFilteredAlignerCase(data);
      }
    }
  };
  /*--------------------------------------------------------------------------------*/
  /*next                                                                            */
  /*--------------------------------------------------------------------------------*/
  next = () => {
    this.setState({ page: this.state.page + 1 });
    let data = {
      filters: {
        uid_or_patient_full_name_cont: this.state.searchInput,
        aligner_case_transition_to_state_in: this.state.filters,
        s: `${this.state.sort.id} ${this.state.sort.desc ? "desc" : "asc"}`,
        archived_case_eq: false,

      },
      page: this.state.page + 2,
      per_page: this.state.pageSize,
    };
    this.GetFilteredAlignerCase(data);
  };
  /*--------------------------------------------------------------------------------*/
  /*prev                                                                            */
  /*--------------------------------------------------------------------------------*/
  prev = () => {
    this.setState({ page: this.state.page - 1 });
    let data = {
      filters: {
        uid_or_patient_full_name_cont: this.state.searchInput,
        aligner_case_transition_to_state_in: this.state.filters,
        s: `${this.state.sort.id} ${this.state.sort.desc ? "desc" : "asc"}`,
        archived_case_eq: false,

      },
      page: this.state.page,
      per_page: this.state.pageSize,
    };
    this.GetFilteredAlignerCase(data);
  };
  /*--------------------------------------------------------------------------------*/
  /*on Page Size Change                                                             */
  /*--------------------------------------------------------------------------------*/
  onPageSizeChange(pageSize) {
    this.setState({ pageSize });
    let data = {
      filters: {
        uid_or_patient_full_name_cont: this.state.searchInput,
        aligner_case_transition_to_state_in: this.state.filters,
        s: `${this.state.sort.id} ${this.state.sort.desc ? "desc" : "asc"}`,
        archived_case_eq: false,

      },
      page: this.state.page + 1,
      per_page: pageSize,
    };
    this.GetFilteredAlignerCase(data);
  }
  /*--------------------------------------------------------------------------------*/
  /*  on Sorted Change                                                              */
  /*--------------------------------------------------------------------------------*/
  onSortedChange(sort) {
    this.setState({ sort });
    let data = {
      filters: {
        uid_or_patient_full_name_cont: this.state.searchInput,
        aligner_case_transition_to_state_in: this.state.filters,
        s: `${sort.id} ${sort.desc ? "desc" : "asc"}`,
        archived_case_eq: false,

      },
      page: this.state.page + 1,
      per_page: this.state.pageSize,
    };
    this.GetFilteredAlignerCase(data);
  }
  /*--------------------------------------------------------------------------------*/
  /*  archive modal                                                           */
  /*--------------------------------------------------------------------------------*/
  toggleArchiveModal = () => {
    this.setState({ archiveModal: !this.state.archiveModal });
  };
  ArchiveCase = () => {
    archiveCase(this.state.id, this.state.obj.case_id).then((res) => {
      successToaster("archived case successfully");
      this.toggleArchiveModal();

      this.GetAllCases();
    });
  };
  /*--------------------------------------------------------------------------------*/
  /*  archive modal                                                           */
  /*--------------------------------------------------------------------------------*/
  toggleUnArchiveModal = () => {
    this.setState({ unArchiveModal: !this.state.unArchiveModal });
  };

  unArchiveCase = () => {
    unArchiveCase(this.state.id, this.state.obj.case_id).then((res) => {
      successToaster("Restored case successfully");
      this.toggleUnArchiveModal();
      // this.GetAllCases();
      this.setState({ Loading: true });
      let data = {
        ...(this.props.location.state?.data
          ? this.props.location.state?.data
          : {
            filters: {
              uid_or_patient_full_name_cont: this.state.searchInput,
              aligner_case_transition_to_state_in: this.state.filters,
              s: "created_at desc",
              archived_case_eq: true

            },
            page: this.state.page + 1,
            per_page: this.state.pageSize,
          }),
      };
      this.GetFilteredAlignerCase(data);
    });
  };
  archivedStatus = (status) => {
    let statuses = [
      "Incomplete Submission",
      "Pending Courier Notification",
      "Pending Record Approval",
      "Pending TS Approval",
      "Generating TS",
      "Rejected TS",
      "Records Rejected",
      "Complete",
      "Awaiting Impressions",
      "Generating Adjustment TS",
      "Awaiting completion",
    ];
    return statuses.includes(status);
  };
  /*--------------------------------------------------------------------------------*/
  render() {
    const { new_cases_Date } = this.state
    /*  filtered Data to render on table   */
    let filteredData = this.state.cases.map((prop, key) => {
      return {
        id: key,
        patient_full_name: prop.patient.full_name,
        case_id: prop.id,
        is_archived: prop.is_archived,
        uid: (
          <div
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => {
              this.props.history.push({
                pathname: `/case/${prop.id}`,
                state: {
                  data: {
                    filters: {
                      uid_or_patient_full_name_cont: this.state.searchInput,
                      aligner_case_transition_to_state_in: this.state.filters,
                      s: `${this.state.sort.id} ${this.state.sort.desc ? "desc" : "asc"
                        }`,
                      archived_case_eq: false,

                    },
                    page: this.state.page + 1,
                    per_page: this.state.pageSize,
                    filter: this.state.filter,
                  },
                },
              });
            }}
          >
            {" "}
            {prop.uid
              ? prop.status !== "Incomplete Submission"
                ? prop.uid
                : ""
              : ""}
          </div>
        ),
        case_type: caseType[prop.case_type] || prop.case_type,
        status: (
          <div
            className="status_button"
            style={{
              color: statusColors[prop.status]
                ? `${statusColors[prop.status].color}`
                : "#67757c",
            }}
          >
            {prop.status}
          </div>
        ),
        created_at: prop.submitted_at,
        actions: (
          <div className="text-center align-items-center">
            {prop.status === "Incomplete Submission" && !prop.is_archived && (
              <div>
                {" "}
                <Button
                  onClick={() => {
                    let obj = filteredData.find((o) => o.id === key);
                    this.setState({
                      modal: !this.state.modal,
                      obj: obj,
                    });

                    // if(new Date(prop.submitted_at)  < new Date(new_cases_Date)){
                    //   this.props.history.push(`/case/new?id=${prop.id}`);

                    // }else{ 
                    this.props.clearSteps()
                    this.props.history.push(`/case-submission?id=${prop.id}`);

                    //  }
                  }}
                  size="sm"
                  className="btn green-btn"
                >
                  Continue submission
                </Button>
              </div>
            )}

            {prop.status === "Records Rejected" && !prop.is_archived && (
              <Button
                onClick={() => {
                  let obj = filteredData.find((o) => o.id === key);
                  this.setState({
                    modal: !this.state.modal,
                    obj: obj,
                  });
                  this.props.history.push({
                    pathname: `/case/${prop.id}`,
                    state: {
                      data: {
                        filters: {
                          uid_or_patient_full_name_cont: this.state.searchInput,
                          aligner_case_transition_to_state_in: this.state
                            .filters,
                          s: `${this.state.sort.id} ${this.state.sort.desc ? "desc" : "asc"
                            }`,
                          archived_case_eq: false
                        },
                        page: this.state.page + 1,
                        per_page: this.state.pageSize,
                        filter: this.state.filter,
                      },
                    },
                  });
                }}
                color="info"
                size="sm"
                className="btn action-btn"
              >
                Update records
              </Button>
            )}
            {prop.status === "Pending TS Approval" && !prop.is_archived && (
              <Button
                onClick={() => {
                  let obj = filteredData.find((o) => o.id === key);
                  this.setState({
                    modal: !this.state.modal,
                    obj: obj,
                  });
                  this.props.history.push({
                    pathname: `/case/${prop.id}`,
                    state: {
                      data: {
                        filters: {
                          uid_or_patient_full_name_cont: this.state.searchInput,
                          aligner_case_transition_to_state_in: this.state
                            .filters,
                          s: `${this.state.sort.id} ${this.state.sort.desc ? "desc" : "asc"
                            }`,
                          archived_case_eq: false
                        },
                        page: this.state.page + 1,
                        per_page: this.state.pageSize,
                        filter: this.state.filter,
                      },
                    },
                  });
                }}
                color="info"
                size="sm"
                className="btn action-btn"
              >
                View Treatment plan
              </Button>
            )}
          </div>
        ),
        archive: (
          <div className="text-center align-items-center justify-content-center ">
            {this.archivedStatus(prop.status) && !prop.is_archived && (
              <div>
                <Button
                  onClick={() => {
                    let obj = filteredData.find((o) => o.id === key);
                    this.setState({
                      archiveModal: !this.state.archiveModal,
                      obj: obj,
                    });
                  }}
                  className="ml-1"
                  color="white"
                  size="md"
                  round="true"
                  icon="true"
                  title=" Archive case"
                >
                  <i className="fas fa-archive"></i>
                </Button>
              </div>
            )}
            {prop.is_archived && (
              <div>
                <Button
                  onClick={() => {
                    let obj = filteredData.find((o) => o.id === key);
                    this.setState({
                      unArchiveModal: !this.state.unArchiveModal,
                      obj: obj,
                    });
                  }}
                  className="ml-1"
                  color="white"
                  size="md"
                  round="true"
                  icon="true"
                  title="Restore case"
                >
                  <i className="fas fa-undo"></i>{" "}
                </Button>
              </div>
            )}
          </div>
        ),
      };
    });

    return (
      <div>
        <style>
          {`.ReactTable .rt-thead{
              color:#00C7B1
          }`}
        </style>
        {/*--------------------------------------------------------------------------------*/}
        {/* Archive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.archiveModal}
          toggle={this.toggleArchiveModal}
        >
          <ModalHeader toggle={this.toggleArchiveModal}>
            Archive a Case
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <h5>Are you sure you want to archive this case?</h5>
            </FormGroup>
            <FormGroup>
              <Button
                className="btn red-btn pull-right m-1 "
                size="md"
                color="danger"
                onClick={this.toggleArchiveModal}
              >
                Cancel
              </Button>
              <Button
                className="btn green-btn pull-right m-1"
                size="md"
                type="submit"
                onClick={this.ArchiveCase}
              >
                Archive
              </Button>
            </FormGroup>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Archive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* unArchive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.unArchiveModal}
          toggle={this.toggleUnArchiveModal}
        >
          <ModalHeader toggle={this.toggleUnArchiveModal}>
            Restore a Case
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <h5>Are you sure you want to restore this case?</h5>
            </FormGroup>
            <FormGroup>
              <Button
                className="btn red-btn pull-right m-1 "
                size="md"
                color="danger"
                onClick={this.toggleUnArchiveModal}
              >
                Cancel
              </Button>
              <Button
                className="btn green-btn pull-right m-1"
                size="md"
                type="submit"
                onClick={this.unArchiveCase}
              >
                Restore
              </Button>
            </FormGroup>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End unArchive a case Modal /}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* Fixed header-footer table*/}
        {/*--------------------------------------------------------------------------------*/}
        <Card>
          <CardTitle
            className={`mb-0 p-3 ${window.innerWidth > 839 ? "border-bottom" : ""
              } `}
          >
            <Row className="p-2 align-items-center">
              <Col>Aligner Cases</Col>
              {window.innerWidth > 839 && (
                <Col>
                  <Button
                    className="btn green-btn h-100 pull-right"
                    size="md"
                    onClick={() => {
                      this.props.clearSteps()
                      window.location.href = `/case-submission`;


                      //this.props.history.push(`/case/new`);
                    }}
                  >
                    Submit a Case
                  </Button>
                </Col>
              )}
            </Row>
            {/*--------------------------------------------------------------------------------*/}
            {/* Filters*/}
            {/*--------------------------------------------------------------------------------*/}
            {!this.state.Loading ? (
              <Row className="align-items-center list-title">
                <Col xs="12" md="7" lg="7" className="order_1">
                  <Row className="case-status">
                    <Col xs="6" md="4" lg="2" className="pt-2 pb-2">
                      <Button
                        className={`btn ${this.state.filter === "All"
                          ? `green-btn-active1`
                          : `green-btn-not-active1`
                          } w-100 h-100 m-1`}
                        size="sm"
                        onClick={() => {
                          this.setState({
                            filter: "All",
                            filters: "",
                          });
                          this.Filter("All", "");
                        }}
                      >
                        All
                      </Button>
                    </Col>
                    <Col xs="6" md="4" lg="2" className="pt-2 pb-2">
                      <Button
                        className={`btn ${this.state.filter === "Active"
                          ? `green-btn-active1`
                          : `green-btn-not-active1`
                          } w-100 h-100 m-1`}
                        size="sm"
                        onClick={() => {
                          this.Filter("Active", "active");
                        }}
                      >
                        Active
                      </Button>
                    </Col>
                    <Col xs="6" md="4" lg="2" className="pt-2 pb-2">
                      <Button
                        className={`btn ${this.state.filter === "Complete"
                          ? `green-btn-active1`
                          : `green-btn-not-active1`
                          } w-100 h-100 m-1`}
                        size="sm"
                        onClick={() => {
                          this.Filter("Complete", "complete");
                        }}
                      >
                        Complete
                      </Button>
                    </Col>
                    <Col xs="6" md="4" lg="2" className="pt-2 pb-2">
                      <Button
                        className={`btn ${this.state.filter === "Pending"
                          ? `green-btn-active1`
                          : `green-btn-not-active1`
                          } w-100 h-100 m-1`}
                        size="sm"
                        onClick={() => {
                          this.Filter("Pending", [
                            "pending_courier_notification",
                            "pending_records_approval",
                            "pending_ts_approval",
                            "pending_accounting_clearance",
                          ]);
                        }}
                      >
                        Pending
                      </Button>
                    </Col>
                    <Col xs="6" md="4" lg="2" className="pt-2 pb-2">
                      <Button
                        className={`btn ${this.state.filter === "Rejected"
                          ? `green-btn-active1`
                          : `green-btn-not-active1`
                          } w-100 h-100 m-1`}
                        size="sm"
                        onClick={() => {
                          this.Filter("Rejected", [
                            "rejected_records",
                            "doctor_rejected_ts",
                          ]);
                        }}
                      >
                        Rejected
                      </Button>
                    </Col>
                    <Col xs="6" md="4" lg="2" className="pt-2 pb-2">
                      <Button
                        className={`btn ${this.state.filter === "Archived"
                          ? `green-btn-active1`
                          : `green-btn-not-active1`
                          } w-100 h-100 m-1`}
                        size="sm"
                        onClick={() => {
                          this.Archived("Archived", true);
                        }}
                      >
                        Archived
                      </Button>
                    </Col>
                  </Row>
                </Col>
                {/* <Col xs="12" md="1" lg="1" className="order_2"></Col> */}
                <Col xs="12" md="5" lg="5" className="pt-2 pb-2 order_3">
                  <Row className="align-items-center">
                    <Col xs="10" md="10" lg="10" className="pr-0">
                      {" "}
                      <div className="btn-group w-100 ">
                        <Input
                          bsSize="large"
                          name="searchInput"
                          placeholder=" Search "
                          value={this.state.searchInput || ""}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              this.clearData();
                            } else {
                              this.setState({ searchInput: e.target.value });
                            }
                          }}
                          label="Search"
                          autoComplete="off"
                          className="green_input"
                          onKeyDown={(e) => {
                            if (e.target.value !== "" && e.key === "Enter") {
                              this.setState({ searchInput: e.target.value });
                              this.handleChange();
                            }
                          }}
                        />
                        <span
                          id="searchclear"
                          className={`${this.state.searchInput === ""
                            ? "disabled_search"
                            : "active-clear"
                            }`}
                          onClick={() => {
                            this.clearData();
                          }}
                        >
                          <i className="fas fa-times"></i>{" "}
                        </span>
                      </div>
                    </Col>
                    <Col xs="2" md="2" lg="2" className="pl-0">
                      <div
                        className="green-btn3"
                        onClick={() => {
                          this.handleChange();
                        }}
                      >
                        <i className="fas fa-search" aria-hidden="true"></i>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
                <div></div>
              )}
            {/*--------------------------------------------------------------------------------*/}
            {/* End Filters*/}
            {/*--------------------------------------------------------------------------------*/}
          </CardTitle>
          <CardBody
            className={
              !this.state.Loading ? "" : "m-auto d-flex align-items-center "
            }
            id="cases_table"
          >
            {!this.state.Loading ? (
              <>
                <div className="step-progress-new-case upload-container">
                  {this.state.loadingData && (
                    <div className={`loading2`}>
                      <div className="spinner_2"></div>
                    </div>
                  )}
                </div>
                {/*--------------------------------------------------------------------------------*/}
                {/* Desktop Table */}
                {/*--------------------------------------------------------------------------------*/}
                {window.innerWidth > 839 && (
                  <>
                    {" "}
                    <ReactTable
                      ref={this.reactTable}
                      data={filteredData}
                      columns={
                        window.innerWidth > 839
                          ? [
                            {
                              Header: "Patient Name",
                              accessor: "patient_full_name",
                              width: 200,
                            },
                            { Header: "Case #", accessor: "uid", width: 150 },
                            {
                              Header: "Case Type",
                              accessor: "case_type",
                              width: 150,
                            },
                            {
                              Header: "Date Submitted",
                              accessor: "created_at",
                              width: 200,
                            },
                            {
                              Header: "Status",
                              accessor: "status",
                              width: 200,
                              sortable: false,
                            },
                            {
                              Header: "Action",
                              accessor: "actions",
                              width: 200,
                              sortable: false,
                            },
                            {
                              Header: "Archive",
                              accessor: "archive",
                              width: 100,
                              sortable: false,
                            },
                          ]
                          : [
                            {
                              Header: "Patient Name",
                              accessor: "patient_full_name",
                              width: 100,
                            },
                            {
                              Header: "Status",
                              accessor: "status",
                              width: 100,
                            },
                            {
                              Header: "Action",
                              accessor: "actions",
                              sortable: false,
                              width: 100,
                            },
                          ]
                      }
                      defaultPageSize={this.state.pageSize}
                      onSortedChange={(sort) => {
                        this.onSortedChange(sort[0]);
                      }}
                      onPageSizeChange={(pageSize) =>
                        this.onPageSizeChange(pageSize)
                      }
                      page={this.state.page}
                      onPageChange={(page) => this.onPageChange(page)}
                      // style={{
                      //   height: "400px", // This will force the table body to overflow and scroll, since there is not enough room
                      // }}
                      showPagination={false}
                      pageSizeOptions={[5, 10, 20]}
                      loading={this.state.loadingData}
                      manual
                      pages={this.state.pages}
                      minWidth={650}
                      loadingText="Loading..."
                      className=" -highlight"
                    />
                    <div className="pagination-bottom ReactTable">
                      {" "}
                      <div className="-pagination">
                        <div className="-previous">
                          <button
                            type="button"
                            disabled={this.state.page === 0}
                            className="-btn"
                            onClick={() =>
                              this.onPageChange(this.state.page - 1)
                            }
                          >
                            Previous
                          </button>
                        </div>
                        <div className="-center">
                          <span className="-pageInfo">
                            Page
                            <div className="-pageJump">
                              <input
                                aria-label="jump to page"
                                type="number"
                                step="1"
                                value={this.state.page + 1}
                                min={1}
                                max={this.state.pages}
                                onChange={this.mobilePagination}
                              />
                            </div>{" "}
                            of{" "}
                            <span className="-totalPages">
                              {this.state.pages}
                            </span>
                          </span>
                          <span className="select-wrap -pageSizeOptions">
                            <select
                              aria-label="rows per page"
                              value={this.state.pageSize}
                              onChange={(e) => {
                                this.onPageSizeChange(Number(e.target.value));
                              }}
                            >
                              <option value={5}>5 rows</option>
                              <option value={10}>10 rows</option>
                              <option value={20}>20 rows</option>
                            </select>
                          </span>
                        </div>
                        <div className="-next">
                          <button
                            type="button"
                            className="-btn"
                            disabled={this.state.page === this.state.pages - 1}
                            onClick={() =>
                              this.onPageChange(this.state.page + 1)
                            }
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/*--------------------------------------------------------------------------------*/}
                {/* End Desktop Table*/}
                {/*--------------------------------------------------------------------------------*/}
                {/*--------------------------------------------------------------------------------*/}
                {/* Mobile Table */}
                {/*--------------------------------------------------------------------------------*/}
                {window.innerWidth < 840 && (
                  <>
                    <Table className="w-100 p-0" bordered size="sm">
                      <thead>
                        <tr>
                          <th>Patient Name</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.cases.map((value, index) => (
                          <tr key={index}>
                            <td>{value.patient.full_name}</td>
                            <td>
                              {value.uid &&
                                value.status !== "Incomplete Submission" ? (
                                  <div
                                    style={{ color: "blue" }}
                                    onClick={() => {
                                      this.props.history.push({
                                        pathname: `/case/${value.id}`,
                                        state: {
                                          data: {
                                            filters: {
                                              uid_or_patient_full_name_cont: this
                                                .state.searchInput,
                                              aligner_case_transition_to_state_in: this
                                                .state.filters,
                                              s: `${this.state.sort.id} ${this.state.sort.desc
                                                ? "desc"
                                                : "asc"
                                                }`,
                                              archived_case_eq: false

                                            },
                                            page: this.state.page + 1,
                                            per_page: this.state.pageSize,
                                            filter: this.state.filter,
                                          },
                                        },
                                      });
                                    }}
                                  >
                                    {value.status}
                                  </div>
                                ) : (
                                  value.status
                                )}
                            </td>
                            <td>
                              {" "}
                              {value.status === "Incomplete Submission" && (
                                <Button
                                  onClick={() => {
                                    this.props.clearSteps()

                                    // if(new Date(value.submitted_at)  < new Date(new_cases_Date)){
                                    //   this.props.history.push(`/case/new?id=${value.id}`);

                                    // }else{
                                    this.props.history.push(`/case-submission?id=${value.id}`);
                                    //  }

                                  }}
                                  size="sm"
                                  className="btn green-btn"
                                >
                                  Continue submission
                                </Button>
                              )}
                              {value.status === "Records Rejected" && (
                                <Button
                                  onClick={() => {
                                    this.props.history.push(
                                      `/case/${value.id}`
                                    );
                                  }}
                                  color="info"
                                  size="sm"
                                  className="btn action-btn"
                                >
                                  Update records
                                </Button>
                              )}
                              {value.status === "Pending TS Approval" && (
                                <Button
                                  onClick={() => {
                                    this.props.history.push(
                                      `/case/${value.id}`
                                    );
                                  }}
                                  color="info"
                                  size="sm"
                                  className="btn action-btn"
                                >
                                  View Treatment plan
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="pagination_grid">
                      <div>
                        <Button
                          className="sm w-100 page-btn"
                          disabled={this.state.page === 0}
                          onClick={this.prev}
                        >
                          previous
                        </Button>
                      </div>
                      <div className="pagination_grid2">
                        <div> Page</div>
                        <Input
                          type="number"
                          step="1"
                          value={this.state.page + 1}
                          bsSize="sm"
                          min={1}
                          max={this.state.pages}
                          onChange={this.mobilePagination}
                        />
                        <div>of {this.state.pages}</div>
                      </div>
                      <div>
                        <Button
                          className="sm w-100 page-btn"
                          disabled={this.state.page === this.state.pages - 1}
                          onClick={this.next}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                    <div className="new_case2">
                      <div id="container_btn">
                        <div id="content">
                          <Button
                            className="service-panel-toggle text-white add-case btn green-btn"
                            size="md"
                            title="Submit a case"
                            onClick={() => {
                              this.props.clearSteps()

                              window.location.href = `/case-submission`;
                              // this.props.history.push(`/case/new`);
                            }}
                          >
                            Submit a Case
                          </Button>{" "}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/*--------------------------------------------------------------------------------*/}
                {/* End Mobile Table */}
                {/*--------------------------------------------------------------------------------*/}
              </>
            ) : (
                <Spinner className="green-spinner" type="grow" />
              )}
          </CardBody>
        </Card>
        {/*--------------------------------------------------------------------------------*/}
        {/* End fixed header-footer table*/}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Cases);
