const meshesForStep = [];

//let the loader populate the meshes

function fillAtEnd(pos, j, m, last, olast) {
   //console.log("FILLS End",pos,j, last, olast);
  if (pos < last - 1) meshesForStep[pos][j] = m;
  else {
    for (let i = pos; i < olast; i++) {
      meshesForStep[i][j] = m;
      //console.log("FILLS iterating", i);
    }
  }
}

function fillAtStart(pos, j, m, last, olast) {
  const delta = olast - last;
  // console.log("FILLS Start",pos,j, last, olast);
  if (pos > 0) meshesForStep[pos + delta][j] = m;
  else {
    for (let i = 0; i <= delta; i++) {
      meshesForStep[i][j] = m;
      //console.log("FILLS iterating", i);
    }
  }
}

const createScene = function (lowerNum, upperNum, startTogether) {
  if (startTogether) {
    if (lowerNum <= upperNum) {
      return {
        lower: {
          addTeeth: (i, m) => fillAtEnd(i, 0, m, lowerNum, upperNum), //meshesForStep[i][0] = m,
          addGums: (i, m) => fillAtEnd(i, 1, m, lowerNum, upperNum), //meshesForStep[i][1] = m,
          addAttachments: (i, m) => fillAtEnd(i, 2, m, lowerNum, upperNum), //meshesForStep[i][2] = m,
          addSuperImpose: (i, m) => fillAtEnd(i, 6, m, lowerNum, upperNum), //meshesForStep[i][2] = m,
        },
        upper: {
          addTeeth: (i, m) => (meshesForStep[i][3] = m),
          addGums: (i, m) => (meshesForStep[i][4] = m),
          addAttachments: (i, m) => (meshesForStep[i][5] = m),
          addSuperImpose: (i, m) => (meshesForStep[i][7] = m),
        },
      };
    }
    return {
      lower: {
        addTeeth: (i, m) => (meshesForStep[i][0] = m),
        addGums: (i, m) => (meshesForStep[i][1] = m),
        addAttachments: (i, m) => (meshesForStep[i][2] = m),
        addSuperImpose: (i, m) => (meshesForStep[i][6] = m),
      },
      upper: {
        addTeeth: (i, m) => fillAtEnd(i, 3, m, upperNum, lowerNum), //meshesForStep[i][3] = m,
        addGums: (i, m) => fillAtEnd(i, 4, m, upperNum, lowerNum), //meshesForStep[i][4] = m,
        addAttachments: (i, m) => fillAtEnd(i, 5, m, upperNum, lowerNum), //meshesForStep[i][5] = m,
        addSuperImpose: (i, m) => fillAtEnd(i, 7, m, upperNum, lowerNum), //meshesForStep[i][5] = m,
      },
    };
  } else {
    if (lowerNum <= upperNum) {
      return {
        lower: {
          addTeeth: (i, m) => fillAtStart(i, 0, m, lowerNum, upperNum), //meshesForStep[i][0] = m,
          addGums: (i, m) => fillAtStart(i, 1, m, lowerNum, upperNum), //meshesForStep[i][1] = m,
          addAttachments: (i, m) => fillAtStart(i, 2, m, lowerNum, upperNum), //meshesForStep[i][2] = m,
          addSuperImpose: (i, m) => fillAtStart(i, 6, m, lowerNum, upperNum), //meshesForStep[i][2] = m,
        },
        upper: {
          addTeeth: (i, m) => (meshesForStep[i][3] = m),
          addGums: (i, m) => (meshesForStep[i][4] = m),
          addAttachments: (i, m) => (meshesForStep[i][5] = m),
          addSuperImpose: (i, m) => (meshesForStep[i][7] = m),
        },
      };
    }

    return {
      lower: {
        addTeeth: (i, m) => (meshesForStep[i][0] = m),
        addGums: (i, m) => (meshesForStep[i][1] = m),
        addAttachments: (i, m) => (meshesForStep[i][2] = m),
        addSuperImpose: (i, m) => (meshesForStep[i][6] = m),
      },
      upper: {
        // when invoke fillAtStart function , should add =>  i,(3, 4, 5) , m ,upperNum, lowerNum
        addTeeth: (i, m) => fillAtStart(i, 3, m, upperNum, lowerNum), //meshesForStep[i][3] = m,
        addGums: (i, m) => fillAtStart(i, 4, m, upperNum, lowerNum), //meshesForStep[i][4] = m,
        addAttachments: (i, m) => fillAtStart(i, 5, m, upperNum, lowerNum), //meshesForStep[i][5] = m,
        addSuperImpose: (i, m) => fillAtStart(i, 7, m, upperNum, lowerNum), //meshesForStep[i][5] = m,
      },
    };
  }
};

export { createScene, meshesForStep };
