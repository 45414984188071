import React, { Component } from "react";
import { connect } from "react-redux";

import CustomeTeeth from "../../../../Teeth/CustomeTeeth";
import { Button, Row, Col } from "reactstrap";
import { updateAdvancedModules } from "../../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../../redux/customeTeeth/action"
import ToggleSwitch from "../../../../../components/Switch/Switch";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Extraction extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_extraction: this.props.caseSubmission.advanced_modules.extraction
        .use_extraction,
    };
  }
  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "extraction",
      data: {
        use_extraction: this.state.use_extraction,
        teeth: this.props.customeTeethReducer.Case_Extraction,
      },
    });
    this.props.save();
  };



  slider = (e, key) => {
    if (e.target.value) {
      if (e.target.value === "0") {
        this.setState({
          [key]: false
        })
      } else if (e.target.value === "50") {
        this.setState({
          [key]: "eonToDecide"
        })
      } else if (e.target.value === "100") {
        this.setState({
          [key]: true
        })
      }
    }
  };

  sliderValue = (key) => {
    if (this.state[key] === true) {
      return 100;
    } else if (this.state[key] === "eonToDecide") {
      return 50;
    } else {
      return 0;
    }
  };
  render() {
    const { use_extraction } = this.state;

    return (
      <div>
        {/* 
        <div>
          <div className="control-label title_active-gco text-center mb-2">
            Used Extraction for this case                    </div>
          <div className="toggle-slider form-group content form-block-holder custome-toggle m-auto mt-2 ">
            <input
              type="range"
              step="50"
              onChange={(e) => {
                this.slider(e, "use_extraction")

                if (e?.target?.value === "0" || e?.target?.value === "50") {
                  this.props.Clear_Teeth_Data("Case_Extraction")

                }
              }}
              value={this.sliderValue("use_extraction")}
              style={{ pointerEvents: this.props.summary ? "none" : "" }}
            />
            <Row className="align-items-center ">
              <Col xs="4" md="4" lg="4" className="text-left">
                <label className="title_active">No</label>
              </Col>
              <Col xs="4" md="4" lg="4" className="text-center">
                <label className="title_active">EON to Decide</label>
              </Col>
              <Col xs="4" md="4" lg="4" className="text-right">
                <label className="title_active">Yes</label>
              </Col>
            </Row>
          </div>

        </div>
 */}

        <div className="auto_grid4 pb-2">
          <div className="control-label title_active-gco">
            Used Extraction for this case{" "}
          </div>
          <div>
            <ToggleSwitch
              id="use-attachments"
              disabled={this.props.summary}

              checked={use_extraction}
              onChange={() => {
                console.log(!use_extraction);
                this.setState({ use_extraction: !use_extraction });
                if (use_extraction) {
                  this.props.Clear_Teeth_Data("Case_Extraction")
                }
              }}
            />
          </div>
        </div>
        <div className="teeth_selector">
        <div className="control-label title_active-gco text-center pb-2 mt-2  ">
            Extract
              </div>
          <CustomeTeeth action_key="Case_Extraction" disabled={!use_extraction || use_extraction === "eonToDecide"} summary={this.props.summary} />
        </div>
        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => {
              this.UpdateAdvancedModules();
            }}
          >
            Save
        </Button>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Extraction);
