import { handleResponse, authHeader } from "../jwt/_helpers";

/*--------------------------------------------------------------------------------*/
/* credentials                                                       */
/*--------------------------------------------------------------------------------*/
const credentials = {
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  grant_type: process.env.REACT_APP_GRANT_TYPE,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  scope: process.env.REACT_APP_SCOPE,
  bridge_url: process.env.REACT_APP_BRIDGE_URL,
  viewer_url: process.env.REACT_APP_VIEWER_URL,
};

/*--------------------------------------------------------------------------------*/
/* configrations for api                                                       */
/*--------------------------------------------------------------------------------*/
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("token")
      ? `Bearer ${localStorage.getItem("token")}`
      : "",
  },
};
/*--------------------------------------------------------------------------------*/
/* getAuthrizationCode                                                     */
/*--------------------------------------------------------------------------------*/
export const getAuthrizationCode = async () => {
  window.location = `${credentials.BASE_URL}/oauth/authorize?client_id=${credentials.client_id}&scope=${credentials.scope}&redirect_uri=${credentials.redirect_uri}&response_type=code`;
};
/*--------------------------------------------------------------------------------*/
/* getAccessToken                                                      */
/*--------------------------------------------------------------------------------*/
export const getAccessToken = async (code) => {
  try {
    const configuration = {
      client_id: credentials.client_id,
      client_secret: credentials.client_secret,
      redirect_uri: credentials.redirect_uri,
      grant_type: credentials.grant_type,
      code: code,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(configuration),
    };
    return fetch(
      `${credentials.BASE_URL}/oauth/token`,
      requestOptions
    ).then((response) => response.json());
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* getDoctors                                                       */
/*--------------------------------------------------------------------------------*/

export const getDoctors = () => {
  try {
    const requestOptions = { method: "GET", headers: authHeader() };
    return fetch(
      `${credentials.BASE_URL}/doctors`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* getDoctor                                                      */
/*--------------------------------------------------------------------------------*/

export const getDoctorInfo = (doctor_Id) => {
  try {
    const requestOptions = { method: "GET", headers: authHeader() };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* getDoctor                                                      */
/*--------------------------------------------------------------------------------*/
export const UpdateDoctorInfo = (doctor_Id, data) => {
  try {
    const requestOptions = {
      method: "PUT",
      headers: authHeader(),
      body: data,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* createAddress                                                      */
/*--------------------------------------------------------------------------------*/
export const createAddress = async (doctor_Id, data) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: data,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/addresses`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* getDoctorAddress                                                      */
/*--------------------------------------------------------------------------------*/

export const getDoctorAddresses = async (doctor_Id) => {
  try {
    const requestOptions = { method: "GET", headers: authHeader() };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/addresses`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* getAddressDetail                                                      */
/*--------------------------------------------------------------------------------*/

export const getAddressDetails = async (doctor_Id, address_Id) => {
  try {
    const requestOptions = { method: "GET", headers: authHeader() };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/addresses/${address_Id}`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* Update Address                                                    */
/*--------------------------------------------------------------------------------*/

export const updateAddress = async (doctor_Id, address_Id, data) => {
  try {
    const requestOptions = { method: "PUT", headers: authHeader(), body: data };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/addresses/${address_Id}`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* Delete Address                                                    */
/*--------------------------------------------------------------------------------*/

export const deleteAddress = async (doctor_Id, address_Id) => {
  try {
    const requestOptions = { method: "delete", headers: authHeader() };
    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/addresses/${address_Id}`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* getAlignerCases                                                       */
/*--------------------------------------------------------------------------------*/

export const getAlignerCases = async (doctor_Id) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: config.headers,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* getFilteredAlignerCases                                                       */
/*--------------------------------------------------------------------------------*/
export const getFilteredAlignerCases = async (doctor_Id, data) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: data,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/list`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* getAlignerCase                                                       */
/*--------------------------------------------------------------------------------*/
export const getAlignerCase = async (doctor_Id, case_Id) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: config.headers,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* create Aligner Case                                                       */
/*--------------------------------------------------------------------------------*/
export const createAlignerCase = async (doctor_Id, data) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: data,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* update Aligner Case                                                       */
/*--------------------------------------------------------------------------------*/
export const updateAlignerCase = async (doctor_Id, case_Id, data) => {
  try {
    const requestOptions = {
      method: "PUT",
      headers: config.headers,
      body: data,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
export const updatePhotosXraysAPI= async (doctor_Id, case_Id, data) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: data,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/xrays_and_patient_photos`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
}
/*--------------------------------------------------------------------------------*/
/* Request Retainer                                                       */
/*--------------------------------------------------------------------------------*/
export const requestRetainer = async (doctor_Id, case_Id, data) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: data,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/retainers`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Get Retainers                                                       */
/*--------------------------------------------------------------------------------*/
export const getRetainers = async (doctor_Id, case_Id) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: config.headers,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/retainers`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* Download Treatment PDF                                                       */
/*--------------------------------------------------------------------------------*/
export const downloadTreatmentPDF = async (doctor_Id, case_Id) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: config.headers,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/summary.pdf`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* mark Case As Complete                                                     */
/*--------------------------------------------------------------------------------*/
export const markCaseAsComplete = async (doctor_Id, case_Id) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: "",
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/mark_as_complete`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Pause Aligner Case                                                     */
/*--------------------------------------------------------------------------------*/
export const pauseAlignerCase = async (doctor_Id, case_Id) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: "",
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/pause`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Resume Aligner Case                                                       */
/*--------------------------------------------------------------------------------*/
export const resumeAlignerCase = async (doctor_Id, case_Id) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: "",
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/resume`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* Submit Replacement                                                     */
/*--------------------------------------------------------------------------------*/
export const submitReplacement = async (doctor_Id, case_Id, data) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: data,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/replacements`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* Get Replacement                                                     */
/*--------------------------------------------------------------------------------*/
export const getReplacements = async (doctor_Id, case_Id) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: config.headers,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/replacements`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Get comment                                                    */
/*--------------------------------------------------------------------------------*/
export const getComments = async (doctor_Id, case_Id) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: config.headers,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/comments`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Post comment                                                    */
/*--------------------------------------------------------------------------------*/
export const postComment = async (doctor_Id, case_Id, data) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: data,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/comments`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Submit Adjustment                                                   */
/*--------------------------------------------------------------------------------*/
export const submitAdjustment = async (doctor_Id, case_uid, data) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: data,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_uid}/adjustments`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Revoke access token                                                   */
/*--------------------------------------------------------------------------------*/
export const Revokeaccesstoken = async (token) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: JSON.stringify({
        token: token,
      }),
    };

    return fetch(
      `${credentials.BASE_URL}/oauth/revoke`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Get Tretment SetUp                                                   */
/*--------------------------------------------------------------------------------*/
export const getTretmentSetup = async (doctor_Id, case_Id) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: config.headers,
    };
    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/treatment_setups`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Get Tretment SetUp by id                                               */
/*--------------------------------------------------------------------------------*/
export const getTretmentSetupById = async (
  doctor_Id,
  case_Id,
  treatment_setup_id
) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: config.headers,
    };
    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/treatment_setups/${treatment_setup_id}`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Get Notifications                                                              */
/*--------------------------------------------------------------------------------*/
export const getNotifications = async (doctor_Id) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: config.headers,
    };
    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/action_items`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* mark all Notifications as read                                                    */
/*--------------------------------------------------------------------------------*/
export const markNotificationsAsRead = async (doctor_Id) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: "",
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/action_items/mark_all_as_read`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Approve Treatment Setup                                                    */
/*--------------------------------------------------------------------------------*/
export const approveTreatmentSetup = async (doctor_Id, case_Id, setup_Id) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/treatment_setups/${setup_Id}/approve`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Disapprove Treatment Setup                                                     */
/*--------------------------------------------------------------------------------*/
export const disApproveTreatmentSetup = async (
  doctor_Id,
  case_Id,
  setup_Id,
  data
) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: data,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/treatment_setups/${setup_Id}/disapprove`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Fix Adjustment Rejections                                                      */
/*--------------------------------------------------------------------------------*/
export const fixAdjustmentRejections = async (
  doctor_Id,
  case_Id,
  adjustment_Id,
  data
) => {
  try {
    const requestOptions = {
      method: "PUT",
      headers: config.headers,
      body: data,
    };

    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/adjustments/${adjustment_Id}`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Get getViewerId                                                              */
/*--------------------------------------------------------------------------------*/
export const getViewerId = async (uid, tsId, upper, lower) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    return fetch(
      `${credentials.bridge_url}/peep/validateTreatmentSetup/${uid}/${tsId}/${upper}/${lower}?public=true`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Get getViewerId                                                              */
/*--------------------------------------------------------------------------------*/
export const generateURL = (viewer_id) => {
  return `${credentials.viewer_url}?viewerId=${viewer_id}`;
};

/*--------------------------------------------------------------------------------*/
/* Get Doctor Preferences                                                              */
/*--------------------------------------------------------------------------------*/
export const getDoctorPreferences = async (doctor_Id) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: config.headers,
    };
    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/doctor_preferences`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* create Doctor Preferences                                            */
/*--------------------------------------------------------------------------------*/
export const createDoctorPreferences = async (doctor_Id, data) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: data,
    };
    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/doctor_preferences`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* archive Case                                          */
/*--------------------------------------------------------------------------------*/
export const archiveCase = async (doctor_Id, case_Id) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: "",
    };
    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/archive`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* unArchive Case                                         */
/*--------------------------------------------------------------------------------*/
export const unArchiveCase = async (doctor_Id, case_Id) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: "",
    };
    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/unarchive`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};

/*--------------------------------------------------------------------------------*/
/* Get Cases Numbers By Status                                    */
/*--------------------------------------------------------------------------------*/
export const getCasesNumbersByStatus = async (doctor_Id, status) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: config.headers,
    };
    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/statistics?case_status=${status}`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};
/*--------------------------------------------------------------------------------*/
/* Summary                                        */
/*--------------------------------------------------------------------------------*/
export const SummaryStep = async (doctor_Id, case_Id) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: config.headers,
      body: "",
    };
    return fetch(
      `${credentials.BASE_URL}/doctors/${doctor_Id}/aligner_cases/${case_Id}/summary_and_terms`,
      requestOptions
    ).then((response) => handleResponse(response));
  } catch (e) {
    console.error(e);
  }
};