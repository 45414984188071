import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Input, Row, Col } from "reactstrap";
import CustomeTeeth from "../../../Teeth/CustomeTeeth";
import ToggleSwitch from "../../../../components/Switch/Switch";
import { mapTeethToApi } from "../../../../services/mapTeethToApi";
import { updateAdvancedModules } from "../../../../redux/CaseSubmission/action";
import { Clear_Teeth_Data } from "../../../../redux/customeTeeth/action"
import TreatmentOptions from "../../../../components/TreatmentOptions/TreatmentOptions";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updateAdvancedModules: (payload) => dispatch(updateAdvancedModules(payload)),
  Clear_Teeth_Data: (payload) => dispatch(Clear_Teeth_Data(payload)),

});
class Attachments extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      use_attachments: this.props.caseSubmission.advanced_modules.attachments
        .useAttachments,
      attachments_timing_3: false,
      attachments_timing_7: false,
      attachmentsTiming: this.props.caseSubmission.advanced_modules.attachments
        .attachmentsTiming,
    };
  }

  UpdateAdvancedModules = () => {
    this.props.updateAdvancedModules({
      key: "attachments",
      data: {
        useAttachments: this.state.use_attachments,
        attachmentsTiming: this.state.attachmentsTiming,
        attachmentsRestriction:
          this.props.customeTeethReducer.Case_Attachments

      },
    });
    this.props.save();
  };

  /* --------------------------------------------------------------------------------*/
  /* handel Treatment Options Changes */
  /* --------------------------------------------------------------------------------*/
  handelTreatmentOptionsChanges = (value, key) => {
    this.setState({
      [key]: value
    })
  }
  render() {
    const {
      use_attachments,

      attachmentsTiming,
    } = this.state;

    return (
      <div>
        <div className="form-group content form-block-holder grid_container ">

          <div className="text-center">
            <div className="control-label title_active-gco text-center mb-2">
              Use attachments for this case{" "}
            </div>
            <TreatmentOptions value={use_attachments} name="use_attachments" summary={this.props.summary} onChange={(value) => {
              this.handelTreatmentOptionsChanges(value, "use_attachments")
              if (value !== true) {
                this.props.Clear_Teeth_Data("Case_Attachments")
                this.setState({ attachmentsTiming: "" })
              }
            }} />
          </div>

          <div className="grid_container">
            {" "}
            <div className="auto_grid_timing pb-2">
              <div className="control-label title_active-gco align-self-end">
                Attachments timing{" "}
              </div>
              <div className="auto_grid2">
                {/* <Button
                  className={`rounded-circle ${attachmentsTiming === 3 ? "num-btn-active" : "num-btn"
                    }`}
                  onClick={() => {
                    this.setState({
                      attachmentsTiming: 3,
                    });
                  }}
                  disabled={!use_attachments || use_attachments === "eonToDecide"}
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}

                >
                  3
                </Button>
                <Button
                  className={`rounded-circle ${attachmentsTiming === 7 ? "num-btn-active" : "num-btn"
                    }`}
                  onClick={() => {
                    this.setState({
                      attachmentsTiming: 7,
                    });
                  }}
                  disabled={!use_attachments || use_attachments === "eonToDecide"}
                  style={{ pointerEvents: this.props.summary ? "none" : "" }}

                >
                  7
                </Button> */}
                {/* <div className="control-label title_active-gco custom-label">
                  Custom
                </div> */}
                <div className="form-material">
                  <Input
                    type="number"
                    placeholder=""
                    className="custom-number"
                    // value={
                    //   attachmentsTiming === 3 || attachmentsTiming === 7
                    //     ? ""
                    //     : attachmentsTiming
                    // }
                    onClick = {e => {
                      e.target.focus();
                      }}
                      onMouseUp = {e => {
                        e.target.blur();
                      }}
                      value={
                        attachmentsTiming
                      }
                      min="1"                 
                      onChange={(e) => {
                        this.setState({
                          attachmentsTiming: e.target.value,
                        });
                      }}
                    onChange={(e) => {
                      this.setState({
                        attachmentsTiming: e.target.value,
                      });
                    }}
                    

                    disabled={!use_attachments || use_attachments === "eonToDecide" || this.props.summary}

                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="control-label title_active-gco pb-2 text-center mt-2 ">
                Restrictions
              </div>
              <div className="teeth_selector">
                <CustomeTeeth
                  action_key="Case_Attachments"
                  disabled={!use_attachments || use_attachments === "eonToDecide"}
                  summary={this.props.summary}
                />
              </div>
            </div>
          </div>
        </div>

        {
          !this.props.summary && <Button
            className={`btn mt-2 ${"preference-btn"}  float-right text-center  btn-radius`}
            size="sm"
            type="button"
            onClick={() => this.UpdateAdvancedModules()}
          >
            Save
        </Button>
        }
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Attachments);
