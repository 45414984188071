import React, { Component, Fragment } from "react";
import { Text, StyleSheet, View, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    paddingTop: 10,
    // alignItems: "center",
    paddingBottom: 10,
  },

  header: {
    fontSize: 12,
    textAlign: "left",
    color: "#00c7b1",
    width: "100%",
  },
  container: {
    flexDirection: "row",
    // borderBottomWidth: 1,
    // borderColor: "gray",
    paddingBottom: 20,
    paddingTop: 10,
  },
  container2: {
    flexDirection: "row",
  },
  field: {
    flexDirection: "row",
    paddingTop: 10,
  },
  col: {
    flexDirection: "column",
    width: "50%",
  },
  col_4: {
    flexDirection: "column",
    width: "40%",
  },
  col_5: {
    flexDirection: "column",
    width: "50%",
  },
  col_1: {
    flexDirection: "column",
    width: "10%",
  },
  title: {
    fontSize: 10,
    textAlign: "left",
    color: "#00c7b1",
    padding: "10px 0 5px 0",
  },
  title2: {
    fontSize: 10,
    textAlign: "left",
    color: "#00c7b1",
  },
  info: {
    fontSize: 10,
    textAlign: "left",
  },
  image: {
    // backgroundColor: "red",
    width: "90px",
    height: "85px",
    borderRadius: 4,
  },

  green_btn_not_active: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    borderColor: "#d7f5f2",
    borderWidth: 1,
    backgroundColor: "#d7f5f2",
    borderRadius: 8,
    fontSize: 8,
    padding: 2,
    margin: 3,
    flexDirection: "column",
    width: "30%",
  },
  col_title: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    fontSize: 8,
    padding: "0 5px 0 0",
    // margin: 1,
    flexDirection: "column",
    width: "10%",
  },
  green_btn_active: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00c7b1",
    borderRadius: 8,
    fontSize: 8,
    padding: 2,
    margin: 3,
    flexDirection: "column",
    width: "30%",
  },
  emplty_col: {
    width: "30%",
  },
  text_active: {
    fontSize: 8,
    color: "white",
    padding: 1,
  },
  text_not_active: {
    fontSize: 8,
    color: "#00c7b1",
    padding: 1,
  },
  teeth_border: {
    borderWidth: 1,
    borderColor: "#00c7b1",
  },
  text_area: {
    flexGrow: 1,
    borderColor: "#a0f0e7",
    borderWidth: 1,
    borderRadius: 2,
    fontSize: 8,
    padding: 2,
    margin: 3,
    minHeight: "50px",
    flexDirection: "column",
  },
  photo_header: {
    flexDirection: "row",
    padding: 3,
    fontSize: 7,
    color: "#00c7b1",
  },
  value: {
    padding: "10px",
  },
  photo: {
    flexDirection: "column",
    width: "90px",
    height: "85px",
    margin: 2,
  },
  space: {
    width: "10%",
  },
});
export default class PhotosXrays extends Component {
  render() {
    const { photos_xrays } = this.props.mappedData;
    return (
      <Fragment>
        <View style={styles.row}>
          <Text style={styles.header}>{`Photos & Xrays :`}</Text>
          <View style={styles.field}>
            <View style={styles.photo}>
              <Text style={styles.photo_header}>Front Smiling</Text>

              <Image
                style={styles.image}
                source={{
                  uri: photos_xrays.front_smiling,
                  headers: {
                    Pragma: "no-cache",
                    "Cache-Control": "no-cache",
                  },
                }}
              />
            </View>
            <View style={styles.photo}>
              <Text style={styles.photo_header}>Front Pose</Text>

              <Image
                style={styles.image}
                source={{
                  uri: photos_xrays.front_pose,
                  headers: {
                    Pragma: "no-cache",
                    "Cache-Control": "no-cache",
                  },
                }}
              />
            </View>
            <View style={styles.photo}>
              <Text style={styles.photo_header}>Profile</Text>

              <Image
                style={styles.image}
                source={{
                  uri: photos_xrays.profile,
                  headers: {
                    Pragma: "no-cache",
                    "Cache-Control": "no-cache",
                  },
                }}
              />
            </View>

            <View style={styles.space}></View>

            <View style={styles.photo}>
              <Text style={styles.photo_header}>Panoramic Xray</Text>

              <Image
                style={styles.image}
                source={{
                  uri: photos_xrays.panaromic,
                  headers: {
                    Pragma: "no-cache",
                    "Cache-Control": "no-cache",
                  },
                }}
              />
            </View>
          </View>

          <View style={styles.field}>
            <View style={styles.photo}>
              <Text style={styles.photo_header}>Upper Occlusal</Text>

              <Image
                style={styles.image}
                source={{
                  uri: photos_xrays.upper_occlusal,
                  headers: {
                    Pragma: "no-cache",
                    "Cache-Control": "no-cache",
                  },
                }}
              />
            </View>
            <View style={styles.photo}>
              <Text style={styles.photo_header}>Frontal</Text>

              <Image
                style={styles.image}
                source={{
                  uri: photos_xrays.frontal,
                  headers: {
                    Pragma: "no-cache",
                    "Cache-Control": "no-cache",
                  },
                }}
              />
            </View>
            <View style={styles.photo}>
              <Text style={styles.photo_header}>Lower Occlusal</Text>

              <Image
                style={styles.image}
                source={{
                  uri: photos_xrays.lower_occlusal,
                  headers: {
                    Pragma: "no-cache",
                    "Cache-Control": "no-cache",
                  },
                }}
              />
            </View>

            <View style={styles.space}></View>

            <View style={styles.photo}>
              <Text style={styles.photo_header}>Cephalometric Xray</Text>

              <Image
                style={styles.image}
                source={{
                  uri: photos_xrays.cephalometric,
                  headers: {
                    Pragma: "no-cache",
                    "Cache-Control": "no-cache",
                  },
                }}
              />
            </View>
          </View>

          <View style={styles.field}>
            <View style={styles.photo}>
              <Text style={styles.photo_header}>Right Buccal</Text>

              <Image
                style={styles.image}
                source={{
                  uri: photos_xrays.right_buccal,
                  headers: {
                    Pragma: "no-cache",
                    "Cache-Control": "no-cache",
                  },
                }}
              />
            </View>
            <View style={styles.photo}></View>

            <View style={styles.photo}>
              <Text style={styles.photo_header}>Left Buccal</Text>

              <Image
                style={styles.image}
                source={{
                  uri: photos_xrays.left_buccal,
                  headers: {
                    Pragma: "no-cache",
                    "Cache-Control": "no-cache",
                  },
                }}
              />
            </View>
            <View style={styles.space}></View>

            <View style={styles.photo}></View>
          </View>
        </View>
      </Fragment>
    );
  }
}
