import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { updateAddress, createAddress, deleteAddress } from "../../api/api";
import ReactTable from "react-table";
import { countries } from "../../helpers/countries";
import Select from "react-select";
import { successToaster } from "../../services/toast";
import { Formik, Field, ErrorMessage } from "formik";
import { address_schema } from "../../helpers/addressValidations";
export default class Addresses extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      createUpdateAddressModal: false,
      deleteModal: false,
      obj: {},
      line1: "",
      line2: "",
      zip: "",
      phone: "",
      state: "",
      country: "",
      city: "",
      id: "",
    };
  }
  /* --------------------------------------------------------------------------------*/
  /*  toggle create and update address modal */
  /* --------------------------------------------------------------------------------*/
  toggleCreateUpdateAddressModal = () => {
    this.setState({
      createUpdateAddressModal: !this.state.createUpdateAddressModal,
      line1: "",
      line2: "",
      zip: "",
      phone: "",
      state: "",
      country: "",
      city: "",
      id: "",
    });
  };
  /* --------------------------------------------------------------------------------*/
  /*  toggle delete modal */
  /* --------------------------------------------------------------------------------*/
  toggleDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };
  /* --------------------------------------------------------------------------------*/
  /*  Create and  Update Address */
  /* --------------------------------------------------------------------------------*/
  handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const data = {
      address: {
        country: values.country.value,
        city: values.city,
        state: values.state,
        line1: values.line1,
        line2: values.line2,
        zip: values.zip,
        phone: values.phone,
      },
    };
    if (this.state.id) {
      //update address
      updateAddress(this.props.id, this.state.id, JSON.stringify(data)).then(
        (res) => {
          this.props.getDoctorAddresses();
          this.setState({
            createUpdateAddressModal: !this.state.createUpdateAddressModal,
            country: "",
            city: "",
            state: "",
            line1: "",
            line2: "",
            zip: "",
            phone: "",
          });
          setSubmitting(false);

          successToaster("Updated address successfully ");
        }
      );
    } else {
      // add new address
      createAddress(this.props.id, JSON.stringify(data)).then((res) => {
        this.props.getDoctorAddresses();
        this.setState({
          createUpdateAddressModal: !this.state.createUpdateAddressModal,
          country: "",
          city: "",
          state: "",
          line1: "",
          line2: "",
          zip: "",
          phone: "",
        });
        setSubmitting(false);
        successToaster("Created new address successfully");
      });
    }
  };
  /* --------------------------------------------------------------------------------*/
  /*  Delete Address */
  /* --------------------------------------------------------------------------------*/
  DeleteAddress = () => {
    deleteAddress(this.props.id, this.state.obj.id).then((res) => {
      this.props.getDoctorAddresses();
      this.setState({
        deleteModal: !this.state.deleteModal,
      });
      successToaster("deleted address successfully");
    });
  };
  /* --------------------------------------------------------------------------------*/
  render() {
    /* --------------------------------------------------------------------------------*/
    /*  data needed for table  */
    /* --------------------------------------------------------------------------------*/
    const data = this.props.addresses.map((prop, key) => {
      return {
        id: prop.id,
        city: prop.city,
        country: this.props.GetCountryLabel(prop.country),
        line1: prop.line1,
        line2: prop.line2,
        state: prop.state,
        zip: prop.zip,
        phone: prop.phone,
        actions: (
          // we've added some custom button actions
          <div className="text-center">
            <style>
              {`
                .fa-trash-alt{
                  color:red
                }
                `}
            </style>
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                let obj = data.find((o) => o.id === prop.id);
                this.setState({
                  createUpdateAddressModal: !this.state
                    .createUpdateAddressModal,
                  obj: obj,
                  id: prop.id,
                  line1: obj.line1,
                  line2: obj.line2,
                  zip: obj.zip,
                  phone: obj.phone,
                  state: obj.state,
                  country: this.props.GetCountryValue(obj.country),
                  city: obj.city,
                });
              }}
              // className="m-1"
              color="white"
              size="md"
              round="true"
              icon="true"
            >
              <i className="far fa-edit" style={{ color: "blue" }} />
            </Button>
            <Button
              onClick={() => {
                let obj = data.find((o) => o.id === prop.id);
                this.setState({
                  deleteModal: !this.state.deleteModal,
                  obj: obj,
                });
              }}
              // className="m-1"
              color="white"
              size="md"
              round="true"
              icon="true"
            >
              <i className="fas fa-trash-alt " />
            </Button>
          </div>
        ),
      };
    });

    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/* Create and Update Adress modal */}
        {/*--------------------------------------------------------------------------------*/}
        <Modal
          isOpen={this.state.createUpdateAddressModal}
          toggle={this.toggleCreateUpdateAddressModal}
        >
          <ModalHeader toggle={this.toggleCreateUpdateAddressModal}>
            {this.state.obj.country ? `Update Address` : `New Address`}
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                id: "",
                country: this.state.country,
                city: this.state.city,
                line1: this.state.line1,
                line2: this.state.line2,
                zip: this.state.zip,
                phone: this.state.phone,
                state: this.state.state,
              }}
              validationSchema={address_schema}
              onSubmit={this.handleSubmit}
            >
              {({
                touched,
                errors,
                isSubmitting,
                values,
                handleSubmit,
                handleBlur,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Input type="hidden" name="id" id="id" />
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="country">
                          Country <span style={{ color: "red" }}>*</span>
                        </Label>

                        <Select
                          defaultValue={{
                            label: this.state.obj.country,
                            value: this.state.country,
                          }}
                          onBlur={handleBlur}
                          onChange={(country) => {
                            setFieldValue("country", country);
                          }}
                          options={countries}
                        />
                        {errors.country && touched.country ? (
                          <div
                            style={{
                              width: "100%",
                              marginTop: ".25rem",
                              fontSize: "80%",
                              color: "#dc3545",
                            }}
                          >
                            {errors.country}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="city">
                          City
                          <span style={{ color: "red" }}>*</span>{" "}
                        </Label>
                        <Field
                          type="text"
                          name="city"
                          id="city"
                          onBlur={handleBlur}
                          className={`form-control ${touched.city && errors.city ? "is-invalid" : ""
                            }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="city"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="line1">Line 1</Label>
                        <Field
                          type="text"
                          name="line1"
                          id="line1"
                          className={`form-control ${touched.line1 && errors.line1 ? "is-invalid" : ""
                            }`}
                          onBlur={handleBlur}
                          value={values.line1}
                        />
                        <ErrorMessage
                          component="div"
                          name="line1"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="line2">Line 2</Label>
                        <Field
                          type="text"
                          name="line2"
                          id="line2"
                          className={`form-control ${touched.line2 && errors.line2 ? "is-invalid" : ""
                            }`}
                          onBlur={handleBlur}
                          value={values.line2}
                        />
                        <ErrorMessage
                          component="div"
                          name="line2"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="state">State</Label>
                        <Field
                          type="text"
                          name="state"
                          id="state"
                          className={`form-control ${touched.state && errors.state ? "is-invalid" : ""
                            }`}
                          onBlur={handleBlur}
                          value={values.state}
                        />
                        <ErrorMessage
                          component="div"
                          name="state"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="zip">Zip</Label>
                        <Field
                          type="text"
                          name="zip"
                          id="zip"
                          className={`form-control ${touched.zip && errors.zip ? "is-invalid" : ""
                            }`}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          component="div"
                          name="zip"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="phone">Phone</Label>
                        <Field
                          type="text"
                          name="phone"
                          id="phone"
                          className={`form-control ${touched.phone && errors.phone ? "is-invalid" : ""
                            }`}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          component="div"
                          name="phone"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Button
                      className="btn red-btn pull-right m-1 "
                      size="md"
                      color="danger"
                      onClick={this.toggleCreateUpdateAddressModal}
                    >
                      Cancel
                    </Button>{" "}
                    <Button
                      className="btn green-btn pull-right m-1"
                      size="md"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {this.state.obj.country ? `Update` : `Add`}
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Create and Update Adress modal */}
        {/*--------------------------------------------------------------------------------*/}

        {/*--------------------------------------------------------------------------------*/}
        {/* Delete Address Modal /}
        {/*--------------------------------------------------------------------------------*/}
        <Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal}>
          <ModalHeader toggle={this.toggleDeleteModal}>
            Delete Address
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <h5>Are you sure you want to delete this address?</h5>
            </FormGroup>
            <FormGroup>
              <Button
                className="btn red-btn pull-right m-1 "
                size="md"
                color="danger"
                onClick={this.toggleDeleteModal}
              >
                Cancel
              </Button>
              <Button
                className="btn green-btn pull-right m-1"
                size="md"
                type="submit"
                onClick={this.DeleteAddress}
              >
                Delete
              </Button>
            </FormGroup>
          </ModalBody>
        </Modal>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Delete Address Modal /}
        {/*--------------------------------------------------------------------------------*/}

        <Row className="pb-3">
          <Col>
            {" "}
            <div className="text-right">
              <Button
                onClick={() => {
                  this.setState({
                    createUpdateAddressModal: !this.state
                      .createUpdateAddressModal,
                    obj: {},
                  });
                }}
                className="btn green-btn pull-right"
                size="md"
              // color="inverse"
              // size="md"
              // icon="true"
              >
                New Address
                {/* <i className="fa fa-plus" /> */}
              </Button>
            </div>
          </Col>
        </Row>

        {/*--------------------------------------------------------------------------------*/}
        {/* Fixed header-footer table*/}
        {/*--------------------------------------------------------------------------------*/}
        <Row>
          <Col>
            <ReactTable
              data={data}
              columns={[
                {
                  Header: "Country",
                  accessor: "country",
                  width: 130,
                },
                {
                  Header: "City",
                  accessor: "city",
                  width: 130,
                },
                {
                  Header: "State",
                  accessor: "state",
                  width: 130,
                },
                {
                  Header: "Zip",
                  accessor: "zip",
                  width: 100,
                },
                {
                  Header: "Phone",
                  accessor: "phone",
                  width: 130,
                },
                {
                  Header: "Action",
                  accessor: "actions",
                  width: 130,
                  sortable: false,
                },
              ]}
              defaultPageSize={10}
              style={{
                height: "400px", // This will force the table body to overflow and scroll, since there is not enough room
              }}
              className=" -highlight"
            />
          </Col>
        </Row>
        {/*--------------------------------------------------------------------------------*/}
        {/* End fixed header-footer table*/}
        {/*--------------------------------------------------------------------------------*/}
      </div>
    );
  }
}
