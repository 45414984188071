/*--------------------------------------------------------------------------------*/
/* Fill Patient Info                                                                  */
/*--------------------------------------------------------------------------------*/
export function FillPatientInfo() {
  const id = Math.floor(Math.random() * 100 + 99);

  return {
    email: `test${id}@test${id}.com`,
    firstName: `test${id}`,
    middleName: `test`,
    lastName: `test`,

    day: 18,
    month: 6,
    year: 1993,
  };
}
/*--------------------------------------------------------------------------------*/
/* Fill Clinical Findings                                                                  */
/*--------------------------------------------------------------------------------*/
export function FillClinicalFindings() {
  return {
    chief_complaint: "test",
    skeletal_1: true,
    skeletal_2: false,
    skeletal_3: false,
    skeletal: "I",
    canine_r_1: true,
    canine_r_2: false,
    canine_r_3: false,
    canine_l_1: true,
    canine_l_2: false,
    canine_l_3: false,
    molar_r_1: true,
    molar_r_2: false,
    molar_r_3: false,
    molar_l_1: true,
    molar_l_2: false,
    molar_l_3: false,
    lower_midline_center: true,
    lower_midline_shifted_left: false,
    lower_midline_shifted_right: false,
    lower_midline: "centered",
    upper_midline_center: true,
    upper_midline_shifted_left: false,
    upper_midline_shifted_right: false,
    upper_midline: "centered",
  };
}
/*--------------------------------------------------------------------------------*/
/* Fill Treatment Goal                                                                 */
/*--------------------------------------------------------------------------------*/
export function FillTreatmentGoal() {
  return {
    treat_arches_upper: true,
    treat_arches_lower: false,
    treat_arches_both: false,
    treat_arches: "upper_only",
    midline_upper_maintain: true,
    midline_upper_improve: false,
    midline_upper_center: false,
    upper_midline: "maintain",
    midline_lower_maintain: true,
    midline_lower_improve: false,
    midline_lower_center: false,
    lower_midline: "maintain",
    overjet_maintain: true,
    overjet_improve: false,
    overjet_Ideal: false,
    overjet: "maintain",
    overbite_maintain: true,
    overbite_improve: false,
    overbite_Ideal: false,
    overbite: "maintain",
    arch_form_maintain: true,
    arch_form_improve: false,
    arch_form_constrict: false,
    arch_form: "maintain",
  };
}
/*--------------------------------------------------------------------------------*/
/* Fill Photos                                                                 */
/*--------------------------------------------------------------------------------*/
export function FillPhotos() {
  return {
    //front smillind state
    front_smiling_photo: `https://eon-access-sandbox.s3.eu-central-1.amazonaws.com/public/case-15245/patient-photos/front_smiling_photo/1597055189808`,
    front_smiling: `https://eon-access-sandbox.s3.eu-central-1.amazonaws.com/public/case-15245/patient-photos/front_smiling_photo/1597055189808`,
    front_smiling_image: false,
    //front pose state
    front_pose_photo: `https://eon-access-sandbox.s3.eu-central-1.amazonaws.com/public/case-15245/patient-photos/front_smiling_photo/1597055269612`,
    front_pose: `https://eon-access-sandbox.s3.eu-central-1.amazonaws.com/public/case-15245/patient-photos/front_smiling_photo/1597055269612`,
    front_pose_image: false,
    //profile state
    profile_photo: `https://eon-access-sandbox.s3.eu-central-1.amazonaws.com/public/case-15245/patient-photos/front_smiling_photo/1597055340698`,

    profile: `https://eon-access-sandbox.s3.eu-central-1.amazonaws.com/public/case-15245/patient-photos/front_smiling_photo/1597055340698`,

    profile_image: false,
    //upper occlusal state
    upper_occlusal_photo:
      "https://sandbox.access.eonaligner.com/assets/upper_occlusal-0a36811d5ff03557d250aef5992d608e3e86e608a9139baa6f5a3d75ea423b1a.png",

    upper_occlusal:
      "https://sandbox.access.eonaligner.com/assets/upper_occlusal-0a36811d5ff03557d250aef5992d608e3e86e608a9139baa6f5a3d75ea423b1a.png",

    upper_occlusal_image: false,
    //lower occlusal state
    lower_occlusal_photo:
      "https://sandbox.access.eonaligner.com/assets/lower_occlusal-62eab7310407931bc084dd262b0984d979c39d3bad35eaf32b473574b0e27193.png",

    lower_occlusal:
      "https://sandbox.access.eonaligner.com/assets/lower_occlusal-62eab7310407931bc084dd262b0984d979c39d3bad35eaf32b473574b0e27193.png",

    lower_occlusal_image: false,
    //left buccal state
    left_buccal_photo:
      "https://sandbox.access.eonaligner.com/assets/left_buccal-3f38360956e9f6ea1e8cd8201c7be711988bc6dcb7eab22ca239b87c41dba4a7.png",
    left_buccal:
      "https://sandbox.access.eonaligner.com/assets/left_buccal-3f38360956e9f6ea1e8cd8201c7be711988bc6dcb7eab22ca239b87c41dba4a7.png",

    left_buccal_image: false,
    //frontal state
    frontal_photo:
      "https://sandbox.access.eonaligner.com/assets/frontal-96de170d4720e7d5630d14a55839356c2279d54ad69a9ad00026bdf9df10cdb7.png",

    frontal:
      "https://sandbox.access.eonaligner.com/assets/frontal-96de170d4720e7d5630d14a55839356c2279d54ad69a9ad00026bdf9df10cdb7.png",

    frontal_image: false,
    //right buccal state
    right_buccal_photo:
      "https://sandbox.access.eonaligner.com/assets/right_buccal-00932b39e06fc508f1356955bce568a3576b7481f4becd77fe67491bcb664b81.png",

    right_buccal:
      "https://sandbox.access.eonaligner.com/assets/right_buccal-00932b39e06fc508f1356955bce568a3576b7481f4becd77fe67491bcb664b81.png",

    right_buccal_image: false,
  };
}

export function FillXrays() {
  return {
    // panaromic state
    panaromic_photo: `https://sandbox.access.eonaligner.com/assets/panoramic-xray-f36c05dc286e42b86873b33354d3af20c41b8aeb2ddde6e26755187a619f3174.jpg`,
    panaromic: `https://sandbox.access.eonaligner.com/assets/panoramic-xray-f36c05dc286e42b86873b33354d3af20c41b8aeb2ddde6e26755187a619f3174.jpg`,
    panaromic_image: false,
    // cephalometric state
    cephalometric_photo: `https://sandbox.access.eonaligner.com/assets/cephalometric-xray-e9fb803c4d1929288c953447c89cc6e59b120d155dfab2dea936bb02dc91641b.jpg`,
    cephalometric: `https://sandbox.access.eonaligner.com/assets/cephalometric-xray-e9fb803c4d1929288c953447c89cc6e59b120d155dfab2dea936bb02dc91641b.jpg`,
    cephalometric_image: false,
  };
}
