import React, { Component } from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";

class LoyaltyProgram extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      loyalty: props.loyalty,
    };
  }
  /* --------------------------------------------------------------------------------*/
  /* get current tier color  */
  /* --------------------------------------------------------------------------------*/
  GetColor() {
    switch (this.state.loyalty.current_tier.name) {
      case "Blue":
        return "#56C0E0";
      case "Silver":
        return "#C0C0C0";
      case "Gold":
        return "#FFDF00";
      case "Platinum":
        return "#e5e4e2";
      default:
        return "";
    }
  }
  render() {
    return (
      <div className="">
        <style>{`
        .progress {
          width:100%;
          height:30px;
          border:1.5px solid silver;
          position:relative;
          color:black
      }
      .progress:after {
          content:"";
          position:absolute;
          background: ${this.GetColor()};
          top:0; bottom:0;
          left:0; 
          width:${this.state.loyalty.loyalty_tier_percentage}%;
          border:1px solid ${this.GetColor()};
          border-radius:${
            this.state.loyalty.loyalty_tier_percentage !== 100
              ? "0 50px 50px 0 "
              : ""
          };
      }
      .top{
        position: absolute;
        top: 0;
        right: 0;
        padding:5px;
       z-index:99
      }
        `}</style>

        <div className="text-center loyalty">Your eon Award Points </div>
        <div className="p-3 ">
          <Row>
            <Col className="p-0">
              <div className="progress">
                <span className="top">{`${this.state.loyalty.remaining_cases_for_next_tier} case left to upgrade`}</span>
              </div>
            </Col>
          </Row>
          <Row className="align-items-end p-0">
            <Col
              className="p-0 text-center"
              style={{ borderRight: "1.5px solid silver" }}
            >
              <div>
                <span
                  className="dot"
                  style={{
                    color: "#56C0E0",
                    fontSize: "20px",
                    lineHeight: "20px",
                  }}
                >
                  ●
                </span>
                <span
                  className="label p-1"
                  style={{
                    color: "#56C0E0",
                  }}
                >
                  Blue
                </span>
              </div>
            </Col>
            <Col
              className="p-0 text-center "
              style={{ borderRight: "1.5px solid silver" }}
            >
              <div>
                <span
                  className="dot"
                  style={{
                    color: "#C0C0C0",
                    fontSize: "20px",
                    lineHeight: "20px",
                  }}
                >
                  ●
                </span>
                <span
                  className="label p-1"
                  style={{
                    color: "#C0C0C0",
                  }}
                >
                  Silver
                </span>
              </div>
            </Col>
            <Col
              className="p-0 text-center"
              style={{ borderRight: "1.5px solid silver" }}
            >
              <div>
                <span
                  className="dot"
                  style={{
                    color: "#FFDF00",
                    fontSize: "20px",
                    lineHeight: "20px",
                  }}
                >
                  ●
                </span>
                <span
                  className="label p-1"
                  style={{
                    color: "#FFDF00",
                  }}
                >
                  Gold
                </span>
              </div>
            </Col>
            <Col
              className="p-0 text-center "
              style={{ borderRight: "1.5px solid silver" }}
            >
              <div>
                <span
                  className="dot"
                  style={{
                    color: "#e5e4e2",
                    fontSize: "20px",
                    lineHeight: "20px",
                  }}
                >
                  ●
                </span>
                <span
                  className="label p-1"
                  style={{
                    color: "#e5e4e2",
                  }}
                >
                  Platinum
                </span>
              </div>
            </Col>
          </Row>
          <Row className="p-3 text-center">
            <Col>{`You require ${
              this.state.loyalty.required_cases_to_maintain_tier
            } cases by  ${moment(
              this.state.loyalty.loyalty_period_end_date
            ).format("D MMMM YYYY")} to maintain ${
              this.state.loyalty.current_tier.name
            } status for next year`}</Col>
          </Row>{" "}
        </div>
      </div>
    );
  }
}

export default LoyaltyProgram;
