import React, { Component } from "react";
import {
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  Card,
  CardImg,
  Input,
} from "reactstrap";
import pdf from "../../assets/images/caseSubmission/pdf.svg";
import submit_a_case from "../../assets/images/caseSubmission/submit-a-case.svg";
import { connect } from "react-redux";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "../newPDF/MyDocument";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
class Submit extends Component {
  /* --------------------------------------------------------------------------------*/
  /* constructor */
  /* --------------------------------------------------------------------------------*/
  constructor(props) {
    super(props);
    /* init state */
    this.state = {
      id: props.userReducer.currentUser.id,


    };
  }

  render() {

    return (
      <div>
        <CardTitle className="border-bottom pb-3 title_active-gco case-title submit-page-title-grid">
          <div>Your case has been submitted</div>
          <div className="text-right">    <span className="close-case-submission" onClick={() => {
            window.location.href = "/cases"
          }}>
            &times;              </span></div>
        </CardTitle>
        <CardBody className="treatment_type_grid">  <PDFDownloadLink
          document={
            <MyDocument
              case={this.props.case}
              mappedData={this.props.mappedData}
              doctorInfo={this.props.doctorInfo}
            />
          }
          fileName={`treatment form_${this.props.case.uid}.pdf`}
        >{({ blob, url, loading, error }) => (

          <Card
            className={`p-3 custom_shadow ${"impression_card"} align-items-center`}
            onClick={() => { }}
            id="treatment_type"
          >
            {/* {!loading && this.setState({ percentage: 100 }) && clearInterval(this.state.intervalID)} */}
            {
              loading ?
                <div className="submit-loader">
                  <div className="loader"></div>
                </div> : <img
                  src={pdf}
                  className="treatment-type-img"
                  alt="Submission PDF"
                />
            }


            <CardTitle className="pt-2 m-0 treatment-type-title">
              {loading ? "Loading document..." : "Submission PDF"}
            </CardTitle>


           </Card>)}
        </PDFDownloadLink>{" "}
          <Card
            className={`p-3 custom_shadow ${"impression_card"} align-items-center`}
           
            onClick={() => {
              window.location.href = "/case-submission";
            }}
            id="treatment_type"
          >
            <img
              src={submit_a_case}
              className="treatment-type-img"
              alt="Submission PDF"
            />
            <CardTitle className="pt-2 m-0 treatment-type-title">
              Submit Another Case{" "}
            </CardTitle>
          </Card>
        </CardBody>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Submit);
