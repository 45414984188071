import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CardBody,
  CardTitle,
  Card,
  CardFooter,
  CardImg,
  Button,
} from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { schema } from "../../helpers/photosXraysValidations";
import { Photos, Xrays, PhotosXraysData } from "../../helpers/photos-xrays";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { uploadFile, keyToUrl } from "../../helpers/s3";
import Resizer from "react-image-file-resizer";
import { updateAlignerCase, updatePhotosXraysAPI } from "../../api/api";
import { mapTeethToApi } from "../../services/mapTeethToApi";
import { infoToaster } from "../../services/toast";
import { updatePhotosXrays } from "../../redux/CaseSubmission/action";
import { mapAdvancedToApi } from "../../services/mapAdvancedToApi";
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  updatePhotosXrays: (payload) => dispatch(updatePhotosXrays(payload)),

});
class PhotosXrays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading_percentage: 0,
      intervalID: 0,
      showProgress: false,
      loading_percentage_front_smiling: 0,
      loading_percentage_front_pose: 0,
      loading_percentage_profile: 0,
      loading_percentage_panoramic_xray: 0,
      loading_percentage_upper_occlusal: 0,
      loading_percentage_frontal: 0,
      loading_percentage_lower_occlusal: 0,
      loading_percentage_cephalometric_xray: 0,
      loading_percentage_right_buccal: 0,
      loading_percentage_left_buccal: 0,
      caseId:
        this.props.caseId ||
        (window.location.search.match(/id=([^&]+)/) || [])[1],
    };
  }

  /*--------------------------------------------------------------------------------*/
  /* uploading file timer                                             */
  /*--------------------------------------------------------------------------------*/
  timer = (id) => {
    this.setState({
      [`loading_percentage_${id}`]: this.state[`loading_percentage_${id}`] + 5,
    });
  };

  checkIfChanged = (oldarray, newArray) => {
    for (var x = 0; x < oldarray.length; x++) {
      if (oldarray[x].url !== newArray[x].url) {
        return true
      }
    }
  }
  checkIfChanged2 = (oldobj, newobj) => {
    if (oldobj.panoramic_url === newobj.panoramic_url && oldobj.cephalometric_url === newobj.cephalometric_url) {
      return false
    } else {
      return true
    }
  }
  /*--------------------------------------------------------------------------------*/
  /*Photos and xrays step submit  */
  /*--------------------------------------------------------------------------------*/
  handleSubmit = (values, { setSubmitting }) => {
    const { doctorId } = this.props;
    const caseId = this.props.caseId ||
      (window.location.search.match(/id=([^&]+)/) || [])[1]

    let images = [
      { name: "front_smiling", url: values.front_smiling },
      { name: "front_pose", url: values.front_pose },
      { name: "profile", url: values.profile },
      { name: "upper_occlusal", url: values.upper_occlusal },
      { name: "lower_occlusal", url: values.lower_occlusal },
      { name: "left_buccal", url: values.left_buccal },
      { name: "frontal", url: values.frontal },
      { name: "right_buccal", url: values.right_buccal },
    ];
    let oldimages = [
      { name: "front_smiling", url: this.props.caseSubmission.photos_xrays.front_smiling },
      { name: "front_pose", url: this.props.caseSubmission.photos_xrays.front_pose },
      { name: "profile", url: this.props.caseSubmission.photos_xrays.profile },
      { name: "upper_occlusal", url: this.props.caseSubmission.photos_xrays.upper_occlusal },
      { name: "lower_occlusal", url: this.props.caseSubmission.photos_xrays.lower_occlusal },
      { name: "left_buccal", url: this.props.caseSubmission.photos_xrays.left_buccal },
      { name: "frontal", url: this.props.caseSubmission.photos_xrays.frontal },
      { name: "right_buccal", url: this.props.caseSubmission.photos_xrays.right_buccal },
    ];
    let photos_xrays = {
      front_smiling: values.front_smiling || "",
      front_pose: values.front_pose || "",
      profile: values.profile || "",
      upper_occlusal: values.upper_occlusal || "",
      lower_occlusal: values.lower_occlusal || "",
      left_buccal: values.left_buccal || "",
      frontal: values.frontal || "",
      right_buccal: values.right_buccal || "",
      panaromic: values.panoramic_xray || "",
      cephalometric: values.cephalometric_xray || "",
    }
    let oldxrays = {
      panoramic_url: this.props.caseSubmission.photos_xrays.panaromic,
      cephalometric_url: this.props.caseSubmission.photos_xrays.cephalometric,
    }

    let xrays = {
      panoramic_url: values.panoramic_xray,
      cephalometric_url: values.cephalometric_xray,
    };
    let checkimage = this.checkIfChanged(oldimages, images);
    let checkxrays = this.checkIfChanged2(oldxrays, xrays);
    if (checkimage || checkxrays) {
      setSubmitting(true);
      let filtered_image = images.filter((key, index) => {
        return key.url.match(/http([^&]+)/);
      });


      let filtered_xrays = {};

      for (var key2 in xrays) {
        if (xrays[key2].match(/http([^&]+)/)) {
          filtered_xrays[key2] = xrays[key2];
        }
      }

      let photos_data = {
        aligner_case: {
          // step: "photos",
          photos: filtered_image,
          xrays: filtered_xrays,
          advanced_modules: {
            ...this.props.caseSubmission.advanced_modules,
            ipr: {
              ...this.props.caseSubmission.advanced_modules.ipr,
              iprRestriction: mapTeethToApi(this.props.customeTeethReducer.Case_IPR)
            },
            archExpansion: {
              ...this.props.caseSubmission.advanced_modules.archExpansion,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Arch_Expansion
              )
            },
            attachments: {
              ...this.props.caseSubmission.advanced_modules.attachments,
              attachmentsRestriction: mapTeethToApi(
                this.props.customeTeethReducer.Case_Attachments
              )
            },
            elastics: {
              ...this.props.caseSubmission.advanced_modules.elastics,
              teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics),
              buttons_teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics_Buttons),
              cuts_teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Elastics_Cuts),
            },
            extraction: {
              ...this.props.caseSubmission.advanced_modules.extraction,
              teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Extraction),
            },
            overCorrection: {
              ...this.props.caseSubmission.advanced_modules.overCorrection,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Overcorrections
              ),
            },
            pontics: {
              ...this.props.caseSubmission.advanced_modules.pontics,
              teeth: mapTeethToApi(this.props.customeTeethReducer.Case_Pontics),
            },
            torqueEnhancers: {
              ...this.props.caseSubmission.advanced_modules.torqueEnhancers,
              teeth: mapTeethToApi(
                this.props.customeTeethReducer.Case_Torque_Enhancers
              ),
            },
            malocclusion: {
              ...this.props.caseSubmission.advanced_modules.malocclusion,
              crossbite: {
                ...this.props.caseSubmission.advanced_modules.malocclusion
                  .crossbite,
                teeth: mapTeethToApi(
                  this.props.customeTeethReducer.Case_Crossbites
                ),
              },
              crowding: {
                ...this.props.caseSubmission.advanced_modules.malocclusion
                  .crowding,
                teeth: mapTeethToApi(
                  this.props.customeTeethReducer.Case_Crowding
                ),
              },
              classII: {
                ...this.props.caseSubmission.advanced_modules.malocclusion
                  .classII,
                teeth: mapTeethToApi(
                  this.props.customeTeethReducer.Case_ClassII
                ),
              },
              classIII: {
                ...this.props.caseSubmission.advanced_modules.malocclusion
                  .classIII,
                teeth: mapTeethToApi(
                  this.props.customeTeethReducer.Case_ClassIII
                ),
              },
            },
            all_modules: mapAdvancedToApi(this.props.advancedModules.modules),
          },
        },
      };


      let xrays_data = {
        aligner_case: {
          step: "xrays",
          xrays: filtered_xrays,
          advanced_modules: {
            ...this.props.caseSubmission.advanced_modules,
            all_modules: this.props.advancedModules.modules,
          },
        },

      };

      // this.props.NextStep();

      updatePhotosXraysAPI(doctorId, caseId, JSON.stringify(photos_data)).then((res) => {
        setSubmitting(false);
        this.props.updatePhotosXrays(photos_xrays)
        this.props.NextStep();
      }).catch((error) => {
        setSubmitting(false);
      });
    } else {
      infoToaster("nothing is changed .......")
      this.props.NextStep();
    }

    // updateAlignerCase(doctorId, caseId, JSON.stringify(photos_data))
    //   .then((res) => { })
    //   .then(() => {
    //     updateAlignerCase(doctorId, caseId, JSON.stringify(xrays_data)).then(
    //       (res) => {
    //         setSubmitting(false);
    //         this.props.NextStep();
    //       }
    //     );
    //   })
    //   .catch((error) => {
    //     setSubmitting(false);
    //   });
  };

  /* --------------------------------------------------------------------------------*/
  /* Upload image to s3  */
  /* --------------------------------------------------------------------------------*/
  uploadImage = async (image, id, file) => {
    return uploadFile({
      name: `case-${this.state.caseId}/patient-photos/${id}/${new Date().valueOf()}.JPEG`,
      contentType: image.type,
      file: image,
    });
  };

  /* --------------------------------------------------------------------------------*/
  /* upload photos */
  /* --------------------------------------------------------------------------------*/

  UploadPhoto = async (e, id, setFieldValue) => {
    e.persist();
    if (e.target.files[0]) {
      const intervalID = setInterval(this.timer(id), 1000);
      this.setState({
        loading1: true,
        intervalID,
        [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
      });
      let reader = new FileReader();
      reader.addEventListener("load", (evt) => {
        this.setState({
          id: evt.currentTarget.result,
        });
      });
      reader.readAsDataURL(e.target.files[0]);
      Resizer.imageFileResizer(
        e.target.files[0],
        720,
        1080,
        "JPEG",
        100,
        0,
        async (uri) => {
          const { key } = await this.uploadImage(uri, id, e.target.files[0]);
          clearInterval(this.state.intervalID);
          this.setState({
            [id]: keyToUrl(key),
            [`loading_percentage_${id}`]: 100,
          });
          setFieldValue(id, keyToUrl(key));
          setTimeout(() => {
            this.setState({
              [`showProgress_${id}`]: !this.state[`showProgress_${id}`],
              [`loading_percentage_${id}`]: 0,
            });
          }, 500);
        },
        "blob"
      );
    }
  };


  render() {
    const { photos_xrays } = this.props.caseSubmission;
    return (
      <div>
        <CardTitle className="border-bottom pb-3 title_active-gco case-title">
          <div className="step-header-title">
            <span>{`Photos & X-rays`}</span>
            <span className="patient_info">
              <div>{this.props.patient?.full_name}</div>
              <div> Case {this.state?.caseId}</div>

            </span>
          </div>
        </CardTitle>

        <Formik
          enableReinitialize
          initialValues={{
            front_smiling: photos_xrays.front_smiling,
            front_pose: photos_xrays.front_pose,
            profile: photos_xrays.profile,
            upper_occlusal: photos_xrays.upper_occlusal,
            lower_occlusal: photos_xrays.lower_occlusal,
            left_buccal: photos_xrays.left_buccal,
            frontal: photos_xrays.frontal,
            right_buccal: photos_xrays.right_buccal,
            panoramic_xray: photos_xrays.panaromic,
            cephalometric_xray: photos_xrays.cephalometric,
          }}
          validationSchema={!this.props.userReducer.currentUser.gco_doctor ? schema : ""}
          onSubmit={this.handleSubmit
          }
        >

          {({
            touched,
            errors,
            isSubmitting,
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
          }) => (
            <Form className="form-material" onSubmit={handleSubmit}>
              <div className="step-progress-new-case upload-container">
                {isSubmitting && (
                  <div className={`loading2`}>
                    <div className="spinner_2"></div>
                  </div>
                )}
              </div>
              <CardBody id="case-submission">
                <div className="main-photos-grid ">
                  {PhotosXraysData.map((value, index) => (

                    <div key={index}>
                      {value.key && (
                        <>
                          <Card className="p-2">
                            <CardTitle className="border-bottom pb-2 title_active-gco">
                              {values[value.key] ? value.title : this.state[`showProgress_${value.key}`] ? `Uploading ${value.title}` : <span className="not-active-img">{value.title} </span>}


                              {
                                value.key === "cephalometric_xray" && <div className="optional-input">*optional</div>
                              }  </CardTitle>

                            <div className="image-overlay-container">
                              <label name={value.key} htmlFor={value.key} className=" photos-xrays-img">
                                <CardImg
                                  src={
                                    values[value.key]
                                      ? values[value.key]
                                      : value.img
                                  }
                                  className={`${(value.key === "front_smiling" || value.key === "front_pose" || value.key === "profile") && !values[value.key] ? "gray-scale" : ""} cursor-pointer`}
                                />
                              </label>
                              <input
                                type="file"
                                id={value.key}
                                onChange={(e) => {
                                  this.UploadPhoto(e, value.key, setFieldValue);
                                }}
                                multiple={false}
                                className="visually-hidden"
                                accept="image/x-png,image/gif,image/jpeg"
                              />
                              {this.state[`showProgress_${value.key}`] && (
                                <div className="overlay-loader">
                                  <CircularProgressbar
                                    className="circule-progress-bar"

                                    value={
                                      this.state[
                                      `loading_percentage_${value.key}`
                                      ]
                                    }
                                    text={`${this.state[
                                      `loading_percentage_${value.key}`
                                    ]
                                      }%`}
                                  />
                                </div>
                              )}
                            </div>
                          </Card>
                          <ErrorMessage
                            component="div"
                            name={value.key}
                            className="custom-invalid-feedback m-1"
                          />
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </CardBody>
              <CardFooter className="p-3">
                <Button
                  className={`btn  ${"preference-btn"} float-left text-center mb-2 btn-radius`}
                  size="sm"
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => this.props.PrevStep()}
                >
                  <i className="fas fa-angle-left arrows-icon pr-1"></i> Patient Information
                </Button>{" "}
                <Button
                  className={`btn  ${"preference-btn"} float-right text-center mb-2  btn-radius`}
                  size="sm"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Impressions <i className="fas fa-angle-right arrows-icon pl-1"></i>
                </Button>{" "}
              </CardFooter>{" "}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PhotosXrays);
