import React from "react";
import styles from "../eonAccess-style/eonAccess.module.scss";
export default function GenerateLabels(props) {
  const {
    meshesForStep,
    setCurrentStep,
    loading,
    isDarkMode,
    isTsPrepared,
  } = props;
  return meshesForStep
    ? meshesForStep.map((label, index) => {
        return (
          <div
            key={`${index}`}
            className={styles.label}
            onClick={() => {
              if (loading || isTsPrepared) return;
              setCurrentStep(index);
            }}
            style={isDarkMode ? { color: "white" } : {}}
          >
            {index}
          </div>
        );
      })
    : null;
}
