import React, { Component } from "react";
import MilkywayTemplate from "../../../TS/Templates/milkyway-template";
import EonAccessTemplate from "../../../TS/Templates/eonAccess-template"
import EonAccessCopyTemplate from "../../../TS/Templates/eonAccess-template-copy"
import { setRejectionReson } from "../../../redux/Tooth_IPR_Options/action";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setRejectionReson: (payload) => dispatch(setRejectionReson(payload)),


});

class TSViewer extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.state = {
      collapse: false,
      details: props.details,
    };
  }
  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  // UNSAFE_componentWillMount() {
  //   window.dispatchEvent(new Event("print_report"));
  //   window.addEventListener("print_report", () => {
  //     return {
  //       name: "test",
  //     };
  //   });

  //   // Create the event.
  //   // const event = document.createEvent("Event");
  //   // // Define that the event name is 'build'.
  //   // event.initEvent("build", true, true);
  //   // window.dispatchEvent(event);
  // }

  updateSize = () => {
    let vh = window.innerHeight - 60;
    let vh2 = window.innerHeight - 2;
    if (window.innerWidth < 840) {
      document
        .getElementById("container")
        .style.setProperty("height", `${vh}px`);
    } else {
      document
        .getElementById("container")
        .style.setProperty("height", `${vh2}px`);
    }
  };
  componentDidMount = () => {
    window.addEventListener("resize", this.updateSize());
    // let iframe = document.getElementById("test");
    // window.onmessage = (e) => {
    //   if (e.data) {
    //     iframe.contentWindow.postMessage(this.props.details, "*");
    // iframe.contentWindow.postMessage("eon", "*");
    // }
    // };
    // setTimeout(() => {
    //   iframe.contentWindow.postMessage("iframe", "*");

    // }, 3000);
    // window.top.postMessage("I am Iframe", "*");
    // window.onmessage = (event) => {
    //   if (event.data === "GOT_YOU_IFRAME") {
    //     console.log("Parent received successfully.");
    //   }
    // };
  };
  componentDidUpdate = () => {
    window.addEventListener("resize", this.updateSize());
    // console.log(
    //   document.getElementById("container").clientHeight - 60,
    //   "countainer.....222",
    //   window.innerHeight - 60
    // );
  };
  saveRejectionReason = (data) => {
    this.props.setRejectionReson(data);
    this.props.saveRejectionReason(data)
  }
  finished = (data) => {
    if (data) {
      this.props.finishLoadingTs()
    }
  }
  render() {
    const { details, doctorId, caseId, case_data, showBiteRecord } = this.props
    return (
      <div id="container">
        <EonAccessTemplate
          caseId={caseId}
          tsId={details.id}
          doctorId={doctorId}
          collapse={this.props.collapse}
          photos={case_data.media.patient_photos}
          showBiteRecord={showBiteRecord}
          saveRejectionReason={this.saveRejectionReason}
          finished={this.finished}
        />
        {/* <iframe
          id="test"
          title="ts-viewer"
          src="https://dev.dqs2i8p0nvca9.amplifyapp.com"
          style={{ width: "100%", height: "100%", border: "none" }}
        ></iframe> */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TSViewer);
