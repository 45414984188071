import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  resolvedData,
  howManyTsFiles,
  resolvePromise,
  generateStepsForMeshes,
} from "../../TS-Helper";
import loader from "../../TS-VIEWER/loader";
import { createScene, meshesForStep } from "../../TS-VIEWER/Scene";
import viewer from "../../TS-VIEWER/viewer";
import styles from "./milkyway-style/milkyway.module.scss";
import {
  updateMeshesAccordingToStepNumber,
  indicatorTracking,
} from "./milkyway-functionality";
import ProgressBar from "./milkyway-components/progress-bar";
import ViewerNotFound from "./milkyway-components/ts-not-found";
import DarkMode from "./milkyway-components/dark-mode";
import GenerateSteps from "./milkyway-components/generate-steps";
import GenerateLabels from "./milkyway-components/generate-labels";
import PlayPauseAnimate from "./milkyway-components/animate-steps";
import Header from "./milkyway-components/header";
import SideBar from "./milkyway-components/side-bar";
import ModalSettings from "./milkyway-components/modal-settings";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Helmet } from "react-helmet";

function MilkywayTemplate(props) {
  const renderCanvas = useRef(null);
  const currentWidthStepsRef = useRef(null);

  const [tsOptions, setTSOptions] = useState({
    //default options
    STEPS_PAUSE: 500,
    showUpperArc: true,
    showLowerArc: true,
    showUpper: true, // for maxilla
    showLower: true, // for mandible
    loading: true,
    isAttachmentsFromParam: false, // check if this template toggled the attachments from the params url
    isAttachment: true, // for toggle attachments
    isDarkMode: false,
    isTSViewerFound: true,
    isAnimateStart: false,
    isSuperImpose: false,
    hasSuperImpose: true,
    isLoaded: false,
    isTsPrepared: false,
  });

  const [stepsOptions, setStepsOptions] = useState({});

  const [progress, setProgress] = useState(0);
  const [countProgress, setCountProgress] = useState(1);
  const [currentStep, setCurrentStep] = useState(0);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight);
  const [indicatorWidth, setIndicatorWidth] = useState(
    indicatorTracking(currentStep, meshesForStep, currentWidthStepsRef)
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  let drawerWidth = window.innerWidth < 840 ? "auto" : isDrawerOpen ? 250 : 100;

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  let preparingTSViewer = useCallback(async () => {
    try {
      let resolver = await resolvePromise();
      let { data } = await resolvedData(resolver("manifest.json"));
      const lowerSteps = data["lowerStepsNumber"];
      const upperSteps = data["upperStepsNumber"];
      const startTogether = data["startTogether"];
      let passiveAligners = data["passiveAligners"];
      let upperOvercorrectionStepsNumber =
        data["upperOvercorrectionStepsNumber"];
      let lowerOvercorrectionStepsNumber =
        data["lowerOvercorrectionStepsNumber"];
      if (lowerSteps < upperSteps) {
        // override lowerOvercorrectionStepsNumber
        lowerOvercorrectionStepsNumber = passiveAligners
          ? 0
          : data["lowerOvercorrectionStepsNumber"];
      } else if (upperSteps < lowerSteps) {
        // override lowerOvercorrectionStepsNumber
        upperOvercorrectionStepsNumber = passiveAligners
          ? 0
          : data["upperOvercorrectionStepsNumber"];
      }

      setStepsOptions({
        lowerSteps,
        upperSteps,
        startTogether,
        passiveAligners,
        lowerOvercorrectionStepsNumber,
        upperOvercorrectionStepsNumber,
      });

      let howManyFiles = howManyTsFiles(data, true, tsOptions.hasSuperImpose);
      setCountProgress(howManyFiles);
      generateStepsForMeshes(lowerSteps, upperSteps);
      let scene = createScene(lowerSteps, upperSteps, startTogether);
      await loader(
        resolver,
        data,
        scene,
        setProgress,
        setCountProgress,
        setTSOptions,
        tsOptions.isAttachmentsFromParam,
        tsOptions.hasSuperImpose
      );

      updateMeshesAccordingToStepNumber(currentStep, tsOptions);
      setTSOptions((prevTSOptions) => ({
        ...prevTSOptions,
        isLoaded: true,
      }));
    } catch (err) {
      //  Treatment plan not found
      setTSOptions((prevTSOptions) => ({
        ...prevTSOptions,
        loading: false,
        isTSViewerFound: false,
        isTsPrepared: false,
        isLoaded: true,
      }));
      console.log(err, "Treatment plan not found");
    } finally {
      if (tsOptions.isTSViewerFound) {
        viewer.initializeAndRun(renderCanvas.current, {
          action: "milkyway-template",
        });
        setTSOptions((prevTSOptions) => ({
          ...prevTSOptions,
          isTsPrepared: false,
          loading: false,
        }));
      }
    }
  }, []);

  const handleResizeContainer = () => {
    setContainerHeight(window.innerHeight);
    setIsModalOpen(false);
    setIndicatorWidth(
      indicatorTracking(currentStep, meshesForStep, currentWidthStepsRef)
    );
    drawerWidth = window.innerWidth < 840 ? "auto" : isDrawerOpen ? 250 : 100;
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeContainer);
  }, []);
  useEffect(() => {
    if (!tsOptions.isLoaded) {
      preparingTSViewer();
    }
  }, [tsOptions.isLoaded]);

  useEffect(() => {
    setIndicatorWidth(
      indicatorTracking(currentStep, meshesForStep, currentWidthStepsRef)
    );
  }, [currentStep, isDrawerOpen, meshesForStep.length]);

  useEffect(() => {
    if (meshesForStep.length) {
      updateMeshesAccordingToStepNumber(currentStep, tsOptions);
    }
  }, [
    currentStep,
    tsOptions.showUpperArc,
    tsOptions.showLowerArc,
    tsOptions.showUpper,
    tsOptions.showLower,
    tsOptions.isAttachment,
    tsOptions.isSuperImpose,
    tsOptions.isAnimateStart,
  ]);

  return (
    <div
      className={styles.container}
      style={
        isDrawerOpen
          ? { height: containerHeight, gridTemplateColumns: "250px 1fr" }
          : { height: containerHeight }
      }
    >
      <Helmet>
        {/* disable zoom on mobile web page */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, 
     user-scalable=0"
        />
        <title>Plan Viewer</title>
      </Helmet>
      {/* <!-- first column --> */}
      <SideBar
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        tsOptions={tsOptions}
        setTSOptions={setTSOptions}
        width={drawerWidth}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        meshesForStep={meshesForStep}
      />

      {/* <!-- Second column --> */}

      <div className={styles.thewiewerwindow}>
        {/* <!-- Trigger/Open The Modal --> */}
        <div className={styles.header_dot_container}>
          <div
            className={styles.dot_container}
            onClick={() => {
              if (
                tsOptions.loading ||
                tsOptions.isTsPrepared ||
                !tsOptions.isTSViewerFound
              )
                return;
              onOpenModal();
            }}
          >
            <div
              className={styles.dot}
              style={tsOptions.isDarkMode ? { backgroundColor: "white" } : {}}
            ></div>
            <div
              className={styles.dot}
              style={tsOptions.isDarkMode ? { backgroundColor: "white" } : {}}
            ></div>
            <div
              className={styles.dot}
              style={tsOptions.isDarkMode ? { backgroundColor: "white" } : {}}
            ></div>
          </div>

          <Modal open={isModalOpen} onClose={onCloseModal} center>
            <ModalSettings
              tsOptions={tsOptions}
              setTSOptions={setTSOptions}
              renderCanvas={renderCanvas.current}
            />
          </Modal>
        </div>

        {/* <!-- Header steps --> */}
        <Header
          currentStep={currentStep}
          stepsOptions={stepsOptions}
          isDarkMode={tsOptions.isDarkMode}
        />

        {/* <!-- DarkMode toggle --> */}
        <DarkMode
          loading={tsOptions.loading}
          isTSViewerFound={tsOptions.isTSViewerFound}
          setTSOptions={setTSOptions}
          renderCanvas={renderCanvas.current}
          isDarkMode={tsOptions.isDarkMode}
          isTsPrepared={tsOptions.isTsPrepared}
        />

        <canvas className={styles.rendercanvas} ref={renderCanvas}></canvas>

        {/* Progress bar  */}
        <ProgressBar
          loading={tsOptions.loading}
          progress={progress}
          countProgress={countProgress}
          isTsPrepared={tsOptions.isTsPrepared}
        />
        {/* Not found viwer  */}
        <ViewerNotFound isTSViewerFound={tsOptions.isTSViewerFound} />

        {/* <!-- Play/Pause animate --> */}
        <PlayPauseAnimate
          tsOptions={tsOptions}
          setTSOptions={setTSOptions}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          meshesForStep={meshesForStep}
        />

        {/* <!-- Steps container --> */}
        <div
          className={styles.steps_container}
          style={
            tsOptions.loading ||
            tsOptions.isTsPrepared ||
            !tsOptions.isTSViewerFound
              ? { zIndex: "-1" }
              : { zIndex: "1" }
          }
        >
          <div className={styles.steps_content}>
            {/* <!-- Generate upper steps --> */}
            <GenerateSteps
              isTsPrepared={tsOptions.isTsPrepared}
              meshesForStep={meshesForStep}
              setCurrentStep={setCurrentStep}
              loading={tsOptions.loading}
              lowerSteps={stepsOptions.lowerSteps}
              upperSteps={stepsOptions.upperSteps}
              allSteps={stepsOptions.upperSteps} // all upper steps
              startTogether={stepsOptions.startTogether}
              passiveAligners={stepsOptions.passiveAligners}
              overCorrectionStepsNumber={
                stepsOptions.upperOvercorrectionStepsNumber // overcorrection for upper steps
              }
            />
          </div>

          <div className={styles.steps_content}>
            {/* <!-- Generate lower steps --> */}
            <GenerateSteps
              isTsPrepared={tsOptions.isTsPrepared}
              meshesForStep={meshesForStep}
              setCurrentStep={setCurrentStep}
              loading={tsOptions.loading}
              lowerSteps={stepsOptions.lowerSteps}
              upperSteps={stepsOptions.upperSteps}
              allSteps={stepsOptions.lowerSteps} // all lower steps
              startTogether={stepsOptions.startTogether}
              passiveAligners={stepsOptions.passiveAligners}
              overCorrectionStepsNumber={
                stepsOptions.lowerOvercorrectionStepsNumber // overcorrection for lower steps
              }
            />
          </div>
          <div className={styles.labels_content} ref={currentWidthStepsRef}>
            <div
              className={styles.tooltip_orange}
              style={
                indicatorWidth
                  ? { left: indicatorWidth }
                  : {
                      left: indicatorTracking(
                        currentStep,
                        meshesForStep,
                        currentWidthStepsRef
                      ),
                    }
              }
            ></div>
            <div
              className={styles.tooltip_white}
              style={
                indicatorWidth
                  ? { left: indicatorWidth + 3 }
                  : {
                      left:
                        indicatorTracking(
                          currentStep,
                          meshesForStep,
                          currentWidthStepsRef
                        ) + 3,
                    }
              }
            ></div>
            <GenerateLabels
              meshesForStep={meshesForStep}
              setCurrentStep={setCurrentStep}
              loading={tsOptions.loading}
              isDarkMode={tsOptions.isDarkMode}
              isTsPrepared={tsOptions.isTsPrepared}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MilkywayTemplate;
