import { submitAdjustment } from "../api/api";

export function newAdjustmetn(doctor_id, case_uid, newData) {
  let adjustmentInfo = newData.adjustmentInfo;
  let photos = newData.photos;
  let impressions = newData.impressions;
  let imgs = [
    { name: "front_smiling", url: photos.front_smiling },
    { name: "front_pose", url: photos.front_pose },
    { name: "profile", url: photos.profile },
    { name: "upper_occlusal", url: photos.upper_occlusal },
    { name: "lower_occlusal", url: photos.lower_occlusal },
    { name: "left_buccal", url: photos.left_buccal },
    { name: "frontal", url: photos.frontal },
    { name: "right_buccal", url: photos.right_buccal },
  ];
  let filteredImg = imgs.filter((value) => value.url !== "");
  let impressions2 = {
    upper_arch_url: impressions.upperImageValue,
    lower_arch_url: impressions.lowerImageValue,
  };
  let filtered_impressions = {};

  for (var key1 in impressions2) {
    if (impressions2[key1].match(/http([^&]+)/)) {
      filtered_impressions[key1] = impressions2[key1];
    }
  }
  let mappedData = {
    adjustment: {
      current_upper_step: adjustmentInfo.current_upper_step,
      current_lower_step: adjustmentInfo.current_lower_step,
      tracking_upper_step: adjustmentInfo.tracking_upper_step,
      tracking_lower_step: adjustmentInfo.tracking_lower_step,
      additional_instructions: adjustmentInfo.additional_instructions,
      reason: adjustmentInfo.reason,
      impressions_method: impressions.Impression_Type,
      impressions_third_party_note: impressions.additional_comments,
      ...(impressions.Impression_Type === "upload" && {
        impressions: filtered_impressions,
      }),

      ...(filteredImg.length && {
        photos: filteredImg,
      }),
    },
  };
  return submitAdjustment(doctor_id, case_uid, JSON.stringify(mappedData)).then(
    (res) => {
      return "ok";
    }
  );
}
