import upper from "../assets/images/imperssions/upper.png";
import lower from "../assets/images/imperssions/lower.png";

export const impressions = [
  {
    title: "Upload 3d Scan",
    value: "upload",
    img: "",
  },
  {
    title: "Other Digital Means",
    value: "third_party",
    img: "",
  },
  {
    title: "Impression Pickup",
    value: "courier",
    img: "",
  },
];

export const upload_impression_data = [
  {
    img: upper,
    title: "Upload Upper Arch",
    key: "upper_arch_url",
  },
  {
    img: lower,
    title: "Upload Lower Arch",
    key: "lower_arch_url",
  },
];
