import React, { useEffect, useState } from "react";
import styles from "../eonAccess-style/eonAccess.module.scss";
import viewer from "../../../TS-VIEWER/viewer";
export default function UpperNav(props) {
  const {
    tsOptions,
    setTSOptions,
  } = props;

  const [viewActiveTs, setViewActiveTs] = useState("front");

  const onTsViewChange = (action) => {
    switch (action.viewActiveTs) {
      case "left-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("left");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: true,
        }));
        viewer.leftView();
        break;

      case "right-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("right");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: true,
        }));
        viewer.rightView();
        break;
      case "front-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("front");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: true,
          showUpper: true,
          showLower: true,
        }));
        viewer.frontView();
        break;
      case "upper-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("upper");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: false,
          showUpper: false,
          showLower: true,
        }));
        viewer.upperView();
        break;
      case "lower-view":
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("lower");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: false,
          showUpperArc: true,
          showUpper: true,
          showLower: false,
        }));
        viewer.lowerView();
        break;
      default:
        if (!tsOptions.isTSViewerFound) return;
        setViewActiveTs("front");
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          showLowerArc: true,
          showUpperArc: true,
        }));
        viewer.frontView();
    }
  };

  return (
    <div className={styles.upperNav_container}>
    <div
    onClick={() => {
      if (tsOptions.loading || tsOptions.isTsPrepared) return;
      onTsViewChange({ viewActiveTs: "front-view" });
    }}>
      <div>
        <img
        src={`${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${ viewActiveTs === "front" ?  "front_active.svg" : "front.svg"}`}
        alt=""
        className={styles.upperNav_icon}
        />
      </div>
      </div>

    <div
    onClick={() => {
      if (tsOptions.loading || tsOptions.isTsPrepared) return;
      onTsViewChange({ viewActiveTs: "upper-view" });
    }}>
      <div>
      <img
      src={`${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${ viewActiveTs === "upper" ? "top_active.svg" : "top.svg"}`}
      alt=""
      className={styles.upperNav_icon}
      />
      </div>
      </div>

    <div
    onClick={() => {
      if (tsOptions.loading || tsOptions.isTsPrepared) return;
      onTsViewChange({ viewActiveTs: "lower-view" });
    }}>
      <div>
      <img
      src={`${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${ viewActiveTs === "lower" ? "bottom_active.svg": "bottom.svg"}`}
      alt=""
      className={styles.upperNav_icon}
      />
      </div>
      </div>

    <div
    onClick={() => {
      if (tsOptions.loading || tsOptions.isTsPrepared) return;
      onTsViewChange({ viewActiveTs: "left-view" });
    }}>
      <div>
      <img
      src={`${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${ viewActiveTs === "left" ? "left_active.svg": "left.svg"}`}
      alt=""
      className={styles.upperNav_icon}
    />
      </div>
      </div>

    <div
    onClick={() => {
      if (tsOptions.loading || tsOptions.isTsPrepared) return;
      onTsViewChange({ viewActiveTs: "right-view" });
    }}>
      <div>
      <img
      src={`${process.env.REACT_APP_IMAGE_URL}/eonAccess-imgs/${ viewActiveTs === "right" ? "right_active.svg": "right.svg"}`}
      alt=""
      className={styles.upperNav_icon}
    />
      </div>
      </div>
</div>
);
}