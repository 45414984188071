import { upper_teeth, lower_teeth } from "../redux/newCase/init_teeth";

export function mapTeethFDI(newData, teethMovement, advanced_modules) {
  let RightTeethUpper = mapRightTeeth(newData, "upper_r_", upper_teeth, "R", "upper_teeth", 18, false, "UR", teethMovement, advanced_modules);
  let RightTeethLower = mapRightTeeth(newData, "lower_r_", lower_teeth, "R", "lower_teeth", 48, false, "LR", teethMovement, advanced_modules);

  let LeftTeethUpper = mapLeftTeeth(newData, "upper_l_", upper_teeth, "L", "upper_teeth", 21, true, "UL", teethMovement, advanced_modules);
  let LeftTeethLower = mapLeftTeeth(newData, "lower_l_", lower_teeth, "L", "lower_teeth", 31, true, "LL", teethMovement, advanced_modules);

  let teeth = {
    upper_teeth: { ...RightTeethUpper, ...LeftTeethUpper },
    lower_teeth: { ...RightTeethLower, ...LeftTeethLower },
  };
  return teeth;
}

function mapRightTeeth(newData, key, teeth, side, position, counter, increment, position2, teethMovement, advanced_modules) {
  let num = 8;
  let count = counter
  let rightTeeth = {};
  for (var x = 1; x < 9; x++) {
    rightTeeth = {
      ...rightTeeth,

      [`${key}${num}`]:
        newData !== undefined && newData[position] !== {} && newData[position] !== undefined
          ? {
            ...teeth[`${key}${num}`],
            ...newData[position][`${key}${num}`],
            image:
              newData[position][`${key}${num}`].value
                ? teeth[`${key}${num}`].active
                : teeth[`${key}${num}`].not_active,
            value: newData[position][`${key}${num}`].value,
            updated: newData[position][`${key}${num}`].updated || false,
            movement: findMovement(newData[position][`${key}${num}`].fdi_key, teethMovement, `${key}${num}`, advanced_modules)

          }
          : {
            ...teeth[`${key}${num}`],
            updated: false,
            index: num,
            amount: "",
            before_step: "",
            divider: true,
            use_ipr: false,
            do_not_perform_ipr: false,
            fdi_key: increment ? count + 1 : count - 1,
            tooth_key: `${position2}_${num} / ${position2}_${num - 1}`,
            movement: findMovement(increment ? count + 1 : count - 1, teethMovement, `${key}${num}`, advanced_modules)


          },
    };
    num--;
  }
  return rightTeeth;
}

function mapLeftTeeth(newData, key, teeth, side, position, counter, increment, position2, teethMovement, advanced_modules) {
  let num = 1;
  let count = counter

  let leftTeeth = {};
  for (var x = 1; x < 9; x++) {
    leftTeeth = {
      ...leftTeeth,

      [`${key}${num}`]:
        newData !== undefined && newData[position] !== {} && newData[position] !== undefined
          ? {
            ...teeth[`${key}${num}`],
            ...newData[position][`${key}${num}`],
            image:
              newData[position][`${key}${num}`].value
                ? teeth[`${key}${num}`].active
                : teeth[`${key}${num}`].not_active,
            value: newData[position][`${key}${num}`].value,
            updated: newData[position][`${key}${num}`].updated || false,
            movement: findMovement(newData[position][`${key}${num}`].fdi_key, teethMovement, `${key}${num}`, advanced_modules)

          }
          : {
            ...teeth[`${key}${num}`],
            updated: false,
            ...x < 8 && {
              amount: "",
              before_step: "",
              divider: true,
              use_ipr: false,
              do_not_perform_ipr: false,
              tooth_key: `${position2}_${num} / ${position2}_${num + 1}`

            },
            fdi_key: increment ? count + 1 : count - 1,
            movement: findMovement(increment ? count + 1 : count - 1, teethMovement, `${key}${num}`, advanced_modules)


          },
    };
    num++;
  }
  return leftTeeth;
}


function findMovement(tooth, teethMovement, tooth_name, advanced_modules) {
  let filtered_teeth = teethMovement?.filter((value) => {
    return Number(value.ToothNumber) === tooth;
  }) || [];
  if (filtered_teeth.length > 0) {
    filtered_teeth[0]["updated"] = false;
    filtered_teeth[0]["ToothName"] = tooth_name;
    filtered_teeth[0]["use_extraction"] = advanced_modules?.extraction?.use_extraction || false;
    filtered_teeth[0]["useAttachments"] = advanced_modules?.attachments?.useAttachments || false;
    filtered_teeth[0]["attachments_step"] = advanced_modules?.attachments?.attachmentsTiming || "";
    filtered_teeth[0]["attachment_type"] = advanced_modules?.attachments?.attachment_type || "";
    filtered_teeth[0]["usePontics"] = advanced_modules?.pontics?.usePontics || false;
    filtered_teeth[0]["useTorqueEnhancers"] = advanced_modules?.torqueEnhancers?.useTorqueEnhancers || false;
    filtered_teeth[0]["use_buttons"] = advanced_modules?.elastics?.use_buttons || false;
    filtered_teeth[0]["use_cuts"] = advanced_modules?.elastics?.use_cuts || false;
    filtered_teeth[0]["note"] = "";
    return filtered_teeth[0]
  } else {
    return {
      Angulation: "",
      Circumference: "",
      ExtrusionIntrusion: "",
      ForwardBackward: "",
      Inclination: "",
      IprDistal: "",
      IprMesial: "",
      LeftRight: "",
      LongAxis: "",
      Rotation: "",
      use_extraction: advanced_modules?.extraction?.use_extraction || false,
      useAttachments: advanced_modules?.attachments?.useAttachments || false,
      attachments_step: advanced_modules?.attachments?.attachmentsTiming || "",
      attachment_type: advanced_modules?.attachments?.attachment_type || "",
      usePontics: advanced_modules?.pontics?.usePontics || false,
      useTorqueEnhancers: advanced_modules?.torqueEnhancers?.useTorqueEnhancers || false,
      use_buttons: advanced_modules?.elastics?.use_buttons || false,
      use_cuts: advanced_modules?.elastics?.use_cuts || false,
      ToothNumber: tooth,
      ToothName: tooth_name,
      updated: false,
      note: ""

    }
  }
}