import attachments from "./attachmentsParser";
import gums from "./gumsParser";
import teeth from "./teethParser";
import superImpose from "./superImposeParser";
import decompressor from "./decompressor";
import { resolvedData, checkAttachments } from "../TS-Helper";
const COLORTABLE_SIZE = 100;
const color_table = new Float32Array(COLORTABLE_SIZE * 3);

const loadAll = function (
  resolver,
  progressBar,
  colorTable,
  data,
  missingAndExtractedTeeth,
  scene,
  isAttachmentsFromParams,
  manifest,
  setProgress,
  setCountProgress,
  setTSOptions,
  hasSuperImpose
) {
  // console.log("data", resolver);
  let promises = [];
  let isAttchments = checkAttachments(isAttachmentsFromParams);

  if (isAttchments) {
    for (let a of data.attachments) {
      promises.push(() =>
        resolvedData(
          resolver(a.dataFile),
          {
            responseType: "arraybuffer",
          },
          setProgress,
          setCountProgress,
          setTSOptions
        )
          .then((response) => {
            // console.log("response", response.data);
            return response.data;
          })
          .then((data) => decompressor(data, colorTable))
          .then((buffer) =>
            attachments.parse(buffer, a.from, a.to, color_table, scene)
          )
      );
    }
  }
  for (let g of data.gums) {
    // console.log(resolver, resolver(g.dataFile));
    promises.push(() =>
      resolvedData(
        resolver(g.dataFile),
        {
          responseType: "arraybuffer",
        },
        setProgress,
        setCountProgress,
        setTSOptions
      )
        .then((response) => {
          // console.log("response", response.data);
          return response.data;
        })
        .then((data) => decompressor(data, colorTable))
        .then((buffer) => gums.parse(buffer, g.from, g.to, color_table, scene))
    );
  }

  for (let t of data.teeth) {
    promises.push(() =>
      resolvedData(
        resolver(t.dataFile),
        {
          responseType: "arraybuffer",
        },
        setProgress,
        setCountProgress,
        setTSOptions
      )
        .then((response) => response.data)
        .then((data) => decompressor(data, colorTable))
        .then((buffer) =>
          teeth.parse(
            buffer,
            t.from,
            t.to,
            missingAndExtractedTeeth,
            color_table,
            scene
          )
        )
    );
  }

  if (hasSuperImpose) {
    let superImposeFirstStep = data?.teeth[0];
    if (superImposeFirstStep) {
      promises.push(() =>
        resolvedData(
          resolver(superImposeFirstStep.dataFile),
          {
            responseType: "arraybuffer",
          },
          setProgress,
          setCountProgress
        )
          .then((response) => response.data)
          .then((data) => decompressor(data, colorTable))
          .then((buffer) =>
            superImpose.parse(
              buffer,
              superImposeFirstStep.from,
              superImposeFirstStep.to,
              missingAndExtractedTeeth,
              color_table,
              scene
            )
          )
      );
    }
  }

  // console.log("promises", promises)

  // return Promise.all(promises);
  return Promise.allConcurrent(10)(promises);
};

function promiseAllStepN(n, list) {
  let tail = list.splice(n);
  let head = list;
  let resolved = [];
  let processed = 0;
  return new Promise((resolve) => {
    head.forEach((x) => {
      let res = x();
      resolved.push(res);
      res.then((y) => {
        runNext();
        return y;
      });
    });
    function runNext() {
      if (processed === tail.length) {
        resolve(Promise.all(resolved));
      } else {
        resolved.push(
          tail[processed]().then((x) => {
            runNext();
            return x;
          })
        );
        processed++;
      }
    }
  });
}

Promise.allConcurrent = (n) => (list) => promiseAllStepN(n, list);

function fetchDataAndProcess(
  resolver,
  manifest,
  scene,
  setProgress,
  setCountProgress,
  setTSOptions,
  isAttachmentsFromParams,
  hasSuperImpose
) {
  //for future use
  let progressBar = {};

  let missingAndExtractedTeethLower = 0;
  let missingAndExtractedTeethUpper = 0;

  const arcPromises = [];
  //LOWER
  /*SavedShapeData*/
  let ml = manifest.lower;
  if (ml && ml.teeth.length && ml.gums.length) {
    progressBar.lower = true;
    arcPromises.push(() =>
      loadAll(
        resolver,
        progressBar,
        color_table,
        ml,
        missingAndExtractedTeethLower,
        scene.lower,
        isAttachmentsFromParams,
        manifest,
        setProgress,
        setCountProgress,
        setTSOptions,
        hasSuperImpose
      )
    );
  }

  //UPPER
  /* SavedShapeData*/
  let mu = manifest.upper;
  if (mu && mu.teeth.length && mu.gums.length) {
    progressBar.upper = true;
    //ProgressBars.Part progressBar = view.getProgessBars().getUpper();
    arcPromises.push(() =>
      loadAll(
        resolver,
        progressBar,
        color_table,
        mu,
        missingAndExtractedTeethUpper,
        scene.upper,
        isAttachmentsFromParams,
        manifest,
        setProgress,
        setCountProgress,
        setTSOptions,
        hasSuperImpose
      )
    );
  }

  return Promise.allConcurrent(3)(arcPromises);
}
export default fetchDataAndProcess;
