import React, { Component } from "react";

import MsgForm from "./MsgForm";
import MsgContent from "./MsgContent";
class Inbox extends Component {
  /*--------------------------------------------------------------------------------*/
  /* remove event listener whin leave component                                     */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    return (
      <div>
        <MsgContent formKey={this.props.formKey} />
        <MsgForm caseId={this.props.caseId} formKey={this.props.formKey} />
      </div>
    );
  }
}

export default Inbox;
